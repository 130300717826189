import {
  Device,
  Model,
  RegionService as Service,
} from '@/internal';

export default class Region extends Model {

  static get key() {
    return 'region';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      area: {
        preparePayload: (value) => {
          return value ? JSON.stringify(value) : null;
        },
      }
    };
  }

  get devices () {
    return this.hasMany(Device);
  }

}
