<template>
  <div id="tag-form" class="is-padded">

    <BLoading active v-if="loading"/>

    <Section title="Basics" open>

      <Field>
        <Label>Name *</Label>
        <TextInput v-model="form.fields.name" placeholder="Name"/>
        <FormError field="name" :form="form"/>
      </Field>

      <Field>
        <Label>Color *</Label>
        <ColorInputWithTextInput v-model="form.fields.color"/>
        <FormError field="color" :form="form"/>
      </Field>

    </Section>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>

          <Button @click="handleTagDelete" outlined danger :loading="deleting" v-if="tag">
            Delete
          </Button>

        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleTagSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>


    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import color from 'color';

import {
  Form,
} from '@/internal';
import {randomColor} from '@/helpers';

export default {
  name: 'Form',
  computed: {
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    tag() {
      return this.$route.params.tagUUID ? this.$store.getters['tag/findBy'](this.$route.params.tagUUID, 'uuid') : null;
    },
    pageTitle() {
      return this.$label('Tag');
    },
  },
  created() {

    this.domainUUID = window.app.storage.getItem('domainBeingManaged');

    if (this.$route.params.tagUUID) {
      this.loadTag();
    }
  },
  data() {
    return {
      deleting: false,
      domainUUID: null,
      form: new Form({
        name: '',
        color: randomColor(),
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      tagDestroy: 'tag/destroy',
      tagShow: 'tag/show',
      tagStore: 'tag/store',
      tagUpdate: 'tag/update',
    }),
    fillForm() {
      this.form.fields = {
        ...this.tag
      };
    },
    handleTagDelete() {
      this.$deleteDialog({
        target: this.tag.name,
        targetType: this.$label('Tag'),
        onConfirm: () => {
          this.loading = true;

          let payload = {
            id: this.tag.id,
            params: {}
          };

          if (this.domainUUID) {
            payload.params.domain = this.domainUUID;
          }

          this.tagDestroy(payload).then(() => {
            window.app.snackbar('Tag Deleted');

            let routeName = 'tag/index'

            if (this.domainUUID) {
              routeName = 'domain/tag/index';
            }

            this.$router.push({
              name: routeName,
            });

          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.loading = false;
          });
        },
      });
    },
    handleTagSave() {

      this.form.errors.clear();

      let action = null;

      if (this.tag) {
        action = this.tagUpdate;
      } else {
        action = this.tagStore;
      }

      let payload = {
        ...this.form.fields,
      }

      if (this.domainUUID) {
        payload.domain = this.domainUUID;
      }

      if (action) {

        this.loading = true;

        action(payload).then(() => {

          window.app.snackbar('Tag Saved');

          let routeName = 'tag/index'

          if (this.domainUUID) {
            routeName = 'domain/tag/index';
          }

          this.$router.push({
            name: routeName,
          });

        }).catch((error) => {
          this.form.recordErrors(error);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    loadTag() {
      this.loading = true;

      let payload = {
        id: this.$route.params.tagUUID,
        params: {}
      };

      if (this.domainUUID) {
        payload.params.domain = this.domainUUID;
      }

      this.tagShow(payload).then(this.fillForm).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>

<style scoped lang="scss">

</style>
