<template>
  <div id="domain-features" class="is-padded">

    <BLoading active v-if="loading"/>

    <template v-else>

      <Section :title="group" v-for="group in groups" open :key="group">
        <template v-for="feature in featuresAvailable" v-if="domain && system">
          <div class="has-margin-bottom-xs" v-if="form.fields.features[feature.key]" :key="feature.key">
            <BSwitch v-model="form.fields.features[feature.key].enabled" style="margin-right: 0;"
                     v-if="feature.group === group">
              <BTooltip :label="feature.description" multilined position="is-bottom">
                <b>{{ feature.title }}</b>
              </BTooltip>
            </BSwitch>
          </div>
        </template>
      </Section>

      <Teleport to="#inspector-footer">
        <Controls>
          <template v-slot:right>
            <Button primary @click="handleSave">
              Save
            </Button>
          </template>
        </Controls>
      </Teleport>

    </template>


  </div>
</template>

<script>
import _ from 'lodash';
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';
import {camelCase} from 'lodash';

export default {
  name: 'Features',
  computed: {
    domain() {
      return this.domainUUID ? this.$store.getters['domain/findBy'](this.domainUUID, 'uuid') : null;
    },
    groups() {
      return _.uniq(this.featuresAvailable.map(feature => feature.group)) || [];
    },
    featuresAvailable() {
      return this.system ? Object.values(this.system.featuresAvailable) : [];
    },
    system() {
      return this.$store.state.system || null;
    },
  },
  created() {
    this.domainUUID = window.app.storage.getItem('domainBeingManaged');
    this.loadDomain();
  },
  data() {
    return {
      domainUUID: null,
      form: new Form({
        features: {},
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      domainShow: 'domain/show',
      domainUpdate: 'domain/update',
    }),
    handleSave() {

      this.loading = true;
      this.form.errors.clear();

      this.domainUpdate({
        ...this.domain,
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar('Saved');
      }).catch((error) => {
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    fillForm() {

      if (!this.domain || !this.system) {
        return;
      }

      let features = {};

      Object.values(this.featuresAvailable).forEach((feature) => {

        const key = camelCase(feature.key);

        features[feature.key] = {
          enabled: this.domain.features && this.domain.features[key] && this.domain.features[key].enabled,
        };
      });

      this.form.fields = {
        ...this.form.fields,
        features,
      }
    },
    loadDomain() {

      if (!this.domainUUID) {
        console.error('No domainUUID');
        return;
      }

      this.loading = true;

      return this.domainShow({
        id: this.domainUUID,
      }).then(() => {
        this.fillForm();
      }).catch((e) => {
        console.error(e);
        window.app.snackbar('Error loading Domain');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
  watch: {
    domain: {
      deep: true,
      immediate: true,
      handler() {
        this.fillForm();
      },
    },
    system: {
      deep: true,
      immediate: true,
      handler() {
        this.fillForm();
      },
    },
  }
}
</script>

<style scoped lang="scss">

</style>
