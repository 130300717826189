import {
  ResourceService,
} from '@/internal';

export default class UplinkImportService extends ResourceService {

  constructor() {
    super();
    this.baseUrl = 'uplink-import';
  }

  headers(payload = {}) {

    const {params, formData, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `${this.baseUrl}/headers`,
      params: this.prepareParams(params),
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  store(payload = {}) {

    const {params, formData, ...rest} = payload;

    return this.request({
      method: 'post',
      url: this.baseUrl,
      params: this.prepareParams(params),
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  //
  // delete(id, payload = {}) {
  //
  //   const { params, ...rest } = payload;
  //
  //   return this.request({
  //     method: 'delete',
  //     url: `/uplink/import/${id}`,
  //     params: this.prepareParams(params),
  //     data: this.preparePayload(rest)
  //   });
  // }

}
