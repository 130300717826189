<template>
  <div class="actions-form">

    <div class="resource-list">
      <template v-for="(action, i) in localValue">
        <div class="resource-list-item is-flex is-justify-content-space-between" :class="{ 'is-dark': $themeDark }">
          <div class="left">
            <ActionLabel :action="action"/>
          </div>
          <div class="right is-flex">
            <div class="condition-edit pointer" @click="handleActionEdit(i)">
              <BIcon icon="pencil"/>
            </div>
            <div class="condition-remove pointer has-margin-left-xs" @click="handleActionDelete(i)">
              <BIcon icon="times-circle"/>
            </div>
          </div>
        </div>
      </template>
    </div>

    <BModal :active.sync="modalActive">
      <div class="container container-extra-extra-small">
        <Box flat :black="$themeDark">

          <Title>Action</Title>

          <Field>
            <Label>Type *</Label>
            <SelectInput v-model="form.fields.type" :options="types"/>
          </Field>

          <template v-if="form.fields.type === 'App\\Actions\\Notification'">

            <Field>
              <Label>Channel *</Label>
              <SelectInput v-model="form.fields.config.channel" :options="notificationChannelOptions"/>
            </Field>

            <template v-if="form.fields.config.channel === 'mail'">

              <Field>
                <Label>To *</Label>

                <BTaginput v-model="form.fields.config.to"
                           icon="envelope"
                           placeholder="Add an email"
                           :class="{ 'is-dark': $themeDark }"
                />

                <template v-for="user in accountUsers">
                  <div class="has-margin-top-xs is-flex" v-if="!form.fields.config.to.includes(user.email)">
                    <div class="left is-flex-grow-1">
                      {{ user.fullName }} ({{ user.email }})
                    </div>
                    <div class="right has-text-right pointer" @click="handleEmailAdd(user.email)">
                      <BIcon icon="circle-plus"/>
                    </div>
                  </div>
                </template>

              </Field>

            </template>

            <template v-if="form.fields.config.channel === 'sms'">

              <Field>
                <Label>To *</Label>

                <BTaginput v-model="form.fields.config.smsNumbers"
                           icon="phone"
                           placeholder="Add a cell phone (US Only)"
                           :class="{ 'is-dark': $themeDark }"
                           @input="validateSmsNumbers"
                />

                <Help>* Only available for US based phone numbers</Help>

              </Field>

            </template>

            <template v-if="form.fields.config.channel === 'slack'">

              <Field>
                <Label>Slack Webhook URL *</Label>
                <UrlInput v-model="form.fields.config.slackWebhookUrl"/>
              </Field>

            </template>

          </template>

          <template v-if="form.fields.type === 'App\\Actions\\Integration'">

            <Field>
              <Label>Integration *</Label>
              <ResourceSelect :resource="resource"
                              placeholder="Search for an Integration"
                              v-model="form.fields.config.integrationId"
                              clearable
                              v-if="resource"
              />
            </Field>

          </template>

          <div class="has-margin-top has-text-right">
            <Button @click="handleActionCancel" outlined :light="$themeDark" :dark="$themeLight">
              Cancel
            </Button>
            <Button @click="handleActionSave" primary class="has-margin-left">
              Save
            </Button>
          </div>

        </Box>
      </div>
    </BModal>

    <div class="has-text-right has-margin-top">
      <Button outlined :light="$themeDark" @click="handleActionAdd">
        Add Action
      </Button>
    </div>

  </div>
</template>

<script>
import {
  Form,
  Integration,
} from '@/internal';

import _ from 'lodash';

import ActionLabel from '@/components-V2/ActionLabel/ActionLabel.vue';
import {phone} from 'phone';

export default {
  name: 'ActionsForm',
  components: {
    ActionLabel
  },
  computed: {
    account() {
      return this.user && this.user.account ? this.$store.getters['account/findBy'](this.user.account.uuid, 'uuid') : null;
    },
    accountUsers() {
      return this.account ? this.account.users : [];
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  data() {
    return {
      form: new Form({
        type: null,
        config: {},
      }),
      localValue: [],
      manuallyInputtingEmail: false,
      manuallyInputEmail: '',
      modalActive: false,
      notificationChannelOptions: [
        {
          label: '- Select Notification Type -',
          value: null,
        },
        {
          label: 'Email',
          value: 'mail',
        },
        {
          label: 'Slack',
          value: 'slack',
        },
        {
          label: 'SMS (separate charges may apply)',
          value: 'sms',
        },
      ],
      resource: Integration,
      types: [
        {
          label: '- Select a Type -',
          value: null,
        },
        {
          icon: 'bell-on',
          label: 'Send Notification',
          value: 'App\\Actions\\Notification',
        },
        {
          icon: 'webhook',
          label: 'Trigger Integration',
          value: 'App\\Actions\\Integration',
        },
        // {
        //   icon: 'gear',
        //   label: 'Update Device',
        //   value: 'device_update',
        // },
      ],
    };
  },
  methods: {
    formReset() {

      // Not sure white this.form.reset() isn't working

      this.form.fields = {
        type: null,
        config: {
          to: [],
          slackWebhookUrl: null,
          smsNumbers: [],
        },
      };
    },
    handleActionAdd() {
      this.formReset();
      this.modalActive = true;
    },
    handleActionCancel() {
      this.modalActive = false;
      this.formReset();
    },
    handleActionDelete(index) {
      this.localValue = [
        ...this.localValue.filter((action, i) => i !== index),
      ];
    },
    handleActionEdit(index) {
      let action = this.localValue[index];

      let payload = {
        editing: index,
        type: action.type,
        config: {
          ...action.config,
        },
      };

      if (action.id) {
        payload.id = action.id;
      }

      if (action.config.to) {
        payload.config.to = [
          ..._.uniq([
            ...payload.config.to,
            ...action.config.to,
          ]),
        ];
      }

      if (action.config.slackWebhookUrl) {
        payload.config.slackWebhookUrl = action.config.slackWebhookUrl;
      }

      if (action.config.smsNumbers) {
        payload.config.smsNumbers =[
          ..._.uniq([
            ...payload.config.smsNumbers,
            ...action.config.smsNumbers,
          ]),
        ];
      }

      this.form.fields = {
        ...payload,
      };

      this.form.fields.config = {
        ...payload.config,
      }

      this.modalActive = true;
    },
    handleActionSave() {

      if (this.form.fields.editing !== undefined) {
        this.localValue = [
          ...this.localValue.map(((condition, i) => this.form.fields.editing === i ? this.form.fields : condition)),
        ];
      } else {
        this.localValue = [
          ...this.localValue,
          {
            ...this.form.fields,
          },
        ];
      }

      this.form.reset();

      this.modalActive = false;
    },
    handleEmailAdd(email) {
      this.form.fields.config.to = [
        ..._.uniq([
          ...this.form.fields.config.to,
          email,
        ]),
      ];
    },
    notificationMethodLabel(channel) {
      return this.notificationChannelOptions.find(option => option.value === channel);
    },
    validateSmsNumbers() {

      const validNumbers = this.form.fields.config.smsNumbers.filter((smsNumber) => {

        const valid = phone(smsNumber, {country: 'USA'}).isValid;

        if (!valid) {
          window.app.snackbar({
            message: 'Phone Number Invalid',
            type: 'is-danger',
          });
        }

        return valid;

      }).map((smsNumber) => {
        return phone(smsNumber, {country: 'USA'}).phoneNumber;
      });

      this.form.fields.config.smsNumbers = [
        ..._.uniq(validNumbers),
      ];
    },
  },
  props: {
    ruleForm: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  watch: {
    localValue: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
          this.$emit('input', this.localValue);
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
          this.localValue = [
            ...this.value,
          ];
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">

</style>
