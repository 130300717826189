// Domain
import Analytics from '@/views/Domain/Analytics';
import Billing from '@/views/Domain/Billing';
import Branding from '@/views/Domain/Branding';
import Configuration from '@/views/Domain/Configuration';
import Features from '@/views/Domain/Features';
import Picker from '@/views/Domain/Picker';
import Profile from '@/views/Domain/Profile';

// Accounts
import Index from '@/views/Domain/Account/Index';
import Show from '@/views/Domain/Account/Show';
import Mail from '@/views/Domain/Mail';

// API Keys
import ApiKeyIndex from '@/views/ApiKey/Index';
import ApiKeyForm from '@/views/ApiKey/Form';

// Tags

import TagIndex from '@/views/Tag/Index';
import TagForm from '@/views/Tag/Form';

// Products

import ProductDefinitions from '@/views/Domain/Product/Definitions.vue';
import ProductInventory from '@/views/Domain/Product/Inventory';

const routes = [
  {
    name: 'domain/analytics',
    path: '/domain/analytics',
    component: Analytics,
    meta: {
      containerSize: 'container-extra-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'display-chart-up',
      title: 'Analytics',
    }
  },
  {
    name: 'domain/billing',
    path: '/domain/billing',
    component: Billing,
    meta: {
      containerSize: 'container-small',
      fullScreen: true,
      hideMap: true,
      icon: 'credit-card',
      title: 'Billing',
    }
  },
  {
    name: 'domain/branding',
    path: '/domain/branding',
    component: Branding,
    meta: {
      containerSize: 'container-extra-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'palette',
      title: 'Branding',
    }
  },
  {
    name: 'domain/configuration',
    path: '/domain/configuration',
    component: Configuration,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'globe',
      title: 'URL\'s & DNS',
    }
  },
  {
    name: 'domain/features',
    path: '/domain/features',
    component: Features,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'toggle-on',
      title: 'Features',
    }
  },
  {
    name: 'domain/mail',
    path: '/domain/mail',
    component: Mail,
    meta: {
      containerSize: 'container-extra-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'envelope',
      title: 'Mail',
    }
  },
  {
    name: 'domain/picker',
    path: '/domain/picker',
    component: Picker,
    meta: {
      containerSize: 'container-extra-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'shuffle',
      title: 'Switch',
    }
  },
  {
    name: 'domain/profile',
    path: '/domain/profile',
    component: Profile,
    meta: {
      containerSize: 'container-extra-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'user-circle',
      title: 'Profile',
    }
  },
  {
    name: 'domain/account/index',
    path: '/domain/account',
    component: Index,
    meta: {
      containerSize: 'container-small',
      fullScreen: true,
      hideMap: true,
      icon: 'buildings',
      title: 'Accounts',
    },
  },
  {
    name: 'domain/account/show',
    path: '/domain/account/:accountUUID',
    component: Show,
    meta: {
      containerSize: 'container-small',
      fullScreen: true,
      hideMap: true,
      icon: 'building',
      title: 'Account',
    },
  },

  // API Keys

  {
    name: 'domain/api-key/index',
    path: '/domain/api-key',
    component: ApiKeyIndex,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'key',
      title: 'API Keys',
    },
  },
  {
    name: 'domain/api-key/create',
    path: '/domain/api-key/create',
    component: ApiKeyForm,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'key',
      title: 'New API key',
    },
  },
  {
    name: 'domain/api-key/edit',
    path: '/domain/api-key/:apiKeyUUID/edit',
    component: ApiKeyForm,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('domain/api-key/index');
      },
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'key',
      title: 'Edit API Key',
    },
  },

  // Tags

  {
    name: 'domain/tag/index',
    path: '/domain/tag',
    component: TagIndex,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'tags',
      title: 'Tags',
    },
  },
  {
    name: 'domain/tag/create',
    path: '/domain/tag/create',
    component: TagForm,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'tags',
      title: 'New Tag',
    },
  },
  {
    name: 'domain/tag/edit',
    path: '/domain/tag/:tagUUID/edit',
    component: TagForm,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('domain/tag/index');
      },
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'tags',
      title: 'Edit Tag',
    },
  },

  // Products

  {
    name: 'domain/product/definition',
    path: '/domain/product/definition',
    component: ProductDefinitions,
    meta: {
      // backRoute: () => {
      //   return window.app.findRouteByName('domain/tag/index');
      // },
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'gears',
      title: 'Definitions',
    },
  },
  {
    name: 'domain/product/inventory',
    path: '/domain/product/inventory',
    component: ProductInventory,
    meta: {
      // backRoute: () => {
      //   return window.app.findRouteByName('domain/tag/index');
      // },
      containerSize: null,
      fullScreen: true,
      hideMap: true,
      icon: 'tally',
      title: 'Inventory',
    },
  },
];

export default routes;
