import {
  Model,
} from '@/internal';

import Notification from '../Notification/Notification';
import NotificationChannelService from './NotificationChannelService';

export default class NotificationChannel extends Model {

  static get key() {
    return 'notificationChannel';
  }

  static get service() {
    return NotificationChannelService;
  }

  settings() {
    return this.hasMany(Notification);
  }
}
