import SubscriptionService from './SubscriptionService';

const service = new SubscriptionService();
const stripe = process.env.VUE_APP_STRIPE_PUBLIC_KEY ? new window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY) : null

export default {
  key: 'subscription',
  namespaced: true,
  state: {},
  actions: {
    start({}, payload = {}) {
      return service.start(payload).then((response) => {

        const sessionId = response && response.data && response.data.data ? response.data.data.session.id : null;

        if (sessionId) {
          return stripe.redirectToCheckout({
            sessionId,
          });
        }

      });
    },
    manage({}, payload = {}) {
      return service.manage(payload).then((response) => {

        const url = response && response.data && response.data.data && response.data.data.session ? response.data.data.session.url : null;

        if (url) {
          window.location.replace(url);
        } else {
          window.app.snackbar({
            message: 'Error: Unable to manage subscription',
            type: 'is-danger',
          })
        }

      });
    },
  },
  mutations: {

  },
};
