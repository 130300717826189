<template>
  <div class="resource-list">

    <BLoading active v-if="loading" :is-full-page="false"/>

    <ResourceListHeader v-model="header"
                        @search="handleSearch"
                        :addLabel="addLabel"
                        :addRoute="addRoute"
                        :sortByOptions="sortByOptions"
                        :searchable="searchable"
    />

    <template v-for="resource in collection">
      <component :is="renderAs"
                 :resource="resource"
                 :openInModal="openInModal"
      />
    </template>

    <ResourceListPaginator :paginator="paginator"
                           @previous="handlePagePrevious"
                           @next="handlePageNext"
    />

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import ResourceListHeader from '@/components-V2/ResourceList/ResourceListHeader';
import ResourceListPaginator from '@/components-V2/ResourceList/ResourceListPaginator';

import ResourceListItem from '@/components-V2/ResourceList/ResourceListItem';

export default {
  name: 'ResourceList',
  components: {
    ResourceListHeader,
    ResourceListPaginator,
  },
  computed: {
    collection() {
      return this.resource.vuexModuleKey ? this.$store.getters[`${this.resource.vuexModuleKey}/collection`](this.ids) : [];
    },
    indexAction() {
      return this.resource.vuexModuleKey ? `${this.resource.vuexModuleKey}/index` : null;
    },
  },
  created() {
    this.header.settings = {
      ...this.header.settings,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
    };

    // loadData called from watcher
  },
  data() {
    return {
      ids: [],
      loading: false,
      paginator: null,
      header: {
        search: '',
        settings: {
          sortBy: 'name',
          sortOrder: 'asc',
        },
      },
    };
  },
  methods: {
    ...mapActions({}),
    loadData(page = null, inBackground = false) {

      if (!this.indexAction) {
        console.error('No indexAction');
        return false;
      }

      let params = {
        page,
        perPage: 10,
        search: this.header.search,
        ...this.params,
        ...this.query,
      };

      if (this.header.settings.sortBy) {
        params.sortBy = this.header.settings.sortBy;
        params.sortOrder = this.header.settings.sortOrder;
      }

      this.loading = !inBackground;
      return this.$store.dispatch(this.indexAction, {
        params,
      }).then(({ids, paginator}) => {
        this.ids = ids;
        this.paginator = paginator;
      }).finally(() => {
        this.loading = false;
      });
    },
    handlePageNext() {
      return this.loadData(this.paginator.currentPage + 1);
    },
    handlePagePrevious() {
      return this.loadData(this.paginator.currentPage - 1);
    },
    handleSearch() {
      this.loadData();
    },
  },
  props: {
    addLabel: {
      default: null,
      required: false,
      type: String,
    },
    addRoute: {
      required: false,
    },
    openInModal: {
      default: false,
      type: Boolean,
    },
    params: {
      default: () => {
      },
      required: false,
    },
    query: {
      default: () => {
      },
      required: false,
    },
    renderAs: {
      default: () => ResourceListItem,
      required: false,
    },
    resource: {
      required: true,
    },
    searchable: {
      default: true,
      required: false,
      type: Boolean,
    },
    sortBy: {
      default: 'name',
      required: false,
    },
    sortByOptions: {
      default: () => [],
      required: false,
      type: Array,
    },
    sortOrder: {
      default: 'asc',
      required: false,
    },
  },
  watch: {
    ['header.settings']: {
      deep: true,
      immediate: false,
      handler() {
        this.loadData();
      },
    }
  },
}
</script>

<style scoped lang="scss">
.resource-list {

}
</style>
