<template>
  <div class="resource-select">
    <BAutocomplete :class="{ 'is-dark': $themeDark }"
                   v-model="search"
                   :loading="loading"
                   :placeholder="placeholder"
                   field="label"
                   :data="collection"
                   :disabled="disabled"
                   @input="handleInput"
                   @select="handleSelect"
                   :clearable="clearable"
    >
      <template #empty>No results found</template>
    </BAutocomplete>
  </div>
</template>

<script>
import {debounce} from 'lodash';

export default {
  name: 'ResourceSelect',
  computed: {
    collection() {
      return this.resource.vuexModuleKey ? this.$store.getters[`${this.resource.vuexModuleKey}/collection`](this.ids).map((resource) => {
        return {
          id: resource[this.resourceId],
          label: resource[this.resourceLabel],
        }
      }) : [];
    },
    indexAction() {
      return this.resource.vuexModuleKey ? `${this.resource.vuexModuleKey}/index` : null;
    },
    showAction() {
      return this.resource.vuexModuleKey ? `${this.resource.vuexModuleKey}/show` : null;
    },
  },
  data() {
    return {
      ids: [],
      loading: false,
      paginator: null,
      search: '',
      performSearch: debounce(() => {
        this.loading = true;

        let params = {
          perPage: 10,
          search: this.search,
          ...this.params,
          ...this.query,
        };

        return this.$store.dispatch(this.indexAction, {
          params,
        }).then(({ids, paginator}) => {
          this.ids = ids;
          this.paginator = paginator;
        }).finally(() => {
          this.loading = false;
        });

      }, 500),
    };
  },
  methods: {
    handleInput() {
      if (this.search.length > 1) {
        this.performSearch();
      }
    },
    handleSelect(option) {
      this.$emit('input', option.id);
    },
  },
  props: {
    clearable: {
      required: false,
      type: Boolean,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    params: {
      default: () => {
      },
      required: false,
    },
    placeholder: {
      default: '',
      required: false,
      type: String,
    },
    query: {
      default: () => {
      },
      required: false,
    },
    resource: {
      required: true,
    },
    resourceId: {
      default: 'id',
      required: false,
    },
    resourceLabel: {
      default: 'name',
      required: false,
    },
    value: {
      required: true,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value && !this.search) {
          const resource = this.$store.getters[this.showAction](this.value);
          this.search = resource[this.resourceLabel];
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
.resource-select {

}
</style>
