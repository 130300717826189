<template>
  <div class="s1-wired-soil-sensor">

    <div class="is-flex is-justify-content-space-between has-margin-bottom">
      <div class="left is-flex is-align-content-space-between">
        <div class="device-name">
          <StatusDot :device="device"/>
          <span class="has-margin-left-xs">{{ device.name }} [{{device.lastFourOfDeviceEui}}]</span>
        </div>
      </div>
      <div class="right has-text-right is-flex is-justify-content-space-between">

        <template v-if="batteryParameter && batteryParameter.latestParameterValue">
          <BTooltip :label="batteryParameter.latestParameterValue.formattedValue" position="is-bottom">
              <span class="battery has-margin-left-xs">
                <BIcon pack="fa-duotone" :icon="batteryIcon(batteryParameter)" style="font-size: 1.3em;"/>
              </span>
          </BTooltip>
        </template>

        <template v-if="rssiParameter && rssiParameter.latestParameterValue">
          <BTooltip :label="rssiParameter.latestParameterValue.formattedValue" position="is-bottom">
              <span class="rssi has-margin-left-xs">
                <BIcon pack="fa-duotone" :icon="rssiIcon(rssiParameter)"/>
              </span>
          </BTooltip>
        </template>

        <div class="tag device-edit pointer has-margin-left-xs" @click="handleDeviceEdit(device)">
          <BIcon icon="wrench" style="margin-right: 2.5px;"/>
          Manage
        </div>
      </div>
    </div>

    <div class="has-text-centered moisture">
      <Subtitle>Soil Moisture</Subtitle>
      <Title>
        {{parameterValue(moistureParameter)}}
      </Title>
      <Subtitle class="parameter-value-created-at">
        {{ parameterValueCreatedAt(moistureParameter) }}
      </Subtitle>
    </div>

    <div class="columns is-mobile has-margin-vertical">
      <div class="column has-text-centered" v-if="temperatureParameter">
        <Title>{{parameterValue(temperatureParameter)}}</Title>
        <Subtitle class="parameter-value-created-at">Soil Temperature</Subtitle>
      </div>
      <div class="column has-text-centered" v-if="ecParameter">
        <Title>{{parameterValue(ecParameter)}}</Title>
        <Subtitle class="parameter-value-created-at">Soil Conductivity (EC)</Subtitle>
      </div>
    </div>

    <Section title="Charts">
      <DeviceCharts :device="device"/>
    </Section>

    <Section title="Uplinks">
      <UplinkResourceList :query="{deviceId: device.id}"
                          openInModal
      />
    </Section>

  </div>
</template>

<script>
import StatusDot from '@/components-V2/Device/StatusDot.vue';
import DeviceCharts from '@/components-V2/Device/Charts.vue';
import UplinkResourceList from '@/components-V2/Uplink/ResourceList.vue';

export default {
  name: 'S1WiredSoilSensor',
  components: {
    DeviceCharts,
    StatusDot,
    UplinkResourceList,
  },
  computed: {
    batteryParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.title === 'Battery') : null;
    },
    ecParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.title === 'Soil Conductivity (EC)') : null;
    },
    moistureParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.title === 'Soil Moisture') : null;
    },
    parameters() {
      return this.device.parameters || [];
    },
    rssiParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.title === 'RSSI') : null;
    },
    temperatureParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.title === 'Soil Temperature') : null;
    },
  },
  methods: {
    batteryIcon(batteryParameter) {

      let batteryIcon = 'battery';
      let batteryPercentage = batteryParameter.latestParameterValue.value;

      if (batteryPercentage > 90) {
        batteryIcon = 'battery-full';
      } else if (batteryPercentage > 75) {
        batteryIcon = 'battery-three-quarters';
      } else if (batteryPercentage > 50) {
        batteryIcon = 'battery-half';
      } else if (batteryPercentage > 25) {
        batteryIcon = 'battery-quarter';
      } else {
        batteryIcon = 'battery-low';
      }

      return batteryIcon;
    },
    handleDeviceEdit(device) {
      this.$router.push({
        name: 'device/edit',
        params: {
          deviceUUID: device.uuid,
        },
      });
    },
    parameterValue(parameter) {
      if (parameter && parameter.latestParameterValue) {
        return parameter.latestParameterValue.formattedValue;
      } else {
        return 'No Data Yet';
      }
    },
    parameterValueCreatedAt(parameter) {

      let createdAt = null;

      if (parameter && parameter.latestParameterValue) {
        createdAt = parameter.latestParameterValue.createdAt;
      }

      if (createdAt) {
        return createdAt.format('MMM Do ・ h:mm a');
      }

      return '';
    },
    rssiIcon(rssiParameter) {

      let rssiIcon = 'wifi';
      let rssiPercentage = rssiParameter.latestParameterValue.value;

      if (rssiPercentage > -90) {
        rssiIcon = 'wifi';
      } else if (rssiPercentage > -115) {
        rssiIcon = 'wifi-fair';
      } else {
        rssiIcon = 'wifi-weak';
      }

      return rssiIcon;
    },
  },
  props: {
    device: {
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.s1-wired-soil-sensor {
  .device-name {
    font-size: 18px;
  }

  .device-edit {

  }

  .moisture {
    margin: 30px 0 15px;

    .title {
      font-size: 5em;
    }
  }

  .parameter-value-created-at {
    font-size: 0.8em;
  }

  .chart-wrapper {
    border-radius: 0 0 6px 6px;
    overflow: hidden;
  }
}
</style>
