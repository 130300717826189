<template>
  <router-link :to="`/domain/account/${account.uuid}`" class="account resource-list-item" :class="{ 'is-dark': $themeDark }">
    {{account.name}}
  </router-link>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    account() {
      return this.resource;
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
