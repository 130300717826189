<template>
  <div id="support-faqs">
    <Title>FAQ's</Title>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: `FAQ's`,
  computed: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {},
}
</script>

<style scoped lang="scss">
#support-faqs {

}
</style>
