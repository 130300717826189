import Index from '@/views/Region/Index';
import Show from '@/views/Region/Show';
import Form from '@/views/Region/Form';

const routes = [
  {
    name: 'region/index',
    path: '/region',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'vector-square',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? window.app.vue.$label('Regions', true) : 'Regions';
      },
    },
  },
  {
    name: 'region/create',
    path: '/region/create',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('region/index');
      },
      containerSize: 'container-extra-small',
      icon: 'vector-square',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? `New ${window.app.vue.$label('Region', false)}` : 'New Region';
      },
    },
  },
  {
    name: 'region/show',
    path: '/region/:regionUUID',
    component: Show,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('region/index');
      },
      containerSize: 'container-medium',
      icon: 'vector-square',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? window.app.vue.$label('Region', false) : 'Region';
      },
      editRoute: (route) => {
        return window.app.findRouteByName('region/edit', {
          regionUUID: route.params.regionUUID,
        });
      },
    },
  },
  {
    name: 'region/edit',
    path: '/region/:regionUUID/edit',
    component: Form,
    meta: {
      backRoute: (route) => {
        return window.app.findRouteByName('region/show', {
          regionUUID: route.params.regionUUID,
        });
      },
      containerSize: 'container-extra-small',
      icon: 'vector-square',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? `Edit ${window.app.vue.$label('Region', false)}` : 'Edit Region';
      },
    },
  },
];

export default routes;
