<template>
  <div class="uplink">

    <LabelLineValue label="Inspect Uplink" :to="`/uplink/${uplink.uuid}`"
                    v-if="allowLink && $route.name !== 'uplink/show' && $route.name !== 'uplink/edit'"
    />

    <LabelLineValue label="Device" :value="device.name" :to="`/device/${device.uuid}`"
                    v-if="device && $route.name !== 'device/show'"/>

    <LabelLineValue label="Received" :value="uplink.createdAt.format(system.settings.datetimeFormat)"/>

    <LabelLineValue label="Raw">
      <JSON :data="raw"/>
    </LabelLineValue>

    <LabelLineValue label="Parsed Parameters" expanded>
      <div class="parameters">
        <ParameterValues :uplink="uplink"/>
      </div>
    </LabelLineValue>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import ParameterValues from '@/components-V2/Uplink/ParameterValues';

export default {
  name: 'Preview',
  components: {
    ParameterValues,
  },
  computed: {
    device() {
      return this.uplink ? this.$store.getters['device/show'](this.uplink.deviceId) : null;
    },
    system() {
      return this.$store.state.system;
    },
    user() {
      return this.$store.getters['auth/user'] || null;
    },
  },
  created() {
    this.loadUplinkRaw();
  },
  data() {
    return {
      raw: null,
    };
  },
  methods: {
    ...mapActions({
      uplinkRaw: 'uplink/raw',
    }),
    loadUplinkRaw() {
      this.uplinkRaw({
        id: this.uplink.id,
      }).then((response) => {
        this.raw = response.data;
      });
    },
  },
  props: {
    allowLink: {
      default: true,
      type: Boolean,
    },
    uplink: {
      required: true,
    },
  },
  watch: {
    uplink: {
      deep: true,
      handler() {
        this.loadUplinkRaw();
      },
    }
  },
}
</script>

<style scoped lang="scss">
.uplink {
  .parameters {
    margin-top: 30px;
  }
}
</style>
