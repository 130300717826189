<template>
  <div id="inspector-container"
       :class="{
          'has-footer-content': hasFooterContent,
          [`is-${inspector.position}`]: !isFullScreen,
          'is-anchored': $layoutAnchored || isMobile,
          'is-dark': $themeDark,
          'is-floating': $layoutFloating && !isMobile,
          'is-full-screen': isFullScreen,
          'is-mobile': isMobile,
          'is-standalone': $isStandalone,
          'primary-menu-is-active': primaryMenu.active,
       }"
       :style="{
         height: isPositionBottom ? `${height}px` : 'auto',
         width: isPositionRight ? `${width}px` : 'auto',
        }"
  >

    <div class="resizable-handle resizable-handle-height" v-if="isPositionBottom && !isMobile">
      <div class="resizable-handle-line resizable-handle-height-line"></div>
    </div>

    <div class="resizable-handle resizable-handle-width" v-if="isPositionRight && !isMobile">
      <div class="resizable-handle-line resizable-handle-width-line"></div>
    </div>

    <InspectorControls v-if="!hideControls" />

    <div id="inspector-wrapper" v-if="inspectorFooterMounted">
      <div id="inspector">
        <div id="inspector-content" :class="`${container}`">
          <slot/>
        </div>
      </div>
    </div>

    <InspectorFooter @mounted="inspectorFooterMounted = true"/>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import interact from 'interactjs';

import InspectorControls from '@/components-V2/Inspector/InspectorControls';
import InspectorSettings from '@/components-V2/Inspector/InspectorSettings';

const minHeight = window.innerHeight * 0.15;
const maxHeight = window.innerHeight * 0.90;

const minWidth = window.innerWidth * 0.25;
const maxWidth = window.innerWidth * 0.75;

export default {
  name: 'Inspector',
  components: {
    InspectorControls,
    InspectorSettings,
  },
  computed: {
    container() {
      return this.$route.meta.containerSize ? `container ${this.$route.meta.containerSize}` : null;
    },
    hasFooterContent() {
      return this.inspector && this.inspector.hasFooterContent;
    },
    inspector() {
      return this.$store.state.ui.inspector;
    },
    isFullScreen() {
      return this.$route.meta.fullScreen || this.fullScreen || this.inspector.fullScreen;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    isPositionBottom() {
      return !this.isFullScreen && this.inspector.position === 'bottom';
    },
    isPositionRight() {
      return !this.isFullScreen && this.inspector.position === 'right';
    },
    primaryMenu() {
      return this.$store.state.ui.primaryMenu;
    },
  },
  created() {

    interact('#inspector-container').resizable({
      edges: {
        top: '.resizable-handle-height',
        left: '.resizable-handle-width',
        bottom: false,
        right: false
      },
      listeners: {
        move: (event) => {

          if (this.inspector.position === 'bottom') {
            this.setHeight(this.height - event.delta.y);
          } else if (this.inspector.position === 'right') {
            this.setWidth(this.width - event.delta.x);
          }
        },
      },
    }).on(['resizeend'], () => {
      this.handleInspectorUpdate({
        height: this.height,
        width: this.width,
      });
    });

    this.height = this.inspector.height || minHeight;
    this.width = this.inspector.width || minWidth;
  },
  data() {
    return {
      inspectorFooterMounted: false,
      height: minHeight,
      width: minWidth,
    }
  },
  methods: {
    ...mapActions({
      inspectorUpdate: 'ui/inspector',
    }),
    setHeight(height) {
      if (height <= maxHeight && height >= minHeight) {
        this.height = height;
      }
    },
    handleInspectorBack() {
      this.$router.back();
    },
    handleInspectorClose() {
      this.$router.push('/');
    },
    setWidth(width) {
      if (width <= maxWidth && width >= minWidth) {
        this.width = width;
      }
    },
    handleInspectorUpdate(payload) {
      this.inspectorUpdate({
        ...payload,
      });
    },
  },
  props: {
    fullScreen: {
      default: false,
      required: false,
      type: Boolean,
    },
    hideControls: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
#inspector-container {
  background: $theme-light-background-color;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: $zindex-inspector;

  &.is-mobile {
    border: none !important;
    bottom: 70px !important;
    height: auto !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    width: auto !important;

    &.is-standalone {
      bottom: 80px !important;
    }
  }

  &.is-dark {
    background: $theme-dark-background-color;

    .resizable-handle {
      background: $theme-dark-background-color;
      border: 1px solid $navigation-border-color;

      .resizable-handle-line {
        border: 1px solid $navigation-border-color;
      }
    }
  }

  &.is-anchored {
    border-left: 1px solid $navigation-border-color;

    &.is-full-screen {
      border-left: 0;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;

      &.primary-menu-is-active {
        left: $primary-menu-width-active;
      }
    }

    &.is-right {
      bottom: 0;
      right: 0;
      top: 0;
    }

    &.is-bottom {
      border-left: 0;
      border-top: 1px solid $navigation-border-color;
      bottom: 0;
      left: 0;
      right: 0;
      top: auto;

      &.primary-menu-is-active {
        left: $primary-menu-width-active;
      }
    }
  }

  &.is-floating {
    border-radius: $layout-floating-border-radius;

    &.is-full-screen {
      bottom: $layout-floating-spacing;
      left: $primary-menu-width-active + ($layout-floating-spacing * 2);
      right: $layout-floating-spacing;
      top: $layout-floating-spacing;
    }

    &.is-right {
      bottom: $layout-floating-spacing;
      right: $layout-floating-spacing;
      top: $layout-floating-spacing;
    }

    &.is-bottom {
      bottom: $layout-floating-spacing;
      left: $layout-floating-spacing;
      right: $layout-floating-spacing;
      top: auto;

      &.primary-menu-is-active {
        left: $primary-menu-width-active + ($layout-floating-spacing * 2);
      }
    }
  }

  $resizableHandleDim1: 12px;
  $resizableHandleDim2: 40px;

  .resizable-handle {
    align-items: center;
    background: $grey-lighter;
    border-radius: 6px;
    border: 1px solid $navigation-border-color;
    display: flex;
    justify-content: center;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: $zindex-inspector + 1;

    .resizable-handle-line {
      background: $navigation-border-color;
    }
  }

  .resizable-handle-height {
    cursor: ns-resize;
    height: $resizableHandleDim1;
    left: 50%;
    top: 0;
    width: $resizableHandleDim2;

    .resizable-handle-height-line {
      height: 1px;
      width: 70%;
    }
  }

  .resizable-handle-width {
    cursor: ew-resize;
    height: $resizableHandleDim2;
    left: 0;
    top: 50%;
    width: $resizableHandleDim1;

    .resizable-handle-width-line {
      height: 70%;
      width: 1px;
    }
  }

  #inspector-wrapper {
    flex: 1;
    overflow: hidden;

    #inspector-content {
      height: 100%;
    }
  }

  &.has-footer-content {
    #inspector-wrapper {
      padding-bottom: 75px;
    }
  }

  #inspector {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
}
</style>
