<template>
  <div class="condition-label">
    <span class="tag">{{ parentLabel }}</span>
    <span class="tag">{{ targetLabel }}</span>
    <span class="tag">{{ operatorLabel }}</span>
    <span class="tag">{{ valueLabel }}</span>
    <span class="tag">{{ unitLabel }}</span>
  </div>
</template>

<script>
export default {
  name: 'ConditionsLabel',
  computed: {
    converter() {
      return this.parameter && this.parameter.converterId && this.parameter.converterId ? this.$store.getters['converter/show'](this.parameter.converterId) : null;
    },
    convertToUnit() {
      return this.converter && this.converter.unitToId ? this.$store.getters['unit/show'](this.converter.unitToId) : null;
    },
    device() {
      return this.parameter && this.parameter.deviceId ? this.$store.getters['device/show'](this.parameter.deviceId, 'id') : null;
    },
    operatorLabel() {
      switch (this.condition.config.operator) {
        case '==':
          return 'equal to';
        case '!=':
          return 'not equal to';
        case '>':
          return 'greater than';
        case '<':
          return 'less than';
        case '<=':
          return 'less than or equal to';
        case '>=':
          return 'greater than or equal to';
        case 'entering':
          return 'entering';
        case 'exiting':
          return 'exiting';
        default:
          return 'unknown operator';
      }
    },
    parameter() {
      return this.condition.type && this.condition.targetId ? this.$store.getters['parameter/show'](this.condition.targetId) : null;
    },
    parentLabel() {
      if (this.condition.type === 'App\\Conditions\\ParameterValue' && this.device) {
        return `${this.device.name} [${this.device.lastFourOfDeviceEui}]`;
      }

      return 'Unknown condition.type';
    },
    targetLabel() {
      if (this.condition.type === 'App\\Conditions\\ParameterValue' && this.parameter) {
        return this.parameter.title;
      }

      return 'Unknown condition.type';
    },
    valueLabel() {
      return this.condition.config.value;
    },
    unit() {

      let unit = null;

      if (this.convertToUnit) {
        unit = this.convertToUnit;
      } else if (this.parameter && this.parameter.unitId) {
        unit = this.$store.getters['unit/show'](this.parameter.unitId);
      }

      return unit;
    },
    unitLabel() {

      if (!this.unit) {
        return '';
      } else if (this.unit.symbol) {
        return this.unit.symbol;
      } else if (this.unit.title) {
        return ' ' + this.unit.title.toUpperCase();
      } else if (this.unit.slug) {
        return ' ' + this.unit.slug.toUpperCase()
      } else {
        return '';
      }
    },
  },
  data() {
    return {};
  },
  props: {
    condition: {
      required: true,
    }
  },
};

</script>

<style scoped lang="scss">
.tag {
  margin-right: 5px;
}
</style>
