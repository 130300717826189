<template>
  <div>
    <div class="label-line-value">
      <div class="llv-label">
        {{ label }}
        <BTooltip v-if="time" :label="time" position="is-right">
          <BIcon icon="clock" />
        </BTooltip>
      </div>
      <div class="line"/>
      <div class="value truncate" v-html="formattedValue"></div>

      <BTooltip label="Click to Copy" v-if="clickToCopy" position="is-left">
        <span @click="handleCopy">
          <BIcon icon="copy" type="is-info"/>
        </span>
      </BTooltip>

      <BTooltip label="Click to Expand" v-if="hasSlot" position="is-left">
        <span @click="handleExpand" class="pointer">
          <BIcon :icon="valueExpanded ? 'minus' : 'plus'" type="is-info"/>
        </span>
      </BTooltip>

      <BTooltip label="Click to Inspect" v-if="to" position="is-left">
        <span @click="handleInspect">
          <BIcon icon="long-arrow-right" type="is-info"/>
        </span>
      </BTooltip>

      <BTooltip label="Click to Open Link" v-if="url" position="is-left">
        <span @click="handleExternalUrl">
          <BIcon icon="external-link" type="is-info"/>
        </span>
      </BTooltip>

    </div>

    <div class="has-margin-vertical" v-if="valueExpanded">
      <slot/>
    </div>
  </div>

</template>

<script>
import copy from 'copy-to-clipboard';

import numeral from 'numeral';

export default {
  name: 'LabelLineValue',
  computed: {
    formattedValue() {
      if (this.numberFormat) {
        return numeral(this.value).format(this.numberFormat);
      } else {
        return this.value;
      }
    },
    hasSlot() {
      return this.$slots['default'] !== undefined;
    },
    tooltipActive() {
      return this.clickToCopy === true || this.expanded !== null;
    },
    tooltipLabel() {
      if (this.clickToCopy) {
        return 'Click to Copy';
      } else if (this.expanded !== null) {
        return 'Click to Expand';
      }
    }
  },
  created(){
    this.valueExpanded = this.expanded;
  },
  data() {
    return {
      valueExpanded: false,
    };
  },
  methods: {
    handleCopy() {
      if (this.clickToCopy) {
        copy(this.value);
        window.app.snackbar('Copied');
      }
    },
    handleExpand() {
      this.valueExpanded = !this.valueExpanded;
    },
    handleExternalUrl() {
      window.open(this.url);
    },
    handleInspect() {
      this.$router.push(this.to);
    },
  },
  props: {
    label: {
      required: true,
    },
    numberFormat: {
      required: false,
      type: String,
    },
    to: {
      required: false,
    },
    value: {
      required: false,
    },
    expanded: {
      default: false,
      required: false,
      type: Boolean,
    },
    clickToCopy: {
      default: false,
      type: Boolean,
    },
    url: {},
    time: {
      required: false,
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.label-line-value {
  cursor: default;
  display: flex;
  font-size: 1em;
  margin: 10px 0;
  min-width: 300px;
  white-space: nowrap;

  .llv-label {

  }

  .line {
    border-bottom: 1px dotted $grey-light;
    flex: 1;
    margin: 0 5px;
    position: relative;
    top: -5px;
  }

  .value {
    max-width: 60%;
  }

  .icon {
    margin-left: 5px;
    margin-right: -3px;
  }
}
</style>
