<template>
  <router-link :to="`/integration/${integration.uuid}/edit`" class="integration resource-list-item" :class="{ 'is-dark': $themeDark }">
    <div class="integration-name">{{integration.name}} <span class="tag is-info is-pulled-right">{{typeLabel}}</span></div>
  </router-link>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    integration() {
      return this.resource;
    },
    typeLabel() {
      return this.integration.type.replace('App\\Integrations\\', '');
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
