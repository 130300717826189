<template>
  <div class="map-control has-map-preview">

    <div @click="handleToggle">
      <BTooltip label="Map Style" position="is-bottom" :active="!isMobile">
        <BIcon icon="map" size="is-medium"/>
      </BTooltip>
    </div>

    <div class="sub-map-controls" v-if="active">
      <div class="map-styles">
        <template v-for="(mapStyle, key) in mapStyles">
          <div class="map-style">
            <div class="left is-flex is-align-items-center is-flex-grow-1" @click="handleSetMapStyle(key)">
              <div class="map-style-preview is-flex is-align-items-center is-justify-content-center"
                   v-if="mapStyle.custom">
                <BIcon icon="tools"/>
              </div>
              <div class="map-style-preview" :style="{ 'background-image': `url('${mapStyle.preview}')` }" v-else></div>
              <div class="map-style-label is-flex-grow-1">{{ mapStyle.label }}</div>
            </div>
            <div class="right">
              <div class="map-style-remove" v-show="mapStyle.custom" @click="handleRemoveCustomStyle(key)">
                <BIcon icon="minus-circle" type="is-danger"/>
              </div>
            </div>
          </div>
        </template>

        <div class="map-style" @click="handleAddCustomMapStyle" v-if="false">
          <div class="left is-flex is-align-items-center">
            <div class="map-style-preview is-flex is-align-items-center is-justify-content-center">
              <BIcon icon="plus"/>
            </div>
            <div class="map-style-label">Add Custom Style</div>
          </div>
        </div>
      </div>

      <div class="is-padded" style="width: 100%;" v-if="false">
        <a class="button is-light is-outlined is-block" href="https://www.mapbox.com/mapbox-studio" target="_blank">
          Mapbox Studio
        </a>
        <Help class="is-padded has-text-centered" style="max-width: 300px;">
          Custom map styles must be public to be added to {{$domainName}}
        </Help>
      </div>
    </div>

        <BModal :active.sync="mapStyleFormActive" :width="400">
          <Box flat black>
            <Title>Add Custom Style</Title>
            <Field>
              <Label>Label *</Label>
              <TextInput v-model="form.fields.label"/>
            </Field>
            <Field>
              <Label>URL *</Label>
              <TextInput v-model="form.fields.url" placeholder="mapbox://styles/mapbox/navigation-day-v1"/>
            </Field>
            <div class="controls has-text-right">
              <Button primary @click="handleSaveMapStyle" :loading="savingMapStyle">
                Save
              </Button>
            </div>
          </Box>
        </BModal>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

import {base58Encode} from '@/helpers';

export default {
  name: 'MapStylesControl',
  computed: {
    active() {
      return this.$store.state.ui.mapControls.mapStyles || false;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    map () {
      return this.$store.state.map.map;
    },
    mapStyle () {
      return this.$store.state.map.mapStyle;
    },
    mapStyles () {
      return this.$store.state.map.mapStyles;
    },
  },
  data() {
    return {
      mapStyleFormActive: false,
      form: new Form({
        label: '',
        url: '',
      }),
      savingMapStyle: false,
    };
  },
  methods: {
    ...mapActions({
      reloadAllLayers: 'mapLayer/reloadAllLayers',
      setMapStyle: 'map/setMapStyle',
      mapControls: 'ui/mapControls',
    }),
    handleAddCustomMapStyle() {
      this.mapStyleFormActive = !this.mapStyleFormActive;
    },
    handleRemoveCustomStyle (hash) {
      window.app.confirm({
        title: 'Remove Custom Map Style',
        message: 'Are you sure you want to remove this style?',
        onConfirm: () => {
          const map = window.app.storage.getItem('map');
          delete map.customMapStyles[hash];
          map.style = 'default';
          window.app.storage.setItem('map', map);
          window.location.reload();
        },
      });
    },
    handleSaveMapStyle () {

      if (!this.form.fields.label || !this.form.fields.url) {
        window.app.snackbar({
          message: 'Label and URL are required.',
          type: 'is-danger',
        });
        return;
      }

      this.savingMapStyle = true;
      const map = window.app.storage.getItem('map') || {};
      let customMapStyles = map.customMapStyles || {};

      const style = {
        custom: true,
        label: this.form.fields.label,
        url: this.form.fields.url,
      };

      const hash = base58Encode(JSON.stringify(style));
      customMapStyles[hash] = style;
      map.customMapStyles = customMapStyles;
      map.style = hash;
      window.app.storage.setItem('map', map);
      window.location.reload();
    },
    handleSetMapStyle (mapStyle) {
      const map = window.app.storage.getItem('map') || {};
      map.style = mapStyle;
      window.app.storage.setItem('map', map);
      this.setMapStyle(mapStyle).then(() => {
        const mapStyleObject = this.mapStyles[mapStyle];
        const style = mapStyleObject.custom ? mapStyleObject.url : `mapbox://styles/${mapStyleObject.url}`;
        this.map.setStyle(style);
        setTimeout(() => {
          this.reloadAllLayers();
        }, 500);
      });
    },
    handleToggle() {
      this.mapControls({
        mapStyles: !this.active,
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">
.map-style {
  margin: 5px;
}
</style>
