<template>
  <div id="account-create" class="is-padded">

    <Field v-if="account">
      <Label>ID</Label>
      <p>{{ account.id }}</p>
    </Field>

    <Field>
      <Label>Account Name *</Label>
      <TextInput v-model="form.fields.name"/>
      <FormError field="name" :form="form"/>
    </Field>

    <Field v-if="user.account.id">
      <BCheckbox v-model="form.fields.hasOwner">Create on behalf of someone else</BCheckbox>
    </Field>

    <template v-if="form.fields.hasOwner">

      <Field>
        <Label>What's your role on this account? *</Label>
        <SelectInput :options="roleOptions" v-model="form.fields.role"/>
        <FormError field="role" :form="form"/>
      </Field>

      <Box flat :black="$themeDark">

        <Field>
          <Label>First Name *</Label>
          <TextInput v-model="form.fields.firstName"/>
          <FormError field="firstName" :form="form"/>
        </Field>

        <Field>
          <Label>Last Name *</Label>
          <TextInput v-model="form.fields.lastName"/>
          <FormError field="lastName" :form="form"/>
        </Field>

        <Field>
          <Label>
            Email *
          </Label>
          <EmailInput v-model="form.fields.email"/>
          <FormError field="email" :form="form"/>
        </Field>

        <Help>This user will be assigned the <code>owner</code> role.</Help>

      </Box>

    </template>

    <Teleport to="#inspector-footer">
      <Controls>
        <template v-slot:right>
          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>
          <Button primary :loading="loading" @click="handleSave" class="has-margin-left">Save</Button>
        </template>
      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

export default {
  name: 'Form',
  components: {},
  computed: {
    activeAccountId() {
      return this.$store.state.system.activeAccountId;
    },
    account () {
      return this.$route.params.accountId ? this.$store.getters['account/show'](this.$route.params.accountId) : null;
    },
    user () {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (this.account) {
      this.form.fields = {
        ...this.account
      };
    }
  },
  data () {
    return {
      form: new Form({
        id: this.$route.params.accountId || null,
        hasOwner: false,
        firstName: null,
        lastName: null,
        email: null,
        role: 'owner',
        name: '',
      }),
      roleOptions: [
        {
          label: 'Owner',
          value: 'owner',
        },
        {
          label: 'Support',
          value: 'support',
        },
        {
          label: 'User',
          value: 'user',
        },
      ],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      accountStore: 'account/store',
      accountUpdate: 'account/update',
      userShow: 'auth/userShow',
      setActiveAccountId: 'system/setActiveAccountId'
    }),
    handleCancel() {
      this.$router.back();
    },
    handleSave () {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.form.errors.clear();

      let action;

      let payload = {
        ...this.form.fields,
      };

      if (this.$route.params.accountId) {
        action = this.accountUpdate;
      } else {
        action = this.accountStore;
      }

      action({
        ...payload,
      }).then((response) => {

        const accountId = response;

        window.app.snackbar({
          message: this.$route.params.accountId ? 'Account Updated' : 'Account Added',
        });

        this.setActiveAccountId(accountId);

        window.location.href = '/';

      }).catch((error) => {

        this.form.recordErrors(error);

      }).finally(() => {
        this.loading = false;
      });
    },
  }
};
</script>

<style scoped lang="scss">

</style>
