import {
  Model,
  ActionService as Service,
} from '@/internal';

export default class Action extends Model {

  static get key() {
    return 'action';
  }

  static get service() {
    return Service;
  }

}
