<template>
  <div id="advanced-charting">

    <BLoading active v-if="loading"/>

    <BModal :active.sync="deviceAddModalActive">

      <div class="is-flex is-align-items-center is-justify-content-center" style="min-height: 500px;">

        <Box flat :black="$themeDark">

          <ResourceSearchInput :action="deviceIndex"
                               label="Search for Devices"
                               :params="{}"
                               placeholder="Search by Device Name"
                               @select="handleDevicesSelected"
                               vuexKey="device"
          />

        </Box>

      </div>

    </BModal>

    <div id="device-list">

      <div class="is-padded-horizontal is-padded-bottom">
        <Button primary block @click="handleDeviceAdd">
          Select Devices
        </Button>
      </div>

      <template v-for="device in devicesArray">

        <div class="device">

          <div class="is-flex is-align-items-center">
            <span class="pointer" @click="handleDeviceRemove(device)">
              <BIcon icon="minus-circle"/>
            </span>
            <span class="device-name">{{ device.name }}</span>
          </div>

          <div class="parameter-list">
            <template v-for="parameter in device.parameters">
              <div class="parameter is-flex is-align-items-center" style="height: 30px;">

                <span v-if="parameters[parameter.id] && parameters[parameter.id].loading">
                  <BIcon icon="loader" custom-class="fa-spin"/> <span
                  style="margin-left: -2px; margin-top: -2px;">{{ parameter.title }}</span>
                </span>

                <BCheckbox :value="parameters[parameter.id] !== undefined" @input="handleParameterClick(parameter)"
                           v-else>{{ parameter.title }}
                </BCheckbox>

              </div>
            </template>
          </div>

        </div>

      </template>
    </div>

    <div id="chart-wrapper">

      <AdvancedChartControls v-model="controls" storageKey="advancedChart.controls"/>

      <template v-if="!devicesArray.length">
        <div class="container container-extra-extra-small is-flex is-flex-direction-column is-justify-content-center">
          <Button primary block @click="handleDeviceAdd">
            Select Devices to load charts
          </Button>
        </div>

      </template>

      <highcharts id="highcharts-chart" :key="`highcharts-${primaryMenu.active}`" :ref="chart.id" :options="chart" v-else-if="chart"></highcharts>

    </div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {Chart} from 'highcharts-vue';
import moment from 'moment-timezone';

import AdvancedChartControls from '@/components/AdvancedChartControls';
import ResourceSearchInput from '@/components-V2/Inputs/ResourceSearchInput';

export default {
  name: 'AdvancedChart',
  components: {
    AdvancedChartControls,
    highcharts: Chart,
    ResourceSearchInput,
  },
  computed: {
    devicesArray() {
      return Object.values(this.devices);
    },
    isFullScreen() {
      return this.$route.meta.fullScreen || this.inspector.fullScreen;
    },
    primaryMenu() {
      return this.$store.state.ui.primaryMenu;
    },
    series() {
      return Object.values(this.parameters).filter(parameter => !parameter.loading && parameter.loaded).map(parameter => parameter.chartData);
    },
    system() {
      return this.$store.state.system;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (this.$route.query.devices) {
      this.loadDevices(this.$route.query.devices)
    }
  },
  data() {
    return {
      changingDateRange: false,
      chart: null,
      chartData: {},
      dateRange: window.app.storage.getItem('range-picker.advanced-charting') || '1w',
      devices: {},
      deviceAddModalActive: false,
      controls: {},
      loading: false,
      parameters: {},
    };
  },
  methods: {
    ...mapActions({
      deviceIndex: 'device/index',
      parameterChartData: 'parameter/chartData',
    }),
    createChart() {
      this.chart = {
        id: 'comparison-chart',
        chart: {
          type: 'spline',
          backgroundColor: this.$themeDark ? '#121212' : '#EBEBEB',
          spacing: 25,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          area: {
            animation: false,
          },
          bubble: {
            animation: false,
          },
          column: {
            animation: false,
          },
          line: {
            animation: false,
          },
          spline: {
            animation: false,
            connectNulls: true,
          },
          series: {
            dataLabels: {
              enabled: false,
            },
            marker: {
              enabled: false,
            },
            turboThreshold: 10000,
          },
        },
        tooltip: {
          backgroundColor: '#FFFFFF',
          borderColor: 'transparent',
          borderRadius: 0,
          borderWidth: 0,
          style: {
            color: '#111111',
          },
          shadow: false,
          className: 'harvest-hawk-tooltip',
          useHTML: true,
          formatter: function () {
            const system = window.app.storage.getItem('system');
            const timezone = system.settings.timezone || moment.tz.guess() || 'utc';
            const dateTimeFormat = system.settings.dateTimeFormat || 'll LTS z';
            let date = moment.tz(this.x, timezone).format(dateTimeFormat);

            return [''].concat(this.points ?
              this.points.map(function (point) {
                return `
                  <div style="text-transform: uppercase;">
                    ${date}<br />
                    ${point.series.name}: ${point.y} ${point.point.unit || ''}</div>`;
              }) : []);
          },
          enabled: true,
          pointFormat: '',
          valueDecimals: 0, //parameter.precision || 0,
          split: true,
          units: '',//parameter.unit,
        },
        series: [
          ...this.series
        ],
        title: null,
        xAxis: {
          crosshair: {
            label: {
              enabled: true,
              padding: 10
            }
          },
          labels: {
            enabled: true,
          },
          min: this.controls.startDate ? moment(this.controls.startDate).toDate().getTime() : null,
          max: this.controls.endDate ? moment(this.controls.endDate).toDate().getTime() : null,
          minorTickLength: 0,
          tickLength: 0,
          title: {
            text: '',
          },
          type: 'datetime',
        },
        yAxis: {
          gridLineColor: '#222',
          labels: {
            enabled: true,
          },
          title: {
            text: '',
          },
          min: null, //parameter.minY !== undefined ? parameter.minY : null,
          max: null, //parameter.maxY !== undefined ? parameter.maxY : null,
          startOnTick: false,
          endOnTick: false,
        },
      };
    },
    loadDevices(ids) {
      this.loading = true;
      return this.deviceIndex({
        params: {
          include: ['parameters'],
        },
      }).then(() => {

        let devices = {};

        this.$store.getters['device/collection'](ids.split(',')).forEach((device) => {
          devices[device.id] = device;
        });

        this.devices = {
          ...this.devices,
          ...devices,
        };

      }).finally(() => {
        this.loading = false;
      });
    },
    handleDeviceAdd() {
      this.deviceAddModalActive = true;
    },
    handleDeviceRemove(device) {

      let parameters = {
        ...this.parameters,
      };

      device.parameters.forEach((parameter) => {
        delete parameters[parameter.id];
      });

      this.parameters = {
        ...parameters,
      };

      let devices = {
        ...this.devices,
      };

      delete devices[device.id];

      this.devices = {
        ...devices,
      };

      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          devices: Object.keys(this.devices).join(','),
        },
      });
    },
    handleDevicesSelected(devicesSelected = []) {

      this.deviceAddModalActive = false;

      let devices = {
        ...this.devices,
      };

      devicesSelected.forEach((device) => {
        devices[device.id] = device;
      });

      this.devices = {
        ...devices,
      };

      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          devices: Object.keys(this.devices).join(','),
        },
      });

      this.loadDevices(this.$route.query.devices);
    },
    handleParameterClick(parameter) {

      if (this.parameters[parameter.id]) {

        let parameters = {
          ...this.parameters,
        };

        delete parameters[parameter.id];

        this.parameters = {
          ...parameters,
        };

        return;
      }

      parameter.loading = true;

      this.parameters = {
        ...this.parameters,
        [parameter.id]: parameter,
      };

      this.loadParametersChartData([parameter.id]);
    },
    loadParametersChartData(ids = []) {

      this.loading = true;

      let parameters = {
        ...this.parameters,
      };

      return this.parameterChartData({
        params: {
          ids: ids.join(','),
          startDate: moment(this.controls.startDate).toISOString(),
          endDate: moment(this.controls.endDate).toISOString(),
          sampleAmount: this.controls.amount,
          round: this.controls.roundingEnabled ? this.controls.rounding : null,
        },
      }).then((response) => {

        ids.forEach((id) => {
          let parameter = parameters[id];
          parameter.chartData = response.data[id].chartData;
          parameter.loaded = true;
        });

      }).finally(() => {

        ids.forEach((id) => {
          let parameter = parameters[id];
          parameter.loading = false;
        });

        this.parameters = {
          ...parameters,
        };

        this.loading = false;
      });
    },
  },
  watch: {
    controls: {
      deep: true,
      handler() {
        let parameterIds = Object.keys(this.parameters);
        if (parameterIds.length) {
          this.loadParametersChartData(parameterIds);
        }
      },
    },
    $route: {
      deep: true,
      handler() {
        let storedQuery = window.app.storage.getItem('advancedChartsQuery');

        if (JSON.stringify(storedQuery) !== JSON.stringify(this.$route.query)) {
          window.app.storage.setItem('advancedChartsQuery', this.$route.query);
        }
      },
    },
    series: {
      deep: true,
      handler() {
        this.createChart();
      },
    },
    // parameters: {
    //   handler() {
    //     this.$router.push({
    //       name: this.$route.name,
    //       query: {
    //         ...this.$route.query,
    //         parameters: Object.keys(this.parameters).join(','),
    //       },
    //     });
    //   },
    // },
  },
};
</script>

<style lang="scss">
#advanced-charting {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;

  #device-list {
    border-right: 1px solid $navigation-border-color;
    height: 100%;
    overflow-y: auto;
    padding: 15px 0;
    width: $primary-menu-width-inactive;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    .device {
      padding: 5px 15px;

      .device-name {
        margin-left: 5px;
      }

      .parameter-list {
        margin-left: 11px;
        margin-top: 4px;
        padding: 5px 5px 0 0;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 1px;
          top: -5px;
          bottom: 17px;
          border-left: 1px dashed $grey;
        }

        .parameter {
          padding: 5px 0 5px 17px;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 15px;
            border-bottom: 1px dashed $grey;
          }
        }
      }
    }
  }

  #chart-wrapper {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    #highcharts-chart {
      height: auto;
      flex: 1;

      .highcharts-container {
        height: 100% !important;
      }
    }
  }
}
</style>
