import {
  Integration as Model,
  IntegrationService as Service,
  ResourceStore,
} from '@/internal';

const service = new Service();

const store = new ResourceStore({
  model: Model,
  actions: {
    webhookTest({commit}, payload = {}) {
      return service.webhookTest(payload);
    },
  },
});

export default store.toObject();
