<template>
  <div id="domain-dashboard" class="is-padded">
    <div class="has-text-centered">
      <div style="font-size: 40px; margin: 20% 0;">
        Coming Soon!
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'Dashboard',
  computed: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
