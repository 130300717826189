<template>
  <div id="domain-picker" class="is-padded">

    <BLoading active v-if="loading"/>

    <template v-else-if="domainCreate">

      <p>
        To create a new domain, contact hello@uplinkengine.com and we'll get you setup.
      </p>

      <div class="controls has-margin-top">
        <div class="columns">
          <div class="column">
            <Button block outlined :light="$themeDark" :dark="$themeLight" @click="domainCreate = false">
              Cancel
            </Button>
          </div>
        </div>
      </div>

      <template v-if="false">

        <Field>
          <Label>Domain/Brand Name *</Label>
          <TextInput v-model="form.fields.name" placeholder="Acme IoT"/>
          <FormError field="name" :form="form"/>
        </Field>

        <Field>
          <Label>Domain URL *</Label>
          <TextInput v-model="form.fields.url" placeholder="acme-iot.com"/>
          <FormError field="url" :form="form"/>
        </Field>

        <div class="controls has-margin-top">
          <div class="columns">
            <div class="column">
              <Button block outlined :light="$themeDark" :dark="$themeLight" @click="domainCreate = false">
                Cancel
              </Button>
            </div>
            <div class="column">
              <Button block primary @click="handleDomainStore">
                Save
              </Button>
            </div>
          </div>
        </div>

      </template>

    </template>

    <div class="is-padded-vertical" v-else>

      <div class="domains">
        <template v-for="domain in domains">
          <Button outlined :dark="$themeLight" :light="$themeDark" block class="domain has-margin-vertical" @click="handleDomainSwitch(domain)">
            {{ domain.name }}
          </Button>
        </template>
      </div>

      <Button primary block @click="handleDomainCreate">
        Create New Domain
      </Button>

    </div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {Form} from '@/internal';

export default {
  name: 'Picker',
  computed: {
    domains() {
      return this.user ? this.user.domains.sort((a, b) => a.name > b.name ? 1 : -1) : [];
    },
    user() {
      return this.$store.getters['auth/user'] || null;
    },
  },
  created() {
    this.loadUser();
  },
  data() {
    return {
      domainCreate: false,
      form: new Form({
        id: null,
        apiUrl: '',
        appUrl: '',
        name: '',
        url: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      authUserShow: 'auth/userShow',
      domainStore: 'domain/store',
    }),
    handleDomainCreate() {
      this.domainCreate = true;
    },
    handleDomainSwitch(domain) {
      this.loading = true;
      window.location.replace(`/?domain=${domain.uuid}`);
    },
    handleDomainStore() {

      this.loading = true;

      let url = this.form.fields.url.replace('https://', '').replace('http://',).replace('www.', '');
      let apiUrl = `https://api.${url}`;
      let appUrl = `https://app.${url}`;
      url = `https://${url}`;

      this.form.fields = {
        ...this.form.fields,
        url,
        apiUrl,
        appUrl,
      }

      return this.domainStore({
        ...this.form.fields,
      }).then((id) => {

        let domain = this.$store.getters['domain/findBy'](id);

        if (domain) {
          window.app.snackbar('Domain Created');
          this.loading = true;
          window.location.replace(`/?domain=${domain.uuid}`);
        }
      }).catch((error) => {
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      })
    },
    loadUser() {
      this.loading = true;
      return this.authUserShow({
        params: {
          include: ['domains'],
        },
      }).then(() => {
        if (!this.domains.length) {
          this.domainCreate = true;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">
#domain-picker {
  .domains {
    margin-bottom: 30px;
  }
}
</style>
