export default (to, from, next) => {

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params.domain) {
    window.app.storage.setItem('domainBeingManaged', params.domain);
  }

  const activeDomain = window.app.storage.getItem('domainBeingManaged');

  if (!activeDomain) {

    return next({path: '/domain/picker'});

  } else {

    return next();

  }
}
