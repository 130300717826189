<template>
  <div class="notes-resource-list">
    <ResourceList addLabel="Add Note"
                  :addRoute="addRoute"
                  :params="params"
                  :query="query"
                  :resource="resource"
                  :renderAs="renderAs"
                  sortBy="created_at"
                  sortOrder="desc"
                  :sortByOptions="sortByOptions"
    />
  </div>
</template>

<script>

import NoteResourceListItem from '@/components-V2/Note/ResourceListItem';
import {Note} from '@/internal';

export default {
  name: 'NoteResourceList',
  computed: {},
  data() {
    return {
      addRoute: !this.hideAddButton ? window.app.findRouteByName('note/create', {}, {
        query: {
          notableId: this.notableId,
          notableType: this.notableType,
        },
      }) : null,
      params: {
        include: [],
        notableId: this.notableId,
        notableType: this.notableType,
      },
      renderAs: NoteResourceListItem,
      resource: Note,
      sortByOptions: [
        {
          label: 'Created Date',
          value: 'created_at',
        },
      ],
    };
  },
  methods: {},
  props: {
    notableId: {
      type: Number,
      required: true
    },
    notableType: {
      type: String,
      required: true
    },
    query: {
      default: () => {
        return {};
      },
      required: false,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
