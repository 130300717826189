<template>
  <div id="api-key-form" class="is-padded">

    <BLoading active v-if="loading" :isFullPage="false"/>

    <Field>
      <Label>Name *</Label>
      <TextInput v-model="form.fields.name"/>
      <FormError :form="form" field="name"/>
    </Field>

    <Field v-if="apiKey">
      <Label>Key</Label>
      <FakeInput clickToCopy>
        {{ apiKey.uuid }}
      </FakeInput>
    </Field>

    <Verifier :form="form"
              v-model="form.fields.verificationCode"
              v-if="form.fields.verificationUUID"
              channel="mail"
              reason="New API Key"
              @uuid="uuid => form.fields.verificationUUID = uuid"
    />

    <Teleport to="#inspector-footer">
      <Controls>

        <template v-slot:left>
          <Button @click="handleDelete" outlined danger :loading="deleting" v-if="apiKey">
            Delete
          </Button>
        </template>

        <template v-slot:right>
          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>
          <Button primary @click="handleSave" :loading="loading" class="has-margin-left">
            Save
          </Button>
        </template>
      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {Form} from '@/internal';
import {mapActions} from 'vuex';

export default {
  name: 'Form',
  computed: {
    apiKey() {
      return this.$route.params.apiKeyUUID ? this.$store.getters['apiKey/findBy'](this.$route.params.apiKeyUUID, 'uuid') : null;
    },
    domain() {
      return this.domainUUID ? this.$store.getters['domain/findBy'](this.domainUUID, 'uuid') : null;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {

    this.domainUUID = window.app.storage.getItem('domainBeingManaged');

    if (this.$route.params.apiKeyUUID) {
      this.loadApiKey();
    }
  },
  data() {
    return {
      deleting: false,
      domainUUID: null,
      form: new Form({
        name: '',
        verificationCode: '',
        verificationUUID: null,
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      apiKeyDestroy: 'apiKey/destroy',
      apiKeyShow: 'apiKey/show',
      apiKeyStore: 'apiKey/store',
      apiKeyUpdate: 'apiKey/update',
    }),
    fillForm() {

      if (!this.apiKey) {
        return;
      }

      this.form.fields = {
        ...this.form.fields,
        id: this.apiKey.id,
        name: this.apiKey.name,
        uuid: this.apiKey.uuid,
      };
    },
    handleCancel() {
      this.form.reset();
      this.$router.back();
    },
    handleDelete() {

      this.$deleteDialog({
        target: this.apiKey.name,
        targetType: 'API Key',
        onConfirm: () => {
          this.deleting = true;

          let payload = {
            id: this.$route.params.apiKeyUUID,
            params: {}
          };

          if (this.domainUUID) {
            payload.params.domain = this.domainUUID;
          }

          this.apiKeyDestroy(payload).then(() => {

            window.app.snackbar('API Key Deleted');

            let routeName = 'api-key/index'

            if (this.domainUUID) {
              routeName = 'domain/api-key/index';
            }

            this.$router.push({
              name: routeName,
            });
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.deleting = false;
          });
        },
      });

    },
    handleSave() {
      this.loading = true;

      let action;
      let payload;

      if (this.apiKey) {
        action = this.apiKeyUpdate;
      } else {
        action = this.apiKeyStore;
      }

      payload = {
        ...this.form.fields,
      };

      if (this.domainUUID) {
        payload.domain = this.domainUUID;
      }

      action(payload).then(() => {
        window.app.snackbar('API Key Saved');
        this.form.reset();
        this.$router.back();
      }).catch((error) => {

        if (window.app.verificationRequiredError(error)) {
          const verificationRequired = window.app.verificationRequiredError(error);
          this.form.fields.verificationUUID = verificationRequired ? verificationRequired.uuid : null;
        }

        this.form.recordErrors(error);

      }).finally(() => {
        this.loading = false;
      });
    },
    loadApiKey() {
      this.loading = true;

      let payload = {
        id: this.$route.params.apiKeyUUID,
        params: {}
      };

      if (this.domainUUID) {
        payload.params.domain = this.domainUUID;
      }

      this.apiKeyShow(payload).then(() => {
        this.fillForm();
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
};
</script>

<style scoped lang="scss">

</style>
