<template>
  <div id="system-settings" class="is-padded">

    <Field v-if="!isMobile">
      <Label>UI Layout</Label>
      <UILayoutInput v-model="settings.layout"/>
    </Field>

    <Field>
      <Label>Theme</Label>
      <ThemeInput v-model="settings.theme"/>
    </Field>

    <Field>
      <Label>Date/Time Format</Label>
      <TextInput v-model="settings.datetimeFormat" :placeholder="defaultDatetimeFormat"/>
      <Help>
        <a href="https://momentjs.com/docs/#/displaying/format/" target="_blank">Formatting
          Options</a> &middot; <span class="pointer" @click="handleDatetimeFormatReset">Reset</span>
      </Help>
    </Field>

    <Field>
      <Label>Timezone</Label>
      <TimezoneInput v-model="settings.timezone"/>
    </Field>

    <Teleport to="#inspector-footer">
      <Controls>
        <template v-slot:right>
          <Button primary @click="handleSave">Save</Button>
        </template>
      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import moment from 'moment-timezone';

import ThemeInput from '@/components-V2/Inputs/ThemeInput';
import UILayoutInput from '@/components-V2/Inputs/UILayoutInput';

const defaultDatetimeFormat = 'll LTS z';

export default {
  name: 'Settings',
  components: {
    ThemeInput,
    UILayoutInput,
  },
  computed: {
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    system() {
      return this.$store.state.system;
    },
  },
  created() {
    this.settings = {
      ...this.system.settings,
    };
  },
  data() {
    return {
      defaultDatetimeFormat,
      settings: {
        datetimeFormat: defaultDatetimeFormat,
        layout: '',
        theme: '',
        timezone: '',
      },
    };
  },
  methods: {
    ...mapActions({
      updateSettings: 'system/settings',
    }),
    handleDatetimeFormatReset() {
      this.settings.datetimeFormat = defaultDatetimeFormat;
    },
    handleSave() {

      if (!this.datetimeFormatValid()) {
        window.app.snackbar({
          message: 'Date/Time format is not valid',
          type: 'is-danger',
        });
        return;
      }

      const reload = this.system.settings.timezone !== this.settings.timezone
                  || this.system.settings.theme !== this.settings.theme
                  || this.system.settings.layout !== this.settings.layout;

      return this.updateSettings({
        ...this.settings,
      }).then(() => {

        if (reload) {
          window.location.reload();
        }

        window.app.snackbar('Settings Saved');
      });
    },
    datetimeFormatValid() {
      return moment().format(this.settings.datetimeFormat) !== 'Invalid date';
    }
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
