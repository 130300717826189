import queryString from 'querystring';
import store from '@/store';

export default (to, from, next) => {

  const parsedQuery = queryString.parse(window.location.search);

  if (parsedQuery.t) {
    window.app.auth.setToken(atob(parsedQuery.t));
    return next(window.app.findRouteByName('home'));
  }

  let authenticated = store.state.auth.authenticated;
  let path = window.app.findRouteByName('auth/login').path;

  if (!authenticated && to.path !== path) {

    window.app.d('setting redirectRoute', to);

    window.app.storage.setItem('redirectRoute', to);

    window.app.snackbar('You must login to access that page.');

    window.app.d(`[authMiddleware] Redirected to ${path}`);

    return next({ path });

  } else {

    return next();

  }
}
