<template>
  <div id="domain-product-definitions" class="is-padded">
    <Instruction>
      Contact support for additional product definitions.
    </Instruction>
  </div>
</template>

<script>
export default {
  name: 'Definitions',
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
#domain-product-definitions {

}
</style>
