<template>
  <Box class="no-data-placeholder">
    <BIcon icon="inbox" custom-class="fa-4x" />
    <Title>
      <slot></slot>
    </Title>
  </Box>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'NoDataPlaceholder',
  computed: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {},
}
</script>

<style scoped lang="scss">
  .no-data-placeholder {
    background: $black-bis !important;
    padding: 60px 30px 45px;
    text-align: center;

    .icon {
      margin-bottom: 45px;
    }

    .title {
      font-size: 20px;
      line-height: 2em;
    }
  }
</style>
