import {
  ResourceService,
} from '@/internal';

export default class RuleService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'rule';
  }
}
