import {
  Model,
  ConditionService as Service,
  Rule,
} from '@/internal';

export default class Condition extends Model {

  static get key() {
    return 'condition';
  }

  static get service() {
    return Service;
  }

  get rule() {
    return this.hasOne(Rule);
  }

}
