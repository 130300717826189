import {
  Account,
  Domain,
  Model,
  UserService as Service,
} from '@/internal';

import {featureEnabled} from '@/helpers';

export default class User extends Model {

  static get key() {
    return 'user';
  }

  static get fields() {
    return {
      twoFactorEnabled: {
        default: false,
        type: Boolean,
      },
      firstName: {
        required: true,
        type: String,
      },
      lastName: {
        required: true,
        type: String,
      },
      email: {
        required: true,
        type: String,
      },
      accounts: {
        preparePayload: (value) => {
          return value ? value.map(account => account.id) : [];
        },
        type: Account,
      },
      isDomainOwner: {
        default: false,
        type: Boolean,
      },
    };
  }

  static get service() {
    return Service;
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get initials() {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
  }

  get accounts() {
    return this.hasMany(Account);
  }

  get domains() {
    return this.hasMany(Domain);
  }

  featureEnabled(key, defaultValue = false) {
    return featureEnabled(key, defaultValue);
  }

  hasRole(role) {
    return this.role === role;
  }

  hasPermission(permission) {
    return this.permissions[permission];
  }
}
