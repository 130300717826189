<template>
  <div class="is-padded">
    <Box flat :black="$themeDark">
      <Field>
        <Label>Key</Label>
        <TextInput :value="form.fields.key" @input="handleInput" />
        <Help>
          The key is a six character code that should be included with your device.
        </Help>
      </Field>
      <div class="controls has-text-right">
        <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
          Cancel
        </Button>
        <Button primary @click="handleClaim" class="has-margin-left-xs">
          Next
        </Button>
      </div>
    </Box>
  </div>
</template>

<script>
import {Form} from '@/internal';

export default {
  name: 'Claim',
  data() {
    return {
      form: new Form({
        key: '',
      }),
    };
  },
  methods: {
    handleClaim() {
      this.$router.push({
        name: 'qr-code/show',
        params: {
          qrCodeKey: this.form.fields.key,
        },
      })
    },
    handleInput(value = '') {
      this.form.fields.key =  value.length ? value.toUpperCase() : '';
    },
  }
}
</script>

<style scoped lang="scss">

</style>
