import Index from '@/views/Unit/Index';
import Form from '@/views/Unit/Form';

const routes = [
  {
    name: 'unit/index',
    path: '/unit',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'weight',
      middleware: ['auth'],
      title: 'Units',
    },
  },
  {
    name: 'unit/create',
    path: '/unit/create',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('unit/index');
      },
      containerSize: 'container-extra-small',
      icon: 'weight',
      middleware: ['auth'],
      title: 'New Unit',
    },
  },
  {
    name: 'unit/edit',
    path: '/unit/:unitUUID/edit',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('unit/index');
      },
      containerSize: 'container-extra-small',
      icon: 'weight',
      title: 'Edit Unit',
    },
  },
];

export default routes;
