<template>
  <div id="asset-show" v-if="asset" class="is-padded">

    <BLoading active v-if="loading" :is-full-page="false"/>

    <div class="is-padded">
      <Preview :asset="asset" />
    </div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import Preview from '@/components-V2/Asset/Preview';

export default {
  name: 'Show',
  components: {
    Preview,
  },
  computed: {
    asset() {
      return this.$route.params.assetUUID ? this.$store.getters['asset/findBy'](this.$route.params.assetUUID, 'uuid') : null;
    },
   user() {
      return this.$store.getters['auth/user'];
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      show: 'asset/show',
    }),
    handleEdit() {
      const route = window.app.findRouteByName('asset/edit', [], {
        params: {
          assetUUID: this.asset.uuid
        },
      });

      this.$router.push(route);
    },
  },
}
</script>

<style scoped lang="scss">

</style>
