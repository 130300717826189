<template>
  <div id="user-menu" class="nav" v-click-outside="handleCloseMenu">

    <template v-if="updateAvailable">
      <div class="is-padded">
        <Button block primary @click="handleReloadApplication">
          v{{ $store.state.system.version.api }} Update Available
        </Button>
      </div>
      <div class="nav-divider"/>
    </template>

    <template v-for="menu in menus">
      <InspectorSection :title="menu.title" :open="menu.open" v-if="menu">
        <template v-for="menuItem in menu.menuItems">
          <MenuItem :menuItem="menuItem" />
        </template>
      </InspectorSection>
    </template>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import vClickOutside from 'v-click-outside';

import AccountMenu from '@/menus/AccountMenu';
import LegalMenu from '@/menus/LegalMenu';
import SystemMenu from '@/menus/SystemMenu';
import UserMenu from '@/menus/UserMenu';

export default {
  name: 'UserMenu',

  computed: {
    fullPath() {
      return this.$route.fullPath;
    },
    system() {
      return this.$store.state.system;
    },
    updateAvailable() {
      return this.system.updateAvailable;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {

    let menus = [];

    menus.push(new AccountMenu({ user: this.user}));
    menus.push(new UserMenu({ user: this.user}));
    menus.push(new SystemMenu({ system: this.system, user: this.user }));
    menus.push(new LegalMenu());

    this.menus = menus;

    this.userShow({
      params: {
        include: [],
      },
    });
  },
  data() {
    return {
      menus: [],
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    ...mapActions({
      userShow: 'auth/userShow',
    }),
    handleCloseMenu() {
      this.$emit('close');
    },
    handleReloadApplication() {
      window.location.reload(true);
    },
  },
  props: {},
  watch: {
    fullPath() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
#user-menu {
  background: $black-bis;
  border-left: 1px solid $grey;
  bottom: 0;
  width: 450px;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 3.5rem;

  section {
    padding: 15px 0;
  }

  .nav-item {
    color: $white;
    cursor: pointer;
    display: block;
    margin: 10px 0;
  }

  .nav-divider {
    background: $grey;
    height: 1px;
  }

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
</style>
