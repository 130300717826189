import Dashboard from '@/views/Dashboard';
import DomainManagementDashboard from '@/views/Domain/Dashboard';
import Logout from '@/views/Auth/Logout';
import HarmonyAnalyticaDashboard from '@/_instances/HarmonyAnalytica/views/Device/Index';
import MeatCacheDashboard from '@/_instances/MeatCache/views/Dashboard';
import SystemSettings from '@/views/System/Settings';

const isManagingDomain = window.location.origin.includes(process.env.VUE_APP_DOMAIN_MANAGEMENT_URL);

let homeRoute;

if (window.location.hostname.includes('meatcache.com') || window.location.hostname.includes('lakestreet.xyz')) {
  homeRoute = {
    name: 'home',
    component: MeatCacheDashboard,
    path: '/',
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'dashboard',
      middleware: ['auth'],
      title: 'Dashboard',
    },
  };
} else if (window.location.hostname.includes('harmonyanalytica.com')) {
  homeRoute = {
    name: 'home',
    component: HarmonyAnalyticaDashboard,
    path: '/',
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'dashboard',
      middleware: ['auth'],
      title: 'Dashboard',
    },
  };
} else if (isManagingDomain) {
  homeRoute = {
    name: 'home',
    component: DomainManagementDashboard,
    path: '/',
    meta: {
      fullScreen: true,
      hideMap: true,
      icon: 'dashboard',
      middleware: ['isManagingDomain'],
      title: 'Dashboard',
    },
  };
} else {
  homeRoute = {
    name: 'home',
    path: '/',
    meta: {
      hideInspector: true,
      icon: 'map',
    },
  };
}

const routes = [
  homeRoute,
  {
    name: 'dashboard',
    path: '/summary',
    component: Dashboard,
    meta: {
      icon: 'dashboard',
      title: 'Summary',
    },
  },
  {
    name: 'auth/logout',
    path: '/auth/logout',
    component: Logout,
    meta: {
      icon: 'power-off',
      title: 'Logout',
    },
  },
  {
    name: 'system/settings',
    path: '/system/settings',
    component: SystemSettings,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'wrench',
      middleware: ['auth'],
      title: 'Settings',
    },
  },
];

export default routes;
