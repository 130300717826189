<template>
  <div class="is-padded-horizontal">

    <Box flat>
      <Title>Run Converters</Title>

      <Field>
        <Label>From</Label>
        <BDatepicker
          v-model="form.fields.from"
          class="datepicker-dark"
          placeholder="Click to select..."
          icon="calendar"
          trap-focus>
        </BDatepicker>
      </Field>

      <Field style="margin-bottom:300px">
        <Label>To</Label>
        <BDatepicker
          v-model="form.fields.to"
          class="datepicker-dark"
          placeholder="Click to select..."
          icon="calendar"
          trap-focus>
        </BDatepicker>
      </Field>

      <div class="controls has-text-right">
        <Button primary @click="handleRun">Run</Button>
      </div>

    </Box>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import moment from 'moment';

import {
  Form,
} from '@/internal';


export default {
  name: 'Convert',
  components: {},
  computed: {
    device() {
      return this.$route.params.deviceUUID ? this.$store.getters['device/findBy'](this.$route.params.deviceUUID, 'uuid') : null;
    },
  },
  created() {
  },
  data () {
    return {
      loading: false,
      form: new Form({
        from: moment().toDate(),
        to: moment().toDate()
      }),
    };
  },
  methods: {
    ...mapActions({
      uplinkConvert: 'uplink/convert'
    }),
    handleRun() {
      const fields = {
        from: this.form.fields.from ? moment(this.form.fields.from).format('YYYY-MM-DD') : null,
        to: this.form.fields.to ? moment(this.form.fields.to).format('YYYY-MM-DD') : null,
        device_id: this.device.id
      };

      this.uplinkConvert(fields).then((response) => {
        this.$emit('close');
        window.app.snackbar('Converters are running');
      }).catch((error) => {
        window.app.snackbar('Error');
      }).finally(() => {
        this.loading = false;
      });

    },
  },
  props: {},
};
</script>

<style lang="scss">

.datepicker-dark {

  input {
    background: #040D14;
    border-color: #363636;
    color: white;
  }

}

</style>
