<template>
  <div class="title" :class="{ 'has-text-light': $themeDark || light }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    light: {
      default: false,
      type: Boolean,
    },
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 1.5rem;

  .icon {
    margin-right: 10px;
  }
}
</style>
