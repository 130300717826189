<template>
  <div id="trip-show" class="is-padded" v-if="trip">

    <BLoading active v-if="loading" :is-full-page="false"/>

    <LabelLineValue label="Status" :value="trip.active ? 'Active' : 'Complete'"/>
    <LabelLineValue label="First Position" :value="trip.createdAt.format('lll z')"/>
    <LabelLineValue label="Last Position" :value="trip.lastPositionAt.format('lll z')" v-if="trip.lastPositionAt" />
    <LabelLineValue label="Total Positions" :value="trip.positionCount"/>

    <Section title="Virtual" v-if="trip.device.isVirtual">
      <template v-if="trip.destination">
        <LabelLineValue label="Destination" :value="trip.destination.name" v-if="trip.destination.name"/>
        <LabelLineValue label="Address" :value="trip.destination.address" v-if="trip.destination.address"/>
      </template>
      <template v-else>
        <p>Virtual Trip Complete</p>
      </template>
    </Section>

    <Section title="Positions" v-if="loaded && trip.positions" :loading="loading">
      <PositionResourceList :query="{tripId: trip.id}"/>
    </Section>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import mapboxgl from 'mapbox-gl';

import PositionResourceList from '@/components-V2/Position/ResourceList';
import RepeaterLabelInput from '@/components/Inputs/RepeaterLabelInput';
import Uplink from '@/components-V2/Uplink/Preview';

export default {
  name: 'Show',
  components: {
    PositionResourceList,
    RepeaterLabelInput,
    Uplink,
  },
  computed: {
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    directions() {
      return this.trip && this.trip.directions ? this.trip.directions : [];
    },
    loaded() {
      return this.trip !== null;
    },
    map() {
      return this.$store.state.map.map || null;
    },
    positions() {
      return this.trip ? this.trip.positions : [];
    },
    trip() {
      return this.$route.params.tripUUID ? this.$store.getters['trip/findBy'](this.$route.params.tripUUID, 'uuid') : null;
    },
  },
  created() {
    if (this.$route.params.tripUUID) {
      this.loadTrip()
    }

    window.app.vue.$on('uplinkReceived', this.reloadTrip);
  },
  destroyed() {
    this.removeTripFromMap();
    window.app.vue.$off('uplinkReceived', this.reloadTrip);
  },
  data() {
    return {
      loading: false,
      mapLayerIdCircles: `${this.$route.params.tripUUID}Circles`,
      mapLayerIdDirections: `${this.$route.params.tripUUID}Directions`,
      mapLayerIdDestination: `${this.$route.params.tripUUID}Destination`,
      mapLayerIdPath: this.$route.params.tripUUID,
    };
  },
  methods: {
    ...mapActions({
      show: 'trip/show'
    }),
    handleEdit() {
      const route = window.app.findRouteByName('uplink/edit', [], {
        params: {
          uplinkUUID: this.uplink.uuid
        },
      });

      this.$router.push(route);
    },
    loadTrip() {

      if (this.loading || this.loadingInBackground) {
        return;
      }

      if (this.loaded) {
        this.loadingInBackground = true;
      } else {
        this.loading = true;
      }

      return this.show({
        id: this.$route.params.tripUUID,
        params: {
          include: [
            'positions',
          ]
        },
      }).then(() => {
        this.renderTripOnMap();
      }).catch((e) => {
        console.log(e);
        window.app.snackbar('Error loading trip');
      }).finally(() => {
        this.loading = false;
        this.loadingInBackground = false;
      });
    },
    reloadTrip(e) {
      if (this.trip.device.id === parseInt(e.deviceId)) {
        this.loadTrip();
      }
    },
    removeTripFromMap() {

      // Remove Directions

      if (this.map.getLayer(this.mapLayerIdDirections)) {
        this.map.removeLayer(this.mapLayerIdDirections);
      }

      if (this.map.getSource(this.mapLayerIdDirections)) {
        this.map.removeSource(this.mapLayerIdDirections);
      }

      if (this.map.getLayer(this.mapLayerIdDestination)) {
        this.map.removeLayer(this.mapLayerIdDestination);
      }

      if (this.map.getSource(this.mapLayerIdDestination)) {
        this.map.removeSource(this.mapLayerIdDestination);
      }

      // Remove Route

      if (this.map.getLayer(this.mapLayerIdPath)) {
        this.map.removeLayer(this.mapLayerIdPath);
      }

      if (this.map.getLayer(this.mapLayerIdCircles)) {
        this.map.removeLayer(this.mapLayerIdCircles);
      }

      if (this.map.getSource(this.mapLayerIdPath)) {
        this.map.removeSource(this.mapLayerIdPath);
      }

    },
    renderTripOnMap() {

      if (!this.trip || !this.map) {
        return;
      }

      let routeColor = '#209CEE';
      let directionsColor = '#4E4598';

      // Route

      let coordinates = this.trip.positions.map((position) => [position.lng, position.lat]);

      let routeData = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [
            ...coordinates,
          ]
        }
      };

      if (this.map.getSource(this.mapLayerIdPath)) {
        this.map.getSource(this.mapLayerIdPath).setData(routeData);
      } else {
        this.map.addSource(this.mapLayerIdPath, {
          type: 'geojson',
          data: routeData,
        });
      }

      if (!this.map.getLayer(this.mapLayerIdPath)) {

        this.map.addLayer({
          id: this.mapLayerIdPath,
          type: 'line',
          source: this.mapLayerIdPath,
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': routeColor,
            'line-width': 4
          }
        }, 'zIndex1');
      }

      if (!this.map.getLayer(this.mapLayerIdCircles)) {
        this.map.addLayer({
          id: this.mapLayerIdCircles,
          type: 'circle',
          source: this.mapLayerIdPath,
          paint: {
            'circle-color': routeColor,
            'circle-pitch-alignment': 'map',
            'circle-radius': 6,
          },
        }, 'zIndex1');
      }

      if (this.directions.length) {

        let directions = [
          coordinates[coordinates.length - 1],
          ...this.directions.map((position) => [position.longitude, position.latitude]),
        ];

        // Only applicable to virtual devices

        // Directions

        let directionsData = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [
              ...directions,
            ]
          }
        };

        if (this.map.getSource(this.mapLayerIdDirections)) {
          this.map.getSource(this.mapLayerIdDirections).setData(directionsData);
        } else {
          this.map.addSource(this.mapLayerIdDirections, {
            type: 'geojson',
            data: directionsData,
          });
        }

        if (!this.map.getLayer(this.mapLayerIdDirections)) {

          this.map.addLayer({
            id: this.mapLayerIdDirections,
            type: 'line',
            source: this.mapLayerIdDirections,
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': directionsColor,
              'line-width': 4,
              'line-dasharray': [3, 3],
            }
          }, 'zIndex1');

        }

        // Destination

        let destinationData = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [
              ...directions[directions.length - 1],
            ]
          }
        };

        if (this.map.getSource(this.mapLayerIdDestination)) {
          this.map.getSource(this.mapLayerIdDestination).setData(destinationData);
        } else {
          this.map.addSource(this.mapLayerIdDestination, {
            type: 'geojson',
            data: destinationData,
          });
        }

        if (!this.map.getLayer(this.mapLayerIdDestination)) {

          this.map.addLayer({
            id: this.mapLayerIdDestination,
            type: 'circle',
            source: this.mapLayerIdDestination,
            paint: {
              'circle-color': directionsColor,
              'circle-pitch-alignment': 'map',
              'circle-radius': 6,
            },
          }, 'zIndex1');
        }

      } else {

        if (this.map.getLayer(this.mapLayerIdDestination)) {
          this.map.removeLayer(this.mapLayerIdDestination);
        }

        if (this.map.getSource(this.mapLayerIdDestination)) {
          this.map.removeSource(this.mapLayerIdDestination);
        }

        if (this.map.getLayer(this.mapLayerIdDirections)) {
          this.map.removeLayer(this.mapLayerIdDirections);
        }

        if (this.map.getSource(this.mapLayerIdDirections)) {
          this.map.removeSource(this.mapLayerIdDirections);
        }
      }

      // Reposition Map, if following Device

      if (this.trip.active && this.lockOnDevice) {

        window.app.vue.$emit('flyTo', coordinates[coordinates.length - 1]);

      } else if (!this.trip.active) {

        const bounds = new mapboxgl.LngLatBounds(
          coordinates[0],
          coordinates[0]
        );

        for (const coord of coordinates) {
          bounds.extend(coord);
        }

        this.map.fitBounds(bounds, {
          padding: 20,
        });

      }

    }
  },
}
</script>

<style scoped lang="scss">

</style>
