<template>
  <div id="uplink-form" v-if="uplink" class="is-padded">

    <Section title="Uplink" open>
      <Uplink :uplink="uplink" />
    </Section>

    <Section title="Parameter Values" open>

      <template v-if="parameterValues.length" v-for="(parameterValue, id) in parameterValues">
        <div class="columns">
          <div class="column">
            <Field>
              <Label class="has-text-centered">{{ parameterValue.parameter.title }}</Label>
              <TextInput v-model="form.fields.parameterValues[id].value" @input="calculateCalibration(parameterValue)" placeholder="Value"/>
              <FormError :field="`parameter_values.${id}.value`" :form="form"/>
            </Field>
          </div>
          <div class="column">
            <Field>
              <Label class="has-text-centered">Calibration</Label>
              <p class="value">{{parameterValue.parameter.calibration || 0}}</p>
            </Field>
          </div>
          <div class="column">
            <Field>
              <Label class="has-text-centered">After Calibration</Label>
              <p class="value">{{form.fields.parameterValues[id].valueCalibrated}}</p>
            </Field>
          </div>
          <div class="column">
            <Field>
              <Label class="has-text-centered">Unit</Label>
              <TextInput v-model="form.fields.parameterValues[id].unit" placeholder="Unit"/>
              <FormError :field="`parameter_values.${id}.unit`" :form="form"/>
            </Field>
          </div>
        </div>
      </template>

    </Section>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:right>

          <Button @click="$router.back()" outlined light>
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

</div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

import Uplink from '@/components-V2/Uplink/Preview';

export default {
  name: 'Form',
  components: {
    Uplink,
  },
  computed: {
    uplink() {
      return this.$route.params.uplinkUUID ? this.$store.getters['uplink/findBy'](this.$route.params.uplinkUUID, 'uuid') : null;
    },
    parameterValues() {
      return this.uplink ? this.uplink.parameterValues : [];
    },
    pageTitle() {
      return 'Uplink';
    }
  },
  created() {
    if (this.uplink) {
      this.fillForm();
    } else {
      this.loadUplink()
    }
  },
  data() {
    return {
      form: new Form({
        parameterValues: [],
      }),
      loading: false
    };
  },
  methods: {
    ...mapActions({
      show: 'uplink/show',
      update: 'uplink/update',
    }),
    calculateCalibration(parameterValue) {
      let newValue = parseFloat(parameterValue.value);

      if (! isNaN(newValue)) {
        let calibration = parameterValue.parameter.calibration || 0;
        parameterValue.valueCalibrated = newValue + calibration;
      } else {
        parameterValue.valueCalibrated = 0;
      }
    },
    handleSave() {

      const fields = {
        ...this.form.fields
      };
      this.form.errors.clear();

      this.loading = true;

      this.update(fields).then((uplinkId) => {

        const uplink = this.$store.getters['uplink/show'](uplinkId);

        window.app.snackbar('Uplink Saved');

        this.$router.push({
          name: 'uplink/show',
          params: {
            uplinkUUID: uplink.uuid,
            include: ['parameterValues'],
          },
        });

      }).catch((error) => {
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    fillForm() {
      this.form.fields = {
        ...this.uplink,
        parameterValues: this.parameterValues,
      };
    },
    loadUplink() {

      if (this.loading || this.loadingInBackground) {
        return;
      }

      if (this.loaded) {
        this.loadingInBackground = true;
      } else {
        this.loading = true;
      }

      return this.show({
        id: this.$route.params.uplinkUUID,
        params: {},
      }).then(() => {
        this.fillForm()
        this.loaded = true;
      }).catch((error) => {
        window.app.snackbar('Error loading uplink');
      }).finally(() => {
        this.loading = false;
        this.loadingInBackground = false;
      });
    },
  },
}
</script>

<style scoped lang="scss">
#uplink-form {
  .value {
    padding: 5px 15px;
    text-align: center;
  }
}
</style>
