<template>
  <div id="unit-index" class="is-padded">
    <UnitResourceList />
  </div>
</template>

<script>
import UnitResourceList from '@/components-V2/Unit/ResourceList';

export default {
  name: 'Index',
  components: {
    UnitResourceList,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
