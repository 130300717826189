<template>
  <div id="notifications" class="is-padded">

    <Instruction>
      These are the different notifications the system will send out. You can define which <router-link to="/my/channels">Channels</router-link> you'd like
      the notifications to be sent to.
    </Instruction>

    <div class="notifications">
      <template v-for="notification in notifications">
        <Box flat :black="$themeDark" class="my-notification">
          <Label>{{notification.label}}</Label>
          <p>{{notification.description}}</p>
          <div v-for="channel in notification.channels">
            <BCheckbox v-model="channel.enabled"
                       @input="handleNotificationUpdate(notification, channel)"
                       :disabled="(channel.requiresVerification && !channel.verified) || !notification.options.includes(channel.type)"
            >
              {{channel.name}}
              <div class="tag is-danger" style="font-size: .6em;" v-if="channel.requiresVerification && !channel.verified">UNVERIFIED</div>
              <BIcon icon="spinner" custom-class="fa-spin" v-if="updating[`${notification.id}.${channel.id}`] === 'saving'" size="is-small" />
              <BIcon icon="check-circle" type="is-success" v-if="updating[`${notification.id}.${channel.id}`] === 'saved'" size="is-small" />
            </BCheckbox>
          </div>
        </Box>
      </template>
    </div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from "@/internal";

export default {
  name: 'Notifications',
  computed: {
    notifications () {
      return this.$store.state.notification.notifications;
    },
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  created() {
    this.loadNotifications();
  },
  data() {
    return {
      form: new Form({}),
      ids: [],
      loading: false,
      updating: {},
    };
  },
  methods: {
    ...mapActions({
      index: 'notification/index',
      update: 'notification/update',
    }),
    handleNotificationUpdate(notification, channel) {
      const key = `${notification.id}.${channel.id}`;
      this.updating[key] = 'saving';
      this.update({
        notifications: this.notifications,
      }).then(() => {
        setTimeout(() => {

          this.updating = {
            ...this.updating,
            [key]: 'saved',
          };

          setTimeout(() => {
            this.updating = {
              ...this.updating,
              [key]: false,
            }
          }, 1500);

        }, 250);
      }).catch((error) => {
        this.form.recordErrors(error);
      });
    },
    loadNotifications() {
      this.index();
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
