import {
  ApiService,
} from '@/internal';

export default class NotificationService extends ApiService {

  constructor() {
    super();
    this.baseUrl = 'notification';
  }

  index(payload = {}) {

    const { params } = payload;

    return this.request({
      method: 'get',
      url: this.baseUrl,
      params: this.prepareParams(params),
    });
  }

  update(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'put',
      url: this.baseUrl,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }
}
