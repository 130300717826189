<template>
  <div class="domain-billing-faqs">
    <Title>FAQ's</Title>
    <template v-for="faq in faqs">
      <div class="faq" @click="faq.active = !faq.active">
        <div class="question" v-html="faq.question"></div>
        <div class="answer" v-html="faq.answer" v-if="faq.active"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DomainBillingFaqs',
  data() {
    return {
      faqs: [
        // {
        //   question: 'Is there a free license?',
        //   answer: `All accounts are provided one <span class="tag">XS Balanced</span> license for free, forever.`
        // },
        {
          question: 'Does my domain have to offer all license types?',
          answer: 'Any license type can be enabled/disabled.',
        },
        {
          question: 'Can I set my own prices?',
          answer: 'You can set the retail price of all license types.',
        },
        {
          question: 'How are accounts billed?',
          answer: `<p>Account billing is prepaid, meaning the account will pay for their license at the time they request it.</p>
                   <p>Account billing prorations are computed by Stripe and occur any time a device license is added, removed or changed.</p>
                   <p>Account device licenses will auto-renew until cancelled.</p>`,
        },
        {
          question: 'How are domains billed?',
          answer: `<p>Domain billing is postpaid, meaning we compute all domain licensing for the month and bill in one invoice for the entire month.</p>
                   <p>Domain prorations are computed by UplinkEngine, based on the domain's accounts adding, removing or changing device licenses.</p>
                   <p>Domain billing is tracked to the second and billed in hourly increments.</p>`,
        }
      ].map((faq) => {
        faq.active = false;
        return faq;
      }),
    };
  },
}
</script>

<style scoped lang="scss">
.domain-billing-faqs {
  margin: 60px auto;
  max-width: 60%;
  text-align: center;

  .faq {
    border: 1px solid $grey;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px 0;
    padding: 15px;
    text-align: left;

    .question {
      font-weight: 700;
    }

    .answer {
      margin-top: 15px;
    }
  }
}
</style>
