<template>
  <div id="note-form">

    <div class="is-padded">

      <Field v-if="notable">
        <Label>Note for {{$label($route.query.notableType)}}</Label>
        <FakeInput>{{notable.name || notable.title}}</FakeInput>
      </Field>

      <Field>
        <Label>Title *</Label>
        <TextInput v-model="form.fields.title" placeholder="Name"/>
        <FormError field="title" :form="form"/>
      </Field>

      <Field>
        <Label>Description *</Label>
        <TextAreaInput v-model="form.fields.description" placeholder="Description"/>
        <FormError field="description" :form="form"/>
      </Field>

    </div>

    <Teleport to="#inspector-footer">
      <Controls>

        <template v-slot:left>

          <Button @click="handleDelete" outlined danger :loading="deleting" v-if="note">
            Delete
          </Button>

        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined light>
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
  Note
} from '@/internal';


export default {
  name: 'Form',
  components: {},
  computed: {
    note() {
      return this.noteId ? this.$store.getters['note/findBy'](this.noteId, 'id') : null;
    },
    notable() {
      let notableId = this.form.fields.notableId;
      let notableType = this.form.fields.notableType ? this.form.fields.notableType.replace('App\\Models\\', '').toLowerCase() : null;

      if (notableType && notableId) {
        return this.$store.getters[`${notableType}/show`](notableId);
      }

      return null;
    },
    pageTitle() {
      return 'Note';
    }
  },
  created() {
    if(this.$route.params.noteUUID) {
      this.loadNote();
    } else {
      this.form.fields.notableId = this.$route.query.notableId;
      this.form.fields.notableType = 'App\\Models\\' + this.$route.query.notableType;
    }
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        notableType: null,
        notableId: null,
        title: '',
        description: ''
      }),
      loading: false,
      noteId: null,
      params: {
        include: [],
        limit: 10,
      },
    };
  },
  methods: {
    ...mapActions({
      destroy: 'note/destroy',
      deviceShow: 'device/show',
      deviceIndex: 'device/index',
      store: 'note/store',
      show: 'note/show',
      update: 'note/update',
    }),
    handleDelete () {
      this.$deleteDialog({
        target: this.note.title,
        targetType: 'Note',
        onConfirm: () => {
          this.loading = true;
          this.destroy({
            id: this.note.id,
          }).then(() => {
            window.app.snackbar('Note Deleted');
            this.$router.back();
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.loading = false;
          });
        },
      });
    },
    handleSave() {

      const fields = {
        ...this.form.fields
      };

      this.form.errors.clear();

      let action = null;

      if (this.note) {
        action = this.update;
      } else {
        action = this.store;
      }

      if (action) {
        this.loading = true;

        action(Note.preparePayload(fields)).then(() => {
          window.app.snackbar('Note Saved');
          this.$router.back();
        }).catch((error) => {
          this.form.recordErrors(error);
        }).finally(() => {
          this.loading = false;
        });
      }

    },
    fillForm() {
      this.form.fields = {
        ...this.note
      };
    },
    loadNote() {
      this.loading = true;
      return this.show({
        id: this.$route.params.noteUUID,
      }).then((response) => {
        this.noteId = response[0];
      }).catch((error) => {
        window.app.snackbar('Error loading note');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  watch: {
    note() {
      this.fillForm();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
