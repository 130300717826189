<template>
  <TextInput type="email" :value="value" @input="handleInput"/>
</template>

<script>
export default {
  name: 'EmailInput',
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
  props: {
    value: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">

</style>
