import {
  Model,
  ApiKeyService as Service
} from '@/internal';

export default class ApiKey extends Model {

  static get key() {
    return 'apiKey';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      name: {
        required: true,
        type: String,
      },
    };
  }

}
