<template>
  <div class="inspector-controls"
       :class="{
           'is-active': primaryMenu.active,
           'is-anchored': $layoutAnchored,
           'is-dark': $themeDark,
           'is-floating': $layoutFloating,
           'is-full-screen': isFullScreen,
           'is-inactive': !primaryMenu.active,
           'is-mobile': isMobile,
       }"
  >

    <div class="left">

      <BTooltip label="Back" position="is-right" v-if="backRoute" :active="!isMobile">
        <div class="inspector-control inspector-back" @click="handleInspectorBack">
          <BIcon icon="long-arrow-left" custom-class="fa-xl fa-fw"/>
        </div>
      </BTooltip>

      <BTooltip label="Edit" position="is-right" v-if="editRoute" :active="!isMobile">
        <router-link :to="editRoute" class="inspector-control" @click="handleInspectorBack">
          <BIcon icon="pencil" custom-class="fa-xl fa-fw"/>
        </router-link>
      </BTooltip>

    </div>

    <div class="center" v-if="shouldUseDomainLogo">
      <Logo style="font-size: 1.1em; color: #ffffff;"/>
    </div>

    <div class="center" v-else>
      <Title class="has-text-centered truncate">{{ $label(title) }}</Title>
    </div>

    <div class="right">

      <BTooltip label="Full Screen" position="is-left" v-if="!$route.meta.fullScreen && !isMobile">
        <div class="inspector-control" @click="handleInspectorFullScreenToggle">
          <BIcon :icon="isFullScreen ? 'compress-wide' : 'expand-wide'" custom-class="fa-xl fa-fw"/>
        </div>
      </BTooltip>

      <BTooltip label="Move" position="is-left" v-if="!isMobile">
        <div class="inspector-control" @click="handleInspectorPositionToggle" v-if="!isFullScreen">
          <BIcon :icon="inspector.position === 'right' ? 'square-arrow-down-left' : 'square-arrow-up-right'"
                 custom-class="fa-xl fa-fw"/>
        </div>
      </BTooltip>

      <BTooltip label="Close" position="is-left" :active="!isMobile" v-if="!isFullScreen">
        <div class="inspector-control inspector-close" @click="handleInspectorClose">
          <BIcon icon="times" custom-class="fa-xl fa-fw"/>
        </div>
      </BTooltip>

    </div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import Logo from '@/components/Logo';

export default {
  name: 'InspectorControls',
  components: {
    Logo,
  },
  computed: {
    backRoute() {
      return this.$route.meta.backRoute ? this.$route.meta.backRoute(this.$route) : null;
    },
    controlsLeft() {
      return this.inspector.header.left || [];
    },
    editRoute() {
      return this.$route.meta.editRoute ? this.$route.meta.editRoute(this.$route) : null;
    },
    inspector() {
      return this.$store.state.ui.inspector;
    },
    isFullScreen() {
      return this.$route.meta.fullScreen || this.inspector.fullScreen;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    primaryMenu() {
      return this.$store.state.ui.primaryMenu;
    },
    shouldUseDomainLogo() {
      return this.isMobile && window.location.hostname.includes('harmonyanalytica.com');
    },
    title() {

      if (typeof this.$route.meta.title === 'function') {
        return this.$route.meta.title();
      }

      return this.$route.meta.title;
    },
  },
  data() {
    return {
      currentRoute: null,
      lastRoute: null,
    };
  },
  methods: {
    ...mapActions({
      inspectorUpdate: 'ui/inspector',
    }),
    handleInspectorBack() {
      if (this.backRoute) {
        this.$router.push(this.backRoute);
      }
    },
    handleInspectorClose() {
      this.$router.push('/');
    },
    handleInspectorPositionToggle() {
      this.inspectorUpdate({
        position: this.inspector.position === 'right' ? 'bottom' : 'right',
      });
    },
    handleInspectorFullScreenToggle() {
      this.inspectorUpdate({
        fullScreen: !this.inspector.fullScreen,
      });
    },
  },
  props: {},
  watch: {
    ['$route']: {
      deep: true,
      immediate: true,
      handler() {
        if (this.currentRoute) {
          this.lastRoute = {
            ...this.currentRoute,
          };
        }

        this.currentRoute = {
          ...this.$route,
        };
      },
    }
  }
}
</script>

<style scoped lang="scss">
.inspector-controls {
  align-items: center;
  border-bottom: 1px solid $navigation-border-color;
  display: flex;
  justify-content: space-between;
  padding: 14px 7.5px 15px;

  &.is-dark {
    color: $white;
  }

  &.is-mobile {
    background: $black;
  }

  &.is-full-screen.is-primary-menu-inactive {
    padding-left: $primary-menu-width-inactive + 7.5px;
  }

  .inspector-control {
    align-items: center;
    color: $text;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 0 5px;
    padding: 0 2.5px;
  }

  &.is-dark {
    .inspector-control {
      color: $white;
    }
  }

  .left {
    align-items: center;
    display: flex;
    flex: 1;
  }

  .center {
    align-items: center;
    flex: 1;
    justify-content: center;
  }

  .center {
    width: 50%;
  }

  .right {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}
</style>
