import {
  Model,
  User,
  ReportService as Service,
} from '@/internal';

import moment from 'moment';

export default class Report extends Model {

  static get key() {
    return 'report';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      config: {
        preparePayload: (value) => {
          return value ? JSON.stringify(value) : null;
        },
      },
      sendAtTime: {
        preparePayload: (value) => {
          return value ? moment(value).format("HH:mm") : null;
        },
      },
      users: {
        type: User
      }
    };
  }

  get users() {
    return this.hasMany(User);
  }

}
