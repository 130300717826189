<template>
  <div id="domain-billing" class="is-padded">

    <BLoading active v-if="loading"/>

    <template v-if="loaded && domain">

      <template v-if="!domain.stripeSubscription">

        <div class="has-text-centered" style="margin-bottom: 15px;">
          <div style="font-size: 60px;">$49/month</div>
          <div style="font-size: 30px;">+</div>
        </div>

        <LicensesExplorer/>

        <div class="controls has-margin-top has-text-centered">

          <Button primary @click="handleDomainSubscription">
            Setup Subscription
          </Button>

        </div>

        <DomainBillingFaqs/>

      </template>

      <template v-else>

        <Section title="UplinkEngine Subscription">

          <template v-if="domain.stripeSubscription">

            <p>
              This is where you manage your subscription to UplinkEngine.
            </p>

            <Controls>

              <template v-slot:right>

                <Button primary outlined @click="handleDomainSubscription">
                  Manage Subscription
                </Button>

              </template>

            </Controls>


          </template>

          <template v-else-if="domain.billing.type === 'none'">

            <p>
              No subscription required for this domain.
            </p>

          </template>

        </Section>

        <Section :title="`${domain.name} Stripe Account`">

          <Instruction type="is-warning" v-if="!domain.stripeAccount">
            In order to get paid from your customers, you'll need to setup your Stripe account.
          </Instruction>

          <p>
            Within your Stripe Dashboard, you can see your subscriptions, invoices, payments, credit balances, etc.
            related to the financial transactions that occur in {{ domain.name }}.
          </p>

          <Controls>

            <template v-slot:right>

              <a v-if="domain && domain.stripeAccount && domain.stripeAccount.detailsSubmitted"
                 href="https://dashboard.stripe.com" class="button is-primary is-outlined" target="_blank">
                View Stripe Dashboard
              </a>

              <Button v-else primary outlined @click="handleDomainStripeOnboard">
                Setup Stripe Account
              </Button>

            </template>

          </Controls>

        </Section>

        <Section title="Account Billing" v-if="domain.billing.type !== 'custom'">

          <div class="columns">
            <div class="column is-half-desktop">
              <Field>
                <Label>Default Billing Method</Label>
                <SelectInput :options="billingTypeOptions" v-model="form.fields.accountBilling.type"/>
                <Help>When a new account is created, this is the billing method that will be assigned to the account.
                </Help>
              </Field>
            </div>
          </div>

        </Section>

        <Section title="Device Licenses" v-if="shouldShowLicenses">

          <template v-if="!domain.stripeAccount">

            <Instruction type="is-warning">
              You need to setup your Stripe account before you can manage your Device License Options.
            </Instruction>

          </template>

          <template v-else>

            <Instruction>
              When an account purchases a device license, they will be shown the options listed below.
            </Instruction>

            <table class="table licenses-table" :class="{ 'is-dark': $themeDark }">
              <tr>
                <th>License</th>
                <th>Enabled</th>
                <th>Cost / Month</th>
                <th>Price / Month</th>
                <th>Edit</th>
              </tr>
              <template v-for="product in products">
                <tr>
                  <td>{{ product.name }}</td>
                  <td>
                    <div class="tag is-success" v-if="product.enabled">Yes</div>
                    <div class="tag is-warning" v-else>No</div>
                  </td>
                  <td>
                    {{ $numeral(product.uplinkEnginePricePerMonth).format('$0,0.00') }}
                  </td>
                  <td>
                    <template v-if="product.domainStripePrice">
                      {{ $numeral(product.domainStripePrice.unitAmount / 100).format('$0,0.00') }}
                    </template>
                  </td>
                  <td>
                    <div class="pointer" @click="handleProductEdit(product)">
                      <BIcon icon="pencil"/>
                    </div>
                  </td>
                </tr>
              </template>
            </table>

            <div class="has-text-right">
              <Button primary outlined @click="handleLicensePickerPreview">
                Preview License Picker
              </Button>
            </div>

          </template>

        </Section>

        <DomainBillingFaqs/>

      </template>

      <BModal :active.sync="productFormActive">
        <div class="container container-extra-extra-small">
          <Box flat :black="$themeDark">

            <BLoading active v-if="loading"/>

            <div class="columns is-align-items-center">
              <div class="column is-four-fifths">
                <Field>
                  <Label>Name</Label>
                  <TextInput v-model="productForm.fields.name"/>
                </Field>
              </div>
              <div class="column">
                <Field>
                  <Label>Enabled</Label>
                  <BSwitch v-model="productForm.fields.enabled"/>
                </Field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <Field>
                  <Label>Cost / Month</Label>
                  <FakeInput locked>{{ $numeral(productForm.fields.cost).format('0,0.00') }}</FakeInput>
                </Field>
              </div>
              <div class="column">
                <Field>
                  <Label>Price / Month</Label>
                  <NumberInput v-model="productForm.fields.price" :precision="2" :step="0.01"/>
                </Field>
              </div>
            </div>

            <Controls>
              <template v-slot:right>

                <Button @click="productFormActive = false" outlined light>
                  Cancel
                </Button>

                <Button primary @click="handleProductSave" class="has-margin-left">
                  Save
                </Button>

              </template>
            </Controls>

          </Box>
        </div>
      </BModal>

      <BModal :active.sync="licensePickerPreviewActive">
        <div class="container container-small">
          <Box flat :black="$themeDark">
            <LicensePicker :domain="domain" @selected="handleLicensePickerSelected"/>
          </Box>
        </div>
      </BModal>

    </template>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:right>

          <Button @click="$router.back()" outlined light>
            Cancel
          </Button>

          <Button @click="handleDomainSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import DomainBillingFaqs from '@/components-V2/DomainBillingFaqs/DomainBillingFaqs';
import LicensePicker from '@/components-V2/LicensePicker/LicensePicker';
import LicensesExplorer from '@/components-V2/LicensesExplorer/LicensesExplorer';
import {Form} from '@/internal';

export default {
  name: 'Billing',
  components: {
    DomainBillingFaqs,
    LicensePicker,
    LicensesExplorer,
  },
  computed: {
    baseUrl() {
      return this.domain && this.domain.url ? this.domain.url.replace('https://', '').replace('http://', '') : '';
    },
    domain() {
      return this.domainUUID ? this.$store.getters['domain/findBy'](this.domainUUID, 'uuid') : null;
    },
    shouldShowCustomStripeProducts() {
      return this.domain && this.domain.billing.type === 'custom';
    },
    shouldShowLicenses() {
      return this.domain.billing.type === 'license';
    },
    products() {
      return this.domain ? this.domain.products
          .sort((a, b) => {

            if (!a.domainStripePrice || !b.domainStripePrice) {
              return 1;
            }

            return a.domainStripePrice.unitAmount < b.domainStripePrice.unitAmount ? 1 : -1;
          })
          .sort((a, b) => a.group > b.group ? 1 : -1)
        : [];
    },
  },
  created() {
    this.domainUUID = window.app.storage.getItem('domainBeingManaged');
    this.loadDomain().then(() => {
      this.loaded = true;

      if (this.$route.query.checkoutComplete !== undefined && this.domain.stripeSubscription) {
        window.app.snackbar('UplinkEngine Subscription Setup');
      }

      if (this.$route.query.onboardComplete !== undefined && this.domain.stripeAccount) {
        window.app.snackbar('Stripe Account Setup Complete');
      }
    });
  },
  data() {
    return {
      billingTypeOptions: [
        {
          label: '- Select a Method -',
          value: null,
        },
        {
          label: 'None',
          value: 'none',
          description: 'The account will not have any billing within UplinkEngine.'
        },
        {
          label: 'Per Device License',
          value: 'license',
          description: `Each device will have a product, which defines it's cost and quotas.`,
        },
        // {
        //   label: 'Flat Rate per Month',
        //   value: 'flat',
        //   description: `Accounts will be billed a flat rate each month, regardless of the number of devices they have.`,
        // },
      ],
      domainUUID: null,
      form: new Form({
        accountBilling: {
          type: 'license',
        },
      }),
      loaded: false,
      loading: false,
      productFormActive: false,
      productForm: new Form({
        id: null,
        name: '',
        cost: '',
        enabled: false,
        price: 0,
      }),
      licensePickerPreviewActive: false,
    };
  },
  methods: {
    ...mapActions({
      domainShow: 'domain/show',
      domainStripeOnboard: 'domain/stripeOnboard',
      domainSubscription: 'domain/subscription',
      domainUpdate: 'domain/update',
      productUpdate: 'product/update',
    }),
    fillForm() {
      this.form.fields = {
        accountBilling: {
          ...this.form.fields.accountBilling,
          ...this.domain ? this.domain.accountBilling : {},
        }
      }
    },
    handleProductEdit(product) {
      this.productForm.fields = {
        id: product.uuid,
        name: product.name,
        enabled: product.enabled,
        cost: product.uplinkEnginePricePerMonth,
        price: product.domainStripePrice.unitAmount / 100,
      };

      this.productFormActive = true;
    },
    handleLicensePickerSelected(product) {
      window.app.snackbar(`${product.name} picked`);
      this.licensePickerPreviewActive = false;
    },
    handleProductSave() {
      this.loading = true;
      this.productUpdate({
        ...this.productForm.fields,
      }).then(this.loadDomain).then(() => {
        this.productFormActive = false;
        window.app.snackbar('Product Updated');
      }).catch((error) => {
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleLicensePickerPreview() {
      this.licensePickerPreviewActive = !this.licensePickerPreviewActive;
    },
    handleDomainSave() {
      this.loading = true;
      this.form.errors.clear();

      this.domainUpdate({
        ...this.domain,
        ...this.form.fields,
      }).then(this.loadDomain).then(() => {
        window.app.snackbar('Domain Saved');
      }).catch((error) => {
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleDomainStripeOnboard() {
      this.loading = true;
      return this.domainStripeOnboard({
        id: this.domain.id,
      }).then((response) => {
        const link = response.data.data.link;
        if (link.url) {
          window.location.replace(link.url);
        } else {
          window.app.snackbar('Error. Please contact support');
          this.loading = false
        }
      }).catch((error) => {
        window.app.snackbarError(error);
        this.loading = false;
      }).finally(() => {

      });
    },
    handleDomainSubscription() {
      this.loading = true;
      return this.domainSubscription({
        id: this.domain.uuid,
      }).then((response) => {
        const url = response.data.data.session.url;
        if (url) {
          window.location.replace(url);
        } else {
          window.app.snackbar('Error. Please contact support');
          this.loading = false
        }
      }).catch((error) => {
        window.app.snackbarError(error);
        this.loading = false;
      }).finally(() => {
      });
    },
    loadDomain() {

      if (!this.domainUUID) {
        console.error('No domainUUID');
        return;
      }

      this.loading = true;

      return this.domainShow({
        id: this.domainUUID,
        params: {
          include: [
            'products'
          ],
          with: [
            'stripe_account',
            'stripe_customer_subscription',
          ].join(','),
        },
      }).then(this.fillForm).catch((e) => {
        console.error(e);
        window.app.snackbar('Error loading Domain');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">
#domain-billing {
  .licenses-table {

    border-radius: 4px;
    overflow: hidden;
    width: 100%;

    tr {
      th {
        background: $grey-dark;
        color: $white;
        text-align: center;
        white-space: nowrap;
      }

      td {

        font-size: 1.1em;
        padding: 15px;
        text-align: center;

        &:first-child {
          text-align: left;
        }
      }
    }
  }
}
</style>
