import {
  ResourceService,
} from '@/internal';

export default class AlarmService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'alarm';
  }

  saveMany(payload = {}) {
    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: this.baseUrl + '/save',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }
}
