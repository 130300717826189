<template>
  <div>

    <Instruction type="is-warning" v-if="inventory.claimedAt">
      This device has already been claimed.
    </Instruction>

    <LakeStreetMonitor1 v-if="isLakeStreetMonitor1" :inventory="inventory" />
    <HarmonyAnalyticaFS1FlowSensor v-else-if="isHarmonyAnalyticaFS1FlowSensor" :inventory="inventory" />
    <HarmonyAnalyticaH2SoilSensor v-else-if="isHarmonyAnalyticaH2SoilSensor" :inventory="inventory" />
    <HarmonyAnalyticaPS1PressureSensor v-else-if="isHarmonyAnalyticaPS1PressureSensor" :inventory="inventory" />
    <HarmonyAnalyticaS1WiredSoilSensor v-else-if="isHarmonyAnalyticaS1WiredSoilSensor" :inventory="inventory"  />
    <UnknownProduct v-else />
<!--    <JSON :data="inventory" />-->
  </div>
</template>

<script>
import LakeStreetMonitor1 from '@/components-V2/Definintions/LakeStreetMonitor1.vue';
import HarmonyAnalyticaH2SoilSensor from '@/components-V2/Definintions/HarmonyAnalyticaH2SoilSensor.vue';
import HarmonyAnalyticaS1WiredSoilSensor from '@/components-V2/Definintions/HarmonyAnalyticaS1WiredSoilSensor.vue';
import UnknownProduct from '@/components-V2/Definintions/UnknownProduct.vue';
import HarmonyAnalyticaFS1FlowSensor from '@/components-V2/Definintions/HarmonyAnalyticaFS12FlowSensor.vue';
import HarmonyAnalyticaPS1PressureSensor from '@/components-V2/Definintions/HarmonyAnalyticaPS1PressureSensor.vue';

export default {
  name: 'Product',
  components: {
    HarmonyAnalyticaFS1FlowSensor,
    HarmonyAnalyticaH2SoilSensor,
    HarmonyAnalyticaPS1PressureSensor,
    HarmonyAnalyticaS1WiredSoilSensor,
    LakeStreetMonitor1,
    UnknownProduct,
  },
  computed: {
    isHarmonyAnalyticaFS1FlowSensor() {
      return this.inventory.config.product === 'App\\Definitions\\HarmonyAnalyticaFS1FlowSensorTTN' || this.inventory.config.product === 'App\\Definitions\\HarmonyAnalyticaFS1FlowSensorChirpStack';
    },
    isHarmonyAnalyticaH2SoilSensor() {
      return this.inventory.config.product === 'App\\Definitions\\HarmonyAnalyticaH2SoilSensor' || this.inventory.config.product === 'App\\Definitions\\HarmonyAnalyticaH2SoilSensorChirpStack';
    },
    isHarmonyAnalyticaPS1PressureSensor() {
      return this.inventory.config.product === 'App\\Definitions\\HarmonyAnalyticaPS1PressureSensorTTN' || this.inventory.config.product === 'App\\Definitions\\HarmonyAnalyticaPS1PressureSensorChirpStack';
    },
    isHarmonyAnalyticaS1WiredSoilSensor() {
      return this.inventory.config.product === 'App\\Definitions\\HarmonyAnalyticaS1WiredSoilSensor' || this.inventory.config.product === 'App\\Definitions\\HarmonyAnalyticaS1WiredSoilSensorChirpStack';
    },
    isLakeStreetMonitor1() {
      return this.inventory.config.product === 'App\\Definitions\\LakeStreetMonitor1';
    },
  },
  props: {
    inventory: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
