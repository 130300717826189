import Claim from '@/views/Inventory/Claim';

const routes = [
  {
    name: 'inventory/claim',
    path: '/inventory/:inventoryUUID/claim',
    component: Claim,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('home');
      },
      containerSize: 'container-extra-extra-small',
      fullScreen: true,
      icon: 'box',
      middleware: ['auth'],
      title: 'Claim',
    },
  },
];

export default routes;
