<template>
  <div class="lorawan-network-server-resource-list">
    <ResourceList addLabel="Add LoRaWAN Network Server"
                  :addRoute="addRoute"
                  :params="params"
                  :query="query"
                  :resource="resource"
                  :renderAs="renderAs"
                  :sortByOptions="sortByOptions"
    />
  </div>
</template>

<script>
import {LorawanNetworkServer} from '@/internal';
import LorawanNetworkServerResourceListItem from '@/components-V2/LorawanNetworkServer/ResourceListItem';

export default {
  name: 'LorawanNetworkServerResourceList',
  data() {
    return {
      addRoute: !this.hideAddButton ? window.app.findRouteByName('lorawan-network-server/create') : null,
      params: {},
      renderAs: LorawanNetworkServerResourceListItem,
      resource: LorawanNetworkServer,
      sortByOptions: [
        {
          label: 'Name',
          value: 'name',
        },
        {
          label: 'Created Date',
          value: 'created_at',
        },
      ],
    };
  },
  props: {
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {},
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
