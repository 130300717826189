<template>
  <div class="heartbeat-interval-input">
    <SelectInput @input="handleSelectChange" :options="intervals" v-model="interval" />
    <template v-if="interval === 'custom'">
      <Label class="has-margin-top">Heartbeat Interval (seconds)</Label>
      <NumberInput :value="value" @input="handleInput" placeholder="Seconds" />
    </template>
  </div>
</template>

<script>

export default {
  name: 'HeartbeatIntervalInput',
  components: {},
  computed: {},
  created() {
    if (this.valueIsCommon(this.value)) {
      this.interval = this.value;
    } else {
      this.interval = 'custom';
    }
  },
  data () {
    return {
      interval: null,
      intervals: [
        {
          label: 'Hour',
          value: 3600,
        },
        {
          label: 'Day',
          value: 86400,
        },
        {
          label: 'Week',
          value: 604800,
        },
        {
          label: 'Month',
          value: 2592000,
        },
        {
          label: 'Custom',
          value: 'custom',
        },
      ],
    };
  },
  methods: {
    handleSelectChange(value) {
      if (value !== 'custom') {
        this.$emit('input', value);
      }
    },
    handleInput (value) {
      this.$emit('input', value);
    },
    valueIsCommon(value) {
      return this.intervals.map(i => i.value).filter(i => i !== null).includes(parseInt(value));
    },
  },
  props: {
    value: {
      required: true,
    },
  },
};
</script>

<style lang="scss">
.heartbeat-interval-input {

}
</style>
