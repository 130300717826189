<template>
  <div class="repeater-input">

    <template v-for="(item, i) in valueSorted">

      <Box flat :black="$themeDark">

        <div class="is-flex is-align-items-center is-justify-content-flex-end">
               <span class="item-remove" :class="{ 'has-text-grey-dark': i === 0 }"
                     @click="i > 0 ? handleMoveUp(i) : null">
                 <BIcon icon="arrow-circle-up"/>
               </span>
          <span class="item-remove" :class="{ 'has-text-grey-dark': i >= value.length - 1 }"
                @click="i < value.length - 1 ? handleMoveDown(i) : null">
                 <BIcon icon="arrow-circle-down"/>
               </span>
          <span class="item-remove" @click="handleRemove(i)">
                 <BIcon icon="minus-circle"/>
               </span>
        </div>

        <Field>
          <div class="select is-fullwidth" :class="{ 'is-dark': $themeDark }">
            <select v-model="value[i].deviceId">
              <template v-for="option in deviceOptions">
                <option :value="option.value">{{ option.label }}</option>
              </template>
            </select>
          </div>
          <FormError :field="`widgets.${i}.device_id`" :form="form"/>
        </Field>

        <Field v-if="value[i].deviceId">

          <BDropdown scrollable class="is-block">
            <template #trigger>
              <FakeInput class="is-flex is-align-items-center is-justify-content-space-between">
                {{value[i].data && value[i].data.length > 0 ? `${value[i].data.length} Selected` : 'Select Parameters'}} <BIcon icon="chevron-down"></BIcon>
              </FakeInput>
            </template>

            <BDropdownItem v-for="option in parameterOptions[value[i].deviceId]" :key="option.value" custom aria-role="listitem">
              <BCheckbox v-model="value[i].data"
                         :native-value="option.value"
                         :key="option.value"
              >
                {{ option.label }}
              </BCheckbox>
            </BDropdownItem>

          </BDropdown>

          <FormError :field="`widgets.${i}.data`" :form="form"/>
        </Field>

      </Box>

    </template>

    <div class="has-margin-top">
      <Button block outlined :light="$themeDark" :primary="$themeLight" @click="handleAdd">{{ addLabel }}</Button>
    </div>

  </div>
</template>

<script>

const template = {
  deviceId: null,
  data: [],
  rank: 0
};

export default {
  name: 'RepeaterWidgetInput',
  computed: {
    deviceOptions() {
      return [
        {
          label: '- Select Device -',
          value: null,
        },
        ...this.devices.sort((a, b) => a.name > b.name ? 1 : -1).map((device) => {
          return {
            'label': device.name,
            'value': device.id
          };
        }),
      ];
    },
    parameterOptions() {
      let parameters = {};

      this.devices.forEach((device) => {
        parameters[device.id] = device.parameters ? device.parameters.map((parameter) => {
          return {
            label: parameter.title,
            value: parameter.id
          };
        }) : [];
      })

      return parameters;
    },
    valueSorted() {
      return this.value.sort((a, b) => a.rank > b.rank ? 1 : -1);
    }
  },
  data() {
    return {};
  },
  created() {
    if (this.value.length < 1) {
      this.handleAdd();
    }
  },
  methods: {
    handleAdd() {
      this.$emit('input', [
        ...this.value,
        {
          ...template,
          rank: this.value.length
        },
      ]);
    },
    handleMoveUp(i) {
      const toIndex = i - 1;

      if (toIndex < 0) {
        return;
      }

      let widgets = [...this.value];
      let widget = widgets[i];

      widgets.splice(i, 1);
      widgets.splice(toIndex, 0, widget);

      for (let index in widgets) {
        widgets[index]['rank'] = index;
      }

      this.$emit('input', widgets);
    },
    handleMoveDown(i) {
      const toIndex = i + 1;

      if (toIndex > this.value.length - 1) {
        return;
      }

      let widgets = [...this.value];
      let widget = widgets[i];

      widgets.splice(i, 1);
      widgets.splice(toIndex, 0, widget);

      for (let index in widgets) {
        widgets[index]['rank'] = index;
      }

      this.$emit('input', widgets);
    },
    handleInput(property, e, i) {

      let value = [
        ...this.value,
      ];

      value[i][property] = e.target !== undefined ? e.target.value : e;

      this.$emit('input', value);
    },
    handleRemove(i) {
      this.$emit('input', this.value.filter((item, j) => i !== j));
    },
  },
  props: {
    addLabel: {
      type: String,
    },
    form: {
      required: false,
      type: Object,
    },
    value: {
      default: () => [],
      required: true,
      type: Array,
    },
    devices: {
      default: () => [],
      required: true,
      type: Array
    },
  },
}
</script>

<style scoped lang="scss">

</style>
