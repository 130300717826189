import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const {} = payload;

  return new Menu({
    id: 'system',
    title: 'System',
    color: '#6bb208',
    icon: 'cog',
    open: payload.open !== undefined ? payload.open : false,
    menuItems: [
      // {
      //   icon: 'eye',
      //   title: 'Privacy Policy',
      //   onClick() {
      //     window.open(process.env.VUE_APP_PRIVACY_POLICY);
      //   },
      // },
      // {
      //   icon: 'gavel',
      //   title: 'Terms of Use',
      //   onClick() {
      //     window.open(process.env.VUE_APP_TERMS_OF_USE);
      //   },
      // },
      {
        route: window.app.findRouteByName('system/settings'),
      },
    ],
  });
};
