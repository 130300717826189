import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const {
    account,
    domain,
    system,
    user,
  } = payload;

  return new Menu({
    title: user.account.name,
    color: '#17a889',
    icon: 'building',
    open: payload.open !== undefined ? payload.open : false,
    menuItems: [
      {
        route: window.app.findRouteByName('account/edit'),
        when: user.hasPermission('accountEdit'),
      },
      {
        route: window.app.findRouteByName('account/billing'),
        when: account !== null && account.shouldShowBilling() && user.hasPermission('accountEdit'),
      },
      {
        route: window.app.findRouteByName('account/user/index'),
        when: user.hasPermission('accountEdit'),
      },
      {
        route: window.app.findRouteByName('api-key/index'),
        when: domain.featureEnabled('api_keys'),
      },
      {
        route: window.app.findRouteByName('lorawan-network-server/index'),
        when: domain.featureEnabled('lorawan_network_server') && user.hasPermission('accountEdit'),
      },
      {
        route: window.app.findRouteByName('account/onboarding/index'),
        when: domain.featureEnabled('onboarding'),
      },{
        route: window.app.findRouteByName('account/switch'),
      },
    ],
  });
};
