<template>
  <Page id="forgot-password-page">
    <div class="container container-extra-extra-small">
      <div class="columns">
        <div class="column">

          <Title>Forgot Password?</Title>

          <Box flat :black="$themeDark">

            <Field>
              <Label>Email</Label>
              <EmailInput v-model="form.fields.email"/>
              <FormError field="email" :form="form"/>
            </Field>

            <div class="controls has-margin-top">

              <Button primary block :loading="loading" @click="handleForgotPassword">Send Password Reset Email</Button>

              <div class="has-text-centered is-padded" :class="{ 'has-text-light': $themeDark }">
                - or -
              </div>

              <Button router-link outlined block :light="$themeDark" to="/auth/login">Log In</Button>

            </div>

          </Box>

        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

export default {
  name: 'ForgotPassword',
  components: {},
  data() {
    return {
      form: new Form({
        email: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      forgotPassword: 'auth/forgotPassword',
    }),
    handleForgotPassword() {

      this.loading = true;
      this.forgotPassword({
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar('Check your email for more instructions');
      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
