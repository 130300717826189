import Vue from 'vue';
import Vuex from 'vuex';

import {StoreBuilder} from '@lakestreetsoftware/toolbox';

import AccountStore from '@/domain/Account/AccountStore';
import ActionStore from '@/domain/Action/ActionStore';
import ActivityStore from '@/domain/Activity/ActivityStore';
import AlarmStore from '@/domain/Alarm/AlarmStore';
import ApiKeyStore from '@/domain/ApiKey/ApiKeyStore';
import AssetStore from '@/domain/Asset/AssetStore';
import AuthStore from '@/domain/Auth/AuthStore';
import ConditionStore from '@/domain/Condition/ConditionStore';
import ConverterStore from '@/domain/Converter/ConverterStore';
import DashboardStore from '@/domain/Dashboard/DashboardStore';
import DeviceStore from '@/domain/Device/DeviceStore';
import DomainStore from '@/domain/Domain/DomainStore';
import FileStore from '@/domain/File/FileStore';
import IntegrationStore from '@/domain/Integration/IntegrationStore';
import InventoryStore from '@/domain/Inventory/InventoryStore';
import LicenseStore from '@/domain/License/LicenseStore';
import LorawanNetworkServerStore from '@/domain/LorawanNetworkServer/LorawanNetworkServerStore';
import MapStore from '@/domain/Map/MapStore';
import MapLayerStore from '@/domain/MapLayer/MapLayerStore';
import NoteStore from '@/domain/Note/NoteStore';
import NotificationStore from '@/domain/Notification/NotificationStore';
import NotificationChannelStore from '@/domain/NotificationChannel/NotificationChannelStore';
import OnboardingStore from '@/domain/Onboarding/OnboardingStore';
import ParameterStore from '@/domain/Parameter/ParameterStore';
import ParameterValueStore from '@/domain/ParameterValue/ParameterValueStore';
import ProductStore from '@/domain/Product/ProductStore';
import PositionStore from '@/domain/Position/PositionStore';
import QRCodeStore from '@/domain/QRCode/QRCodeStore';
import RegionStore from '@/domain/Region/RegionStore';
import ReportStore from '@/domain/Report/ReportStore';
import RuleStore from '@/domain/Rule/RuleStore';
import SecurityStore from '@/domain/Security/SecurityStore';
import SubscriptionStore from '@/domain/Subscription/SubscriptionStore';
import SystemStore from '@/domain/System/SystemStore';
import TagStore from '@/domain/Tag/TagStore';
import TripStore from '@/domain/Trip/TripStore';
import UIStore from '@/domain/UI/UIStore';
import UplinkImportStore from '@/domain/UplinkImport/UplinkImportStore';
import UplinkExportStore from '@/domain/UplinkExport/UplinkExportStore';
import UplinkStore from '@/domain/Uplink/UplinkStore';
import UsageStore from '@/domain/Usage/UsageStore';
import UserStore from '@/domain/User/UserStore';
import UnitStore from '@/domain/Unit/UnitStore';
import VerificationCodeStore from '@/domain/VerificationCode/VerificationCodeStore';
import WidgetStore from '@/domain/Widget/WidgetStore';

export const storeBuilder = new StoreBuilder();

Vue.use(Vuex);

storeBuilder.addModule(AccountStore);
storeBuilder.addModule(ActionStore);
storeBuilder.addModule(ActivityStore);
storeBuilder.addModule(AlarmStore);
storeBuilder.addModule(ApiKeyStore);
storeBuilder.addModule(AssetStore);
storeBuilder.addModule(AuthStore);
storeBuilder.addModule(ConditionStore);
storeBuilder.addModule(ConverterStore);
storeBuilder.addModule(DashboardStore);
storeBuilder.addModule(DeviceStore);
storeBuilder.addModule(DomainStore);
storeBuilder.addModule(FileStore);
storeBuilder.addModule(IntegrationStore);
storeBuilder.addModule(InventoryStore);
storeBuilder.addModule(LicenseStore);
storeBuilder.addModule(LorawanNetworkServerStore);
storeBuilder.addModule(MapLayerStore);
storeBuilder.addModule(MapStore);
storeBuilder.addModule(NoteStore);
storeBuilder.addModule(NotificationChannelStore);
storeBuilder.addModule(NotificationStore);
storeBuilder.addModule(OnboardingStore);
storeBuilder.addModule(ParameterStore);
storeBuilder.addModule(ParameterValueStore);
storeBuilder.addModule(PositionStore);
storeBuilder.addModule(ProductStore);
storeBuilder.addModule(RegionStore);
storeBuilder.addModule(ReportStore);
storeBuilder.addModule(RuleStore);
storeBuilder.addModule(QRCodeStore);
storeBuilder.addModule(SecurityStore);
storeBuilder.addModule(SubscriptionStore);
storeBuilder.addModule(SystemStore);
storeBuilder.addModule(TagStore);
storeBuilder.addModule(TripStore);
storeBuilder.addModule(UIStore);
storeBuilder.addModule(UplinkImportStore);
storeBuilder.addModule(UplinkExportStore);
storeBuilder.addModule(UplinkStore);
storeBuilder.addModule(UsageStore);
storeBuilder.addModule(UserStore);
storeBuilder.addModule(UnitStore);
storeBuilder.addModule(VerificationCodeStore);
storeBuilder.addModule(WidgetStore);

export default new Vuex.Store({
  modules: storeBuilder.getVuexModules(),
});
