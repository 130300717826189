import {
  Asset,
  Condition,
  Device,
  Model,
  ParameterService as Service,
  ParameterValue, Tag,
  Unit,
} from '@/internal';

export default class Parameter extends Model {

  static get key() {
    return 'parameter';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      payloadPath: {
        required: true,
        type: String,
      },
      parameter: {
        required: true,
        type: String,
      },
      calibration: {
        required: false,
        type: Number,
      },
      unit: {
        required: true,
        type: String,
      },
      minY: {
        required: false,
        type: Number,
      },
      maxY: {
        required: false,
        type: Number,
      },
    };
  }

  static get class() {
    return 'App\\Models\\Parameter';
  }

  get asset() {
    return this.hasOne(Asset);
  }

  get conditions() {
    return this.hasMany(Condition);
  }

  get device() {
    return this.hasOne(Device);
  }

  get latestParameterValue() {
    return this.hasOne(ParameterValue, null, null, 'latestParameterValue');
  }

  get parameterValues() {
    return this.hasMany(ParameterValue);
  }

  get previousParameterValue() {
    return this.hasOne(ParameterValue, null, null, 'previousParameterValue');
  }

  get tags() {
    return this.hasMany(Tag);
  }

  get unit() {
    return this.hasOne(Unit);
  }

  toPlainObject() {
    return {
      ...this,
      asset: this.asset,
      device: this.device,
      latestParameterValue: this.latestParameterValue,
      parameterValues: this.parameterValues,
      previousParameterValue: this.previousParameterValue,
      tags: this.tags,
      unit: this.unit,
    };
  }

}
