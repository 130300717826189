<template>
  <div id="report-index" class="is-padded">
    <ReportResourceList />
  </div>
</template>

<script>
import ReportResourceList from '@/components-V2/Report/ResourceList';

export default {
  name: 'Index',
  components: {
    ReportResourceList,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
