<template>
  <router-link :to="`/note/${note.uuid}`" class="note resource-list-item" :class="{ 'is-dark': $themeDark }">
    <div class="note-title">{{note.title}}</div>
  </router-link>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    note() {
      return this.resource;
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
