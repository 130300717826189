import Index from '@/views/Note/Index';
import Form from '@/views/Note/Form';

const routes = [
  {
    name: 'note/index',
    path: '/note',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'note',
      middleware: ['auth'],
      title: 'Notes',
    },
  },
  {
    name: 'note/create',
    path: '/note/create',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('note/index');
      },
      containerSize: 'container-extra-small',
      icon: 'note',
      middleware: ['auth'],
      title: 'New Note',
    },
  },
  {
    name: 'note/edit',
    path: '/note/:noteUUID/edit',
    component: Form,
    meta: {
      backRoute: (route) => {
        return window.app.findRouteByName('note/show', {
          noteUUID: route.params.noteUUID,
        });
      },
      containerSize: 'container-extra-small',
      icon: 'note',
      middleware: ['auth'],
      title: 'Edit Note',
    }
  },
  {
    name: 'note/show',
    path: '/note/:noteUUID',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('note/index');
      },
      containerSize: 'container-extra-small',
      icon: 'note',
      middleware: ['auth'],
      title: 'Note',
    },
  },
];

export default routes;
