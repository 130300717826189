import {
  File as Model,
  FileService,
  ResourceStore,
} from '@/internal';

const service = new FileService();

const store = new ResourceStore({
  model: Model,
  actions: {
    replace({commit}, payload = {}) {
      return service.replace(payload.id, payload);
    },
    scan({commit}, payload = {}) {
      return service.scan(payload.id, payload);
    },
  },
});

export default store.toObject();
