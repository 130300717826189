<template>
  <router-link :to="`/lorawan-network-server/${lorawanNetworkServer.uuid}/edit`" class="lorawan-network-server resource-list-item" :class="{ 'is-dark': $themeDark }">
    {{lorawanNetworkServer.name}}
  </router-link>
</template>

<script>
import Preview from '@/components-V2/Report/Preview';

export default {
  name: 'ResourceListItem',
  components: {
    Preview,
  },
  computed: {
    lorawanNetworkServer() {
      return this.resource;
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
