import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const {$router} = payload;

  return new Menu({
    title: 'Dashboard',
    // color: '#9100f7',
    icon: 'gauge',
    onClick: () => {
      $router.push('/');
    },
    menuItems: [],
  });
};
