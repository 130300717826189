<template>
  <div id="domain-account-show" class="is-padded">

    <BLoading active v-if="loading"/>

    <template v-else>

      <Section title="Profile" open>

        <Field>
          <Label>ID</Label>
          <FakeInput clickToCopy locked>
            {{ account.uuid }}
          </FakeInput>
        </Field>

        <Field>
          <Label>Name</Label>
          <FakeInput clickToCopy locked>
            {{ account.name }}
          </FakeInput>
        </Field>

      </Section>

      <Section title="Billing Settings">

        <Field>
          <Label>Billing Method</Label>
          <SelectInput :options="billingTypeOptions" v-model="form.fields.billing.type"/>
        </Field>

        <div v-if="form.fields.billing.type === 'usage'">

          <Label>Usage Charges</Label>

          <Instruction>
            Your domain will be charged [x] amount of credits when each of the following events occurs.
            You can adjust how many credits this account is charged by modifying the variables below. These
            variables act like a markup or a discount on what the platform is charging your domain.
          </Instruction>

          <Box flat :black="$themeDark">

            <Field>
              <Label>Data Processing [{{ domain.billing.rates.uplinkIngest }}]</Label>
              <NumberInput v-model="form.fields.billing.rates.uplinkIngest" :min="0"/>
              <Help>
                When an uplink is received, the account will be charged this many credits for each parameter that is
                processed.
              </Help>
              <Instruction type="is-danger"
                           v-if="form.fields.billing.rates.uplinkIngest < domain.billing.rates.uplinkIngest">
                You're charging this account less than your being charged [{{ domain.billing.rates.uplinkIngest }}] for
                this
                event.
              </Instruction>
            </Field>

            <Field>
              <Label>Daily Data Retention [{{ domain.billing.rates.parameterValuesStorage }}]</Label>
              <NumberInput v-model="form.fields.billing.rates.parameterValuesStorage" :min="0"/>
              <Help>
                At midnight (UTC) each night, the account will be charged for all parameter values that are stored on
                the platform.
              </Help>
              <Instruction type="is-danger"
                           v-if="form.fields.billing.rates.parameterValuesStorage < domain.billing.rates.parameterValuesStorage">
                You're charging this account less than your being charged [{{
                  domain.billing.rates.parameterValuesStorage
                }}]
                for this event.
              </Instruction>
            </Field>

          </Box>

        </div>

        <Instruction type="is-danger" v-if="form.fields.billingType === 'none'">
          Your domain will still be charged for this account's usage, even though you are not charging them.
        </Instruction>

      </Section>

    </template>

    <Teleport to="#inspector-footer">
      <Controls>
        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button primary @click="handleSave" class="has-margin-left">
            Save
          </Button>

        </template>
      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

export default {
  name: 'Show',
  components: {},
  computed: {
    account() {
      return this.$route.params.accountUUID ? this.$store.getters['account/findBy'](this.$route.params.accountUUID, 'uuid') : null;
    },
    domain() {
      return this.domainUUID ? this.$store.getters['domain/findBy'](this.domainUUID, 'uuid') : null;
    },
  },
  created() {
    this.domainUUID = window.app.storage.getItem('domainBeingManaged');
    this.loadDomain();
    this.loadAccount();
  },
  data() {
    return {
      billingTypeOptions: [
        {
          label: '- Select a Method -',
          value: null,
        },
        {
          label: 'None',
          value: 'none',
          description: 'The account will not have any billing within UplinkEngine.'
        },
        {
          label: 'Per Device License',
          value: 'license',
          description: `Each device will have a license, which defines it's cost and quotas.`,
        },
        // {
        //   label: 'Flat Rate per Month',
        //   value: 'flat',
        //   description: `Accounts will be billed a flat rate each month, regardless of the number of devices they have.`,
        // },
      ],
      domainUUID: null,
      form: new Form({
        billing: {
          type: 'license',
        },
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      accountShow: 'account/show',
      domainAccountUpdate: 'domain/accountUpdate',
      domainShow: 'domain/show',
    }),
    handleSave() {
      this.loading = true;
      this.domainAccountUpdate({
        id: this.domain.uuid,
        accountId: this.account.id,
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar('Saved');
      }).finally(() => {
        this.loading = false;
      });
    },
    fillForm() {
      this.form.fields = {
        ...this.form.fields,
        billing: {
          ...this.form.fields.billing,
          ...this.account.billing,
          rates: {
            ...this.form.fields.billing.rates,
            ...this.account.billing.rates,
          },
        },
      }
    },
    loadAccount() {
      this.loading = true;
      this.accountShow({
        id: this.$route.params.accountUUID,
        params: {
          with: [
            'plan',
            'subscription',
            'subscribed',
          ].join(','),
        },
      }).then(() => {
        this.fillForm();
      }).finally(() => {
        this.loading = false;
      });
    },
    loadDomain() {

      if (!this.domainUUID) {
        console.error('No domainUUID');
        return;
      }

      this.loading = true;

      return this.domainShow({
        id: this.domainUUID,
      }).catch((e) => {
        console.error(e);
        window.app.snackbar('Error loading Domain');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
