<template>
  <div class="estimated-location">

    <div class="columns">
      <div class="column">
        <span class="tag is-danger">
        HIGHLY EXPERIMENTAL
      </span>
      </div>
      <div class="column is-two-thirds has-text-right" v-if="estimatedLocation">
        <BCheckbox v-model="mapLayersEnabled.location">Location</BCheckbox>
        <BCheckbox v-model="mapLayersEnabled.radius">Radius</BCheckbox>
        <BCheckbox v-model="mapLayersEnabled.hotspots">Hotspots</BCheckbox>
      </div>
    </div>

    <template v-if="estimatedLocation">

      <LabelLineValue label="Latitude" :value="estimatedLocation.lat" click-to-copy />

      <LabelLineValue label="Longitude" :value="estimatedLocation.lng" click-to-copy />

<!--      <LabelLineValue label="Confidence" :value="`${uplink.estimatedLocation.confidence}%`" />-->

<!--      <LabelLineValue label="Hotspots Used" :value="hotspots.length" />-->

<!--      <LabelLineValue label="Hotspots Available" :value="uplink.raw.hotspots.length" />-->

    </template>

    <template v-else>
      <Instruction type="is-warning">
        We weren't able to generate an estimated location for this Uplink.
      </Instruction>
    </template>

  </div>
</template>

<script>
import {
  circle as turfCircle,
} from '@turf/turf';

let estimatedCenterColor = '#00DCAB';
let estimatedRadiusColor = '#00DCAB';
let hotspotsColor = '#FFDD57';

export default {
  name: 'EstimatedLocation',
  computed: {
    estimatedLocation() {
      return this.uplink ? this.uplink.estimatedLocation : null;
    },
    estimatedRadius() {

      if (!this.uplink.estimatedLocation) {
        return null;
      }

      let id = 'estimatedRadius';
      let center = [this.uplink.estimatedLocation.lng, this.uplink.estimatedLocation.lat];
      let radius = this.uplink.estimatedLocation.radius;
      let options = {steps: 64, units: 'meters', properties: {}};

      return {
        id,
        key: 'radius',
        source: {
          type: 'geojson',
          data: turfCircle(center, radius, options),
        },
        layout: {
          type: 'fill',
          paint: {
            'fill-color': estimatedRadiusColor,
            'fill-opacity': 0.2,
          },
          // before: 'zIndex2',
        },
      };
    },
    estimatedCenter() {

      if (!this.uplink.estimatedLocation) {
        return null;
      }

      let id = 'estimatedCenter';

      return {
        id,
        key: 'location',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [this.uplink.estimatedLocation.lng, this.uplink.estimatedLocation.lat]
            }
          },
        },
        layout: {
          type: 'circle',
          paint: {
            'circle-color': estimatedCenterColor,
            'circle-pitch-alignment': 'map',
            'circle-radius': 8,
          },
          // before: 'zIndex3',
        },
      };
    },
    hotspots() {
      return this.uplink.estimatedLocation.hotspots || [];
    },
    hotspotsPaths() {

      if (!this.uplink.estimatedLocation) {
        return null;
      }

      let id = 'hotspotPaths';

      return {
        id,
        key: 'hotspots',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: this.hotspots.map((hotspot) => {

              return {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [this.uplink.estimatedLocation.lng, this.uplink.estimatedLocation.lat],
                    [hotspot.long, hotspot.lat],
                  ],
                },
                properties: {
                  address: hotspot.name,
                  // color: estimatedRadiusColor,
                },
              };
            }),
          },
        },
        layout: {
          type: 'line',
          paint: {
            'line-color': hotspotsColor,
            'line-opacity': 0.8,
            'line-width': 3,
          },
          // before: 'zIndex1',
        },
      };

    },
    hotspotsPoints() {

      if (!this.uplink.estimatedLocation) {
        return null;
      }

      let id = 'hotspotPoints';

      return {
        id,
        key: 'hotspots',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: this.hotspots.map((hotspot) => {

              return {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [hotspot.long, hotspot.lat],
                },
                properties: {
                  address: hotspot.name,
                  // color: estimatedRadiusColor,
                },
              };
            }),
          },
        },
        layout: {
          type: 'circle',
          paint: {
            'circle-color': hotspotsColor,
            'circle-pitch-alignment': 'map',
            'circle-radius': 6,
          },
          // before: 'zIndex1',
        },
      };

    },
    mapLayers() {
      if (!this.map) {
        return [];
      }

      return [
        this.estimatedRadius,
        this.hotspotsPaths,
        this.hotspotsPoints,
        this.estimatedCenter,
      ].filter(layer => layer).map((layer) => {
        layer.enabled = this.mapLayersEnabled[layer.key];
        return layer;
      });
    },
    map() {
      return this.$store.state.map.map || null;
    },
    user() {
      return this.$store.getters['auth/user'] || null;
    }
  },
  created() {
    window.app.vue.$on('uplinkReceived', this.reloadMap);
  },
  destroyed() {
    this.mapCleanUp();
    window.app.vue.$off('uplinkReceived', this.reloadMap);
  },
  data() {
    return {
      mapLayersEnabled: {
        hotspots: false,
        location: true,
        radius: true,
      },
    };
  },
  methods: {
    mapCleanUp() {
      this.mapLayers.forEach((layer) => {

        if (this.map.getLayer(layer.id)) {
          this.map.removeLayer(layer.id);
        }

        if (this.map.getSource(layer.id)) {
          this.map.removeSource(layer.id);
        }
      });
    },
    mapRenderLayers() {

      this.mapLayers.forEach((layer) => {

        if (layer.enabled) {

          if (this.map.getSource(layer.id)) {
            this.map.getSource(layer.id).setData(layer.source);
          } else {
            this.map.addSource(layer.id, {
              ...layer.source,
            });
          }

          let before = layer.before || null;

          if (!this.map.getLayer(layer.id)) {
            this.map.addLayer({
              id: layer.id,
              source: layer.id,
              ...layer.layout,
            }, before);
          }
        } else {

          if (this.map.getLayer(layer.id)) {
            this.map.removeLayer(layer.id);
          }

          if (this.map.getSource(layer.id)) {
            this.map.removeSource(layer.id);
          }

        }

      });
    },
    reloadMap(e) {
      if (e && this.uplink && parseInt(e.deviceId) === parseInt(this.uplink.deviceId)) {
        this.mapRenderLayers();
      }
    },
  },
  props: {
    uplink: {
      required: true,
    }
  },
  watch: {
    mapLayers: {
      deep: true,
      handler() {
        if (this.map) {
          this.mapRenderLayers();
        }
      },
    },
    uplink: {
      deep: true,
      immediate: true,
      handler() {
        this.mapRenderLayers();
      },
    },
  },
}
</script>

<style scoped lang="scss">
.estimated-location {
  border: 2px solid $danger;
  border-radius: 5px;
  padding: 15px;
}
</style>
