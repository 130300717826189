import DashboardIndex from '@/views/Dashboard/Index';
import DashboardForm from '@/views/Dashboard/Form';
import DashboardShow from '@/views/Dashboard/Show';

const routes = [
  {
    name: 'dashboard/index',
    path: '/dashboard',
    component: DashboardIndex,
    meta: {
      containerSize: 'container-medium',
      icon: 'desktop',
      middleware: ['auth'],
      title: 'Dashboards',
    },
  },
  {
    name: 'dashboard/create',
    path: '/dashboard/create',
    component: DashboardForm,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('dashboard/index');
      },
      containerSize: 'container-extra-small',
      icon: 'desktop',
      middleware: ['auth'],
      title: 'New Dashboard',
    },
  },
  {
    name: 'dashboard/show',
    path: '/dashboard/:dashboardUUID',
    component: DashboardShow,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('dashboard/index');
      },
      middleware: ['auth'],
      title: 'Dashboard',
      editRoute: (route) => {
        return window.app.findRouteByName('dashboard/edit', {
          dashboardUUID: route.params.dashboardUUID,
        });
      },
    },
  },
  {
    name: 'dashboard/edit',
    path: '/dashboard/:dashboardUUID/edit',
    component: DashboardForm,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('dashboard/index');
      },
      containerSize: 'container-extra-small',
      icon: 'desktop',
      middleware: ['auth'],
      title: 'Edit Dashboard',
    }
  },
];

export default routes;
