<template>
  <div class="resource-list-header" :class="{ 'is-dark': $themeDark }">
    <router-link :to="addRoute" class="button is-primary resource-list-add-button" v-if="addLabel && addRoute">
       {{addLabel}}
    </router-link>
    <ResourceListSearch v-model="header.search" @keydown.native.enter="handleSearch" @search="handleSearch" v-if="searchable" />
    <ResourceListSettings v-model="header.settings" :sortByOptions="sortByOptions" />
  </div>
</template>

<script>
import ResourceListSearch from '@/components-V2/ResourceList/ResourceListSearch';
import ResourceListSettings from '@/components-V2/ResourceList/ResourceListSettings';

export default {
  name: 'ResourceListHeader',
  components: {
    ResourceListSearch,
    ResourceListSettings,
  },
  computed: {},
  data() {
    return {
      header: {
        search: '',
        settings: {
          sortBy: 'name',
          sortOrder: 'asc',
        },
      }
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    handleSearch() {
      this.$emit('search');
    }
  },
  props: {
    addLabel: {
      default: null,
      required: false,
      type: String,
    },
    addRoute: {
      required: false,
    },
    searchable: {
      default: true,
      required: false,
      type: Boolean,
    },
    sortByOptions: {
      default: () => [],
      required: false,
      type: Array,
    },
    value: {
      required: true,
    }
  },
  watch: {
    header: {
      deep: true,
      handler() {
        this.$emit('input', this.header);
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.header) !== JSON.stringify(this.value)) {
          this.header = {
            ...this.value,
          };
        }
      },
    }
  }
}
</script>

<style scoped lang="scss">
.resource-list-header {

  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .resource-list-add-button {
    margin-right: 7.5px;
  }

  .resource-list-search {
    flex: 1;
    margin-right: 7.5px;
  }

  .resource-list-settings {

  }
}
</style>
