<template>
  <div class="json-wrapper" :class="{ 'is-dark': $themeDark }">
    <vue-json-pretty :data="data"
                     :deep="deep"
                     @click="handleClick"
    />
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'JSON',
  components: {
    VueJsonPretty,
  },
  methods: {
    handleClick(path) {
      this.$emit('click', path);
    },
  },
  props: {
    data: {
      required: true,
    },
    deep: {
      default: 1,
    },
  },
};
</script>

<style lang="scss">
  .json-wrapper {
    background: #DDDDDD;
    border-radius: 4px;
    cursor: default;
    margin: 5px 0;
    overflow: auto;
    padding: 10px;

    &.is-dark {
      background: $black-ter;

      .vjs-tree-node.is-highlight, .vjs-tree-node:hover {
        background-color: $black-bis !important;
      }
    }
  }
</style>
