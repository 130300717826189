import ApiKeyIndex from '@/views/ApiKey/Index';
import ApiKeyForm from '@/views/ApiKey/Form';

const routes = [
  {
    name: 'api-key/index',
    path: '/api-key',
    component: ApiKeyIndex,
    meta: {
      containerSize: 'container-medium',
      icon: 'key',
      middleware: ['auth'],
      title: 'API Keys',
    },
  },
  {
    name: 'api-key/create',
    path: '/api-key/create',
    component: ApiKeyForm,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('api-key/index');
      },
      containerSize: 'container-extra-small',
      icon: 'key',
      middleware: ['auth'],
      title: 'New API key',
    },
  },
  {
    name: 'api-key/edit',
    path: '/api-key/:apiKeyUUID/edit',
    component: ApiKeyForm,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('api-key/index');
      },
      containerSize: 'container-extra-small',
      icon: 'key',
      middleware: ['auth'],
      title: 'Edit API Key',
    },
  }
];

export default routes;
