import {
  ResourceService,
} from '@/internal';

export default class NotificationChannelService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'notification-channel';
  }
}
