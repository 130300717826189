import axios from 'axios';
import Vue from 'vue';
import Buefy, {DialogProgrammatic as Dialog} from 'buefy';
import VueHotKey from 'v-hotkey';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import vClickOutside from 'v-click-outside';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import {isLocal, label, titleize} from './helpers';
import pluralize from 'pluralize';
import numeral from 'numeral';

import App from './App.vue';
import router, {routerBuilder} from './router';
import store from './store';

import AuthDriver from '@/drivers/AuthDriver';
import LocalStorageDriver from '@/drivers/LocalStorageDriver';
import ThemeDriver from '@/drivers/ThemeDriver';

import Framework from '@/Framework';

import appData from '../package.json';

require('@/assets/scss/app.scss');

// V2

import AddressInput from '@/components-V2/Inputs/AddressInput';
import Box from '@/components-V2/Box/Box';
import Button from '@/components-V2/Button/Button';
import ColorInput from '@/components-V2/Inputs/ColorInput';
import ColorInputWithTextInput from '@/components-V2/Inputs/ColorInputWithTextInput';
import Controls from '@/components-V2/Controls/Controls';
import CopyAttribute from '@/components-V2/CopyAttribute/CopyAttribute.vue';
import DateRangeInput from '@/components-V2/Inputs/DateRangeInput';
import EmailInput from '@/components-V2/Inputs/EmailInput';
import FakeInput from '@/components-V2/Inputs/FakeInput';
import Field from '@/components-V2/Field/Field';
import FileInput from '@/components-V2/Inputs/FileInput';
import FilePickerButton from '@/components-V2/Button/FilePickerButton';
import FontAwesomeIconInput from '@/components-V2/Inputs/FontAwesomeIconInput';
import Help from '@/components-V2/Help/Help';
import ImageFileInput from '@/components-V2/Inputs/ImageFileInput';
import Inspector from '@/components-V2/Inspector/Inspector';
import Instruction from '@/components-V2/Instruction/Instruction';
import JSON from '@/components-V2/JSON/JSON';
import InspectorFooter from '@/components-V2/Inspector/InspectorFooter';
import Label from '@/components-V2/Label/Label';
import LabelHR from '@/components-V2/LabelHR/LabelHR';
import LabelLineValue from '@/components-V2/LabelLineValue/LabelLineValue';
import NestedList from '@/components-V2/NestedList/NestedList';
import NumberInput from '@/components-V2/Inputs/NumberInput';
import PasswordInput from '@/components-V2/Inputs/PasswordInput';
import PhoneInput from '@/components-V2/Inputs/PhoneInput';
import ResourceList from '@/components-V2/ResourceList/ResourceList';
import ResourceSelect from '@/components-V2/ResourceSelect/ResourceSelect';
import Section from '@/components-V2/Section/Section';
import SelectInput from '@/components-V2/Inputs/SelectInput';
import Subtitle from '@/components-V2/Subtitle/Subtitle';
import SystemTag from '@/components-V2/SystemTag/SystemTag';
import Tag from '@/components-V2/Tag/Tag';
import TagInput from '@/components-V2/Inputs/TagInput';
import Teleport from '@/components-V2/Teleport/Teleport';
import TextInput from '@/components-V2/Inputs/TextInput';
import TextAreaInput from '@/components-V2/Inputs/TextAreaInput';
import TimeIntervalInput from '@/components-V2/Inputs/TimeIntervalInput';
import TimezoneInput from '@/components-V2/Inputs/TimezoneInput';
import Title from '@/components-V2/Typography/Title';
import UrlInput from '@/components-V2/Inputs/UrlInput';
import Verifier from '@/components-V2/Verifier/Verifier';
import YesNoInput from '@/components-V2/Inputs/YesNoInput';

// V1 Components

import CountryInput from '@lakestreetsoftware/vue-components/src/Inputs/CountryInput';
import FormError from '@lakestreetsoftware/vue-components/src/Form/FormError';
import InspectorSection from '@lakestreetsoftware/vue-components/src/Inspector/InspectorSection';
import MenuItem from '@/components/Menu/MenuItem';
import MenuItemContent from '@/components/Menu/MenuItemContent';
import NavbarDropdownSwitcher from '@lakestreetsoftware/vue-components/src/Navigation/NavbarDropdownSwitcher';
import NoDataPlaceholder from '@/components/NoDataPlaceholder';
import Page from '@lakestreetsoftware/vue-components/src/Layout/Page';
import RangePickerInput from '@lakestreetsoftware/vue-components/src/Inputs/RangePickerInput';
import Table from '@lakestreetsoftware/vue-components/src/Layout/Table';
import moment from 'moment-timezone';

Vue.component('AddressInput', AddressInput);
Vue.component('Box', Box);
Vue.component('Button', Button);
Vue.component('ColorInput', ColorInput);
Vue.component('ColorInputWithTextInput', ColorInputWithTextInput);
Vue.component('Controls', Controls);
Vue.component('CopyAttribute', CopyAttribute);
Vue.component('CountryInput', CountryInput);
Vue.component('DateRangeInput', DateRangeInput);
Vue.component('EmailInput', EmailInput);
Vue.component('FakeInput', FakeInput);
Vue.component('Field', Field);
Vue.component('FileInput', FileInput);
Vue.component('FilePickerButton', FilePickerButton);
Vue.component('FontAwesomeIconInput', FontAwesomeIconInput);
Vue.component('FormError', FormError);
Vue.component('Help', Help);
Vue.component('ImageFileInput', ImageFileInput);
Vue.component('Inspector', Inspector);
Vue.component('InspectorSection', InspectorSection);
Vue.component('InspectorFooter', InspectorFooter);
Vue.component('Instruction', Instruction);
Vue.component('JSON', JSON);
Vue.component('Label', Label);
Vue.component('LabelHR', LabelHR);
Vue.component('LabelLineValue', LabelLineValue);
Vue.component('MenuItem', MenuItem);
Vue.component('MenuItemContent', MenuItemContent);
Vue.component('NavbarDropdownSwitcher', NavbarDropdownSwitcher);
Vue.component('NestedList', NestedList);
Vue.component('NoDataPlaceholder', NoDataPlaceholder);
Vue.component('NumberInput', NumberInput);
Vue.component('Page', Page);
Vue.component('PasswordInput', PasswordInput);
Vue.component('PhoneInput', PhoneInput);
Vue.component('RangePickerInput', RangePickerInput);
Vue.component('ResourceList', ResourceList);
Vue.component('ResourceSelect', ResourceSelect);
Vue.component('Section', Section);
Vue.component('SelectInput', SelectInput);
Vue.component('Subtitle', Subtitle);
Vue.component('SystemTag', SystemTag);
Vue.component('Table', Table);
Vue.component('Tag', Tag);
Vue.component('TagInput', TagInput);
Vue.component('Teleport', Teleport);
Vue.component('TextInput', TextInput);
Vue.component('TextAreaInput', TextAreaInput);
Vue.component('TimeIntervalInput', TimeIntervalInput);
Vue.component('TimezoneInput', TimezoneInput);
Vue.component('Title', Title);
Vue.component('UrlInput', UrlInput);
Vue.component('Verifier', Verifier);
Vue.component('YesNoInput', YesNoInput);

Vue.use(Buefy, {defaultIconPack: 'fal'});
Vue.use(VueHotKey);
Vue.use(vClickOutside);

// Config

Vue.config.productionTip = process.env.VUE_APP_ENV === 'production';

if (process.env.VUE_APP_PUSHER_KEY) {
  Pusher.logToConsole = process.env.VUE_APP_PUSHER_LOG;
}

highchartsMore(Highcharts)

if (process.env.VUE_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    appVersion: appData.version,
    enabledReleaseStages: [
      'production',
      'staging',
      'local',
    ],
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.VUE_APP_ENV,
  });

  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue.installVueErrorHandler(Vue);
}

const framework = new Framework();

// Wire Up Vue

framework.addVue(Vue, App);
framework.addStore(store);
framework.addRouter(router, routerBuilder);

// Environment variables

const vueAppUrl = window.location.origin;

axios.get(`${process.env.VUE_APP_API_BASE_URL}/domain/check?url=${vueAppUrl}`).then((response) => {

  const domain = response.data;

  if (domain.uuid) {

    framework.env({
      domainUUID: domain.uuid,
      appName: domain.name,
      apiBaseUrl: `${domain.api_url}`,
      appBaseUrl: `${domain.app_url}`,
      environment: process.env.VUE_APP_ENV,
      fathomUrl: domain.fathom_url || null,
      fathomSiteId: domain.fathom_site_id || null,
      labels: domain.labels || [],
      mapboxToken: domain.mapbox_token,
      vueAppUrl: `${domain.app_url}`,
    });

    // Add favicon
    if (domain.favicon) {
      let link = document.createElement('link');
      link.id = 'favicon';
      link.rel = 'icon';
      link.href = domain.favicon;
      document.head.appendChild(link);
    }

    const defaultSystem = {
      settings: {
        datetimeFormat: 'll LTS z',
        layout: 'floating',
        theme: 'dark',
        timezone: moment.tz.guess() || 'America/Los_Angeles',
      },
    };

    const storedSystem = LocalStorageDriver.getItem('system') || defaultSystem;

    const system = {
      ...defaultSystem,
      ...storedSystem,
      settings: {
        ...defaultSystem.settings,
        ...storedSystem.settings,
      }
    };

    // Ensure system has defaults
    LocalStorageDriver.setItem('system', system);

    const theme = system.settings.theme;
    const layout = system.settings.layout;

    Vue.prototype.$deleteDialog = (config = {}) => {

      let message;
      let title = config.targetType ? `Delete ${config.targetType}?` : 'Delete?'
      let confirmMessage = config.targetType ? `Are you sure you want to delete this ${config.targetType.toLowerCase()}?` : 'Are you sure you want to delete this?';

      if (config.target) {
        message = `<p>${confirmMessage}</p>
                 <p style="background: #eee; border-radius: 4px; padding: 10px 0; text-align: center;">
                    <b>${config.target}</b>
                 </p>
                 <p class="has-text-centered">This action is permanent.</p>`;
      } else {
        message = `<p>${confirmMessage}</p>`
      }

      Dialog.confirm({
          title,
          message,
          type: 'is-danger',
          confirmText: 'Delete',
          ...config,
        }
      );
    };
    Vue.prototype.$domainName = domain.name;
    Vue.prototype.$domainFeatures = domain.features;
    Vue.prototype.$domainUrl = domain.url;
    Vue.prototype.$isStandalone = window.navigator.standalone;
    Vue.prototype.$isLocal = isLocal();
    Vue.prototype.$label = label;
    Vue.prototype.$lastRoute = null;
    Vue.prototype.$numeral = numeral;
    Vue.prototype.$pluralize = pluralize;
    Vue.prototype.$titleize = titleize;
    Vue.prototype.$daysToHumanReadable = (days) => {
      if (days % 30 === 0) {
        let months = days / 30;
        return `${months} ${pluralize('month', months)}`;
      } else if (days % 365 === 0) {
        let years = days / 365;
        return `${years} ${pluralize('year', years)}`;
      } else {
        return `${days} days`;
      }
    };

    Vue.prototype.$theme = theme;
    Vue.prototype.$themeDark = theme === 'dark';
    Vue.prototype.$themeLight = theme === 'light';

    Vue.prototype.$layout = layout;
    Vue.prototype.$layoutFloating = layout === 'floating';
    Vue.prototype.$layoutAnchored = layout === 'anchored';

    // Analytics

    if (process.env.VUE_APP_ENV === 'production' && domain.analytics_provider === 'fathom' && domain.analytics_config && domain.analytics_config.fathom_site_id) {
      let script = document.createElement('script');
      script.setAttribute('data-site', domain.analytics_config.fathom_site_id);
      script.setAttribute('data-spa', 'auto');
      script.setAttribute('defer', true);
      script.setAttribute('src', `https://cdn.usefathom.com/script.js`);
      document.body.appendChild(script);
    }

    // Webmanifest

    let head = document.getElementsByTagName('head')[0];
    let manifest = document.createElement('link');
    manifest.setAttribute('rel', 'manifest');
    manifest.setAttribute('href', `${domain.api_url}/webmanifest`)
    head.appendChild(manifest);

    // Drivers

    framework.auth(AuthDriver);
    framework.storage(LocalStorageDriver);
    framework.theme(ThemeDriver);

    // Helpers

    // window.app.helper.bugsnag

    framework.addHelper('bugsnag', Bugsnag);

    framework.addHelper('verificationRequiredError', (error = {}) => {

      let verificationRequired = null;

      const is422 = error.response && error.response.status === 422;
      const errorData = error.response && error.response.data;
      const errors = errorData && errorData.errors;

      if (is422 && errors) {

        const verificationRequiredError = Array.isArray(errors) ? errors.find(error => error.key === 'verification_required') : errors['verification_required'];

        // Todo: Modify to handle object or array payload
        if (verificationRequiredError) {
          verificationRequired = {
            message: verificationRequiredError.detail,
            key: verificationRequiredError.data ? verificationRequiredError.data.key : null,
            uuid: verificationRequiredError.data ? verificationRequiredError.data.uuid : null,
          };
        }

        return verificationRequired;
      }
    });

    // Boot Framework

    framework.boot();

  } else {

    console.error('Domain not found');

  }

});
