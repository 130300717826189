import Onboard from '@/views/Device/Onboard';
import Share from '@/views/Device/Share';
import Terms from '@/views/Terms';

const routes = [
  {
    name: 'deviceShare',
    path: '/device/:deviceUUID/share',
    component: Share,
    meta: {
      title: 'Device',
      navbarDisabled: true,
    },
  },
  {
    name: 'deviceOnboard',
    path: '/onboard/:code',
    component: Onboard,
    meta: {
      title: 'Device Onboard',
      navbarDisabled: true,
    },
  },
  {
    name: 'terms',
    path: '/terms',
    component: Terms,
    meta: {
      title: 'Terms',
    },
  },
];

export default routes;
