import {
  Model,
  TagService as Service,
} from '@/internal';

export default class Tag extends Model {

  static get key() {
    return 'tag';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      name: {
        required: true,
        type: String,
      }
    };
  }

  static get class() {
    return 'App\\Models\\Tag';
  }
}
