<template>
  <div id="asset-form" class="is-padded">

    <BLoading active v-if="loading"/>

    <template v-else>

      <Field>

        <Label>Name *</Label>

        <TextInput v-model="form.fields.name" placeholder="Garage Freezer"/>

        <FormError field="name" :form="form"/>

      </Field>

    </template>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>

          <Button danger outlined @click="handleDelete" class="is-pulled-left" :loading="deleting" v-if="asset">
            Delete
          </Button>

        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

import {phone} from 'phone';

import DeviceState from '@/components-V2/Device/State.vue';

export default {
  name: 'Form',
  components: {
    DeviceState,
  },
  computed: {
    asset() {
      return this.$route.params.assetUUID ? this.$store.getters['asset/findBy'](this.$route.params.assetUUID, 'uuid') : null;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (this.$route.params.assetUUID) {
      this.loadAsset();
    }
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        name: '',
        rendering: 'none',
        parameterId: this.$route.query.parameterId || null,
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      assetDestroy: 'asset/destroy',
      assetShow: 'asset/show',
      assetStore: 'asset/store',
      assetUpdate: 'asset/update',
      userShow: 'auth/userShow',
    }),
    handleDelete() {
      this.$deleteDialog({
        target: this.asset.name,
        targetType: 'asset',
        onConfirm: () => {
          this.deleting = true;
          this.assetDestroy({
            id: this.asset.id,
          }).then(() => {
            window.app.snackbar('Asset Deleted');
            this.$router.push(window.app.findRouteByName('home'));
          }).finally(() => {
            this.deleting = false;
          });
        },
      })
    },
    handleSave() {

      const fields = {
        ...this.form.fields,
      };

      this.form.errors.clear();

      let action = null;

      if (this.asset) {
        action = this.assetUpdate;
      } else {
        action = this.assetStore;
      }

      if (action) {
        this.loading = true;

        action(fields).then(() => {
          window.app.snackbar('Asset Saved');
          this.$router.back();
        }).catch((error) => {
          this.form.recordErrors(error);
        }).finally(() => {
          this.loading = false;
        });
      }

    },
    fillForm() {

      if (!this.asset) {
        return;
      }

      this.form.fields = {
        ...this.asset,
        id: this.asset.uuid,
      };
    },
    loadAsset() {
      this.loading = true;
      return this.assetShow({
        id: this.$route.params.assetUUID,
      }).then(this.fillForm).catch((error) => {
        console.error(error);
        window.app.snackbar('Error loading asset');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>

<style scoped lang="scss">
#asset-form {

}
</style>
