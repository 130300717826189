<template>
  <div class="device-preview">
    <StatusTag :device="device" class="is-pulled-right" />
    <Subtitle>{{device.name}}</Subtitle>
    <State :device="device" />
  </div>
</template>

<script>
import StatusTag from '@/components-V2/Device/StatusTag';
import State from '@/components-V2/Device/State';

export default {
  name: 'Preview',
  components: {
    State,
    StatusTag,
  },
  computed: {},
  data() {
    return {};
  },
  props: {
    device: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">
.device-preview {

}
</style>
