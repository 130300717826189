import {
  Account,
  Model,
  UnitService as Service,
} from '@/internal';

export default class Unit extends Model {

  static get key() {
    return 'unit';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      title: {
        required: true,
        type: String,
      },
    };
  }

  get account() {
    return this.hasOne(Account)
  }

}
