<template>
  <div class="text-input">
    <input :type="type"
           class="input"
           :class="{ 'is-dark': $themeDark }"
           :value="value"
           @input="handleInput($event.target.value)"
           @blur="handleBlur"
           :placeholder="placeholder"
           :maxlength="maxlength"
           :max="max"
           :min="min"
           :step="step"
    />
    <span class="text-input-click-to-copy" v-if="clickToCopy" @click="handleCopy">
      <BIcon icon="copy" />
    </span>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard';

export default {
  name: 'TextInput',
  methods: {
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleCopy() {
      copy(this.value);
      window.app.snackbar('Copied to Clipboard');
    },
    handleInput(value) {
      this.$emit('input', value);
    },
  },
  props: {
    clickToCopy: {
      default: false,
      type: Boolean,
    },
    maxlength: {
      required: false,
    },
    max: {
      default: null,
      required: false,
      type: Number,
    },
    min: {
      default: null,
      required: false,
      type: Number,
    },
    placeholder: {
      type: String,
      required: false,
    },
    step: {
      default: null,
      required: false,
      type: Number,
    },
    type: {
      default: 'text',
      type: String,
      required: false,
    },
    value: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">
.text-input {
  position: relative;

  .text-input-click-to-copy {
    cursor: pointer;
    position: absolute;
    right: 7.5px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
