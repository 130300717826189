<template>
  <div id="unit-form" class="is-padded">

    <BLoading active v-if="loading"/>

    <Field>
      <Label>Title *</Label>
      <TextInput v-model="form.fields.title" :disabled="isSystem" />
      <FormError :form="form" field="title"/>
    </Field>

    <Field>
      <Label>Symbol</Label>
      <TextInput v-model="form.fields.symbol" :disabled="isSystem"/>
      <FormError :form="form" field="symbol"/>
    </Field>


    <Teleport to="#inspector-footer" v-if="!isSystem">
      <Controls>

        <template v-slot:left>
          <Button @click="handleDelete" outlined danger :loading="deleting" v-if="unit">
            Delete
          </Button>
        </template>

        <template v-slot:right>
          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>
          <Button primary @click="handleSave" :loading="loading" class="has-margin-left">
            Save
          </Button>
        </template>
      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {Form} from '@/internal';
import {mapActions} from 'vuex';

export default {
  name: 'Form',
  computed: {
    isSystem() {
      return this.unit && !this.unit.accountId;
    },
    unit() {
      return this.$route.params.unitUUID ? this.$store.getters['unit/findBy'](this.$route.params.unitUUID, 'uuid') : null;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (this.unit) {
      this.fillForm();
    }

    if (this.user) {
      this.form.fields = {
        ...this.form.fields,
        accountId: this.user.account ? this.user.account.id : null,
      };
    }
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        title: '',
        symbol: '',
        accountId: null,
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      unitDestroy: 'unit/destroy',
      unitStore: 'unit/store',
      unitUpdate: 'unit/update',
    }),
    handleCancel() {
      this.form.reset();
      this.$router.back();
    },
    handleDelete() {
      this.$deleteDialog({
        target: this.unit.title,
        targetType: 'unit',
        onConfirm: () => {
          this.deleting = true;
          this.unitDestroy({
            id: this.unit.id,
          }).then(() => {
            window.app.snackbar('Unit Deleted');
            this.$router.push(window.app.findRouteByName('unit/index'));
          }).finally(() => {
            this.deleting = false;
          });
        },
      })
    },
    handleSave() {

      this.loading = true;

      let action;

      if (this.$route.params.unitUUID) {
        action = this.unitUpdate;
      } else {
        action = this.unitStore;
      }

      action({
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar({
          message: 'Unit Saved',
        });
        this.form.reset();
        this.$router.back();
      }).catch((error) => {

        this.form.recordErrors(error);

      }).finally(() => {
        this.loading = false;
      });
    },
    fillForm() {
      this.form.fields = {
        ...this.unit
      };
    },
  },
  props: {},
  watch: {
    unit() {
      this.fillForm();
    },
  }
};
</script>

<style scoped lang="scss">

</style>
