<template>
  <div id="device-form" class="is-padded">

    <BLoading active v-if="loading"/>

    <template v-else>

      <template v-if="device && !device.license">

        <Box>

          <div class="billed-label has-text-centered">
            Billed Every
          </div>

          <div class="has-text-centered">
            <div class="intervals">
              <div class="interval has-text-centered" :class="{ 'is-active': interval === 'year' }"
                   @click="handleIntervalSet('year')">
                1 Year
              </div>
              <div class="interval has-text-centered"
                   :class="{ 'is-active': interval === 'month' && intervalCount === 6 }"
                   @click="handleIntervalSet('month', 6)">
                6 Months
              </div>
              <div class="interval has-text-centered"
                   :class="{ 'is-active': interval === 'month' && intervalCount === 1 }"
                   @click="handleIntervalSet('month')">
                1 Month
              </div>
            </div>

            <div class="price-wrapper">

              <Title class="price">
                {{$numeral(price).format('$0,0.00')}}<span style="font-size: 0.4em;">/mo</span>
              </Title>

              <span class="tag is-warning" v-if="savings">
                Save ~{{ $numeral(savings).format('0') }}%!
              </span>

            </div>

          </div>

          <ul class="has-text-centered">
            Monitor 2 Freezers<br/>
            30-Day Money Back Guarantee<br/>
            Cancel Anytime<br/>
          </ul>

        </Box>

        <Help class="has-text-centered" style="margin-bottom: 30px;">
          Coupon for prepaid service can be input on the next step.
        </Help>

        <Button @click="handleDeviceSubscribe" block primary>
          Add Service
        </Button>

      </template>

      <template v-else>

        <Instruction type="is-warning" v-if="device.license.endsAt">
          Service cancelled. You'll continue to have service until {{ device.license.endsAt.format('ll') }}.
        </Instruction>

        <LabelLineValue label="Name" :value="`Monitor 1 [${device.lastFourOfDeviceEui}]`"/>

        <LabelLineValue label="Service" :value="serviceStatus"/>

        <div class="columns has-margin-top">

          <div class="column">
            <Button :light="$themeDark" :dark="$themeLight" outlined block @click="handleStripePortal">
              Billing Portal
              <BIcon style="margin-left: 5px;" icon="arrow-up-right-from-square"/>
            </Button>
          </div>

          <div class="column">
            <Button danger outlined block @click="handleDeviceUnsubscribe" v-if="!device.license.endsAt">
              Cancel Service
            </Button>
            <Button primary outlined block @click="handleDeviceResubscribe" v-else>
              Resume Service
            </Button>
          </div>

        </div>

      </template>

      <Button block outlined danger class="has-margin-top" @click="handleDelete" style="border: none;">
        Remove Device
      </Button>

    </template>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import DeviceState from '@/components-V2/Device/State.vue';

export default {
  name: 'Form',
  components: {
    DeviceState,
  },
  computed: {
    device() {
      return this.$route.params.deviceUUID ? this.$store.getters['device/findBy'](this.$route.params.deviceUUID, 'uuid') : null;
    },
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    parameters() {
      return this.device ? this.device.parameters : [];
    },
    price() {
      if (this.interval === 'month' && this.intervalCount === 1) {
        return 10.00;
      } else if (this.interval === 'month' && this.intervalCount === 6) {
        return 9.17;
      } else if (this.interval === 'year') {
        return 8.33;
      }
    },
    savings() {
      if (this.interval === 'month' && this.intervalCount === 1) {
        return 0;
      } else {
        return (10 - this.price) / 10 * 100;
      }
    },
    serviceStatus() {

      if (this.device.license && !this.device.license.endsAt) {
        return 'Active';
      } else if (this.device.license && this.device.license.endsAt) {
        return 'Cancelled';
      } else if (!this.device.license) {
        return 'Inactive';
      }

    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  async created() {

    if (this.$route.params.deviceUUID) {
      await this.loadDevice();
    }

    // This is to account for changes made within the Stripe Checkout/Billing Portal

    if (this.$route.query.refresh !== undefined) {
      await this.handleDeviceSubscriptionLicenseSync();
    } else if (this.$route.query.subscribe === 'success') {
      await this.handleDeviceSubscriptionLicenseSync();
      window.app.snackbar('Service Activated');
      this.$router.push({
        name: 'home',
      });
    } else if (this.$route.query.unsubscribe === 'success') {
      await this.handleDeviceSubscriptionLicenseSync();
      window.app.snackbar('Service Cancelled');
    }
  },
  data() {
    return {
      interval: 'month',
      intervalCount: 6,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      accountStripePortal: 'account/stripePortal',
      deviceDestroy: 'device/destroy',
      deviceResubscribe: 'device/resubscribe',
      deviceShow: 'device/show',
      deviceSubscribe: 'device/subscribe',
      deviceUnsubscribe: 'device/unsubscribe',
      deviceSubscriptionLicenseSync: 'device/subscriptionLicenseSync',
    }),
    handleDelete() {

      if (this.device.license && !this.device.license.endsAt) {
        window.app.snackbar({
          message: 'Cancel your service first!',
          type: 'is-danger',
        })
        return;
      }

      this.$deleteDialog({
        target: this.device.name,
        targetType: 'device',
        onConfirm: () => {
          this.deleting = true;
          this.deviceDestroy({
            id: this.device.id,
          }).then(() => {
            window.app.snackbar('Device Removed');
            this.$router.push(window.app.findRouteByName('home'));
          }).finally(() => {
            this.deleting = false;
          });
        },
      })
    },
    handleDeviceResubscribe() {

      this.loading = true;
      this.deviceResubscribe({
        id: this.device.uuid,
        params: {
          returnUrl: window.location.href.split('?')[0],
        },
      }).then((response) => {

        if (response.data.data.session) {
          window.location.replace(response.data.data.session.url);
        } else {
          window.app.snackbar({
            type: 'is-error',
            message: 'Error',
          });

          console.error(response.data);
        }

      }).catch((error) => {
        this.loading = false;
        console.log(error);
      });
    },
    handleDeviceSubscribe() {
      this.loading = true;
      this.deviceSubscribe({
        id: this.device.uuid,
        uplinkEngineProductId: 'connect',
        interval: this.interval,
        intervalCount: this.intervalCount,
      }).then((response) => {

        if (response.data.data.session) {
          window.location.replace(response.data.data.session.url);
        } else {
          window.app.snackbar({
            type: 'is-error',
            message: 'Error',
          });

          console.error(response.data);
        }

      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },
    handleDeviceUnsubscribe() {
      this.loading = true;
      this.deviceUnsubscribe({
        id: this.device.uuid,
        params: {
          returnUrl: window.location.href.split('?')[0],
        },
      }).then((response) => {

        if (response.data.data.session) {
          window.location.replace(response.data.data.session.url);
        } else {
          window.app.snackbar({
            type: 'is-error',
            message: 'Error',
          });

          console.error(response.data);
        }

      }).catch((error) => {
        this.loading = false;
        console.log(error);
      });
    },
    handleDeviceSubscriptionLicenseSync() {
      this.loading = true;
      this.deviceSubscriptionLicenseSync({
        id: this.device.uuid,
      }).then(this.loadAsset).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleIntervalSet(interval, count = 1) {
      this.interval = interval;
      this.intervalCount = count;
    },
    handleStripePortal() {

      this.loading = true;

      return this.accountStripePortal({
        params: {
          returnUrl: window.location.href.split('?')[0],
        },
      }).then((response) => {

        const url = response.data.data.session.url;

        if (url) {
          window.location.replace(url);
        } else {
          window.app.snackbarError('Unable to launch Stripe Billing Portal');
        }

      }).catch((error) => {
        window.app.snackbar({
          message: error.response.data,
          type: 'is-danger',
        });
        this.loading = false;
      });
    },
    loadDevice() {
      this.loading = true;
      return this.deviceShow({
        id: this.$route.params.deviceUUID,
        params: {
          include: ['parameters'],
        },
      }).catch((error) => {
        console.error(error);
        window.app.snackbar('Error loading device');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>

<style scoped lang="scss">
.billed-label {
  font-size: 0.8em;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.intervals {
  align-items: center;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;

  .interval {
    background: $black-ter;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 0.8em;
    flex: 1;
    padding: 2.5px 15px;
    white-space: nowrap;

    &.is-active {
      background: $primary;
    }
  }
}

.price-wrapper {
  margin: 30px 0;

  .price {
    font-size: 3.5rem;
    margin-bottom: 10px;
  }
}


</style>
