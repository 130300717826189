var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.domain)?_c('div',{class:{ 'is-standalone': _vm.$isStandalone },attrs:{"id":"mobile-navbar-footer"}},[(_vm.mobileTabsAuthenticatedMenu)?_c('ul',_vm._l((_vm.mobileTabsAuthenticatedMenu.menuItems),function(item){return _c('li',{class:{ active: item.active },on:{"click":function($event){return _vm.handleTabClick(item)}}},[(item.route)?_c('router-link',{attrs:{"to":item.route},nativeOn:{"click":function($event){item.onClick ? item.onClick() : () => {}}}},[_c('i',{class:{
                          fal: !item.active,
                          fas: item.active,
                          'fa-fw': true,
                          [`fa-${item.icon}`]: true,
                      }})]):(item.onClick)?_c('div',{on:{"click":item.onClick}},[_c('i',{class:{
                          fal: !item.active,
                          fas: item.active,
                          'fa-fw': true,
                          [`fa-${item.icon}`]: true,
                      }})]):_vm._e()],1)}),0):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }