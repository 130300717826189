<template>
  <div>
    <div class="uplink resource-list-item is-clickable"
         :class="{ 'is-dark': $themeDark }"
         v-if="openInModal"
         @click="handleModalLaunch"
    >
      {{uplink.createdAt.tz(system.settings.timezone).format(system.settings.datetimeFormat)}}
    </div>
    <router-link :to="`/uplink/${uplink.uuid}`" class="uplink resource-list-item" :class="{ 'is-dark': $themeDark }" v-else>
      {{uplink.createdAt.tz(system.settings.timezone).format(system.settings.datetimeFormat)}}
    </router-link>
    <BModal :active.sync="modalActive" :width="600">
      <div class="container container-extra-small">
        <div class="is-padded">
          <Box flat :black="$themeDark">
            <UplinkPreview :uplink="uplink"
                           :allowLink="false"
            />
          </Box>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
import UplinkPreview from '@/components-V2/Uplink/Preview.vue';

export default {
  name: 'ResourceListItem',
  components: {
    UplinkPreview,
  },
  computed: {
    system() {
      return this.$store.state.system;
    },
    uplink() {
      return this.resource;
    },
  },
  data() {
    return {
      modalActive: false,
    };
  },
  methods: {
    handleModalLaunch() {
      this.modalActive = true;
    },
  },
  props: {
    openInModal: {
      default: false,
      type: Boolean,
    },
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
