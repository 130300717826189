<template>
    <div class="resource-list-item is-clickable" :class="{ 'is-dark': $themeDark }">
        <div @click="handleClick">
            <div class="is-flex is-align-items-center is-justify-content-space-between">
                <div>
                  <span class="tag is-uppercase">{{subjectType}} > {{ resource.event }}</span>
                </div>
                <div class="tag" v-if="name">{{name}}</div>
            </div>
            <div class="has-text-grey is-small">
                <template v-if="resource.causer">{{ $t('by').toLowerCase() }} {{ resource.causer.fullName }}</template><br />
                {{resource.createdAt.tz(system.settings.timezone).format(system.settings.datetimeFormat)}}
            </div>
        </div>
        <div v-if="showDetails && resource.properties">
            <JSON :data="resource.properties" :deep="1" />
        </div>
    </div>
</template>

<script>
import {camelCase} from 'lodash';

export default {
  name: 'ResourceListItem',
  computed: {
    activity() {
      return this.resource;
    },
    name() {
      // Prioritized in order
      return this.activity.name
        || (this.activity && this.activity.properties && this.activity.properties.attributes && this.activity.properties.attributes.name ? this.activity.properties.attributes.name : null)
        || (this.activity && this.activity.properties && this.activity.properties.old && this.activity.properties.old.name ? this.activity.properties.old.name : null);
    },
    subjectType() {
      return this.activity ? camelCase(this.activity.subjectType.replace(`App\\Models\\`, '')) : '';
    },
    system() {
      return this.$store.state.system;
    },
  },
  data() {
    return {
      showDetails: false,
    };
  },
  methods: {
    handleClick() {
      this.showDetails = !this.showDetails;
    },
  },
  props: {
    resource: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
