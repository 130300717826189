<template>
  <div class="device-license-tag tag pointer" :class="{ 'is-warning': !device.license }" @click="handleLicenseManage">
    {{ device.license ? device.license.product.name : 'Unlicensed' }}
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'LicenseTag',
  computed: {
    account() {
      return this.user && this.user.account ? this.$store.getters['account/findBy'](this.user.account.uuid, 'uuid') : null;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
    handleLicenseManage() {
      if (this.user.hasPermission('accountEdit')) {
        this.$router.push({
          name: 'account/billing',
          query: {
            device: this.device.uuid,
          },
        });
      } else {
        window.app.snackbar('A user with the owner role is required to purchase device licenses.');
      }
    },
  },
  props: {
    device: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">
.device-license-tag {

}
</style>
