export default {
  id: 'regions',
  group: 'map',
  enabled: true,
  label: 'Regions',
  cursorHover: 'pointer',
  source({store}) {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: store.getters['region/all'] ? store.getters['region/all'].filter(device => device.area).map((region) => {
          return {
            type: 'Feature',
            geometry: region.area,
            properties: {
              id: region.id,
              uuid: region.uuid,
              title: region.name,
              color: region.color,
              priority: false
            }
          };
        }) : [],
      },
    };
  },
  layout() {
    return {
      type: 'fill',
      paint: {
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.5
      },
      before: 'zIndex2',
    };
  },
};
