import {
  Model,
  ConverterService as Service,
} from '@/internal';

export default class Converter extends Model {

  static get key() {
    return 'converter';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
    };
  }
}
