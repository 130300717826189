<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  props: ['to'],

  data() {
    return {
      loggingEnabled: false,
    };
  },

  mounted() {
    const target = document.querySelector(this.to);

    if (target) {
      if (this.loggingEnabled) {
        console.log(`Teleporting to "${this.to}"`);
      }
      target.appendChild(this.$el);

      let key = (Math.random() + 1).toString(36).substring(7);

      target.setAttribute('key', key);
    } else {
      console.error(`Teleport target "${this.to}" not found.`);
    }
  },

  beforeDestroy() {
    if (this.loggingEnabled) {
      console.log(`Teleporting out of "${this.to}"`);
    }
    const parent = this.$el.parentNode;
    if (parent) {
      parent.removeChild(this.$el);
    }
  },
};
</script>
