import {ApiService} from '@/internal';

export default class ResourceService extends ApiService {

  index(payload = {}) {

    const {params} = payload;

    return this.request({
      method: 'get',
      url: this.generateUrl(this.baseUrl, params),
      params: this.prepareParams(params),
    });
  }

  show(id, payload = {}) {

    const {params} = payload;

    return this.request({
      method: 'get',
      url: this.generateUrl(`${this.baseUrl}/${id}`, params),
      params: this.prepareParams(params),
    });
  }

  store(payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: this.generateUrl(this.baseUrl, params),
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  update(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'put',
      url: this.generateUrl(`${this.baseUrl}/${id}`, params),
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  archive(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'patch',
      url: this.generateUrl(`${this.baseUrl}/${id}`, params),
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  destroy(id, payload = {}) {
    const {params, ...rest} = payload;

    return this.request({
      method: 'delete',
      url: this.generateUrl(`${this.baseUrl}/${id}`, params),
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  restore(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'patch',
      url: this.generateUrl(`${this.baseUrl}/${id}/restore`, params),
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  destroyAll(payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'delete',
      url: this.generateUrl(`${this.baseUrl}/delete-all`, params),
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  destroyByIds(payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'delete',
      url: this.generateUrl(`${this.baseUrl}/delete-by-ids`, params),
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  generateUrl(urlTemplate, params) {

    let replacedParts = [];

    const parts = urlTemplate.split('/');

    parts.forEach((key) => {

      if (key.charAt(0) === ':') {

        let variableName = key.substr(1);

        if (params[variableName]) {
          replacedParts.push(params[variableName]);
          return;
        } else {
          throw new Error(`No param provided for ${variableName}`);
        }
      }

      replacedParts.push(key);
    });

    return replacedParts.join('/');
  }
}
