<template>
  <div class="image-file-input">

    <FileInput editable
               :types="types"
               :value="value"
               @input="handleInput"
               @edit="handleFileEdit"
    />

    <BModal :active.sync="editing">
      <Box v-if="file" id="image-editor">
        <BLoading active v-if="loading" :isFullPage="false"/>
        <PinturaEditor
          v-bind="editor.defaults"
          :src="file.proxyUrl"
          :imageCropAspectRatio="editor.imageCropAspectRatio"
          v-on:pintura:process="handleEditorProcess"
        />
      </Box>
    </BModal>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {

  getEditorDefaults,
} from '@pqina/pintura';

import {PinturaEditor} from '@pqina/vue-pintura/vue-2';

let editorDefaults = getEditorDefaults();

editorDefaults.utils = [
  'crop',
  'annotate',
  'resize',
];

export default {
  name: 'ImageFileInput',
  components: {
    PinturaEditor,
  },
  computed: {
    file() {
      // return this.value ? this.$store.getters['file/findBy'](this.value) : null;
      // Not sure why 👆 isn't working

      return this.files.find(file => file.id === this.value) || null;
    },
    files() {
      return this.$store.getters['file/all'];
    },
  },
  data() {
    return {
      editing: false,
      editor: {
        defaults: editorDefaults,
      },
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      fileReplace: 'file/replace',
    }),
    handleEditorProcess(imageWriterResult) {
      this.handleFileUpdate(imageWriterResult.dest);
    },
    handleFileEdit() {
      this.editing = true;
    },
    handleInput(value) {
      this.$emit('input', value);
      if (value) {
        this.editing = true;
      }
    },
    handleFileUpdate(file) {

      this.loading = true;
      const formData = new FormData();

      formData.append('file', file);
      formData.append('name', this.file.name);

      this.fileReplace({
        id: this.file.id,
        formData,
      }).then((response) => {
        window.app.snackbar('Changes Saved');
        this.editing = false;
      }).catch((error) => {
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {
    disabled: {
      default: false,
      required: false,
      type: Boolean,
    },
    types: {
      default: () => [],
      required: false,
      type: Array,
    },
    value: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
#image-editor {
  height: 500px;
}
</style>
