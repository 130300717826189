<template>
  <Page>
    <div v-if="isMobile">
      <div class="notification is-warning has-text-centered">
        Only available on Desktop
      </div>
    </div>
    <div v-else>

      <div class="has-text-right has-margin-bottom">
        <div class="tag is-warning">
          EXPERIMENTAL
        </div>
      </div>

      <div class="columns">

        <div class="column">

          <Box flat :black="$themeDark">

            <div class="columns">
              <div class="column">
                <Label>pH</Label>
              </div>
              <div class="column">
                <NumberInput v-model="pH" :min="0" :max="14"/>
              </div>
              <div class="column"></div>
              <div class="column"></div>
            </div>

            <hr>

            <div class="columns">
              <div class="column">
                <Label>Nutrient</Label>
              </div>
              <div class="column has-text-centered">
                <Label>Current</Label>
              </div>
              <div class="column has-text-centered">
                <Label>Addition</Label>
              </div>
              <div class="column has-text-right">
                <Label>Total</Label>
              </div>
            </div>

            <template v-for="element in elements">

              <div class="columns is-align-items-center" style="margin-bottom: 3px;">
                <div class="column">
                  <Label>{{ element.label }} ({{element.symbol}})</Label>
                </div>
                <div class="column">
                  <NumberInput v-model="current[element.key]"/>
                </div>
                <div class="column">
                  <NumberInput v-model="addition[element.key]"/>
                </div>
                <div class="column has-text-right">
                  {{totalWithAdditions(element)}} ppm
                </div>
              </div>

            </template>

          </Box>

        </div>

        <div class="column is-three-fifths">

          <Box flat :black="$themeDark">

            <div class="columns">
              <div class="column">
                <Label>✅ Threshold (%)</Label>
              </div>
              <div class="column">
                <NumberInput v-model="acceptableThreshold" :min="0" :max="100"/>
              </div>
              <div class="column"></div>
              <div class="column"></div>
            </div>

            <hr />

            <div class="columns">
              <div class="column">
                Nutrients
              </div>
              <div class="column has-text-centered">
                Target Ratio
              </div>
              <div class="column has-text-centered">
                Current Ratio
              </div>
              <div class="column has-text-centered">
                % Off Target
              </div>
              <div class="column has-text-centered">
                Ratio w/ Additions
              </div>
              <div class="column has-text-centered">
                % Off Target
              </div>
            </div>

            <template v-for="ratio in ratios">

              <div class="columns" style="margin-bottom: -30px;">
                <div class="column">
                  <Label>{{ ratio.label }}</Label>
                </div>
                <div class="column has-text-centered">
                  {{ lowPH ? ratio.lowPHTarget : ratio.target }} : 1
                </div>
                <div class="column has-text-centered">
                  {{ numeralFormatter(currentRatio(ratio), '0,0.00') }} : 1
                  <p class="help subtle" style="margin-top: -2.5px;">
                    {{numeralFormatter(current[getNumeratorKey(ratio)])}} : {{numeralFormatter(current[getDenominatorKey(ratio)])}}
                  </p>
                </div>
                <div class="column has-text-centered">
                  <span class="tag" :class="{
                    'is-success': currentRatioGood(ratio),
                    'is-danger': !currentRatioGood(ratio),
                  }">{{ numeralFormatter(currentRatioDelta(ratio), '0,0.00') }}%</span>
                </div>
                <div class="column has-text-centered">
                  {{afterAdditionsRatio(ratio)}}
                  <p class="help subtle" style="margin-top: -2.5px;">
                    {{numeralFormatter(total[getNumeratorKey(ratio)])}} : {{numeralFormatter(total[getDenominatorKey(ratio)])}}
                  </p>
                </div>
                <div class="column has-text-centered">
                  <span class="tag" :class="{
                    'is-success': afterAdditionRatioGood(ratio),
                    'is-danger': !afterAdditionRatioGood(ratio),
                  }">{{ numeralFormatter(afterAdditionsRatioDelta(ratio), '0,0.00') }}%</span>
                </div>
              </div>

            </template>

          </Box>

        </div>

      </div>

    </div>
  </Page>
</template>

<script>
import numeral from 'numeral';

export default {
  name: 'NutrientCalculator',
  computed: {
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    lowPH() {
      return this.pH < 7;
    },
    total() {

      const total = {};

      this.elements.forEach((element) => {
        const current = this.current[element.key] || 0;
        const addition = this.addition[element.key] || 0;
        total[element.key] = parseInt(current) + parseInt(addition);
      });

      return total;
    },
  },
  created() {

    const stored = JSON.parse(localStorage.getItem('nutrientCalculator')) || {};

    this.addition = {
      ...this.addition,
      ...stored.addition,
    };

    this.current = {
      ...this.current,
      ...stored.current,
    };

    this.pH = stored.pH || this.pH;
  },
  data() {
    return {
      acceptableThreshold: 10,
      addition: {},
      current: {},
      elements: [
        {
          key: 'nitrogen',
          label: 'Nitrogen',
          symbol: 'N',
        },
        {
          key: 'phosphorus',
          label: 'Phosphorus',
          symbol: 'P',
        },
        {
          key: 'potassium',
          label: 'Potassium',
          symbol: 'K',
        },
        {
          key: 'calcium',
          label: 'Calcium',
          symbol: 'Ca',
        },
        {
          key: 'magnesium',
          label: 'Magnesium',
          symbol: 'Mg',
        },
        {
          key: 'sulfur',
          label: 'Sulfur',
          symbol: 'S',
        },
        {
          key: 'zinc',
          label: 'Zinc',
          symbol: 'Zn',
        },
        {
          key: 'iron',
          label: 'Iron',
          symbol: 'Fe',
        },
        {
          key: 'manganese',
          label: 'Manganese',
          symbol: 'Mn',
        },
        {
          key: 'copper',
          label: 'Copper',
          symbol: 'Cu',
        },
        {
          key: 'boron',
          label: 'Boron',
          symbol: 'B',
        },
      ],
      pH: '',
      ratios: [
        {
          label: 'N : S',
          target: 10,
          lowPHTarget: 10,
        },
        {
          label: 'Ca : Mg',
          target: 6,
          lowPHTarget: 6,
        },
        {
          label: 'Ca : K',
          target: 15,
          lowPHTarget: 10,
        },
        {
          label: 'Ca : P',
          target: 100,
          lowPHTarget: 40,
        },
        {
          label: 'P : Zn',
          target: 15,
          lowPHTarget: 15,
        },
        {
          label: 'P : Mn',
          target: 4,
          lowPHTarget: 4,
        },
        {
          label: 'P : Cu',
          target: 25,
          lowPHTarget: 25,
        },
        {
          label: 'Zn : Cu',
          target: 2,
          lowPHTarget: 2,
        },
        {
          label: 'Mn : Zn',
          target: 3,
          lowPHTarget: 3,
        },
        {
          label: 'Mn : Cu',
          target: 5,
          lowPHTarget: 5,
        },
        {
          label: 'K : B',
          target: 200,
          lowPHTarget: 200,
        },
        {
          label: 'Mg : K',
          target: 2,
          lowPHTarget: 2,
        },
      ],
    };
  },
  methods: {
    afterAdditionsRatio(ratio) {
      const numeratorKey = this.getNumeratorKey(ratio);
      const denominatorKey = this.getDenominatorKey(ratio);

      const numerator = parseInt(this.current[numeratorKey]) + parseInt(this.addition[numeratorKey]);
      const denominator = parseInt(this.current[denominatorKey]) + parseInt(this.addition[denominatorKey]);

      if (numerator && denominator) {
        return (numerator / denominator).toFixed(2);
      }

      return null;
    },
    afterAdditionsRatioDelta(ratio) {
      const afterAdditionsRatio = this.afterAdditionsRatio(ratio);
      const target = (this.lowPH ? ratio.lowPHTarget : ratio.target);
      return (1 - (afterAdditionsRatio / target)) * -100;
    },
    afterAdditionRatioGood(ratio) {
      const afterAdditionsRatioDelta = this.afterAdditionsRatioDelta(ratio);
      return afterAdditionsRatioDelta <= this.acceptableThreshold && afterAdditionsRatioDelta * -1 <= this.acceptableThreshold;
    },
    currentRatio(ratio) {
      const numerator = this.current[this.getNumeratorKey(ratio)];
      const denominator = this.current[this.getDenominatorKey(ratio)];

      if (numerator && denominator) {
        return numerator / denominator;
      }

      return null;
    },
    currentRatioDelta(ratio) {
      const currentRatio = this.currentRatio(ratio);
      const target = (this.lowPH ? ratio.lowPHTarget : ratio.target);
      return (1 - (currentRatio / target)) * -100;
    },
    currentRatioGood(ratio) {
      const currentRatioDelta = this.currentRatioDelta(ratio);
      return currentRatioDelta <= this.acceptableThreshold && currentRatioDelta * -1 <= this.acceptableThreshold;
    },
    numeralFormatter(value, format = '') {
      if (format) {
        return numeral(value).format(format);
      } else {
        return numeral(value).format('0,0');
      }
    },
    getNumeratorKey(ratio) {
      const symbol = ratio.label.split(' : ')[0];
      return this.elements.find(element => element.symbol === symbol).key || null;
    },
    getDenominatorKey(ratio) {
      const symbol = ratio.label.split(' : ')[1];
      return this.elements.find(element => element.symbol === symbol).key || null;
    },
    saveLocally() {
      localStorage.setItem('nutrientCalculator', JSON.stringify({
        addition: {
          ...this.addition,
        },
        current: {
          ...this.current,
        },
        pH: this.pH,
      }));
    },
    totalWithAdditions (element) {
      return numeral(this.total[element.key]).format('0,0');
    },
  },
  watch: {
    addition: {
      deep: true,
      handler() {
        this.saveLocally();
      },
    },
    current: {
      deep: true,
      handler() {
        this.saveLocally();
      },
    },
    pH: {
      deep: true,
      handler() {
        this.saveLocally();
      },
    },
  },
}
</script>

<style scoped lang="scss">

</style>
