import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const isManagingDomain = window.location.origin.includes(process.env.VUE_APP_DOMAIN_MANAGEMENT_URL);
  const domainBeingManaged = payload.domainBeingManaged;

  let menuItems = [];
  let onClick = null;
  let title = '';

  if (!isManagingDomain) {

    title = 'Domain';

    onClick = () => {

      let query = '';

      query += `&domain=${window.app.env.domainUUID}`;

      let token = window.app.storage.getItem('token');

      if (token) {
        query += `&t=${btoa(token)}`;
      }

      window.location.replace(`${process.env.VUE_APP_DOMAIN_MANAGEMENT_URL}?${query}`);
    }

  } else {

    title = domainBeingManaged.name;

    menuItems = [
      {
        route: window.app.findRouteByName('home'),
      },
      {
        route: window.app.findRouteByName('domain/profile'),
      },
      {
        route: window.app.findRouteByName('domain/configuration'),
      },
      {
        route: window.app.findRouteByName('domain/mail'),
      },
      {
        route: window.app.findRouteByName('domain/billing'),
        when: domainBeingManaged.shouldShowBilling(),
      },
      {
        route: window.app.findRouteByName('domain/analytics'),
      },
      {
        route: window.app.findRouteByName('domain/branding'),
      },
      {
        route: window.app.findRouteByName('domain/features'),
      },
      {
        route: window.app.findRouteByName('domain/api-key/index'),
      },
      {
        route: window.app.findRouteByName('domain/tag/index'),
      },
      {
        route: window.app.findRouteByName('domain/account/index'),
      },
      {
        route: window.app.findRouteByName('domain/picker'),
      },
      {
        title: `Exit`,
        icon: 'arrow-turn-down-left',
        onClick: () => {
          window.location.replace(payload.domainBeingManaged.appUrl);
        },
      },
    ];

  }

  return new Menu({
    title,
    color: '#9100f7',
    icon: 'globe',
    open: isManagingDomain !== null,
    onClick,
    menuItems,
  });
};
