import storage from '@/drivers/LocalStorageDriver';

const defaultInspector = {
  fullScreen: false,
  header: {
    left: [],
    right: [],
  },
  height: window.innerHeight * 0.25,
  position: 'right',
  settingsModalActive: false,
  width: window.innerWidth * 0.25,
};

const defaultPrimaryMenu = {
  active: true,
  menuState: {},
  width: null,
};

export default {
  key: 'ui',
  namespaced: true,
  state: {
    inspector: {
      ...defaultInspector,
      ...storage.getItem('inspector'),
    },
    isMobile: false,
    mapControls: {
      mapLayers: false,
      mapStyles: false,
    },
    mobileHeader: {
      disabled: false,
      leftClick: null,
      leftIcon: null,
      leftText: '',
      rightClick: null,
      rightIcon: null,
      rightText: '',
      title: '',
    },
    primaryMenu: {
      ...defaultPrimaryMenu,
      ...storage.getItem('primaryMenu'),
    },
  },
  actions: {
    inspector({commit}, payload) {
      commit('inspector', payload);
    },
    isMobile({commit}, payload) {
      commit('isMobile', payload);
    },
    mapControls({commit}, payload = {}) {
      commit('mapControls', payload);
    },
    mobileHeader({commit}, payload = {}) {
      commit('mobileHeader', payload);
    },
    primaryMenu({commit}, payload) {
      commit('primaryMenu', payload);
    },
  },
  mutations: {
    inspector(state, payload) {

      const inspector = {
        ...state.inspector,
        ...payload,
      };

      state.inspector = {
        ...inspector,
      };
      storage.setItem('inspector', {
        ...inspector,
      });
    },
    isMobile(state, payload) {
      state.isMobile = payload;
    },
    mapControls(state, payload) {
      state.mapControls = {
        mapLayers: payload.mapLayers || false,
        mapStyles: payload.mapStyles || false,
      };
    },
    mobileHeader(state, payload) {
      state.mobileHeader = {
        disabled: payload.disabled || false,
        leftClick: payload.leftClick || null,
        leftIcon: payload.leftIcon || null,
        leftText: payload.leftText || '',
        rightClick: payload.rightClick || null,
        rightIcon: payload.rightIcon || null,
        rightText: payload.rightText || '',
        title: payload.title || '',
      };
    },
    primaryMenu(state, payload) {

      const nextState = {
        ...state.primaryMenu,
        ...payload,
      };

      state.primaryMenu = {
        ...nextState,
      };
      storage.setItem('primaryMenu', {
        ...nextState,
      });
    },
  },
};
