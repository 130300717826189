<template>
  <div class="widget">

    <Subtitle class="truncate" style="margin-bottom: 0;">
      <DeviceStatus :row="device" class="has-margin-right"/>
      <router-link :to="`/device/${device.uuid}`"
                   :class="{ 'has-text-light': $themeDark, 'has-text-dark': $themeLight }">{{ device.name }}
      </router-link>
    </Subtitle>

    <MapSingleDevice v-if="device.autoUpdatePosition" class="has-margin-top" :device="device" />

    <div v-for="parameter in parameters">
      <LabelLineValue
        :label="`${parameter.title} (${parameter.latestParameterValue ? parameter.latestParameterValue.createdAt.fromNow() : ''})`"
        :value="parameter.latestParameterValue ? `${parameter.latestParameterValue.formattedValue}` : 'No Data'"
      />
    </div>

  </div>
</template>

<script>
import DeviceStatus from '@/components/TableColumns/DeviceStatus';
import MapSingleDevice from '@/components-V2/Map/MapSingleDevice';

export default {
  name: 'Widget',
  components: {
    DeviceStatus,
    MapSingleDevice,
  },
  computed: {
    device() {
      return this.widget && this.widget.device ? this.$store.getters['device/findBy'](this.widget.device.uuid, 'uuid') : null;
    },
    parameters() {
      return this.widget.data ? this.$store.getters['parameter/collection'](this.widget.data) : [];
    },
    position() {
      return this.device ? this.device.position : null;
    },
  },
  props: {
    widget: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.widget {
  display: flex;
  height: 100%;
  flex-direction: column;

  .parameter-container {

  }

}
</style>
