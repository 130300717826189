<template>
  <div class="resource-list-search">

    <TextInput placeholder="Search" :value="value" @input="handleInput" @keydown.native.esc="handleClearSearch"/>

    <div class="resource-list-search-control resource-list-search-clear" v-if="value.length" @click="handleClearSearch">
      <BIcon icon="times"/>
    </div>

    <div class="resource-list-search-control resource-list-search-button" :class="{ 'is-active': value.length }" @click="handleSearch">
      <BIcon icon="search" />
    </div>

  </div>
</template>

<script>
export default {
  name: 'ResourceListSearch',
  computed: {},
  data() {
    return {};
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    handleClearSearch() {
      this.$emit('input', '');
      this.$emit('search');
    },
    handleSearch() {
      if (this.value.length > 0) {
        this.$emit('search');
      }
    },
  },
  props: {
    value: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.resource-list-search {

  position: relative;
  z-index: 1;

  .input {
    padding-right: 60px;
  }

  .resource-list-search-control {
    align-items: center;
    border-radius: 4px;
    bottom: 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 6px;
    width: 28px;
    z-index: 2;
  }

  .resource-list-search-clear {
    right: 40px;
  }

  .resource-list-search-button {
    right: 6px;

    &.is-active {
      background: $primary;
    }
  }
}
</style>
