<template>
  <BModal active @close="handleClose">
    <div class="container container-extra-extra-small">
      <Box flat black>
        <Title>Settings</Title>
        <Field>
          <Label>Position</Label>
          
        </Field>
      </Box>
    </div>
  </BModal>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'InspectorSettings',
  computed: {
    inspector() {
      return this.$store.state.ui.inspector;
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      inspectorUpdate: 'ui/inspector',
    }),
    handleClose() {
      this.inspectorUpdate({
        ...this.inspector,
        settingsModalActive: !this.inspector.settingsModalActive,
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
