<template>
  <div>
    <div class="columns">
      <div class="column">
        <div id="mini-map">
          <div id="search" >
            <b-button @click="handleToggleAddressInput"
                      type="is-primary"
                      icon-left="search" />
          </div>
          <div id="help">
            <div  class="tag is-primary is-hidden-touch">
              Use ⌘ or CTRL + Scroll to Zoom the map
            </div>
            <div  class="tag is-primary is-hidden-desktop">
              Use two fingers to move the map
            </div>
          </div>
          <div class="horizontal"></div>
          <div class="vertical"></div>
          <div v-if="mapId" :id="mapId"></div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <template v-if="addressInputActive">
          <AddressInput allowAutocomplete
                        placeholder="Start typing..."
                        v-model="location" />
        </template>
      </div>
    </div>

    <Field>
      <Label>Latitude</Label>
      <div class="control">
        <NumberInput v-model="coordinates.lat" @input="handleManualInput" :min="-90" :max="90" placeholder="Latitude"/>
        <FormError field="positionLat" :form="form" v-if="form"/>
      </div>
    </Field>

    <Field>
      <Label>Longitude</Label>
      <div class="control">
        <NumberInput v-model="coordinates.lng" @input="handleManualInput" :min="-180" :max="180" placeholder="Longitude"/>
        <FormError field="positionLng" :form="form" v-if="form"/>
      </div>
    </Field>

    <Field>
      <Label>Altitude</Label>
      <div class="control">
        <NumberInput v-model="coordinates.altitude" @input="handleManualInput" placeholder="Altitude"/>
        <FormError field="positionAltitude" :form="form" v-if="form"/>
      </div>
    </Field>

  </div>
</template>

<script>

import mapboxgl from 'mapbox-gl';
import {debounce} from "lodash";
import {mapActions} from "vuex";
import polylabel from "@mapbox/polylabel";

export default {
  name: 'PositionInput',
  computed: {
    devices() {
      return this.$store.getters['device/all'];
    },
    regions() {
      return this.$store.getters['region/all'];
    },
    coordinates() {
      return this.value ? this.value : {
        lng: null,
        lat: null,
        altitude: null
      }
    },
    deviceSources() {
      return this.devices.filter((device) => device.position && device.status).map((device) => {
        return  {
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': [device.position.lng, device.position.lat]
          },
          'properties': {
            'id': device.id,
            'uuid': device.uuid,
            'title': device.name,
            'status': device.status,
            'color': device.status === 'online' ? 'green' : 'red',
          }
        }
      })
    },
    regionSources() {
      return this.regions.filter((region) => region.area).map((region) => {
        return  {
          'type': 'Feature',
          'geometry': region.area,
          'properties': {
            'id': region.id,
            'uuid': region.uuid,
            'title': region.name,
            'color': region.color,
            'priority': false
          }
        }
      })
    },
    regionCenterSources() {
      return this.regions.filter((region) => region.area).map((region) => {

        return  {
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': polylabel(region.area.coordinates, 1.0)
          },
          'properties': {
            'id': region.id,
            'uuid': region.uuid,
            'title': region.name,
            'color': region.color,
            'priority': false
          }
        }
      })
    },
  },
  created() {
    // Generate unique map container ID
    this.mapId = `mapbox${this._uid}`;

    if (navigator && navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((response) => {
        if (response.state === 'granted') {
          navigator.geolocation.getCurrentPosition((position) => {
            return this.setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          });
        }
      });
    }
  },
  mounted () {
    this.initMap();
  },
  data() {
    return {
      map: null,
      mapId: null,
      location: {},
      onDrag: debounce(() => {
        const coordinates = this.map.getCenter();

        this.handleDragInput(coordinates)

        this.storeMapPosition();
      }, 50),
      addressInputActive: false
    };
  },
  methods: {
    ...mapActions({
      deviceIndex: 'device/index',
      setMap: 'system/setMap',
      setUserLocation: 'system/setUserLocation'
    }),
    initDevices() {

      if(!this.map.getSource('deviceSources')) {
        this.map.addSource('deviceSources', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': this.deviceSources
          }
        });
      }

      if(!this.map.getLayer('deviceLayer')) {
        this.map.addLayer({
          'id': 'deviceLayer',
          'type': 'circle',
          'source': 'deviceSources',
          'paint': {
            'circle-pitch-alignment': 'map',
            'circle-radius': 8,
            'circle-color': ['get', 'color'],
            'circle-stroke-color': 'rgb(255,255,255)',
            'circle-stroke-width': 2
          }
        });
      }

      if(!this.map.getLayer('deviceLabel')) {
        this.map.addLayer({
          'id': 'deviceLabel',
          'type': 'symbol',
          'source': 'deviceSources',
          'layout': {
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'text-field': ['get', 'title'],
            'text-variable-anchor': ['top'],
            'text-radial-offset': 1,
            'text-justify': 'auto',
            'text-allow-overlap': false,
            'text-ignore-placement': false,
          },
          paint: {
            "text-color": "#ffffff",
          }
        });
      }

    },
    initRegions() {

      if(!this.map.getSource('regionSources')) {
        this.map.addSource('regionSources', {
          'type': 'geojson',
          'cluster': false,
          'data': {
            'type': 'FeatureCollection',
            'features': this.regionSources
          }
        });
      }

      if(!this.map.getLayer('regionSourcesLayer')) {
        this.map.addLayer({
          'id': 'regionSourcesLayer',
          'type': 'fill',
          'source': 'regionSources',
          'paint': {
            'fill-color': ['get', 'color'],
            'fill-opacity': 0.5
          },
        });
      }

      if(!this.map.getSource('regionCenterSources')) {
        this.map.addSource('regionCenterSources', {
          'type': 'geojson',
          'cluster': false,
          'data': {
            'type': 'FeatureCollection',
            'features': this.regionCenterSources
          }
        });
      }

      if(!this.map.getLayer('regionSourcesLabel')) {
        this.map.addLayer({
          'id': 'regionSourcesLabel',
          'type': 'symbol',
          'source': 'regionCenterSources',
          'layout': {
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'text-field': ['get', 'title'],
            'text-variable-anchor': ['center'],
            'text-radial-offset': 0.5,
            'text-justify': 'center',
            'text-allow-overlap': true,
            'text-ignore-placement': true,
          },
          paint: {
            "text-color": "#ffffff",
          },
        });
      }
    },
    handleInput(value) {
      this.$emit('input', value);
    },
    handleDragInput (coordinates) {
      let value = {
        ... this.value,
        lng: coordinates.lng.toString(),
        lat: coordinates.lat.toString(),
      }

      this.handleInput(value);
    },
    handleManualInput(e) {
      let value = {
        ...this.value,
        lng: this.coordinates.lng,
        lat: this.coordinates.lat
      }

      this.updateCenter(value);
      this.handleInput(value);
    },
    handleToggleAddressInput() {
      this.addressInputActive = !this.addressInputActive;
    },
    initMap () {

      const map = window.app.storage.getItem('map') || null;
      const position = map ? map.position : null

      const mapCenter = position ? position.center : null;
      const zoom = position ? position.zoom : 14;
      const bearing = position ? position.bearing : 0;
      const pitch = position ? position.pitch : 0;

      let center;

      if(this.coordinates.lat && this.coordinates.lng) {
        center = [this.coordinates.lng, this.coordinates.lat]
      } else if (mapCenter) {
        center = [mapCenter.lng, mapCenter.lat];
      } else if (this.userLocation) {
        center = [this.userLocation.lng, this.userLocation.lat];
      } else {
        center = [-122.439, 37.755];
      }

      const style = this.$themeDark ? `mapbox://styles/mapbox/dark-v10` : `mapbox://styles/mapbox/light-v10`;

      mapboxgl.accessToken = window.app.env.mapboxToken;

      this.map = new mapboxgl.Map({
        cooperativeGestures: true,
        container: this.mapId,
        style,
        center,
        zoom,
        bearing,
        pitch,
        maxZoom: 22,
        minZoom: 0
      });

      this.map.on('load', () => {
        this.initRegions();
        this.initDevices();
      });

      this.map.on('move', () => {
        this.onDrag();
      });

      this.map.on('drag', (e) => {
        this.storeMapPosition();
      });

      this.map.on('rotate', (e) => {
        this.storeMapPosition();
      });

      this.map.on('zoom', (e) => {
        this.storeMapPosition();
      });

    },
    storeMapPosition () {

      const map = window.app.storage.getItem('map') || {};

      map.position = {
        center: this.map.getCenter(),
        zoom: this.map.getZoom(),
        pitch: this.map.getPitch(),
        bearing: this.map.getBearing(),
      };

      window.app.storage.setItem('map', map);
    },
    updateCenter(value) {
      this.map.setCenter([value.lng, value.lat]);
    }
  },
  props: {
    form: {
      required: false,
    },
    value: {
      default: () => {},
      type: Object | null
    }
  },
  watch: {
    location: {
      deep: true,
      handler () {
        if(this.location.lat && this.location.lng) {
          this.updateCenter(this.location);
        }
      },
    },
  },
}
</script>

<style lang="scss">
#mini-map {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 280px;
  overflow: hidden;
  position: relative;

  .horizontal {
    background-color: white;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    z-index:10;
  }

  .vertical {
    background-color: white;
    bottom: 0;
    left: 50%;
    position: absolute;
    top: 0;
    width: 1px;
    z-index:10;
  }

  .mapboxgl-map {
    flex: 1;
    height: 100%;
    position: relative;
    visibility: visible;

    *:focus {
      outline: none;
    }
  }

  #help {
    top: 15px;
    right: 15px;
    position: absolute;
    opacity: 0.8;
    z-index: 30;
  }

  #search {
    top: 15px;
    left: 15px;
    position: absolute;
    opacity: 0.8;
    z-index: 30;
  }
}

</style>

