import {
  Dashboard,
  Device,
  Model,
  Parameter,
  WidgetService as Service,
} from '@/internal';

export default class Widget extends Model {

  static get key() {
    return 'widget';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
    };
  }

  get dashboard() {
    return this.hasOne(Dashboard)
  }

  get device() {
    return this.hasOne(Device)
  }

}
