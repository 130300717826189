import store from '@/store';

export default (to, from, next) => {

  const user = store.getters['auth/user'];

  if (!user || ! user.account.id) {

    window.app.snackbar(`You don't have any active account. Please create one.`);

    window.app.d(`[hasAccountMiddleware] Redirected to /account/create`);

    return next({ path: '/account/create' });

  } else {

    return next();

  }
}
