<template>
  <div class="eu-menu-item-content">
    <BIcon v-if="menuItem.icon && !menuItem.hideIcon" :icon="menuItem.icon" custom-class="fa-fw" />
    <div class="eu-menu-item-title"
         :class="{ 'justify-content-center': align === 'center' }"
         v-if="!menuItem.hideTitle"
         v-html="menuItem.title"
    />
  </div>
</template>

<script>
export default {
  name: 'MenuItemContent',
  data() {
    return {};
  },
  props: {
    align: {
      type: String,
    },
    menuItem: {
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.eu-menu-item-content {
  align-items: center;
  display: flex;
  flex: 1;
  padding: 2.5px 0;
  position: relative;

  .icon {
    margin-right: 10px;
  }

  .eu-menu-item-title {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;

    &.justify-content-center {
      justify-content: center;
    }
  }
}
</style>
