import {
  Model,
  InventoryService as Service, QRCode,
} from '@/internal';

export default class Inventory extends Model {

  static get class() {
    return 'App\\Models\\Inventory';
  }

  static get key() {
    return 'inventory';
  }

  static get service() {
    return Service;
  }

  get qrCode() {
    return this.hasOne(QRCode);
  }
}
