import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const {
    domain,
  } = payload;

  return new Menu({
    title: 'Products',
    color: '#17a889',
    icon: 'box',
    menuItems: [
      {
        route: window.app.findRouteByName('domain/product/inventory'),
      },
    ],
  });
};
