<template>
  <div id="region-form" class="is-padded">

    <Section title="Basics" open>

      <Field>
        <Label>Name *</Label>
        <TextInput v-model="form.fields.name" placeholder="Name"/>
        <FormError field="name" :form="form"/>
      </Field>

      <Field>
        <Label>Color *</Label>
        <ColorInputWithTextInput v-if="form.fields.color" v-model="form.fields.color" placeholder="Color"/>
        <FormError field="color" :form="form"/>
      </Field>

      <Field>
        <div class="content has-margin-bottom">
          <Label class="has-margin-bottom">Area *</Label>

          <template v-if="false">
            <p class="help">To draw a region follow the next steps:</p>
            <ul class="help">
              <li>Click on the icon at the top-right corner</li>
              <li>A cross-hairs will appear as your mouse pointer over the map. Draw the outline of your region by
                clicking around the outside of the area.
              </li>
              <li>Make sure that your last point matches your first point to end the region.</li>
              <li>Click Save below.</li>
              <li>EDIT: Double click over the region.</li>
            </ul>
          </template>

        </div>

        <MapInput v-model="form.fields.geojson"
                  :color="form.fields.color"
                  :regions="otherRegions"
        />

      </Field>

    </Section>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>

          <Button @click="handleDelete" outlined danger :loading="deleting" v-if="region">
            Delete
          </Button>

        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>



    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
  Region,
} from '@/internal';

import MapInput from '@/components-V2/Inputs/MapInput';

export default {
  name: 'Form',
  components: {
    MapInput,
  },
  computed: {
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    otherRegions() {
      if (this.region) {
        return this.regions.filter((region) => region.area && region.id !== this.region.id);
      } else {
        return this.regions.filter((region) => region.area);
      }
    },
    region() {
      return this.$route.params.regionUUID ? this.$store.getters['region/findBy'](this.$route.params.regionUUID, 'uuid') : null;
    },
    regions() {
      return this.$store.getters['region/all'];
    },
    pageTitle() {
      return this.$label('Region');
    }
  },
  created() {
    if (this.region) {
      this.fillForm();
    }
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        color: '#ff0000',
        geojson: null,
        name: ''
      }),
      loading: false
    };
  },
  methods: {
    ...mapActions({
      destroy: 'region/destroy',
      regionIndex: 'region/index',
      store: 'region/store',
      update: 'region/update',
    }),
    handleDelete (row) {
      this.$deleteDialog({
        target: this.region.name,
        targetType: this.$label('Region'),
        onConfirm: () => {
          this.loading = true;
          this.destroy({
            id: this.region.id,
          }).then(() => {
            window.app.snackbar('Region Deleted');
            this.$router.push({
              name: 'region/index',
            });
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.loading = false;
          });
        },
      });
    },
    handleSave() {

      const fields = {
        ...this.form.fields,
        area: this.form.fields.geojson ? this.form.fields.geojson.geometry : null
      };
      this.form.errors.clear();

      let action = null;

      if (this.region) {
        action = this.update;
      } else {
        action = this.store;
      }

      if (action) {
        this.loading = true;

        action(Region.preparePayload(fields)).then((regionId) => {

          const region = this.$store.getters['region/show'](regionId);

          window.app.snackbar('Region Saved');

          this.$router.push({
            name: 'region/show',
            params: {
              regionUUID: region.uuid,
            },
          });

        }).catch((error) => {
          this.form.recordErrors(error);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    fillForm() {
      this.form.fields = {
        ...this.region
      };
    },
  },
  watch: {
    region() {
      this.fillForm();
    },
  }
}
</script>

<style scoped lang="scss">

</style>
