<template>
  <router-link :to="`/account/user/${user.id}`" class="user resource-list-item" :class="{ 'is-dark': $themeDark }">
    {{user.fullName}} [{{user.email}}] <div class="tag is-info is-pulled-right">{{user.role.toUpperCase()}}</div>
  </router-link>
</template>

<script>
import Preview from '@/components-V2/User/Preview';

export default {
  name: 'ResourceListItem',
  components: {
    Preview,
  },
  computed: {
    user() {
      return this.resource;
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
