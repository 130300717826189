import {
  Menu,
} from '@/internal';

export default (payload = {}) => {
  return new Menu({
    title: 'Legal',
    icon: 'gavel',
    open: payload.open || false,
    menuItems: [

    ],
  });
};
