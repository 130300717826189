<template>
  <div id="integration-form" class="is-padded">

    <BLoading active v-if="loading"/>

    <template v-else>

      <Section title="Basics" open>

        <Field>
          <Label>Name *</Label>
          <TextInput v-model="form.fields.name" placeholder="Name"/>
          <FormError field="name" :form="form"/>
        </Field>

        <Field>
          <Label>Type *</Label>
          <SelectInput v-model="form.fields.type" :options="typeOptions"/>
          <FormError field="type" :form="form"/>
        </Field>

        <Field v-if="form.fields.type === 'App\\Integrations\\Webhook'">
          <WebhookForm v-model="form.fields.config" :form="form"/>
        </Field>

        <Field v-else-if="form.fields.type === 'App\\Integrations\\Slack'">
          <SlackForm v-model="form.fields.config" :form="form"/>
        </Field>

      </Section>

    </template>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>

          <Button @click="handleIntegrationDelete" outlined danger :loading="deleting" v-if="integration">
            Delete
          </Button>

        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleIntegrationSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>


    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

import WebhookForm from '@/components-V2/Integration/WebhookForm';
import SlackForm from '@/components-V2/Integration/SlackForm';

export default {
  name: 'Form',
  components: {
    SlackForm,
    WebhookForm,
  },
  computed: {
    pageTitle() {
      return this.$label('Integration');
    },
    integration() {
      return this.$route.params.integrationUUID ? this.$store.getters['integration/findBy'](this.$route.params.integrationUUID, 'uuid') : null;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (this.$route.params.integrationUUID) {
      this.loadIntegration();
    }
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        name: '',
        type: '',
        config: {},
      }),
      loading: false,
      typeOptions: [
        {
          label: '- Select Type -',
          value: null,
        },
        {
          label: 'Webhook',
          value: 'App\\Integrations\\Webhook',
        },
        // {
        //   label: 'Slack',
        //   value: 'App\\Integrations\\Slack',
        // },
      ]
    };
  },
  methods: {
    ...mapActions({
      integrationDestroy: 'integration/destroy',
      integrationShow: 'integration/show',
      integrationStore: 'integration/store',
      integrationUpdate: 'integration/update',
    }),
    fillForm() {
      this.form.fields = {
        ...this.integration
      };
    },
    handleIntegrationDelete() {
      this.$deleteDialog({
        target: this.integration.name,
        targetType: this.$label('Integration'),
        onConfirm: () => {
          this.loading = true;
          this.integrationDestroy({
            id: this.integration.id,
          }).then(() => {
            window.app.snackbar('Integration Deleted');
            this.$router.push({
              name: 'integration/index',
            });
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.loading = false;
          });
        },
      });
    },
    handleIntegrationSave() {

      this.form.errors.clear();

      let action = null;

      if (this.integration) {
        action = this.integrationUpdate;
      } else {
        action = this.integrationStore;
      }

      if (action) {

        this.loading = true;

        action({
          ...this.form.fields,
          id: this.form.fields.uuid,
        }).then(() => {

          window.app.snackbar('Integration Saved');

          this.$router.push({
            name: 'integration/index',
          })
        }).catch((error) => {
          this.form.recordErrors(error);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    loadIntegration() {
      this.loading = true;
      this.integrationShow({
        id: this.$route.params.integrationUUID,
      }).then(this.fillForm).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>

<style scoped lang="scss">
#conditions {
  .condition-row {
    padding: 15px 0;
  }
}
</style>
