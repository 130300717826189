import {
  Account,
  Parameter,
  Model,
  AssetService as Service, Tag,
} from '@/internal';

export default class Asset extends Model {

  static get key() {
    return 'asset';
  }

  static get service() {
    return Service;
  }

  get account() {
    return this.hasOne(Account)
  }

  get parent() {
    return this.hasOne(Asset, null, null, 'parentId');
  }

  get children() {
    return this.hasMany(Asset, 'parentId');
  }

  get parameters() {
    return this.hasMany(Parameter);
  }

  get tags () {
    return this.hasMany(Tag);
  }
}
