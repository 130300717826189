<template>
  <div class="user-resource-list">
    <ResourceList addLabel="Add User"
                  :addRoute="addRoute"
                  :params="params"
                  :query="query"
                  :resource="resource"
                  :renderAs="renderAs"
                  sortBy="email"
                  :sortByOptions="sortByOptions"
    />
  </div>
</template>

<script>
import {User} from '@/internal';
import UserResourceListItem from '@/components-V2/User/ResourceListItem';

export default {
  name: 'UserResourceList',
  data() {
    return {
      addRoute: !this.hideAddButton ? window.app.findRouteByName('account/user/create') : null,
      params: {},
      renderAs: UserResourceListItem,
      resource: User,
      sortByOptions: [
        {
          label: 'First Name',
          value: 'first_name',
        },
        {
          label: 'Last Name',
          value: 'last_name',
        },
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Created Date',
          value: 'created_at',
        },
      ],
    };
  },
  props: {
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {},
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
