import store from '@/store';

export default (to, from, next) => {

  const authenticated = store.state.auth.authenticated;
  const user = store.getters['auth/user'];
  const path = window.app.findRouteByName('profile').path;

  if (to.name === 'auth/logout') {
    return next();
  }

  if (authenticated && user && !user.emailVerified && to.path !== path) {

    if (!window.app.storage.getItem('redirectRoute')) {
      window.app.storage.setItem('redirectRoute', to);
    }

    window.app.snackbar('You must validate your email address.');

    window.app.d(`[emailVerifiedMiddleware] Redirected to ${path}`);

    return next({path});

  } else {

    return next();

  }
}
