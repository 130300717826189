<template>
  <div class="default-device-input">

    <Box>

      <Field>
        <Label>Name *</Label>
        <TextInput v-model="local.name" />
        <FormError :form="form" field="defaultDeviceName"/>
        <Help>
          Variables will be replaced when the device is onboarded. You may use `serialNumberLastFour` or `devEuiLastFour`. The variable should be within the double curly brackets.
        </Help>
      </Field>

      <Field>
        <Label>Parameters *</Label>
        <ParametersInput v-model="local.parameters" :form="form" deletable />
        <FormError :form="form" field="default_device.parameters"/>
      </Field>

      <Field>
        <Label>Positions Enabled *</Label>
        <BSwitch v-model="local.positionsEnabled"/>
      </Field>

      <div class="columns">
        <div class="column">
          <Field>
            <Label>Trips Enabled *</Label>
            <BSwitch v-model="local.tripsEnabled"/>
          </Field>
        </div>
        <div class="column" v-if="local.tripsEnabled">
          <Field>
            <TimeIntervalInput label="Trips Downtime *" v-model="local.tripsDownTime"/>
          </Field>
        </div>
      </div>

    </Box>

  </div>
</template>

<script>
import ParametersInput from '@/components-V2/Inputs/ParametersInput';

export default {
  name: 'DefaultDeviceInput',
  components: {
    ParametersInput,
  },
  data() {
    return {
      local: {
        name: 'Acme Device {{serialNumberLastFour}}',
        parameters: [],
        positionsEnabled: true,
        public: true,
        tripsEnabled: true,
        tripsDownTime: 86400,
      },
    };
  },
  props: {
    form: {
      required: true,
    },
    value: {
      required: true,
    }
  },
  watch: {
    local: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.local) !== JSON.stringify(this.value)) {
          this.$emit('input', this.local);
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.local) !== JSON.stringify(this.value)) {
          this.local = {
            ...this.local,
            ...this.value,
          };
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">

</style>
