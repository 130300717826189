<template>
  <div id="device-show" class="is-padded" v-if="device">

    <BLoading active v-if="loading" :is-full-page="false"/>

    <div class="columns is-mobile" style="margin-bottom: 0;">
      <div class="column is-flex">
        <DeviceStatusDot :device="device"/>
        <div class="has-margin-left">{{ device.name }}</div>
      </div>
      <div class="column has-text-right">
        <div class="tags is-justify-content-flex-end">
          <DeviceLicenseTag v-if="shouldShowLicensedTag" :device="device"/>
          <div class="tag is-info" v-if="device.isVirtual">Virtual</div>
        </div>
      </div>
    </div>

    <Section title="State" v-if="latestUplink" open>
      <DeviceState :device="device" v-if="latestUplink"/>
      <Instruction type="is-warning" v-else>No Uplinks received yet.</Instruction>
    </Section>

    <Section title="Meta">
      <div class="tags is-justify-content-flex-end">
        <template v-for="tag in device.tags">
          <Tag :tag="tag"/>
        </template>
      </div>
      <LabelLineValue label="Latest Trip" :to="`/trip/${device.latestTripUuid}`"
                      v-if="device.latestTripUuid"/>
      <LabelLineValue label="Manufacturer Label" :url="device.manufacturerLabel.proxyUrl"
                      v-if="device.manufacturerLabel"/>
      <LabelLineValue :label="$label('Device') + ' EUI'" :value="device.deviceEui" clickToCopy/>
      <LabelLineValue label="App EUI" :value="device.appEui" clickToCopy v-if="device.appEui"/>
      <LabelLineValue label="Heartbeat Interval" :value="device.heartbeatIntervalFormatted"/>
      <LabelLineValue label="Auto Update Position" :value="device.autoUpdatePosition ? 'Yes' : 'No'"/>
      <LabelLineValue label="Public" :value="device.public ? 'Yes' : 'No'"/>
      <LabelLineValue label="Public URL" :value="device.publicUrl" click-to-copy v-if="device.public"/>
    </Section>

    <Section title="Virtual" v-if="device.isVirtual">
      <LabelLineValue label="Virtual Device Type" :value="device.virtual.type"/>
      <LabelLineValue label="Uplink Frequency" :value="device.virtual.uplinkFrequency"/>
      <LabelLineValue label="Next Uplink At" :value="nextUplinkAt"/>
    </Section>

    <Section title="How to receive Uplinks" v-else-if="!device.isVirtual && !latestUplink">
      <HowToAddAnUplink/>
    </Section>

    <Section title="Charts">
      <DeviceCharts :device="device"/>
    </Section>

    <Section title="Latest Uplink" v-if="loaded && latestUplink" :loading="loading">
      <UplinkPreview :uplink="latestUplink"/>
    </Section>

    <Section title="Uplinks">
      <UplinkResourceList :query="{deviceId: device.id}"/>
    </Section>

    <Section title="Uplink Exports" v-if="!isMobile && loaded" :loading="loading">
      <UplinkExportTable :device="device"/>
    </Section>

    <Section title="Estimated Location" v-if="user && latestUplink">
      <Instruction>This estimate is based on the last Uplink received.</Instruction>
      <UplinkEstimatedLocation :uplink="latestUplink"/>
    </Section>

    <Section title="Notes" v-if="device" :loading="loading">
      <NoteResourceList :notableId="device.id" notableType="Device"/>
    </Section>

    <Section title="Trips" v-if="loaded && device.tripsEnabled && device.trips"
             :loading="loading">
      <TripResourceList :query="{deviceId: device.id}"/>
    </Section>

  </div>

</template>

<script>
import {
  mapActions,
} from 'vuex';

import moment from 'moment-timezone';

import DeviceCharts from '@/components-V2/Device/Charts';
import DevicePreview from '@/components-V2/Device/Preview';
import DeviceState from '@/components-V2/Device/State';
import DeviceLicense from '@/components-V2/Device/License';
import DeviceLicenseTag from '@/components-V2/Device/LicenseTag';
import DeviceStatusDot from '@/components-V2/Device/StatusDot';
import NoteResourceList from '@/components-V2/Note/ResourceList';
import HowToAddAnUplink from '@/components/HowToAddAnUplink';
import StatusTag from '@/components-V2/Device/StatusTag';
import TripResourceList from '@/components-V2/Trip/ResourceList';
import UplinkEstimatedLocation from '@/components-V2/Uplink/EstimatedLocation';
import UplinkPreview from '@/components-V2/Uplink/Preview';
import UplinkExportTable from '@/components/UplinkExport/Table';
import UplinkResourceList from '@/components-V2/Uplink/ResourceList';
import UplinkConvert from '@/components-V2/Uplink/Convert';

export default {
  name: 'Show',
  components: {
    DeviceCharts,
    DeviceLicense,
    DeviceLicenseTag,
    DevicePreview,
    DeviceState,
    DeviceStatusDot,
    UplinkEstimatedLocation,
    HowToAddAnUplink,
    NoteResourceList,
    StatusTag,
    TripResourceList,
    UplinkExportTable,
    UplinkPreview,
    UplinkResourceList,
    UplinkConvert,
  },
  computed: {
    account() {
      return this.user && this.user.account ? this.$store.getters['account/findBy'](this.user.account.uuid, 'uuid') : null;
    },
    alarms() {
      return this.device ? this.device.alarms : [];
    },
    device() {
      return this.$route.params.deviceUUID ? this.$store.getters['device/findBy'](this.$route.params.deviceUUID, 'uuid') : null;
    },
    fullPath() {
      return this.$route.fullPath;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    latestUplink() {
      return this.device ? this.device.latestUplink : null;
    },
    lockedOnInspected() {
      return this.$store.state.map.lockedOnInspected;
    },
    map() {
      return this.$store.state.map.map || null;
    },
    nextUplinkAt() {
      return this.device.virtual.nextUplinkAt ? moment(this.device.virtual.nextUplinkAt).format(this.system.settings.datetimeFormat) : null;
    },
    pageTitle() {
      return `${this.device ? this.device.name : ''}`;
    },
    parameterValues() {
      return this.uplinks ? this.uplinks.map((uplink) => {
        return uplink.parameterValues;
      }) : [];
    },
    shouldShowLicensedTag() {
      return this.account && this.account.billing.type === 'license';
    },
    system() {
      return this.$store.state.system;
    },
    uplinks() {
      return this.device ? this.device.uplinks : [];
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    this.loadDevice(this.device !== null);
    window.app.vue.$on('uplinkReceived', this.loadDeviceInBackground);
  },
  destroyed() {
    window.app.vue.$off('uplinkReceived', this.loadDeviceInBackground);
  },
  data() {
    return {
      loaded: this.device !== null,
      loading: false,
      loadingInBackground: false,
      dateRange: window.app.storage.getItem('range-picker.device/show') || '1w',
      exportModalActive: false,
      stickyTracking: true,
      runConvertersModalActive: false
    };
  },
  methods: {
    ...mapActions({
      inspectorUpdate: 'ui/inspector',
      show: 'device/show',
    }),
    handleEdit() {
      const route = window.app.findRouteByName('device/edit', [], {
        params: {
          deviceUUID: this.device.uuid
        },
      });

      this.$router.push(route);
    },
    handleImport() {
      const route = window.app.findRouteByName('device/import', [], {
        params: {
          deviceUUID: this.device.uuid
        },
      });

      this.$router.push(route);
    },
    loadDevice(inBackground = false) {

      if (this.loading || this.loadingInBackground) {
        return new Promise((resolve) => {
          resolve();
        });
      }

      if (!inBackground) {
        this.loading = true;
      } else {
        this.loadingInBackground = true;
      }

      let include = [
        'latestUplink',
        'latestUplink.parameterValues',
        'latestUplink.usage',
        'notes',
        'parameters',
        'tags',
      ];

      return this.show({
        id: this.$route.params.deviceUUID,
        params: {
          include,
        },
      }).then(() => {
        this.loaded = true;

        if (this.lockedOnInspected && this.device && this.device.position) {
          window.app.vue.$emit('flyTo', [this.device.position.lng, this.device.position.lat]);
        }

      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.loading = false;
        this.loadingInBackground = false;
      });
    },
    loadDeviceInBackground() {
      return this.loadDevice(true);
    },
  },
}
</script>

<style scoped lang="scss">

</style>
