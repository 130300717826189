import normalize from 'json-api-normalizer';

import Notification from './Notification';

const service = new Notification.service();

export default {
  key: 'notification',
  namespaced: true,
  state: {
    loading: false,
    notifications: [],
  },
  actions: {
    index({commit, dispatch, state}, payload) {

      if (state.loading) {
        return;
      }

      commit('loading', true);

      return service.index(payload).then((response) => {
        const normalized = normalize(response.data);
        const notifications = Object.values(normalized.notification).map((notification) => {
          if (notification.attributes) {
            for (const [key, value] of Object.entries(notification.attributes)) {
              notification[key] = value;
            }
            delete notification.attributes;
          }
          return new Notification(notification);
        });

        commit('notifications', notifications);
        commit('loading', false);
      });
    },
    update: ({commit, dispatch}, payload) => {
      return service.update(payload).then(() => {
        dispatch('auth/userShow', {}, {root:true});
      });
    },
  },
  mutations: {
    loading(state, payload) {
      state.loading = payload;
    },
    notifications(state, payload) {
      state.notifications = payload;
    },
  },
};
