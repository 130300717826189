export default {
  id: 'terrain',
  group: 'map',
  enabled: false,
  label: 'Terrain',
  icon: 'mountain',
  source() {
    return {
      type: 'raster-dem',
      url: 'mapbox://mapbox.terrain-rgb',
    };
  },
  terrain() {
    return {
      source: 'terrain',
      exaggeration: 1.5,
    };
  }
};
