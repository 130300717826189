<template>
  <div>
    <div class="has-text-right has-margin-bottom">
      <Button :light="$themeDark" :dark="$themeLight" outlined @click="handleExportModalToggle">
        <BIcon icon="download" style="margin-right: 5px;"/>
        Export
      </Button>
    </div>

    <div id="import-table">

      <BLoading active v-if="loading" :isFullPage="false"/>

      <BTable class="lss-table" :class="{ 'is-dark': $themeDark }" :data="uplinkExports">

        <BTableColumn field="created_at" label="Date" v-slot="props">
          {{ props.row.createdAt.format('lll') }}
        </BTableColumn>

        <BTableColumn field="parameters" label="Period" v-slot="props">
          {{ formatDate(props.row.parameters.from) }} - {{ formatDate(props.row.parameters.to) }}
        </BTableColumn>

        <BTableColumn field="uplinkImportsCount" label="Rows" v-slot="props">
          {{ formatRowsCount(props.row.rowsCount) }}
        </BTableColumn>

        <BTableColumn field="status" label="Status" v-slot="props">
          <template v-if="props.row.status === 'error'">
            <span class="tag is-danger">
              {{ props.row.status ? props.row.status.toUpperCase() : '' }}
            </span>
          </template>
          <template v-else>
             <span class="tag is-info">
              {{ props.row.status ? props.row.status.toUpperCase() : '' }}
            </span>
          </template>
        </BTableColumn>

        <BTableColumn v-slot="props">
          <div v-if="props.row.url" style="cursor: pointer" @click="handleDownload(props.row)">
            <BIcon icon="download" size="is-small"/>
          </div>
        </BTableColumn>

        <BTableColumn v-slot="props">
          <div style="cursor: pointer" @click="handleDelete(props.row)">
            <BIcon icon="trash-alt" size="is-small"/>
          </div>
        </BTableColumn>

      </BTable>

    </div>

    <BModal :active="exportModalActive" @close="handleExportModalToggle" :width="500">
      <UplinkExportForm :deviceId=device.id @success="handleExportSuccess"/>
    </BModal>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import numeral from 'numeral';
import moment from 'moment';

import UplinkExportForm from '@/components/UplinkExport/Form';


export default {
  name: 'ExportTable',
  components: {
    UplinkExportForm
  },
  computed: {
    uplinkExports() {
      return this.device ? this.device.uplinkExports : [];
    },
  },
  data() {
    return {
      errors: null,
      exportModalActive: false,
      loading: false,
    };
  },
  created() {
    this.loadDevice();
  },
  mounted() {
    window.app.vue.$on('uplinkExportStatus', this.loadUplinkExport);
  },
  destroyed() {
    window.app.vue.$off('uplinkExportStatus', this.loadUplinkExport);
  },
  methods: {
    ...mapActions({
      deviceShow: 'device/show',
      uplinkExportDestroy: 'uplinkExport/destroy',
      uplinkExportShow: 'uplinkExport/show'
    }),
    formatRowsCount(value) {
      return numeral(value).format('0,0');
    },
    formatDate(value) {
      return moment(value).format('L');
    },
    handleDownload(row) {
      window.open(row.url, '_blank');
    },
    handleDelete(row) {

      this.$deleteDialog({
        target: row.uuid,
        targetType: 'Uplink Export',
        onConfirm: () => {
          this.loading = true;
          this.uplinkExportDestroy({
            id: row.id,
          }).then(() => {
            window.app.snackbar('Export Deleted');
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).then(this.loadDevice).finally(() => {
            this.loading = false;
          });
        },
      });
    },
    handleExportModalToggle() {
      this.exportModalActive = !this.exportModalActive;
    },
    handleExportSuccess() {
      this.handleExportModalToggle();
      this.loadDevice();
    },
    loadDevice() {
      this.loading = true;
      this.deviceShow({
        id: this.device.uuid,
        params: {
          include: ['uplinkExports'],
        },
      }).finally(() => {
        this.loading = false;
      });
    },
    loadUplinkExport(e) {
      const uplinkExport = this.uplinkExports.find(uplinkExport => uplinkExport.id === parseInt(e.uplinkExportId));
      const statusChanged = e.status !== uplinkExport.status;

      if (uplinkExport && statusChanged) {
        this.uplinkExportShow({
          id: uplinkExport.uuid,
        });
      }
    },
  },
  props: {
    device: {
      required: true,
    },
  },
}
</script>

<style lang="scss">
.lss-table {

  &.is-dark {
    .table {
      tr {
        th {
          background: $black-bis;
          border-color: $grey-dark;
          color: $white;

          &.sortable:hover {
            background: $grey-darker;
          }
        }

        td {
          border-color: $grey-dark;
          color: $white;
        }
      }
    }
  }

  .table-controls {

    display: flex;
    justify-content: flex-end;

    .search {
      position: relative;

      .search-icon {
        position: absolute;
        top: 10px;
        left: 7.5px;
        z-index: 2;
      }

      input.input[type="text"] {
        padding-left: 35px;
      }

      .search-cancel {
        position: absolute;
        top: 10px;
        right: 7.5px;
        z-index: 2;
      }
    }
  }

  .table-footer {
    .paginator {
      br {
        display: none;
      }
    }
  }

  .table {
    background: transparent;

    tr {
      th {
        background: $grey-light;
        color: $black-ter;
      }

      td {
        background: transparent;
        color: $black-ter;
      }
    }
  }
}

</style>
