<template>
  <div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'License',
  computed: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {
    device: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
