<template>
  <Page id="sign-up-page">
    <div class="container container-extra-extra-small">
      <div class="columns">
        <div class="column">

          <Title>Sign Up</Title>

          <Box flat :black="$themeDark">

            <Field v-if="shouldShowAccount">
              <Label>Account Name *</Label>
              <TextInput v-model="form.fields.account.name"/>
              <FormError field="account.name" :form="form"/>
            </Field>

            <Field>
              <Label>First Name *</Label>
              <TextInput id="firstName" v-model="form.fields.firstName"/>
              <FormError field="firstName" :form="form"/>
            </Field>

            <Field>
              <Label>Last Name *</Label>
              <TextInput id="lastName" v-model="form.fields.lastName"/>
              <FormError field="lastName" :form="form"/>
            </Field>

            <Field>
              <Label>Email *</Label>
              <EmailInput id="email" v-model="form.fields.email"/>
              <FormError field="email" :form="form"/>
            </Field>

            <Field>
              <Label>Password *</Label>
              <PasswordInput id="password" v-model="form.fields.password"/>
              <FormError field="password" :form="form"/>
            </Field>

            <Field>
              <Label>Confirm Password *</Label>
              <PasswordInput id="passwordConfirmation" v-model="form.fields.passwordConfirmation"/>
              <FormError field="passwordConfirmation" :form="form"/>
            </Field>

            <div class="help has-text-centered has-margin-vertical">
              By signing up, you are agreeing to our<br/>
              <a href="https://app.termly.io/document/privacy-policy/c553b543-0e78-4892-bd8f-df2c000ba097"
                 target="_blank">Privacy Policy</a> & <a
              href="https://app.termly.io/document/terms-of-use-for-website/c53a751a-fd3c-4388-a93c-ff8546890e82"
              target="_blank">Terms of Use</a>.
            </div>

            <div class="has-margin-top">

              <Button id="signUpButton" primary block :loading="loading" @click="handleSignUp">Sign Up</Button>

              <div class="has-text-centered is-padded" :class="{ 'has-text-light': $themeDark }">
                - or -
              </div>

              <Button router-link outlined block :light="$themeDark" :to="`/auth/login?flow=${flow}`">Log In</Button>

            </div>

          </Box>

        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import {mapActions} from 'vuex';

import {
  Form,
} from '@/internal'

let flow;

export default {
  name: 'SignUp',
  computed: {
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    flow() {
      return this.$route.query.flow || 'default';
    },
    shouldShowAccount() {
      return this.flow !== 'private-label' && this.domain && this.domain.name !== 'MeatCache';
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {

    if (!this.$domainFeatures.public_sign_up.enabled) {
      this.$router.push({
        name: 'auth/login',
      });
    }

    if (process.env.VUE_APP_ENV === 'local') {
      this.form.fields.account.name = 'Acme Food Co.',
        this.form.fields.firstName = 'Jane';
      this.form.fields.lastName = 'Doe';
      this.form.fields.email = 'jane.doe@gmail.com';
      this.form.fields.password = 'secret';
      this.form.fields.passwordConfirmation = 'secret';
    }

    flow = this.flow;
  },
  data() {
    return {
      form: new Form({
        account: {
          name: '',
        },
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      signUp: 'auth/signUp',
    }),
    handleSignUp() {

      this.loading = true;

      this.form.errors.clear();

      let payload = {
        ...this.form.fields,
      };

      if (this.domain.name === 'MeatCache') {
        payload.account.name = `${payload.lastName} Account`;
      }

      this.signUp({
        device: navigator.userAgent || navigator.vendor,
        flow: this.flow,
        ...payload,
      }).then(() => {

        window.app.snackbar({
          message: 'Signed Up!',
        });

        // For some reason the computed property was changing before this could execute
        if (flow === 'private-label') {
          window.location.replace(`${process.env.VUE_APP_DOMAIN_MANAGEMENT_URL}/domain/create?t=${btoa(window.app.storage.getItem('token'))}`);
          return;
        }

        window.location.replace('/my/profile');

      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
#sign-up-page {
  .logo {
    height: 100px;
  }
}
</style>
