import Index from '@/views/Integration/Index';
import Show from '@/views/Integration/Show';
import Form from '@/views/Integration/Form';

const routes = [
  {
    name: 'integration/index',
    path: '/integration',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'cloud',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? window.app.vue.$label('Integrations', true) : 'Integrations';
      },
    },
  },
  {
    name: 'integration/create',
    path: '/integration/create',
    component: Form,
    meta: {
      containerSize: 'container-extra-small',
      icon: 'cloud',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? `New ${window.app.vue.$label('Integration', false)}` : 'New Integration';
      },
    },
  },
  {
    name: 'integration/show',
    path: '/integration/:integrationUUID',
    component: Show,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('integration/index');
      },
      containerSize: 'container-medium',
      icon: 'cloud',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? window.app.vue.$label('Integration', false) : 'Integration';
      },
      editRoute: (route) => {
        return window.app.findRouteByName('integration/edit', {
          integrationUUID: route.params.integrationUUID,
        });
      },
    },
  },
  {
    name: 'integration/edit',
    path: '/integration/:integrationUUID/edit',
    component: Form,
    meta: {
      backRoute: (route) => {
        return window.app.findRouteByName('integration/show', {
          integrationUUID: route.params.integrationUUID,
        });
      },
      containerSize: 'container-extra-small',
      icon: 'cloud',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? `Edit ${window.app.vue.$label('Integration', false)}` : 'Edit Integration';
      },
    },
  },
];

export default routes;
