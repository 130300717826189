<template>
  <div id="password-form" class="is-padded">
    <Field>
      <Label>Current Password *</Label>
      <PasswordInput v-model="form.fields.passwordCurrent"/>
      <FormError field="passwordCurrent" :form="form"/>
    </Field>
    <Field>
      <Label>New Password *</Label>
      <PasswordInput v-model="form.fields.password"/>
      <FormError field="password" :form="form"/>
    </Field>
    <Field>
      <Label>Confirm New Password *</Label>
      <PasswordInput v-model="form.fields.passwordConfirmation"/>
      <FormError field="passwordConfirmation" :form="form"/>
    </Field>

    <Teleport to="#inspector-footer">
      <Controls>
        <template v-slot:right>
          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>
          <Button primary :loading="loading" @click="handleSave" class="has-margin-left">Save</Button>
        </template>
      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

export default {
  name: 'Password',
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  data() {
    return {
      form: new Form({
        passwordCurrent: '',
        password: '',
        passwordConfirmation: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      update: 'security/password',
    }),
    handleCancel() {
      this.$router.back();
    },
    handleSave() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.form.errors.clear();
      this.update({
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar({
          message: 'Password Updated',
        });
        this.form.reset();
      }).catch((error) => {
        if (error.response && error.response.data) {
          this.form.errors.record(error.response.data.errors);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
