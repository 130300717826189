<template>
  <div class="report-preview">
    <Subtitle>{{report.name}}</Subtitle>
  </div>
</template>

<script>
export default {
  name: 'Preview',
}
</script>

<style scoped lang="scss">
.report-preview {

}
</style>
