var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.key,ref:"primaryMenu",class:{
           'is-active': _vm.primaryMenu.active,
           'is-anchored': _vm.$layoutAnchored || _vm.isMobile,
           'is-dark': _vm.$themeDark,
           'is-floating': _vm.$layoutFloating && !_vm.isMobile,
           'is-full-screen': _vm.isFullScreen,
           'is-inactive': !_vm.primaryMenu.active,
           'is-mobile': _vm.isMobile,
           'is-standalone': _vm.$isStandalone,
       },attrs:{"id":"primary-menu"}},[(_vm.loading)?_c('BLoading',{attrs:{"active":""}}):_vm._e(),(_vm.showPrimaryMenuToggle)?_c('div',{staticClass:"is-flex is-align-items-center",attrs:{"id":"primary-menu-header"}},[_c('div',{staticClass:"pointer",attrs:{"id":"primary-menu-toggle"},on:{"click":_vm.handlePrimaryMenuToggle}},[_c('BIcon',{attrs:{"icon":"bars","custom-class":"fa-lg"}})],1),(!_vm.isMobile || _vm.primaryMenu.active)?_c('router-link',{attrs:{"to":"/","id":"brand"}},[_c('Logo')],1):_vm._e()],1):_vm._e(),(_vm.primaryMenu.active)?[_c('div',{attrs:{"id":"scrollable"}},[_c('div',{attrs:{"id":"menus"}},[_vm._l((_vm.menusArray),function(menu){return [_c('NestedList',{attrs:{"list":menu},on:{"toggle":function($event){return _vm.handleMenuToggle(menu)}}})]})],2)]),_c('div',{staticClass:"system"},[(_vm.system.updateAvailable)?_c('Button',{staticClass:"has-margin-bottom",attrs:{"block":"","primary":"","outlined":""},on:{"click":_vm.handleReloadApplication}},[_c('BIcon',{attrs:{"icon":"bolt"}}),_vm._v(" Update Available ")],1):_vm._e(),_c('div',{staticClass:"version"},[_vm._v(" "+_vm._s(_vm.version)+" ")])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }