<template>
  <div class="map-control">

    <div @click="handleToggle">
      <BTooltip label="Layers" position="is-bottom" :active="!isMobile">
        <BIcon icon="layer-group" size="is-medium"/>
      </BTooltip>
    </div>

    <div class="sub-map-controls" v-show="active">
      <MapLayerManager/>
    </div>

  </div>
</template>

<script>
import MapLayerManager from '@/components-V2/Map/MapLayerManager';

import {
  mapActions,
} from 'vuex';

export default {
  name: 'MapLayersControl',
  components: {
    MapLayerManager
  },
  computed: {
    active() {
      return this.$store.state.ui.mapControls.mapLayers || false;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      mapControls: 'ui/mapControls',
    }),
    handleToggle() {
      this.mapControls({
        mapLayers: !this.active,
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
