<template>
  <div id="tag-show" class="is-padded" v-if="tag">

    <Section title="Meta" open>
      <LabelLineValue label="Name" :value="tag.name" clickToCopy/>
      <LabelLineValue label="Color" :value="tag.color" clickToCopy/>
    </Section>


  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import tinycolor from 'tinycolor2';

import MapInput from '@/components-V2/Inputs/MapInput';
import DeviceResourceList from '@/components-V2/Device/ResourceList';
import TagPreview from '@/components-V2/Tag/Preview';

export default {
  name: 'Show',
  components: {
    MapInput,
    DeviceResourceList,
    TagPreview,
  },
  computed: {
    devices() {
      return this.tag ? this.tag.devices : [];
    },
    fullPath() {
      return this.$route.fullPath;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    pageTitle() {
      return `Tag: ${this.tag ? this.tag.name : ''}`;
    },
    tag() {
      return this.$route.params.tagUUID ? this.$store.getters['tag/findBy'](this.$route.params.tagUUID, 'uuid') : null;
    },
  },
  created() {
    this.loadTag();
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      show: 'tag/show'
    }),
    handleEdit() {
      const route = window.app.findRouteByName('tag/edit', [], {
        params: {
          tagUUID: this.tag.uuid
        },
      });

      this.$router.push(route);
    },
    isLight(color) {
      return tinycolor(color).isLight();
    },
    loadTag() {
      this.loading = true;
      return this.show({
        id: this.$route.params.tagUUID,
        params: {
          include: [
            'parameters',
          ],
        },
      }).then((response) => {

      }).catch((error) => {
        window.app.snackbar('Error loading tag');
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    fullPath() {
      this.loadTag();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
