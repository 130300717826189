<template>
  <div class="is-padded">
    <template v-for="uplink in uplinks" v-if="uplinks.length > 0">
      <Box flat :black="$themeDark" :key="uplink.uuid">
        <Preview :uplink="uplink" />
      </Box>
    </template>

    <div v-if="!uplinks.length">
      <Instruction>
        Waiting for Events...
      </Instruction>
    </div>

    <Teleport to="#inspector-footer">
      <Controls>
        <template v-slot:right>
          <Button outlined :dark="$themeLight" :light="$themeDark" @click="handleClearActivityMonitor">
            Clear Activity Monitor
          </Button>
        </template>
      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import Preview from '@/components-V2/Uplink/Preview';

export default {
  name: 'ActivityMonitor',
  components: {
    Preview,
  },
  computed: {
    uplinks() {
      return this.uplinkIds.length ? this.$store.getters['uplink/collection'](this.uplinkIds) : [];
    },
  },
  created() {
    window.app.vue.$on('uplinkReceived', this.loadUplink);
  },
  destroyed() {
    window.app.vue.$off('uplinkReceived', this.loadUplink);
  },
  data() {
    return {
      uplinkIds: [],
    };
  },
  methods: {
    ...mapActions({
      uplinkShow: 'uplink/show',
    }),
    handleClearActivityMonitor() {
      this.uplinkIds = [];
      window.app.snackbar('Activity Monitor Cleared');
    },
    loadUplink(event) {

      const uplinkId = event.uplinkId;

      if (uplinkId) {
          this.uplinkShow({
            id: uplinkId,
            params: {
              include: [
                'device.parameters',
                'parameterValues',
              ]
            },
          }).then(() => {
            this.uplinkIds = [
              uplinkId,
              ...this.uplinkIds.slice(0, 19),
            ];
          });
      }
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
