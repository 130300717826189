<template>
  <div class="license-warning" :class="{ 'is-dark': $themeDark }" v-if="shouldShowLicenseWarning">

    <router-link to="/account/billing"
                 class="license-warning-header"
                 :class="{ 'is-outlined': $themeDark }"
                 v-if="shouldShowPurchaseLicenseButton"
    >
      <b>You have {{ unlicensedDevices.length }} unlicensed devices.</b>
    </router-link>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'LicenseWarning',
  computed: {
    account() {
      return this.user && this.user.account ? this.$store.getters['account/findBy'](this.user.account.uuid, 'uuid') : null;
    },
    devices() {
      return this.$store.getters['device/all'] || [];
    },
    licenseRequiredDate() {
      return this.system.licenseRequiredDate.format('MMMM Do');
    },
    shouldShowPurchaseLicenseButton() {
      return this.user.hasPermission('accountEdit');
    },
    shouldShowLicenseWarning() {
      return this.account && this.account.billing.type === 'license' && this.unlicensedDevices.length > 0
    },
    system() {
      return this.$store.state.system;
    },
    unlicensedDevices() {
      return this.devices.filter(device => !device.license);
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {},
}
</script>

<style scoped lang="scss">
.license-warning {

  border-top: 1px solid $navigation-border-color;
  margin: 0;

  &.is-dark {
    background: $black-bis;

    .license-warning-body {
      color: $white;
    }
  }

  .license-warning-header {
    background: $warning;
    color: $black-bis;
    display: block;
    padding: 15px;
  }

  .license-warning-body {
    color: $black;
    padding: 15px;
  }
}
</style>
