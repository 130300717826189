var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BTooltip',{attrs:{"label":_vm.title,"position":"is-right"}},[_c('div',{staticClass:"device-status-dot",class:{
        'is-inactive': !_vm.device.active,
        'is-online': _vm.device.active && _vm.device.status && _vm.device.status === 'online',
        'is-offline': _vm.device.active && _vm.device.status && _vm.device.status === 'offline',
        'is-pending': _vm.device.active && _vm.device.status === 'pending' || _vm.device.status === null,
       },style:({
        background: _vm.device.statusColor,
       })})])
}
var staticRenderFns = []

export { render, staticRenderFns }