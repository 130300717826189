<template>
  <div class="is-padded">
    <BLoading active v-if="loading"/>
    <!--    <JSON :data="inventory" />-->
    <Product v-if="isProduct" :inventory="inventory"/>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import Product from '@/components-V2/Inventories/Product.vue';

export default {
  name: 'Claim',
  components: {
    Product,
  },
  computed: {
    inventory() {
      return this.$route.params.inventoryUUID ? this.$store.getters['inventory/findBy'](this.$route.params.inventoryUUID, 'uuid') : null;
    },
    isProduct() {
      return this.inventory && this.inventory.type === 'App\\Inventories\\Product';
    },
  },
  created() {
    if (this.$route.params.inventoryUUID) {
      this.loadInventory();
    }
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    ...mapActions({
      inventoryShow: 'inventory/show',
    }),
    loadInventory() {
      this.loading = true;
      this.inventoryShow({
        id: this.$route.params.inventoryUUID,
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
