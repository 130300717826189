<template>
  <Page>
    <div class="container container-medium">
      <Title>Terms</Title>

    </div>
  </Page>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'Terms',
  computed: {},
  data () {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {},
};
</script>

<style scoped lang="scss">

</style>
