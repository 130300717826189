<template>
  <div class="config">

    <div class="localValue">
      <Field>
        <Label>Slack Webhook URL *</Label>
        <TextInput v-model="localValue.url" placeholder="https://hooks.slack.com/services/A01EZFGUCES/A05JRTX9Z97/Ycan2h4lsLl3XfB8K9ng4upz"/>
        <FormError :form="form" field="url"/>
      </Field>
    </div>

  </div>
</template>

<script>
export default {
  name: 'SlackForm',
  data() {
    return {
      localValue: {
        url: '',
      },
      response: null,
    };
  },
  methods: {

  },
  props: {
    value: {},
    form: {}
  },
  watch: {
    localValue: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
          this.$emit('input', this.localValue);
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
          this.localValue = {
            ...this.localValue,
            ...this.value,
          };
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
.config {
  .localValue {
    min-height: 200px;
  }
}
</style>
