<template>
  <router-link :to="`/trip/${trip.uuid}`" class="trip resource-list-item" :class="{ 'is-dark': $themeDark }">
    <div class="trip-title">{{trip.createdAt.format(system.settings.datetimeFormat)}} - {{trip.lastPositionAt.format(system.settings.datetimeFormat)}}</div>
  </router-link>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    system() {
      return this.$store.state.system;
    },
    trip() {
      return this.resource;
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
