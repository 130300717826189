<template>
  <div id="app" :class="{
      'has-background-dark': $themeDark,
      'has-text-light': $themeDark,
      'is-mobile': isMobile,
      'is-standalone': $isStandalone,
    }">

    <div class="tag is-warning real-time-updates" v-if="websocketsAvailable === false">
      Real Time Updates Unavailable
    </div>

    <MaintenanceSplash v-if="maintenance && !hideMaintenanceSplash"/>
    <UpdateRequiredSplash v-else-if="updateRequired"/>
    <DefaultUI />
  </div>
</template>

<script>
import LogRocket from 'logrocket';

import {
  Menu,
} from '@/internal';

import MaintenanceSplash from '@/components/MaintenanceSplash';
import Navbar from '@/components/Navigation/Navbar';
import UpdateRequiredSplash from '@/components/UpdateRequiredSplash';

import {
  mapActions,
} from 'vuex';

import Echo from 'laravel-echo';
import DefaultUI from '@/user-interface/DefaultUI';
import {isMeatCache} from '@/helpers';

const isMobileWindowWidthThreshold = 1025;

export default {
  name: 'App',
  components: {
    DefaultUI,
    MaintenanceSplash,
    Navbar,
    UpdateRequiredSplash,
  },
  computed: {
    authenticated() {
      return this.$store.state && this.$store.state.auth && this.$store.state.auth.authenticated;
    },
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    emailVerified() {
      return this.user && this.user.emailVerified;
    },
    isManagingDomain() {
      return window.location.origin.includes(process.env.VUE_APP_DOMAIN_MANAGEMENT_URL);
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    maintenance() {
      return this.system.maintenance;
    },
    system() {
      return this.$store.state.system;
    },
    updateRequired() {
      return this.system.updateRequired;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  async created() {
    this.emailWasVerified = this.user && this.user.emailVerified;
    this.systemStatus();
    this.heartbeat = setInterval(this.systemStatus, 60 * 1000);
    await this.loadDomain();
    this.loadUser();
    this.setMaintenanceSplash();
    this.setBodyClass()
    this.setIsMobile(window.innerWidth < isMobileWindowWidthThreshold);

    window.addEventListener('resize', this.windowResize);
  },
  destroyed() {
    clearInterval(this.heartbeat);

    if (this.echo && this.echoAccountChannel) {
      this.echo.leave(this.echoAccountChannel.name);
    }

    if (this.echo && this.echoPublicChannel) {
      this.echo.leave(this.echoPublicChannel.name);
    }

    window.removeEventListener('resize', this.windowResize)
  },
  data() {
    return {
      emailWasVerified: false,
      heartbeat: null,
      hideMaintenanceSplash: false,
      isLoaded: false,
      loading: false,
      mobileMenu: new Menu({
        menuItems: [],
      }),
      echo: null,
      echoAccountChannel: null,
      echoPublicChannel: null,
      websocketsAvailable: null,
    };
  },
  methods: {
    ...mapActions({
      deviceShow: 'device/show',
      domainShow: 'domain/show',
      mobileHeader: 'ui/mobileHeader',
      setIsMobile: 'ui/isMobile',
      systemStatus: 'system/index',
      userShow: 'auth/userShow',
    }),
    initializeEcho() {

      if (!this.user.account.uuid) {
        return;
      }

      this.echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_PUSHER_KEY,
        cluster: 'us3',
        wsHost: process.env.VUE_APP_PUSHER_HOST,
        wsPort: process.env.VUE_APP_PUSHER_PORT,
        wssPort: process.env.VUE_APP_PUSHER_PORT,
        forceTLS: true,
        enabledTransports: ['ws', 'wss'],
        encrypted: true,
        authEndpoint: `${window.app.env.apiBaseUrl}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${window.app.auth.getToken()}`,
          },
        },
      });

      this.echo.connector.pusher.connection.bind('connected', () => {
        this.websocketsAvailable = true;
      });

      this.echo.connector.pusher.connection.bind('unavailable', () => {
        this.echo.connector.pusher.disconnect()
        this.echo = null;
        this.websocketsAvailable = false;
      });

      if (this.user) {

        this.echoAccountChannel = this.echo.private(`account.${this.user.account.id}`)
          .listen('.DeviceStatusChanged', (e) => {

            window.app.vue.$emit(`deviceStatusChanged`, e);

            if (this.$route.name !== 'device/show') {
              this.deviceShow({
                id: e.deviceId,
              });
            }

          })
          .listen('.UplinkImportStatus', (e) => {
            window.app.vue.$emit(`uplinkImportStatus`, e);
          })
          .listen('.UplinkImported', (e) => {
            window.app.vue.$emit(`uplinkImported`, e);
          })
          .listen('.UplinkExportStatus', (e) => {
            window.app.vue.$emit(`uplinkExportStatus`, e);
          })
          .listen('.UplinkReceived', (e) => {

            window.app.vue.$emit(`uplinkReceived`, e);

            if (!isMeatCache() && this.$route.name !== 'device/show') {
              this.deviceShow({
                id: e.deviceId,
              }).then(() => {
                window.app.vue.$emit(`deviceReload`, e.deviceId);
              });
            }
          });

      } else {

        this.echoPublicChannel = this.echo.channel('devicePublic')
          .listen('.UplinkReceivedPublic', (e) => {
            window.app.vue.$emit(`UplinkReceivedPublic`, e);
          });

      }

    },
    initializeLogRocket() {

      if (process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'staging') {
        return;
      }

      LogRocket.init('lake-street-software/uplinkengine');

      if (this.user) {
        LogRocket.identify(this.user.id, {
          name: this.user.fullName,
          email: this.user.email,
        });
      }
    },
    loadDomain() {
      this.loading = true;
      return this.domainShow({
        id: window.app.env.domainUUID,
      }).finally(() => {
        this.loading = false;
      });
    },
    loadUser(inBackground = false) {

      if (!this.authenticated) {
        return;
      }

      let payload = {};

      if (!this.isLoaded) {

        payload = {
          params: {
            include: [
              'account',
              'accounts',
            ],
          }
        }

        this.isLoaded = true;
      }

      this.loading = !inBackground;
      this.userShow(payload).finally(async () => {

        if (this.user) {
          window.app.bugsnag.setUser(this.user.id, this.user.email, this.user.fullName);
        }

        this.initializeEcho();
        this.initializeLogRocket();
        this.loading = false;
      });
    },
    setBodyClass() {
      let root = document.getElementsByTagName('html')[0];

      if (this.$isStandalone) {
        root.classList.add('is-standalone');
      }

      if (this.$themeDark) {
        root.classList.add('is-dark');
      }
    },
    setMaintenanceSplash() {
      if (this.$route.query.hideMaintenanceSplash !== undefined) {
        window.app.storage.setItem('hideMaintenanceSplash', true);
        this.hideMaintenanceSplash = true;
      } else {
        this.hideMaintenanceSplash = window.app.storage.getItem('hideMaintenanceSplash') || false;
      }
    },
    windowResize(event) {
      let windowWidth = event.target.innerWidth;
      this.setIsMobile(windowWidth < isMobileWindowWidthThreshold);
    }
  },
  mounted() {
    this.setBodyClass();
  },
  watch: {
    emailVerified() {
      if (!this.emailWasVerified && this.emailVerified) {
        if (this.$route.path !== '/') {
          this.$router.push('/');
        }
      }
    },
  },
};
</script>

<style lang="scss">
html, body, #app {
  height: 100%;
  margin: 0;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

#app {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .real-time-updates {
    bottom: 15px;
    right: 15px;
    position: fixed;
  }

  #content {

    flex: 1;

    &.has-navbar-fixed {
      margin-top: 60px;
    }

    #map {
      flex: 1;
      height: 100%;
      position: relative;
      z-index: 0;
    }

  }
}
</style>
