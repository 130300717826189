<template>
  <div class="file-picker-button file is-primary" :class="{'has-name': !!file}">
    <BUpload v-model="file" class="file-label" :accept="types.join(', ')" @input="handleInput">
      <Button primary outlined :loading="loading">
        <template v-if="file">
          File: {{ file.name }}
        </template>
        <template v-else>
          <BIcon class="file-icon" icon="upload"/>
          <span class="file-label">Upload</span>
        </template>
      </Button>
    </BUpload>
  </div>
</template>

<script>

export default {
  name: 'FileUpload',
  data() {
    return {
      file: null,
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
  props: {
    extensions: {
      default: () => [],
      required: false
    },
    disabled: {
      default: false,
      required: false
    },
    loading: {
      default: false,
      type: Boolean,
    },
    types: {
      default: () => [],
      required: false,
      type: Array,
    },
    value: {
      required: false
    },
  },
};
</script>

<style lang="scss">

</style>
