import {
  ResourceService,
} from '@/internal';


export default class AccountService extends ResourceService {

  constructor() {
    super();
    this.baseUrl = 'account';
  }

  licenseAssignmentsSession(las, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'get',
      url: `/account/license-assignments-session/${las}`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  paymentMethodUpdate(payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'put',
      url: '/account/payment-method',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  stripePortal(payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: '/account/stripe-portal',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  subscriptionUpdateOrCreate(payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: '/account/subscription',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }
}
