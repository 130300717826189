<template>
  <div class="notification instruction" :class="{
    [`${type}`]: true,
    'is-dark': $themeDark,
    'is-light': $themeLight,
  }">
    <div class="is-flex is-align-items-center">
      <div class="instruction-message is-flex-grow-1">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Instruction',
  props: {
    type: {
      default: 'is-info',
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
    .instruction {
      border-left: 3px solid $white;
      border-radius: 0;
      font-size: 14px;
      margin: 15px 0 !important;
      padding: 15px;
    }
</style>
