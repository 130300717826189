<template>
  <div class="nested-list">

    <div class="nested-list" :class="{ 'is-open': open }" @click="handleOnClick">

      <div class="nested-list-title truncate" @click="handleMenuToggle">

        <div class="nested-list-icon" v-if="icon">
          <div class="icon-wrapper" :style="{ 'background': color }">
            <BIcon :icon="icon"/>
          </div>
        </div>

        <div class="truncate">
          {{ title }}
        </div>

      </div>

      <div class="nested-list-items" v-if="open && children.length">
        <template v-for="listItem in children">
          <NestedListItem :list-item="listItem"/>
        </template>
      </div>

    </div>

  </div>
</template>

<script>
import {NestedList} from '@/internal';
import NestedListItem from '@/components-V2/NestedList/NestedListItem';

export default {
  name: 'NestedList',
  components: {
    NestedListItem
  },
  computed: {
    color() {
      return this.list && this.list.color ? this.list.color : '#00A3E3';
    },
    children() {
      return this.list ? this.list.children : [];
    },
    icon() {
      return this.list ? this.list.icon : null;
    },
    open() {
      return this.list ? this.list.open : false;
    },
    title() {
      return this.list ? this.list.title : '';
    },
  },
  data() {
    return {};
  },
  methods: {
    handleMenuToggle() {
      this.$emit('toggle');
    },
    handleOnClick() {
      if (this.list.onClick) {
        this.list.onClick();
      }
    },
  },
  props: {
    list: {
      required: true,
      type: NestedList,
    },
  },
}
</script>

<style scoped lang="scss">
.nested-list {

  .nested-list-title {
    align-items: center;
    border-radius: 5px;
    color: $black-ter;
    cursor: pointer;
    display: flex;
    font-size: 1.1em;
    padding: 10px;
    position: relative;
    z-index: 5;

    .is-dark & {
      color: $white;

      &:hover {
        background: $black-ter;
      }
    }

    &:hover {
      background: $grey-light;
    }

    .nested-list-icon {
      position: relative;

      .icon-wrapper {
        $size: 36px;
        align-items: center;
        border-radius: 50%;
        color: $white;
        display: flex;
        height: $size;
        justify-content: center;
        margin-right: 10px;
        width: $size;
      }
    }
  }

  .nested-list-items {
    border-left: 1px dashed $grey;
    margin-left: 26px;
    left: 1px;
    margin-bottom: 15px;
    position: relative;
    top: -20px;
  }
}
</style>
