import {
  ResourceService,
} from '@/internal';

export default class ReportService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'report';
  }

}
