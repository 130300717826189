import Show from '@/views/Uplink/Show';
import Form from '@/views/Uplink/Form';

const routes = [
  {
    name: 'uplink/show',
    path: '/uplink/:uplinkUUID',
    component: Show,
    meta: {
      backRoute: (route) => {
        return route;
      },
      middleware: ['auth'],
      title: 'Uplink',
    },
  },
  {
    name: 'uplink/edit',
    path: '/uplink/:uplinkUUID/edit',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('uplink/index');
      },
      middleware: ['auth'],
      title: 'Edit Uplink',
    },
  },
];

export default routes;
