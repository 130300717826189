<template>
  <div id="dashboard" class="is-padded">
    <BLoading active v-if="loading"/>

    <div v-if="dashboard">

      <Title>{{ dashboard.name }}</Title>

      <div class="widgets">
        <template v-for="widget in widgets">
          <Box flat :black="$themeDark">
            <Widget :widget="widget"/>
          </Box>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

import Widget from '@/components-V2/Widget/Widget';

export default {
  name: 'DashboardShow',
  components: {
    Widget
  },
  computed: {
    dashboard() {
      return this.$route.params.dashboardUUID ? this.$store.getters['dashboard/findBy'](this.$route.params.dashboardUUID, 'uuid') : null;
    },
    widgets() {
      return this.dashboard ? this.dashboard.widgets.sort((a, b) => a.rank > b.rank ? 1 : -1) : [];
    }
  },
  async created() {
    await this.loadDashboard();

    this.loadDevices();

    window.app.vue.$on('uplinkReceived', this.reloadDevice);
  },
  destroyed() {
    window.app.vue.$off('uplinkReceived', this.reloadDevice);
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      deviceShow: 'device/show',
      deviceIndex: 'device/index',
      show: 'dashboard/show',
    }),
    handleEdit() {
      this.$router.push({
        name: 'dashboard/edit',
        params: {
          dashboardUUID: this.$route.params.dashboardUUID,
        },
      });
    },
    loadDashboard() {
      this.loading = true;
      return this.show({
        id: this.$route.params.dashboardUUID,
      }).then((response) => {

      }).catch((error) => {
        window.app.snackbar('Error loading dashboard');
      }).finally(() => {
        this.loading = false;
      });
    },
    loadDevices() {
      this.widgets.forEach((widget) => {
        this.deviceShow({
          id: widget.deviceId,
          params: {
            include: [
              'parameters',
              'parameters.unit',
              'latest_uplink.position',
            ],
          },
        });
      })
    },
    reloadDevice(e) {
      if (this.widgets.map(widget => widget.deviceId).includes(parseInt(e.deviceId))) {
        this.deviceShow({
          id: e.deviceId,
          params: {
            include: ['parameters', 'parameters.unit'],
          },
        });
      }
    },
  },
  props: {},
};
</script>

<style scoped lang="scss">
#dashboard {
  .widgets {

  }
}
</style>
