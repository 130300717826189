<template>
  <div class="label-hr">
    <div class="line"></div>
    <Label>
      <slot />
    </Label>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: 'LabelHR',
}
</script>

<style scoped lang="scss">
.label-hr {
  align-items: center;
  display: flex;
  margin: 15px 0;

  .line {
    background: $grey;
    height: 1px;
    flex: 1;
    margin: 10px 0;
  }

  .ue-label {
    font-size: 0.9em;
    margin: 0 10px;
  }
}
</style>
