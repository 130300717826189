import slugify from 'slugify';
import { v4 as uuidv4 } from 'uuid';

import MenuItem from '@/domain/MenuItem/MenuItem';

export default class Menu {
  constructor(config = {}) {
    this.id = slugify(config.id || config.title || uuidv4()).toLowerCase();
    this.title = config.title || '';
    this.color = config.color || null;
    this.open = config.open !== undefined ? config.open : true;
    this.onClick = config.onClick || null;
    this.allMenuItems = config.menuItems ? config.menuItems.map(menuItem => new MenuItem(menuItem)) : [];
    this.menuItems = this.allMenuItems.filter(menuItem => menuItem.when === true || menuItem.when === null)
    this.icon = config.icon || null;
  }
}
