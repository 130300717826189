<template>
  <div class="rule-preview">
    <Subtitle>{{rule.name}}</Subtitle>
  </div>
</template>

<script>
export default {
  name: 'Preview',
  props: {
    rule: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
