<template>
  <router-link :to="`/unit/${unit.uuid}/edit`" class="unit resource-list-item" :class="{ 'is-dark': $themeDark }">
    {{unit.title}} <SystemTag class="is-pulled-right" v-if="!unit.accountId" />
  </router-link>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    unit() {
      return this.resource;
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
