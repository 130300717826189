import Index from '@/views/Tag/Index';
import Show from '@/views/Tag/Show';
import Form from '@/views/Tag/Form';

const routes = [
  {
    name: 'tag/index',
    path: '/tag',
    component: Index,
    meta: {
      containerSize: 'container-small',
      icon: 'tags',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? window.app.vue.$label('Tags', true) : 'Tags';
      },
    },
  },
  {
    name: 'tag/create',
    path: '/tag/create',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('tag/index');
      },
      containerSize: 'container-extra-small',
      icon: 'tags',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? `New ${window.app.vue.$label('Tag', false)}` : 'New Tag';
      },
    },
  },
  {
    name: 'tag/show',
    path: '/tag/:tagUUID',
    component: Show,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('tag/index');
      },
      containerSize: 'container-extra-small',
      icon: 'tags',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? window.app.vue.$label('Tag', false) : 'Tag';
      },
      editRoute: (route) => {
        return window.app.findRouteByName('tag/edit', {
          tagUUID: route.params.tagUUID,
        });
      },
    },
  },
  {
    name: 'tag/edit',
    path: '/tag/:tagUUID/edit',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('tag/index');
      },
      containerSize: 'container-extra-small',
      icon: 'tags',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? `Edit ${window.app.vue.$label('Tag', false)}` : 'Edit Tag';
      },
    },
  },
];

export default routes;
