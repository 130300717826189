<template>
  <div id="map-layers"></div>
</template>

<script>
import {
  mapActions
} from 'vuex';
import {MapLayer} from '@/internal';

import router from '@/router';
import store from '@/store';

const handleMapLayerClick = (e) => {

  let layerId = e.features.length ? e.features[0].layer.id : null;
  let layer = store.getters['mapLayer/layer'](layerId) || null;

  // console.log(layerId, e.originalEvent.cancelBubble, layer);

  if (e.originalEvent.cancelBubble) {
    return;
  }

  if (layer.onClick) {
    layer.onClick({e, store, router});
  }

  e.originalEvent.cancelBubble = true;
};

export default {
  name: 'MapLayers',
  components: {},
  computed: {
    devices() {
      return this.$store.getters['device/all'];
    },
    layers() {
      return this.$store.getters['mapLayer/layers'];
    },
    map() {
      return this.$store.state.map.map;
    },
    regions() {
      return this.$store.getters['region/all'];
    },
    reloadLayers() {
      return this.$store.getters['mapLayer/reloadLayers'];
    },
  },
  created() {
    this.setLayers();
  },
  destroyed() {
    this.removeAllLayers();
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      setMapLayer: 'mapLayer/setLayer',
      mapLayerReloadLayers: 'mapLayer/reloadLayers',
    }),
    layer(layerId) {
      return this.$store.getters['mapLayer/layer'](layerId);
    },
    removeAllLayers() {

      this.layers.forEach((layer) => {

        this.map.off('touchend', layer.id, handleMapLayerClick);

        this.map.off('click', layer.id, handleMapLayerClick);

        if (this.map.getLayer(layer.id)) {
          this.map.removeLayer(layer.id);
        }

        if (this.map.getSource(layer.id)) {
          this.map.removeSource(layer.id);
        }

      });
    },
    setLayer(layer, reload = false) {

      layer = new MapLayer(layer);

      // console.log(layer.id, layer);

      if (!this.$store.state.auth.authenticated && layer.requiresAuth && layer.enabled) {
        layer.enabled = false;
        this.setMapLayer(layer);
        return;
      }

      if (reload) {

        if (this.map.getLayer(layer.id)) {
          this.map.removeLayer(layer.id);
        }

        if (this.map.getSource(layer.id)) {
          this.map.removeSource(layer.id);
        }

        // Also reload any syncWith layers
        this.layers.filter(_layer => _layer.syncedWith === layer.id).forEach(_layer => this.setLayer(_layer, true));
      }

      if (layer.syncedWith) {

        const syncedWith = this.layer(layer.syncedWith);
        const props = layer.sync || ['enabled', 'color'];

        if (props.includes('enabled')) {
          layer.enabled = syncedWith.enabled;
        }

        if (props.includes('color')) {
          layer.color = syncedWith.color;
        }

      }

      if (layer.enabled) {

        // Source

        const source = layer.source ? layer.source({store: this.$store}) : null;

        // Remove layers that are enabled, but no longer have a source

        if (!source) {

          if (this.map.getLayer(layer.id)) {
            this.map.removeLayer(layer.id);
          }

          if (this.map.getSource(layer.id)) {
            this.map.removeSource(layer.id);
          }

        }

        if (source) {

          if (!this.map.getSource(layer.id)) {
            this.map.addSource(layer.id, source);
          } else if (this.map.getSource(layer.id) && source.data) {
            this.map.getSource(layer.id).setData(source.data);
          }

        }

        // Layout

        const layout = layer.layout ? layer.layout({store: this.$store}) : null;

        // Terrain

        if (layer.terrain) {

          this.map.setTerrain(layer.terrain())

        }

        // Layer that isn't currently on the map

        else if (!this.map.getLayer(layer.id) && layout && source) {

          const before = layout.before;

          this.map.addLayer(layout, (before || null));

          if (layer.onClick) {

            // console.log('register click events', layer.id);

            // These this.map.off shouldn't be required, but we were getting multiple
            // this.map.on events, even when it didn't seem like we should be.

            this.map.off('touchend', layer.id, handleMapLayerClick);

            this.map.off('click', layer.id, handleMapLayerClick);

            this.map.on('touchend', layer.id, handleMapLayerClick);

            this.map.on('click', layer.id, handleMapLayerClick);

          }

        }

        // Layer that's already on the map

        else if (this.map.getLayer(layer.id) && layout) {

          if (layout.layout) {
            for (const [key, value] of Object.entries(layout.layout)) {
              this.map.setLayoutProperty(layer.id, key, value);
            }
          }

          if (layout.paint) {
            for (const [key, value] of Object.entries(layout.paint)) {
              this.map.setPaintProperty(layer.id, key, value);
            }
          }

        } else {

          console.log(`No layout defined for ${layer.id}`);

        }

      } else {

        // Remove Click Events

        if (layer.onClick) {

          console.log('remove click events', layer.id);

          this.map.off('touchend', layer.id, handleMapLayerClick);

          this.map.off('click', layer.id, handleMapLayerClick);

        }

        // Remove Terrain

        if (layer.terrain) {
          this.map.setTerrain();
        }

        // Remove Layout

        if (this.map.getLayer(layer.id)) {
          this.map.removeLayer(layer.id);
        }

        // Remove Source

        if (this.map.getSource(layer.id)) {
          this.map.removeSource(layer.id);
        }
      }

    },
    setLayers() {
      this.layers.forEach((layer) => {
        this.setLayer(layer);
      });
    },
  },
  props: {},
  watch: {
    devices: {
      deep: true,
      handler() {
        this.mapLayerReloadLayers([
          'devices',
        ]);
      },
    },
    regions: {
      deep: true,
      handler() {
        this.mapLayerReloadLayers([
          'regions',
        ]);
      },
    },
    reloadLayers: {
      deep: true,
      handler() {
        const reloadLayers = this.reloadLayers;
        if (reloadLayers.length) {
          this.mapLayerReloadLayers([]);
          reloadLayers.forEach((layerId) => {
            const layer = this.layer(layerId);
            if (layer) {
              this.setLayer(layer, true);
            }
          });
        }
      },
    },
  },
};
</script>
