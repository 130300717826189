import store from '@/store';

export default (to, from, next) => {

  const user = store.getters['auth/user'];

  if (!user || !user.isDomainOwner) {

    window.app.snackbar(`You are not the domain owner.`);

    window.app.d(`[hasAccountMiddleware] Redirected to /account/create`);

    return next({ path: '/' });

  } else {

    return next();

  }
}
