<template>
  <div id="note-index" class="is-padded">
    <NoteResourceList/>
  </div>
</template>

<script>
import NoteResourceList from '@/components-V2/Note/ResourceList';

export default {
  name: 'Index',
  components: {
    NoteResourceList,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
