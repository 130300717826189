import SecurityService from './SecurityService';

const service = new SecurityService();

export default {
  key: 'security',
  namespaced: true,
  state: {},
  actions: {
    password({commit}, payload = {}) {
      return service.password(payload);
    },
    twoFactor({dispatch}, payload = {}) {
      return service.twoFactor(payload).then(() => {
        return dispatch('auth/userShow', {}, {root: true});
      });
    },
  },
  mutations: {},
};
