<template>
  <div class="tag is-info">SYSTEM</div>
</template>

<script>
export default {
  name: 'SystemTag',
}
</script>

<style scoped lang="scss">

</style>
