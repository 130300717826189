<template>
  <div id="tag-index" class="is-padded">
    <TagResourceList/>
  </div>
</template>

<script>
import TagResourceList from '@/components-V2/Tag/ResourceList';

export default {
  name: 'Index',
  components: {
    TagResourceList,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
