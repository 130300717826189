import {
  ApiService,
} from '@/internal';

export default class SecurityService extends ApiService {

  password(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'put',
      url: '/security/password',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  twoFactor(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'put',
      url: '/security/two-factor',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

}
