<template>
  <div id="lorawan-network-server-form" class="is-padded">

    <BLoading active v-if="loading"/>

    <Field>
      <Label>Provider *</Label>
      <SelectInput :options="providerOptions" v-model="form.fields.provider"/>
      <FormError :form="form" field="provider"/>
    </Field>

    <Field>
      <Label>Name *</Label>
      <TextInput v-model="form.fields.name" placeholder="Helium Console Primary"/>
      <FormError :form="form" field="name"/>
    </Field>

    <Field>
      <Label>API URL *</Label>
      <TextInput v-model="form.fields.apiUrl" placeholder="https://console.helium.com/api/v1/"/>
      <FormError :form="form" field="apiUrl"/>
    </Field>

    <Field>
      <Label>API Key *</Label>
      <PasswordInput v-model="form.fields.apiKey" placeholder="**************************"/>
      <FormError :form="form" field="apiKey"/>
    </Field>

    <Teleport to="#inspector-footer">
      <Controls>
        <template v-slot:left>
          <Button danger outlined @click="handleDelete" class="is-pulled-left" :loading="deleting" v-if="lorawanNetworkServer">
            Delete
          </Button>
        </template>

        <template v-slot:right>
          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>
          <Button primary @click="handleSave" :loading="loading" class="has-margin-left">
            Save
          </Button>
        </template>
      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';
import {Form} from '@/internal';

export default {
  name: 'Form',
  computed: {
    lorawanNetworkServer() {
      return this.$route.params.lorawanNetworkServerUUID ? this.$store.getters['lorawanNetworkServer/findBy'](this.$route.params.lorawanNetworkServerUUID, 'uuid') : null;
    },
  },
  created() {
    if (this.$route.params.lorawanNetworkServerUUID) {
      this.loadLorawanNetworkServer();
    }
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        provider: 'helium',
        name: '',
        apiUrl: 'https://console.helium.com/api/v1',
        apiKey: '',
      }),
      loading: false,
      providerOptions: [
        {
          label: '- Select a Provider -',
          value: null,
        },
        {
          label: 'Helium',
          value: 'helium',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      show: 'lorawanNetworkServer/show',
      store: 'lorawanNetworkServer/store',
      update: 'lorawanNetworkServer/update',
      destroy: 'lorawanNetworkServer/destroy',
    }),
    fillForm() {
      this.form.fields = {
        ...this.form.fields,
        id: this.lorawanNetworkServer.id,
        uuid: this.lorawanNetworkServer.uuid,
        provider: this.lorawanNetworkServer.provider,
        name: this.lorawanNetworkServer.name,
        apiUrl: this.lorawanNetworkServer.apiUrl,
        apiKey: this.lorawanNetworkServer.apiKey,
      };
    },
    handleCancel() {
      this.$router.back();
    },
    handleDelete() {
      this.$deleteDialog({
        target: this.lorawanNetworkServer.name,
        targetType: 'LoRaWAN Network Server',
        onConfirm: () => {
          this.loading = true;
          return this.destroy({
            id: this.lorawanNetworkServer.id,
          }).then(() => {
            this.$router.back();
            window.app.snackbar('LoRaWAN Network Server Deleted');
          }).finally(() => {
            this.loading = false;
          });
        },
      });
    },
    handleSave() {

      const fields = {
        ...this.form.fields,
      };

      this.loading = true;
      this.form.errors.clear();

      let action = null;

      if (this.lorawanNetworkServer) {
        action = this.update;
      } else {
        action = this.store;
      }

      if (action) {
        action(fields).then(() => {
          window.app.snackbar('LoRaWAN Network Server Saved');
          this.$router.back();
        }).catch((error) => {
          this.form.recordErrors(error);
        }).finally(() => {
          this.loading = false;
        });
      }

    },
    loadLorawanNetworkServer() {
      this.loading = true;
      this.loading = true;
      return this.show({
        id: this.$route.params.lorawanNetworkServerUUID,
      }).then(this.fillForm).catch(() => {
        window.app.snackbar('Error Loading Report');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>

<style scoped lang="scss">

</style>
