<template>

  <div id="device-parameters">

    <draggable @input="handleDragEnd" :value="parameters" handle=".parameter-sort">

      <template v-for="(parameter, i) in parameters" v-if="!showCopyParametersForm">

        <Box flat :black="$themeDark" class="parameter">

          <div class="controls">

            <div class="left">
              <b v-if="parameter">{{ parameter.title || 'Unnamed Parameter' }}</b>
            </div>

            <div class="right">

              <div class="parameter-sort" v-if="value && value.length > 1">
                <BTooltip label="Drag to Sort">
                  <BIcon icon="sort"/>
                </BTooltip>
              </div>

              <div class="parameter-active" v-if="value[i].id">
                <BTooltip :label="value[i].active ? 'Received' : 'Never Received'" :type="value[i].active ? 'is-success' : 'is-warning'">
                  <BIcon :icon="value[i].active ? 'wifi' : 'wifi-slash'"
                         :type="value[i].active ? 'is-success' : 'is-warning'"/>
                </BTooltip>
              </div>

              <div class="parameter-edit" @click="handleParameterEdit(i)">
                <BTooltip label="Edit">
                  <BIcon icon="pencil"/>
                </BTooltip>
              </div>

              <div class="parameter-delete" @click="handleParameterDelete(i)" v-if="deletable">
                <BTooltip label="Delete" type="is-danger">
                  <BIcon icon="minus-circle"/>
                </BTooltip>
              </div>

            </div>

          </div>

          <div class="parameter-form" v-if="parameters[i].editing">

            <Field>
              <Label>
                Title *
                <BTooltip label="What do you call this parameter?" position="is-bottom" multilined>
                  <BIcon icon="question-circle"/>
                </BTooltip>
              </Label>
              <TextInput v-model="parameters[i].title" placeholder="Soil Moisture" :disabled="isVirtual"/>
              <FormError :field="`parameters${i}Title`" :form="form"/>
            </Field>

            <Field>
              <Label>
                Payload Path *
                <BTooltip label="Where is the data located in the uplink payload? Use dot notation for nesting."
                          position="is-bottom"
                          multilined
                >
                  <BIcon icon="question-circle"/>
                </BTooltip>
              </Label>
              <TextInput v-model="parameters[i].payloadPath" placeholder="decoded.battery.voltage"
                         :disabled="isVirtual"/>
              <FormError :field="`parameters${i}PayloadPath`" :form="form"/>
            </Field>

            <Field>
              <Label>
                Icon
                <BTooltip label="What icon should we display with your parameter?" position="is-bottom" multilined>
                  <BIcon icon="question-circle"/>
                </BTooltip>
              </Label>
              <FontAwesomeIconInput v-model="parameters[i].icon"/>
            </Field>

            <template v-if="parameters[i].editing">

              <Field>
                <Label class="relative">
                  Unit *
                  <BTooltip label="What is the unit of the incoming parameter?" position="is-bottom" multilined>
                    <BIcon icon="question-circle"/>
                  </BTooltip>

                  <span class="reload-icon" @click="loadUnits">
                          <BIcon icon="sync"/>
                        </span>

                  <Help class="is-pulled-right align-middle absolute m-0">
                    <a @click="createNewUnit">Add Unit</a>
                  </Help>
                </Label>

                <SelectInput v-model="parameters[i].unitId"
                             :options="units"
                             :disabled="isVirtual"
                />
                <FormError :field="`parameters${i}UnitId`" :form="form"/>

              </Field>

              <Field>
                <Label>
                  Decimal Precision
                  <BTooltip label="How many decimal places should be shown when rendering the values?"
                            position="is-bottom"
                            multilined
                  >
                    <BIcon icon="question-circle"/>
                  </BTooltip>
                </Label>
                <NumberInput v-model="parameters[i].precision" placeholder="2"/>
                <FormError field="precision" :form="form"/>
              </Field>

              <Field>
                <Label>
                  Calibration
                  <BTooltip
                    label="Here you can set a value that will add/subtract from the raw value received in the uplink."
                    position="is-bottom"
                    multilined
                  >
                    <BIcon icon="question-circle"/>
                  </BTooltip>
                </Label>
                <NumberInput v-model="parameters[i].calibration" placeholder="-2"/>
                <FormError field="calibration" :form="form"/>
              </Field>

              <Field>
                <Label>
                  Chart Type
                  <BTooltip label="How should the data be visualized?" position="is-bottom" multilined>
                    <BIcon icon="question-circle"/>
                  </BTooltip>
                </Label>
                <SelectInput v-model="parameters[i].chart"
                             :options="chartOptions"/>
                <FormError field="chart" :form="form"/>
              </Field>

              <Field v-if="parameter.chart !== 'none' && parameter.chart !== 'map'">
                <Label>
                  Min Y Value
                  <BTooltip label="This variable will set the minimum value on the Y axis for the line chart."
                            position="is-bottom"
                            multilined
                  >
                    <BIcon icon="question-circle"/>
                  </BTooltip>
                </Label>
                <NumberInput v-model="parameters[i].minY" placeholder="0"/>
                <FormError field="minY" :form="form"/>
              </Field>

              <Field v-if="parameter.chart !== 'none' && parameter.chart !== 'map'">
                <Label>
                  Max Y Value
                  <BTooltip label="This variable will set the maximum value on the Y axis for the line chart."
                            position="is-bottom"
                            multilined
                  >
                    <BIcon icon="question-circle"/>
                  </BTooltip>
                </Label>
                <NumberInput v-model="parameters[i].maxY" placeholder="5"/>
                <FormError field="maxY" :form="form"/>
              </Field>

              <Field v-if="converterOptions">
                <Label>
                  Function
                  <BTooltip label="Functions allow you to modify an incoming value" position="is-bottom" multilined>
                    <BIcon icon="question-circle"/>
                  </BTooltip>
                </Label>
                <SelectInput v-model="parameters[i].converterId"
                             :options="converterOptions"/>
                <FormError field="converter_id" :form="form"/>
                <Help>
                  Don't see what you're looking for? Shoot us an email and we'll make a custom function for you.
                  <a href="mailto:hello@uplinkengine.com" target="_blank">hello@uplinkengine.com</a>
                </Help>
              </Field>

            </template>

            <Field>
              <Label>Tags</Label>
              <TagInput :max="null" v-model="parameters[i].tags"/>
            </Field>

            <Field v-if="shouldShowAssetResourceSelect">
              <Label>Asset</Label>
              <ResourceSelect :resource="assetResource"
                              placeholder="Search for Asset"
                              v-model="parameters[i].assetId"
                              clearable
              />
            </Field>

          </div>

        </Box>

      </template>

    </draggable>

    <Field v-if="showCopyParametersForm">
      <div class="has-margin-bottom">
        <Label class="has-margin-bottom">Copy existing parameters from</Label>
      </div>
      <ResourceSelect v-model="deviceId"
                      :params="deviceResourceSelectParams"
                      placeholder="Search for Device"
                      :resource="deviceResource"
      />
      <div class="has-margin-top">
        <Button :light="$themeDark" :dark="$themeLight" block outlined @click="showCopyParametersForm = false">
          Cancel
        </Button>
      </div>
    </Field>

    <div class="columns has-margin-top" v-if="!form.fields.isVirtual && !showCopyParametersForm">

      <div class="column">
        <Button primary block outlined @click="handleParameterAdd">
          Add Parameter
        </Button>
      </div>

      <div class="column">
        <Button block outlined @click="showCopyParametersForm = true">
          Clone Parameters
        </Button>
      </div>

    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import {mapActions} from 'vuex';

import {
  Asset,
  Device,
  Parameter,
} from '@/internal';

const parameterTemplate = {
  assetId: null,
  calibration: null,
  chart: null,
  converterId: null,
  editing: true,
  maxY: '',
  minY: '',
  payloadPath: '',
  precision: null,
  tags: [],
  title: '',
  unit: '',
};

export default {
  name: 'ParametersInput',
  components: {
    draggable,
  },
  computed: {
    assetResource() {
      return Asset;
    },
    converterOptions() {
      return [
        {
          label: '- Select a Function -',
          value: null,
        },
        ...this.$store.getters['converter/collection'](this.converterIds).sort((a, b) => a.name > b.name ? 1 : -1).map((converter) => {
          return {
            'label': converter.name,
            'value': converter.id
          };
        }),
      ];
    },
    deviceResourceSelectParams() {
      return {
        include: ['parameters'],
      };
    },
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    shouldShowAssetResourceSelect() {
      return this.domain.featureEnabled('assets');
    },
    units() {

      if (this.unitIds.length) {

        return [
          {
            label: '- Select a Unit -',
            value: null,
          },
          ...this.$store.getters['unit/collection'](this.unitIds).map((unit) => {

            let label = unit.title;

            if (!unit.accountId) {
              label += ' [System]';
            }

            return {
              label: label,
              value: unit.id,
            };
          }).sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)
        ];
      }

      return [];
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    this.loadConverters();
    this.loadUnits();
  },
  data() {
    return {
      chartOptions: [
        {
          label: '- Select a Chart -',
          value: null,
        },
        {
          label: 'Line',
          value: 'line',
        },
        {
          label: 'Spline',
          value: 'spline',
        },
        {
          label: 'Area',
          value: 'area',
        },
        {
          label: 'Column',
          value: 'column',
        },
        {
          label: 'Map',
          value: 'map',
        },
        {
          label: 'None',
          value: 'none',
        },
      ],
      deviceId: null,
      deviceIds: [],
      deviceResource: Device,
      deviceResourceParams: {
        include: ['parameters'],
        limit: 10,
      },
      showCopyParametersForm: false,
      parameters: [],
      unitIds: [],
    };
  },
  methods: {
    ...mapActions({
      copyDeviceParameters: 'device/copyParameters',
      converterIndex: 'converter/index',
      deviceIndex: 'device/index',
      mobileHeader: 'ui/mobileHeader',
      unitIndex: 'unit/index',
    }),
    createNewUnit() {
      const route = this.$router.resolve({name: 'unit/create'});

      window.open(route.href, '_blank');
    },
    loadUnits() {
      this.loading = true;
      return this.unitIndex().then(({ids}) => {
        this.unitIds = ids;
      }).catch((error) => {
        window.app.snackbar('Error loading units');
      }).finally(() => {
        this.loading = false;
      });
    },
    copyParameters(deviceId) {
      this.copyDeviceParameters({
        id: deviceId,
      }).then((parameters) => {
        let data = parameters.map((parameter) => {

          delete parameter.id;

          return new Parameter({
            ...parameter.attributes,
            editing: true,
            active: false,
          });
        });

        this.$emit('input', [
          ...this.value,
          ...data,
        ])
      }).finally(() => {
        this.showCopyParametersForm = false;
      });
    },
    handleDragEnd(parameters) {
      this.parameters = [
        ...parameters.map((parameter, rank) => {
          parameter.rank = rank;
          return parameter;
        }).sort((a, b) => a.rank > b.rank ? 1 : -1),
      ];
    },
    handleParameterAdd() {
      this.parameters = [
        ...this.parameters,
        {
          ...parameterTemplate,
        },
      ];
    },
    handleParameterDelete(index) {
      this.parameters = [
        ...this.parameters.filter((param, i) => i !== index),
      ];
    },
    handleParameterEdit(index) {
      this.parameters = [
        ...this.parameters.map((parameter, i) => {
          if (index === i) {
            parameter.editing = !parameter.editing;
          }

          return parameter;
        }),
      ];
    },
    handleSelectedDevice(selected, i) {
      this.deviceId = selected && Object.keys(selected).length ? selected.value : null;
    },
    loadConverters() {
      this.converterIndex().then((response) => {
        this.converterIds = response.ids;
      });
    },
  },
  props: {
    deletable: {
      default: true,
      type: Boolean,
    },
    form: {
      required: true,
    },
    isVirtual: {
      default: false,
      type: Boolean,
    },
    value: {
      default: () => [],
      required: true,
    },
  },
  watch: {
    deviceId(id) {
      if (id) {
        this.copyParameters(id)
      }
    },
    parameters: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.parameters) !== JSON.stringify(this.value)) {
          this.$emit('input', this.parameters);
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.parameters) !== JSON.stringify(this.value)) {
          this.parameters = [
            ...this.value,
          ];
        }
      },
    },
  }
}
</script>

<style scoped lang="scss">
.parameter {

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  position: relative;

  .controls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    top: 10px;
    right: 15px;
    margin: 0;

    .left {
      flex: 1;
    }

    .right {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }

    .parameter-sort {
      cursor: pointer;
    }

    .parameter-active {
      margin-left: 10px;
    }

    .parameter-edit {
      cursor: pointer;
      margin-left: 10px;
    }

    .parameter-delete {
      color: $danger;
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .parameter-form {
    margin-top: 15px;
  }
}
</style>
