<template>
  <div class="repeater-input">

    <template v-for="(item, i) in value">

      <div class="item">
        <div class="columns">

          <div  class="column">
            <Field>
              <p>{{item.applicationLabel.singular}}</p>
            </Field>
          </div>

          <div class="column">
            <Field>
              <TextInput :value="item.label.singular" :placeholder="item.applicationLabel.singular" @input="(e) => handleInput('singular', e, i)"/>
              <FormError :field="`labels.${i}.label.singular`" :form="form"/>
            </Field>
          </div>

          <div class="column is-flex is-align-items-center">
            <Field v-if="items[i] !== undefined">
              <TextInput :value="item.label.plural" :placeholder="item.applicationLabel.plural" @input="(e) => handleInput('plural', e, i)"/>
              <FormError :field="`labels.${i}.label.plural`" :form="form"/>
            </Field>
            <Field v-else class="has-text-grey">
              <span @click="handleEdit(i)" class="is-clickable">
                <BIcon icon="pencil" class="is-small" />
              </span>
              <span>
                {{ item.label.plural }}
              </span>
            </Field>
          </div>

        </div>

      </div>

    </template>

  </div>
</template>

<script>

import pluralize from 'pluralize';

const template = {
  parameterId: null,
  operator: null,
  value: null,
};

export default {
  name: 'RepeaterLabelInput',
  computed: {},
  data() {
    return {
      items: [],
    };
  },
  created() {

  },
  methods: {
    handleEdit(index) {
      this.items[index] = true;

      this.$emit('input', [
        ...this.value,
      ]);
    },
    handleInput(property, e, i) {

      let value = [
        ...this.value,
      ];

      value[i]['label'][property] = e.target !== undefined ? e.target.value : e;

      if (property === 'singular')
      {
        value[i]['label']['plural'] = pluralize(value[i]['label'][property])
      }

      this.$emit('input', value);
    },
  },
  props: {
    form: {
      type: Object,
      required: false,
    },
    value: {
      default: () => [],
      required: true,
      type: Array,
    },
  },
}
</script>

<style scoped lang="scss">
.repeater-input {

  .item {
    margin-bottom: 5px;
    position: relative;

    .item-remove {
      cursor: pointer;
    }
  }
}
</style>
