import {
  Account as Model,
  AccountService,
  ResourceStore,
} from '@/internal';

const service = new AccountService();

const store = new ResourceStore({
  model: Model,
  actions: {
    stripePortal({}, payload = {}) {
      return service.stripePortal(payload);
    },
    licenseAssignmentsSession({}, payload = {}) {
      return service.licenseAssignmentsSession(payload.id, payload);
    },
    paymentMethodUpdate({}, payload = {}) {
      return service.paymentMethodUpdate(payload);
    },
    subscriptionUpdateOrCreate({}, payload = {}) {
      return service.subscriptionUpdateOrCreate(payload);
    },
  },
});

export default store.toObject();
