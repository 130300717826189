var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.menuItem.onClick)?_c('div',{class:{
          'eu-menu-item': !_vm.menuItem.class,
          [_vm.menuItem.class]: _vm.menuItem.class && !_vm.menuItem.cta,
          'button': _vm.menuItem.cta,
          'is-dark': _vm.menuItem.cta,
          'is-block': _vm.menuItem.cta,
      },staticStyle:{"cursor":"pointer"},on:{"click":_vm.menuItem.onClick}},[_c('MenuItemContent',{attrs:{"menu-item":_vm.menuItem}})],1):(_vm.menuItem.cta)?_c('div',{class:{
          [_vm.menuItem.class]: _vm.menuItem.class,
      }},[(_vm.menuItem.href)?_c('a',{staticClass:"button is-block is-fullwidth is-dark",attrs:{"href":_vm.menuItem.href}},[_c('MenuItemContent',{attrs:{"menu-item":_vm.menuItem,"align":"center"}})],1):(_vm.menuItem.route)?_c('router-link',{staticClass:"button is-block is-fullwidth is-dark",attrs:{"to":_vm.menuItem.route}},[_c('MenuItemContent',{attrs:{"menu-item":_vm.menuItem,"align":"center"}})],1):_vm._e()],1):(_vm.menuItem.href)?_c('a',{class:{
          'eu-menu-item': !_vm.menuItem.class,
          [_vm.menuItem.class]: _vm.menuItem.class && !_vm.menuItem.cta,
          'button': _vm.menuItem.cta,
          'is-dark': _vm.menuItem.cta,
          'is-block': _vm.menuItem.cta,
      },attrs:{"href":_vm.menuItem.href}},[_c('MenuItemContent',{attrs:{"menu-item":_vm.menuItem}})],1):(_vm.menuItem.route)?_c('router-link',{class:{
                  'eu-menu-item': !_vm.menuItem.class,
                  [_vm.menuItem.class]: _vm.menuItem.class && !_vm.menuItem.cta,
                  'button': _vm.menuItem.cta,
                  'is-dark': _vm.menuItem.cta,
                  'is-block': _vm.menuItem.cta,
               },attrs:{"to":_vm.menuItem.route}},[_c('MenuItemContent',{attrs:{"menu-item":_vm.menuItem}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }