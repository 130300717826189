<template>
  <div class="device-status">
    <div class="dot" :class="{
      'is-online': row.status === 'online',
      'is-offline': row.status === 'offline',
      'is-pending': row.status === 'pending' || row.status === null,
    }"></div>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'DeviceStatus',
  computed: {

  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {
    row: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
  .device-status {
    display: inline-block;
    text-align: center;

    .dot {
      $size: 10px;
      display: inline-block;
      border-radius: 50%;
      height: $size;
      position: relative;
      top: -2px;
      width: $size;

      &.is-online {
        background: $green;
      }

      &.is-offline {
        background: $red;
      }

      &.is-pending {
        background: $yellow;
      }
    }
  }
</style>
