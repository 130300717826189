import {
  ResourceService,
} from '@/internal';

export default class DeviceService extends ResourceService {

  constructor() {
    super();
    this.baseUrl = 'device';
  }

  chartData(id, payload = {}) {
    const {params, ...rest} = payload;

    return this.request({
      method: 'get',
      url: `device/${id}/chart-data`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  copyParameters(id, payload = {}) {
    const {params, ...rest} = payload;

    return this.request({
      method: 'get',
      url: `device/${id}/copy-parameters`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  onboard(code, payload = {}) {
    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `device/onboard/${code}`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  resubscribe(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `device/${id}/resubscribe`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  share(id, payload = {}) {
    const {params, ...rest} = payload;

    return this.request({
      method: 'get',
      url: `device/${id}/share`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  subscribe(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `device/${id}/subscribe`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  subscriptionLicenseSync(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `device/${id}/subscription-license-sync`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  unsubscribe(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `device/${id}/unsubscribe`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

}
