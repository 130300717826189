<template>
  <div class="unit-resource-list">
    <ResourceList addLabel="Add API Key"
                  :addRoute="addRoute"
                  :params="params"
                  :query="query"
                  :resource="resource"
                  :renderAs="renderAs"
                  :sortByOptions="sortByOptions"
    />
  </div>
</template>

<script>
import {ApiKey} from '@/internal';
import ApiKeyResourceListItem from '@/components-V2/ApiKey/ResourceListItem';

export default {
  name: 'ApiKeyResourceList',
  computed: {
    addRoute() {
      if (this.hideAddButton) {
        return null;
      } else if (this.domainUUID) {
        return window.app.findRouteByName('domain/api-key/create')
      } else {
        return window.app.findRouteByName('api-key/create');
      }
    },
  },
  created() {

    this.domainUUID = window.app.storage.getItem('domainBeingManaged');

    if (this.domainUUID) {
      this.params = {
        ...this.params,
        domain: this.domainUUID,
      }
    }
  },
  data() {
    return {
      domainUUID: null,
      params: {},
      renderAs: ApiKeyResourceListItem,
      resource: ApiKey,
      sortByOptions: [
        {
          label: 'Name',
          value: 'name',
        },
        {
          label: 'Created Date',
          value: 'created_at',
        },
      ],
    };
  },
  props: {
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {},
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
