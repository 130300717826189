<template>
  <div class="map-input">

    <div id="mini-map">

      <div v-if="!readonly" id="search">
        <b-button @click="handleToggleAddressInput"
                  type="is-primary"
                  icon-left="search"/>
      </div>

      <div v-if="mapId" :id="mapId"></div>

    </div>

    <template v-if="addressInputActive">
      <AddressInput allowAutocomplete
                    label=""
                    placeholder="Start typing..."
                    v-model="location"/>
    </template>

  </div>
</template>

<script>

import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import ExtendDrawControl from '@/components/Map/MapDrawControls/ExtendDrawControl';

import {mapActions} from 'vuex';
import polylabel from '@mapbox/polylabel'
import {debounce} from 'lodash';

import {
  CircleMode,
  DirectMode,
  DragCircleMode,
  SimpleSelectMode,
} from 'mapbox-gl-draw-circle';

export default {
  name: 'MapInput',
  computed: {
    regions() {
      return this.$store.getters['region/all'] || [];
    },
    regionCenter() {
      if (!this.value || !this.value.geometry || !this.value.geometry.coordinates) {
        return null;
      }

      let polygonCenter = polylabel(this.value.geometry.coordinates, 1.0);
      let coordinates;

      if (polygonCenter) {
        coordinates = polygonCenter
      } else {
        coordinates = this.value.geometry.coordinates[0][0]
      }

      return {lat: coordinates[1], lng: coordinates[0]};
    },
    regionSource() {
      if (this.geojson) {
        return {
          'type': 'Feature',
          'geometry': this.geojson.geometry,
          'properties': {
            ...this.geojson.properties,
            'color': this.color ? this.color : '#ff0000'
          }
        };
      } else {
        return null;
      }
    },
    regionSources() {
      return this.regions ? this.regions.map((region) => {
        return {
          'type': 'Feature',
          'geometry': region.area,
          'properties': {
            'id': region.id,
            'title': region.name,
            'color': region.color,
            'priority': false
          }
        }
      }) : [];
    },
    regionCenterSources() {
      return this.regions ? this.regions.filter((region) => region.area).map((region) => {

        return {
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': polylabel(region.area.coordinates, 1.0)
          },
          'properties': {
            'id': region.id,
            'title': region.name,
            'color': region.color,
            'priority': false
          }
        }
      }) : []
    },
  },
  created() {
    // Generate unique map container ID
    this.mapId = `mapbox${this._uid}`;
  },
  mounted() {
    if (this.value) {
      this.geojson = this.value;
    }

    this.initMap();
  },
  data() {
    return {
      addressInputActive: false,
      draw: null,
      map: null,
      mapId: null,
      location: {},
      geojson: {
        'type': 'Feature',
        'geometry': null,
        'properties': {}
      },
      onGeoJSONChange: debounce(() => {
        this.initRegion();
        this.handleInput();
      }, 200),
      onColorChange: debounce(() => {
        this.map.removeControl(this.draw);

        this.draw = this.createDraw();

        this.map.addControl(this.draw);

        this.draw.add(this.value);
      }, 200),
    };
  },
  methods: {
    ...mapActions({
      regionIndex: 'region/index',
      setMap: 'system/setMap',
      setUserLocation: 'system/setUserLocation'
    }),
    createDraw() {
      return new MapboxDraw({
        displayControlsDefault: false,
        controls: {}, // used ExtendDrawControl
        defaultMode: 'simple_select',
        userProperties: true,
        modes: {
          ...MapboxDraw.modes,
          draw_circle: CircleMode,
          drag_circle: DragCircleMode,
          direct_select: DirectMode,
          simple_select: SimpleSelectMode
        },
        styles: [
          // ACTIVE (being drawn)
          // line stroke
          {
            'id': 'gl-draw-line',
            'type': 'line',
            'filter': ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
            'layout': {
              'line-cap': 'round',
              'line-join': 'round'
            },
            'paint': {
              'line-color': '#D20C0C',
              'line-dasharray': [0.2, 2],
              'line-width': 2
            }
          },
          // polygon fill
          {
            'id': 'gl-draw-polygon-fill',
            'type': 'fill',
            'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
            'paint': {
              'fill-color': this.color,
              'fill-outline-color': this.color,
              'fill-opacity': 0.5
            }
          },
          // polygon mid points
          {
            'id': 'gl-draw-polygon-midpoint',
            'type': 'circle',
            'filter': ['all',
              ['==', '$type', 'Point'],
              ['==', 'meta', 'midpoint']],
            'paint': {
              'circle-radius': 3,
              'circle-color': '#fbb03b'
            },
          },
          // polygon outline stroke
          // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
          {
            'id': 'gl-draw-polygon-stroke-active',
            'type': 'line',
            'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
            'layout': {
              'line-cap': 'round',
              'line-join': 'round'
            },
            'paint': {
              'line-color': '#ffffff',
              'line-dasharray': [0.2, 2],
              'line-width': 2
            }
          },
          // vertex point halos
          {
            'id': 'gl-draw-polygon-and-line-vertex-halo-active',
            'type': 'circle',
            'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
            'paint': {
              'circle-radius': 5,
              'circle-color': '#FFF'
            }
          },
          // vertex points
          {
            'id': 'gl-draw-polygon-and-line-vertex-active',
            'type': 'circle',
            'filter': ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
            'paint': {
              'circle-radius': 3,
              'circle-color': '#D20C0C',
            }
          },
          // INACTIVE (static, already drawn)
          // line stroke
          {
            'id': 'gl-draw-line-static',
            'type': 'line',
            'filter': ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
            'layout': {
              'line-cap': 'round',
              'line-join': 'round'
            },
            'paint': {
              'line-color': '#000',
              'line-width': 3
            }
          },
          // polygon fill
          {
            'id': 'gl-draw-polygon-fill-static',
            'type': 'fill',
            'filter': ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
            'paint': {
              'fill-color': '#000',
              'fill-outline-color': '#000',
              'fill-opacity': 0.1
            }
          },
          // polygon outline
          {
            'id': 'gl-draw-polygon-stroke-static',
            'type': 'line',
            'filter': ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
            'layout': {
              'line-cap': 'round',
              'line-join': 'round'
            },
            'paint': {
              'line-color': '#000',
              'line-width': 3
            }
          },
        ]
      });
    },
    handleColorChange() {
      this.map.removeControl(this.draw);

      this.draw = this.createDraw();

      this.map.addControl(this.draw);

      this.draw.add(this.value);
    },
    handleInput() {
      this.$emit('input', this.geojson);
    },
    handleToggleAddressInput() {
      this.addressInputActive = !this.addressInputActive;
    },
    initMap() {

      const map = window.app.storage.getItem('map') || null;
      const position = map ? map.position : null

      const mapCenter = position ? position.center : null;
      const zoom = position ? position.zoom : 14;
      const bearing = position ? position.bearing : 0;
      const pitch = position ? position.pitch : 0;

      let center;

      if (this.regionCenter) {
        center = [this.regionCenter.lng, this.regionCenter.lat];
      } else if (mapCenter) {
        center = [mapCenter.lng, mapCenter.lat];
      } else if (this.userLocation) {
        center = [this.userLocation.lng, this.userLocation.lat];
      } else {
        center = [-122.439, 37.755];
      }

      const mapStyle = this.$store.state.map.mapStyles[this.$store.state.map.mapStyle];
      const style = mapStyle.custom ? mapStyle.url : `mapbox://styles/${mapStyle.url}`;

      mapboxgl.accessToken = window.app.env.mapboxToken;

      this.map = new mapboxgl.Map({
        container: this.mapId,
        style,
        center,
        zoom,
        bearing,
        pitch,
        maxZoom: 22,
        minZoom: 0
      });

      if (!this.readonly) {
        this.draw = this.createDraw();
        const drawControls = new ExtendDrawControl({
          draw: this.draw,
          buttons: [
            // {
            //   on: 'click',
            //   action: this.drawCircle,
            //   classes: ['fal', 'fa-draw-circle'], // This doesn't work? - NBH
            //   attributes: {
            //     id: 'ctrlDrawCircle'
            //   }
            // },
            {
              on: 'click',
              action: this.drawPolygon,
              classes: ['mapbox-gl-draw_polygon'],
              attributes: {
                id: 'ctrlDrawPolygon'
              }
            },
            {
              on: 'click',
              action: this.drawDelete,
              classes: ['mapbox-gl-draw_trash'],
              attributes: {
                id: 'ctrlDelete'
              }
            },

          ]
        });

        this.map.addControl(drawControls);

        this.map.on('draw.create', this.updateArea);
        this.map.on('draw.delete', this.updateArea);
        this.map.on('draw.update', this.updateArea);
      }

      this.map.on('load', () => {

        if (this.value && this.value.geometry) {
          if (this.readonly) {
            this.initRegion();
          } else {
            this.updateDrawControls('draw-disabled')
            this.draw.add(this.value);
            this.drawDirectSelect();
          }
        }

        // Other regions
        if (this.regionSources) {
          this.initRegions();
        }

      });

      this.map.on('drag', (e) => {
        this.storeMapPosition();
      });

      this.map.on('rotate', (e) => {
        this.storeMapPosition();
      });

      this.map.on('zoom', (e) => {
        this.storeMapPosition();
      });
    },
    drawCircle() {
      this.draw.changeMode('draw_circle', {initialRadiusInKm: 0.1});
    },
    drawPolygon() {
      this.draw.changeMode('draw_polygon');
    },
    drawDirectSelect() {
      const data = this.draw.getAll();

      if (data.features.length > 0) {
        const feature = data.features[0];
        this.draw.changeMode('direct_select', {'featureId': feature.id});
      }
    },
    drawDelete() {
      this.draw.deleteAll();
      this.geojson.geometry = null;
      this.updateDrawControls('draw-enabled');
    },
    initRegion() {
      this.removeSourceAndLayer('regionSourceLayer');
      this.removeSourceAndLayer('regionSource');

      this.map.addSource('regionSource', {
        'type': 'geojson',
        'cluster': false,
        'data': {
          'type': 'FeatureCollection',
          'features': [this.regionSource]
        }
      });

      this.map.addLayer({
        'id': 'regionSourceLayer',
        'type': 'fill',
        'source': 'regionSource',
        'layout': {},
        'paint': {
          'fill-color': ['get', 'color'],
          'fill-opacity': 0.5
        }
      });
    },
    initRegions() {
      this.removeSourceAndLayer('regionSourcesLayer');
      this.removeSourceAndLayer('regionSourcesLabel');
      this.removeSourceAndLayer('regionSources');

      this.map.addSource('regionSources', {
        'type': 'geojson',
        'cluster': false,
        'data': {
          'type': 'FeatureCollection',
          'features': this.regionSources
        }
      });

      this.map.addLayer({
        'id': 'regionSourcesLayer',
        'type': 'fill',
        'source': 'regionSources',
        'layout': {},
        'paint': {
          'fill-color': ['get', 'color'],
          'fill-opacity': 0.5
        }
      });

      // Label
      this.map.addSource('regionCenterSources', {
        'type': 'geojson',
        'cluster': false,
        'data': {
          'type': 'FeatureCollection',
          'features': this.regionCenterSources
        }
      });

      this.map.addLayer({
        'id': 'regionSourcesLabel',
        'type': 'symbol',
        'source': 'regionCenterSources',
        'layout': {
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'text-field': ['get', 'title'],
          'text-variable-anchor': ['center'],
          'text-radial-offset': 0.5,
          'text-justify': 'center',
          'text-allow-overlap': true,
          'text-ignore-placement': true
        },
        paint: {
          'text-color': '#ffffff',
        }
      });
    },
    removeSourceAndLayer(id) {
      if (this.map.getLayer(id) != null) this.map.removeLayer(id);
      if (this.map.getSource(id) != null) this.map.removeSource(id);
    },
    storeMapPosition() {

      const map = window.app.storage.getItem('map') || {};

      map.position = {
        center: this.map.getCenter(),
        zoom: this.map.getZoom(),
        pitch: this.map.getPitch(),
        bearing: this.map.getBearing(),
      };

      window.app.storage.setItem('map', map);
    },
    updateArea() {
      const data = this.draw.getAll();

      if (data.features.length > 0) {
        const area = data.features[0];
        const coordinates = area.geometry.coordinates[0];

        this.geojson.geometry = {
          type: 'Polygon',
          coordinates: [coordinates]
        }

        this.geojson.properties = {
          ...this.geojson.properties,
          ...area.properties
        }

        this.updateDrawControls('draw-disabled')
      } else {
        this.updateDrawControls('draw-enabled')
      }
    },
    updateCenter(value) {
      this.map.setCenter([value.lng, value.lat]);
    },
    updateDrawControls(status) {
      if (status === 'draw-enabled') {
        // document.getElementById('ctrlDrawCircle').removeAttribute('disabled');
        document.getElementById('ctrlDrawPolygon').removeAttribute('disabled');
      } else if (status === 'draw-disabled') {
        // document.getElementById('ctrlDrawCircle').setAttribute('disabled', 'disabled');
        document.getElementById('ctrlDrawPolygon').setAttribute('disabled', 'disabled');
      }
    }
  },
  props: {
    // regions: {
    //   default: () => {
    //   },
    // },
    color: {
      default: '#00A3E3',
      type: String
    },
    value: {
      default: () => {
      },
      type: Object | null
    },
    readonly: {
      default: false,
      type: Boolean,
    }
  },
  watch: {
    value() {
      if (this.value && this.readonly) {
        this.initRegion();
      }
    },
    location: {
      deep: true,
      handler() {
        if (this.location.lat && this.location.lng) {
          this.updateCenter(this.location);
        }
      },
    },
    geojson: {
      deep: true,
      handler() {
        this.onGeoJSONChange();
      },
    },
    color() {
      if (this.value) {
        this.onColorChange();
      }
    },

  },
}
</script>

<style lang="scss">
#mini-map {
  border-radius: 5px;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 400px;
  overflow: hidden;
  position: relative;

  .mapboxgl-map {
    flex: 1;
    height: 100%;
    position: relative;
    visibility: visible;

    *:focus {
      outline: none;
    }
  }

  #help {
    top: 15px;
    right: 15px;
    position: absolute;
    opacity: 0.8;
    z-index: 30;
  }

  #search {
    top: 15px;
    left: 15px;
    position: absolute;
    opacity: 0.8;
    z-index: 30;
  }
}

</style>

