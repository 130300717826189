import Form from '@/views/Converter/Form';
import Index from '@/views/Converter/Index';

const routes = [
  {
    name: 'converter/index',
    path: '/function',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'function',
      middleware: ['auth'],
      title: 'Functions',
    },
  },
  {
    name: 'converter/create',
    path: '/function/create',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('converter/index');
      },
      containerSize: 'container-extra-small',
      icon: 'function',
      middleware: ['auth'],
      title: 'New Function',
    },
  },
  {
    name: 'converter/edit',
    path: '/function/:converterUUID/edit',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('converter/index');
      },
      containerSize: 'container-extra-small',
      icon: 'function',
      middleware: ['auth'],
      title: 'Edit Function',
    }
  },
  {
    name: 'converter/show',
    path: '/function/:converterUUID',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('converter/index');
      },
      containerSize: 'container-medium',
      icon: 'function',
      middleware: ['auth'],
      title: 'Function',
    },
  },
];

export default routes;
