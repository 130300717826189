<template>
  <div>
    <div class="nested-list-item" :class="{ 'is-dark': $themeDark }" v-if="listItem.onClick" @click="listItem.onClick">
      <div class="nested-list-item-content truncate">
        <BIcon :icon="listItem.icon" v-if="listItem.icon"/> {{ listItem.title }}
      </div>
    </div>
    <router-link class="nested-list-item" :class="{ 'is-dark': $themeDark }" :to="listItem.route" :title="listItem.title" v-else>
      <div class="nested-list-item-content truncate">
        <BIcon :icon="listItem.icon" v-if="listItem.icon" :pack="listItem.iconPack"/> {{ listItem.title }}
      </div>
    </router-link>
  </div>
</template>

<script>
import {NestedListItem} from '@/internal';

export default {
  name: 'NestedListItem',
  data() {
    return {};
  },
  props: {
    listItem: {
      required: true,
      type: NestedListItem,
    }
  },
}
</script>

<style scoped lang="scss">
.nested-list-item {
  border-radius: 5px;
  color: $black-ter;
  cursor: pointer;
  display: block;
  font-size: 1.1em;
  position: relative;
  top: 20px;

  &.is-dark {
    color: $white;
  }

  &:before {
    border-top: 1px dashed $grey;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 15px;
  }

  .nested-list-item-content {
    border-radius: 4px;
    display: block;
    margin-left: 15px;
    padding: 7.5px 10px;

    .is-dark & {
      &:hover {
        background: $black-ter;
      }
    }

    &:hover {
      background: $grey-light;
    }
  }
}
</style>
