<template>
  <div class="resource-list-settings" :class="{ 'is-dark': $themeDark }">
    <div class="button resource-list-settings-toggle" @click="handleModalToggle">
      <BIcon icon="cog"/>
    </div>

    <BModal :active.sync="modalActive" :dark="$themeDark">

      <div class="container container-extra-extra-small">

        <Box flat :black="$themeDark">

          <Title>List Settings</Title>

          <Field>
            <Label>Sort By</Label>
            <SortByInput v-model="settings.sortBy" :options="sortByOptions" />
          </Field>

          <Field>
            <Label>Sort Order</Label>
            <SortOrderInput v-model="settings.sortOrder" />
          </Field>

          <Controls>
            <template v-slot:right>
              <Button primary @click="handleModalToggle">Done</Button>
            </template>
          </Controls>

        </Box>
      </div>
    </BModal>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import SortByInput from '@/components-V2/Inputs/SortByInput';
import SortOrderInput from '@/components-V2/Inputs/SortOrderInput';

export default {
  name: 'ResourceListSettings',
  components: {
    SortByInput,
    SortOrderInput,
  },
  computed: {},
  data() {
    return {
      modalActive: false,
      settings: {
        sortBy: 'name',
        sortOrder: 'asc',
      },
    };
  },
  methods: {
    ...mapActions({}),
    handleModalToggle() {
      this.modalActive = !this.modalActive;
    },
  },
  props: {
    sortByOptions: {
      default: () => [],
      required: false,
      type: Array,
    },
    value: {
      required: true,
    },
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        this.$emit('input', this.settings);
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.settings) !== JSON.stringify(this.value)) {
          this.settings = {
            ...this.value,
          };
        }
      },
    }
  }
}
</script>

<style scoped lang="scss">
.resource-list-settings {
  .resource-list-settings-toggle {
    background: none;
    border: 1px solid $navigation-border-color;
  }

  &.is-dark {
    .resource-list-settings-toggle {
      color: $white;
    }
  }
}
</style>
