import {
  ResourceService,
} from '@/internal';

export default class TripService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'trip';
  }

}
