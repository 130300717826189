<template>
  <TextInput type="url"
             :value="value"
             @input="handleInput"
             :placeholder="placeholder"
  />
</template>

<script>
export default {
  name: 'UrlInput',
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
  props: {
    placeholder: {
      default: 'https://domain.com',
      required: false,
      type: String,
    },
    value: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">

</style>
