<template>
  <router-link v-if="routerLink" :class="classes" :to="to" :disabled="loading">
    <slot />
  </router-link>
  <div @click="handleClick" :class="classes" :disabled="loading" v-else>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Button',
  computed: {
    classes() {
      return {
        'button': true,
        'is-block': this.block,
        'is-danger': this.danger,
        'is-warning': this.warning,
        'is-dark': this.dark,
        'is-light': this.light,
        'is-loading': this.loading,
        'is-outlined': this.outlined,
        'is-primary': this.primary,
        'is-white': this.white,
        'is-small': this.small,
        'is-text': this.text,
        'is-ghost': this.ghost,
        'is-rounded': this.rounded,
      };
    }
  },
  methods: {
    handleClick() {
      if (this.loading) {
        return;
      }

      this.$emit('click');
    },
  },
  props: {
    block: {
      default: false,
      type: Boolean,
    },
    danger: {
      default: false,
      type: Boolean,
    },
    warning: {
      default: false,
      type: Boolean,
    },
    dark: {
      default: false,
      type: Boolean,
    },
    light: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    outlined: {
      default: false,
      type: Boolean,
    },
    primary: {
      default: false,
      type: Boolean,
    },
    routerLink: {
      default: false,
      type: Boolean,
    },
    to: {
      required: false,
    },
    white: {
      default: false,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
    text: {
      default: false,
      type: Boolean,
    },
    ghost: {
      default: false,
      type: Boolean,
    },
    rounded: {
      default: false,
      type: Boolean,
    }
  },
}
</script>

<style scoped lang="scss">
.button {

  &.is-small {
    border-radius: 4px !important;
    font-size: 0.8rem;
    height: auto;
    padding: 5px 10px;
  }
}
</style>
