<template>
  <div class="is-padded">

    <BLoading active v-if="loading"/>

    <template v-else-if="devices.length">

      <div class="has-margin-bottom has-text-right">
        <Button outlined primary @click="handleDeviceClaim">
          Add Device
        </Button>
      </div>

      <template v-for="device in devices">
        <Monitor1 :device="device"/>
      </template>

    </template>

    <template v-else>

      <Box flat :black="$themeDark" class="has-text-centered" style="margin: 45px auto; max-width: 420px;">

        <Title>Welcome!</Title>

        <p class="has-margin-bottom">
          You're just a couple clicks away from having your cold storage solution setup.
        </p>

        <Button block outlined primary @click="handleDeviceClaim">
          Add Your First Device
        </Button>
      </Box>

    </template>


  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import Monitor1 from '@/_instances/MeatCache/components/Device/Monitor1';

export default {
  name: 'Dashboard',
  components: {
    Monitor1,
  },
  computed: {
    devices() {
      return this.$store.getters['device/all'].sort((a, b) => a.name > b.name ? 1 : -1) || [];
    },
  },
  created() {
    this.loadDevices();
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      deviceIndex: 'device/index',
    }),
    handleDeviceClaim() {
      this.$router.push({
        name: 'device/claim',
      });
    },
    loadDevices() {
      this.loading = true;
      this.deviceIndex({
        params: {
          include: [
            'latestUplink',
            'parameters.asset',
            'parameters.conditions',
            'parameters.conditions.rule',
          ],
        },
      }).finally(() => {
        this.loading = false;
      })
    },
  },
};
</script>

<style scoped lang="scss">
#assets {

}
</style>
