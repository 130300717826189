<template>
  <div class="sample-size-input">
    <span style="white-space: nowrap;">Sample Size: Every</span> <TextInput v-model="localValue" @blur="handleInput" /> <span style="white-space: nowrap;">Data Point(s)</span>
  </div>
</template>

<script>

export default {
  name: 'SampleSizeInput',
  computed: {},
  data() {
    return {
      localValue: 1,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value);
    },
  },
  props: {
    value: {
      required: true,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.localValue = this.value;
      },
    },
  }
}
</script>

<style lang="scss">
.sample-size-input {
  align-items: center;
  display: flex;

  .text-input {
    display: inline-block;
    padding: 0 5px;

    .input {
      text-align: center;
      width: 50px;
    }
  }
}
</style>
