<template>
  <div class="logo" :class="{ 'is-dark': $themeDark }">
    <template v-if="!appName.includes(' ') && appNameParts.length === 2">
      <span class="thin">{{ appNameParts[0] }}</span>
      <span class="thick">{{ appNameParts[1] }}</span>
    </template>
    <template v-else>
      <span class="thick">{{ appName }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  computed: {
    appName() {
      return window.app.env.appName;
    },
    appNameParts() {
      return this.appName.match(/[A-Z][a-z]+/g);
    },
  },
  props: {
    withoutMark: {
      default: false,
      type: Boolean,
    }
  },
};
</script>

<style scoped lang="scss">
.logo {
  color: $black;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  text-transform: uppercase;

  &.is-dark {
    color: $white;
  }

  .thin {
    font-weight: 300;
  }

  .thick {
    font-weight: 600;
  }
}
</style>
