<template>
  <div id="domain-configuration" class="is-padded">

    <BLoading active v-if="loading"/>

    <Instruction type="is-danger">
      Make sure you know exactly what you're doing before changing any of the URL's below.
    </Instruction>

    <Field>
      <Label>Base URL *</Label>
      <UrlInput v-model="form.fields.url"/>
      <FormError field="url" :form="form"/>
      <Help>
        If you already use this URL for another site (ie blog, store front, etc) that's okay.
        You still need to provide it. We use it in a few places for creating links back to the base site.
      </Help>
    </Field>

    <Field>
      <Label>API URL *</Label>
      <UrlInput v-model="form.fields.apiUrl"/>
      <FormError field="api_url" :form="form"/>
      <Help>
        This is mostly hidden from your users, but they may need it when sending data from other services to your
        domain.
      </Help>
    </Field>

    <Field>
      <Label>APP URL *</Label>
      <UrlInput v-model="form.fields.appUrl"/>
      <FormError field="app_url" :form="form"/>
      <Help>
        This is the domain users will utilize to access your instance of UplinkEngine.
      </Help>
    </Field>

    <hr/>

    <Instruction>
      Create the following DNS entries in order to route traffic to UplinkEngine.
    </Instruction>

    <table class="table is-fullwidth" :class="{ 'is-dark': $themeDark }">
      <tr>
        <th>Type</th>
        <th>Name</th>
        <th>Value/Content</th>
      </tr>
      <tr>
        <td>CNAME</td>
        <td>{{ apiSubdomain }}</td>
        <td>{{ loadBalancerIpAddress }}</td>
      </tr>
      <tr>
        <td>CNAME</td>
        <td>{{ appSubdomain }}</td>
        <td>{{ loadBalancerIpAddress }}</td>
      </tr>
    </table>

    <Instruction type="is-danger">
      If you use your Base URL for another site, do NOT create the following DNS entry.
    </Instruction>

    <Instruction type="is-warning">
      If you want to utilize the UplinkEngine landing page, create the following DNS entry.
    </Instruction>

    <table class="table is-fullwidth" :class="{ 'is-dark': $themeDark }">
      <tr>
        <th>Type</th>
        <th>Name</th>
        <th>Value/Content</th>
      </tr>
      <tr>
        <td>A</td>
        <td>@</td>
        <td>{{ loadBalancerIpAddress }}</td>
      </tr>
    </table>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:right>

          <Button @click="$router.back()" outlined light>
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';
import {Form} from '@/internal';

export default {
  name: 'Configuration',
  computed: {
    apiSubdomain() {
      return this.removeProtocol(this.removePort(this.domain.apiUrl.replace(`.${this.baseUrl}`, '')));
    },
    appSubdomain() {
      return this.removeProtocol(this.removePort(this.domain.appUrl.replace(`.${this.baseUrl}`, '')));
    },
    baseUrl() {
      return this.domain && this.domain.url ? this.removeProtocol(this.domain.url) : '';
    },
    domain() {
      return this.domainUUID ? this.$store.getters['domain/findBy'](this.domainUUID, 'uuid') : null;
    },
  },
  created() {
    this.domainUUID = window.app.storage.getItem('domainBeingManaged');
    this.loadDomain();
  },
  data() {
    return {
      domainUUID: null,
      form: new Form({
        apiUrl: '',
        appUrl: '',
        url: '',
      }),
      loadBalancerIpAddress: 'balance.uplinkengine.com',
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      domainShow: 'domain/show',
      domainUpdate: 'domain/update',
    }),
    handleSave() {

      this.loading = true;
      this.form.errors.clear();

      this.domainUpdate({
        ...this.domain,
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar('Domain Saved');
      }).catch((error) => {
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    fillForm() {

      if (!this.domain) {
        return;
      }

      this.form.fields = {
        apiUrl: this.domain.apiUrl,
        appUrl: this.domain.appUrl,
        url: this.domain.url,
      };
    },
    loadDomain() {

      if (!this.domainUUID) {
        console.error('No domainUUID');
        return;
      }

      this.loading = true;

      return this.domainShow({
        id: this.domainUUID,
      }).then(() => {
        this.fillForm();
      }).catch((e) => {
        console.error(e);
        window.app.snackbar('Error loading Domain');
      }).finally(() => {
        this.loading = false;
      });
    },
    removeProtocol(url = '') {
      return url.replace('https://', '').replace('http://', '');
    },
    removePort(url = '') {
      return url.replace(/:\d+/, '');
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
