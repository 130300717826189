<template>
  <div id="asset-form" class="is-padded">

    <BLoading active v-if="loading"/>

    <template v-else>

      <Instruction>
        If your temperature sensor reads a value greater than this...
      </Instruction>

      <Field v-if="form.fields.conditions">

        <Label>Temperature (F&deg;)</Label>

        <NumberInput v-model="form.fields.conditions[0].config.value"/>

      </Field>

      <Instruction>We will send out notifications to these contacts...</Instruction>

      <Field>
        <Label>Emails *</Label>

        <BTaginput v-model="form.fields.actions[0].config.to"
                   icon="envelope"
                   placeholder="Add an email"
                   :class="{ 'is-dark': $themeDark }"
                   autocomplete
                   @typing="getFilteredEmails"
                   :data="filteredEmails"
                   allowNew
        />

        <Help>Press tab or enter after inputting the email.</Help>

      </Field>

      <Field>

        <Label>Phone Numbers *</Label>

        <BTaginput v-model="form.fields.actions[1].config.smsNumbers"
                   icon="phone"
                   placeholder="Add a number"
                   :class="{ 'is-dark': $themeDark }"
                   @input="validateSmsNumbers"
        />

        <Help>Available to U.S. phone numbers only. Press tab or enter after inputting the phone number.</Help>

      </Field>


    </template>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>

          <!--          <Button danger outlined @click="handleDelete" class="is-pulled-left" :loading="deleting" v-if="asset">-->
          <!--            Delete-->
          <!--          </Button>-->

        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

import {phone} from 'phone';

import DeviceState from '@/components-V2/Device/State.vue';
import _ from 'lodash';

export default {
  name: 'Form',
  components: {
    DeviceState,
  },
  computed: {
    account() {
      return this.user && this.user.account ? this.$store.getters['account/findBy'](this.user.account.uuid, 'uuid') : null;
    },
    accountUsers() {
      return this.account ? this.account.users : [];
    },
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    parameters() {
      return this.asset ? this.asset.parameters : [];
    },
    rule() {
      return this.$route.params.ruleUUID ? this.$store.getters['rule/findBy'](this.$route.params.ruleUUID, 'uuid') : null;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  async created() {

    await this.loadUser();

    if (this.$route.params.ruleUUID) {
      await this.loadRule();
    }
  },
  data() {
    return {
      deleting: false,
      filteredEmails: [],
      form: new Form({
        name: 'Freezer Warm',
        actions: [
          {
            type: 'App\\Actions\\Notification',
            config: {
              to: [],
              channel: 'mail',
            },
          },
          {
            type: 'App\\Actions\\Notification',
            config: {
              smsNumbers: [],
              channel: 'sms',
            },
          },
        ],
        conditions: [
          {
            targetId: this.$route.query.parameterId,
            targetType: 'App\\Models\\Parameter',
            type: 'App\\Conditions\\ParameterValue',
            config: {
              value: 20,
              operator: '>',
              compare_to: 'value',
              target_category: 'device',
              target_parent_id: this.$route.query.deviceId,
            },
          }
        ],
        conditionsLogic: 'or',
        repeat: {
          delay: null,
          enabled: false,
          if: null,
          interval: null,
          when: null,
        },
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      ruleShow: 'rule/show',
      ruleStore: 'rule/store',
      ruleUpdate: 'rule/update',
      userShow: 'auth/userShow',
    }),
    fillForm() {

      if (!this.rule) {
        return;
      }

      this.form.fields = {
        ...this.rule,
        id: this.rule.uuid,
        conditions: [
          ...this.rule.conditions,
        ],
        actions: [
          ...this.rule.actions,
        ],
      };
    },
    getFilteredEmails(search) {
      this.filteredEmails = [
        ...this.accountUsers.map((user) => user.email).filter(email => !this.form.fields.actions[0].config.to.includes(email)),
      ];
    },
    handleEmailAdd(email) {
      this.form.fields.actions[0].config.to = [
        ..._.uniq([
          ...this.form.fields.actions[0].config.to,
          email,
        ]),
      ];
    },
    handleSave() {

      const fields = {
        ...this.form.fields,
        actions: [
          ...this.form.fields.actions.map((action) => {

            if (action.config.smsNumbers) {
              action.config.sms_numbers = action.config.smsNumbers;
              delete action.config.smsNumbers;
            }

            return action;
          }),
        ]
      };

      this.form.errors.clear();

      let action = null;

      if (this.rule) {
        action = this.ruleUpdate;
      } else {
        action = this.ruleStore;
      }

      if (action) {
        this.loading = true;

        action(fields).then(() => {
          window.app.snackbar('Saved');
          this.$router.back();
        }).catch((error) => {
          this.form.recordErrors(error);
        }).finally(() => {
          this.loading = false;
        });
      }

    },
    loadRule() {
      this.loading = true;
      return this.ruleShow({
        id: this.$route.params.ruleUUID,
        params: {
          include: [
            'actions',
            'conditions',
          ],
        },
      }).then(this.fillForm).catch((error) => {
        console.error(error);
        window.app.snackbar('Error loading rule');
      }).finally(() => {
        this.loading = false;
      });
    },
    loadUser() {
      this.loading = true;
      return this.userShow({
        params: {
          include: ['account.users'],
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    validateSmsNumbers() {

      const validNumbers = this.form.fields.actions[1].config.smsNumbers.filter((smsNumber) => {
        const valid = phone(smsNumber, {country: 'USA'}).isValid;

        if (!valid) {
          window.app.snackbar({
            message: 'Phone Number Inavlid',
            type: 'is-danger',
          });
        }

        return valid;
      }).map((smsNumber) => {
        return phone(smsNumber, {country: 'USA'}).phoneNumber;
      });

      this.form.fields.actions[1].config.smsNumbers = [
        ..._.uniq(validNumbers),
      ];
    },
  },
}
</script>

<style scoped lang="scss">
#asset-form {

}
</style>
