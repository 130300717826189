<template>
  <div class="input fake-input" :class="{ 'is-dark': $themeDark }" :disabled="disabled">
    <slot/>
    <div class="controls">

      <BTooltip label="Copy" v-if="clickToCopy">
        <span class="control text-input-click-to-copy" @click="handleCopy">
          <BIcon icon="copy"/>
        </span>
      </BTooltip>

      <BTooltip label="Locked" v-if="locked">
        <span class="control fake-input-locked">
          <BIcon icon="lock" />
        </span>
      </BTooltip>

    </div>

  </div>
</template>

<script>
import copy from 'copy-to-clipboard';

export default {
  name: 'FakeInput',
  methods: {
    handleCopy() {
      try {
        copy(this.$slots.default[0].text.trim());
        window.app.snackbar('Copied to Clipboard');
      } catch (e) {
        window.app.snackbar({
          message: 'Unable to Copy',
          type: 'is-danger',
        });
      }
    },
  },
  props: {
    clickToCopy: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    locked: {
      type: Boolean,
    },
  }
}
</script>

<style scoped lang="scss">
.fake-input {
  cursor: default;

  .controls {
    align-items: center;
    display: flex;
    position: absolute;
    right: 7.5px;
    top: 50%;
    transform: translateY(-50%);

    .control {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
</style>
