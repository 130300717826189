import {geoJsonFeature} from '@/helpers';

export default {
  id: 'userLocation',
  group: 'map',
  enabled: true,
  label: 'User Location',
  color: '#0d7bf3',
  source({store}) {

    const userLocation = store ? store.state.map.userLocation : null;

    if (userLocation) {
      return geoJsonFeature([userLocation.lng, userLocation.lat]);
    }

    return null;
  },
  layout() {
    return {
      type: 'circle',
      paint: {
        'circle-pitch-alignment': 'map',
        'circle-color': this.color,
        'circle-radius': 6,
      },
      before: 'zIndex2',
    };
  },
  onClick() {
    // console.log('User Location Click');
  },
};
