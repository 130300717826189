import {
  ApiService,
} from '@/internal';

export default class AuthService extends ApiService {

  accountUpdate(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'put',
      url: '/auth/account',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  domainStore(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/auth/domain`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  forgotPassword(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/auth/forgot-password`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  login(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/auth/login`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  logout(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/auth/logout`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  resetPassword(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/auth/reset-password`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  signUp(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/auth/sign-up`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  switchAccount(payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'put',
      url: '/auth/switch-account',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  userShow(payload = {}) {

    const { params } = payload;

    return this.request({
      method: 'get',
      url: '/auth/user',
      params: this.prepareParams(params),
    });
  }

  userUpdate(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'put',
      url: '/auth/user',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }
}
