<template>
  <div id="asset-index" class="is-padded">
    <AssetResourceList />
  </div>
</template>

<script>
import AssetResourceList from '@/components-V2/Asset/ResourceList';

export default {
  name: 'Index',
  components: {
    AssetResourceList,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
