<template>
    <textarea class="input textarea-input"
              :class="{ 'is-dark': $themeDark }"
              @input="handleInput($event.target.value)"
              :placeholder="placeholder"
              :maxlength="maxlength"
    >{{value}}</textarea>
</template>

<script>
export default {
  name: 'TextInput',
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
  props: {
    maxlength: {
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    type: {
      default: 'text',
      type: String,
      required: false,
    },
    value: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">
.textarea-input {
  height: 90px;
}
</style>
