import {
  Model,
} from '@/internal';

import NotificationService from './NotificationService';

export default class Notification extends Model {

  static get key() {
    return 'notification';
  }

  static get service() {
    return NotificationService;
  }

}
