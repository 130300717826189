<template>
  <div class="device-status-tag tag" :class="{
          'is-success': device.status && device.status === 'online',
          'is-danger': device.status && device.status === 'offline',
          'is-warning': device.status === 'pending' || device.status === null,
         }">
    {{ device.status ? device.status.toUpperCase() : 'PENDING' }}
  </div>
</template>

<script>
export default {
  name: 'StatusTag',
  computed: {},
  props: {
    device: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
