<template>
  <div class="ue-label" :class="{ 'has-text-light': $themeDark }">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Label',
}
</script>

<style scoped lang="scss">
.ue-label {
  font-size: 0.8em;
  margin-bottom: 10px;
  text-transform: uppercase;
}
</style>
