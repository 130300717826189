import {
  Model,
  QRCodeService as Service
} from '@/internal';

export default class QRCode extends Model {

  static get key() {
    return 'qrCode';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {};
  }
}
