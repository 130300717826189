<template>
  <div class="position-resource-list">
    <ResourceList :resource="resource"
                  :renderAs="renderAs"
                  :query="query"
                  :searchable="false"
                  sortBy="created_at"
                  :sortByOptions="sortByOptions"
    />
  </div>
</template>

<script>

import PositionResourceListItem from '@/components-V2/Position/ResourceListItem';
import {Position} from '@/internal';

export default {
  name: 'PositionResourceList',
  computed: {},
  data() {
    return {
      params: {
        include: [],
      },
      renderAs: PositionResourceListItem,
      resource: Position,
      sortByOptions: [
        {
          label: 'Created Date',
          value: 'created_at',
        },
      ],
    };
  },
  methods: {},
  props: {
    query: {
      default: () => {
        return {};
      },
      required: false,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
