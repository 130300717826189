import {
  Device,
  Model,
  PositionService as Service,
} from '@/internal';

export default class Position extends Model {

  static get key() {
    return 'position';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      lat: {
        required: true,
        type: Number,
      },
      lng: {
        required: true,
        type: Number,
      },
      altitude: {
        required: false,
        type: Number,
      }
    };
  }

  get device() {
    return this.hasOne(Device)
  }

}
