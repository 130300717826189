import {
  Model,
  UplinkExportService as Service,
} from '@/internal';

export default class UplinkExport extends Model {

  static get key() {
    return 'uplinkExport';
  }

  static get service() {
    return Service;
  }
}
