import {
  Uplink as Model,
  ResourceStore,
} from '@/internal';

const service = new Model.service();

const store = new ResourceStore({
  model: Model,
  actions: {
    convert({commit}, payload = {}) {
      return service.convert(payload).then((response) => {
        return response.data;

        // return {
        //   ids: ResourceStore.processData('device', commit, response.data),
        // };
      });
    },
    raw ({ commit }, payload = {}) {
      return service.raw(payload.id, payload);
    },
  },
});

export default store.toObject();
