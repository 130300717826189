import {
  Model,
  LorawanNetworkServerService as Service,
} from '@/internal';

export default class LorawanNetworkServer extends Model {

  static get key() {
    return 'lorawanNetworkServer';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      rules: {
        required: true,
        type: Array,
      }
    };
  }

}
