<template>
  <div class="theme-input">
    <SelectInput :options="options" :value="value" @input="handleInput" />
  </div>
</template>

<script>
export default {
  name: 'ThemeInput',
  computed: {},
  data() {
    return {
      options: [
        {
          label: '- Select a Theme -',
          value: null,
        },
        {
          label: 'Light',
          value: 'light',
        },
        {
          label: 'Dark',
          value: 'dark',
        },
      ]
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    }
  },
  props: {
    value: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.theme-input {}
</style>
