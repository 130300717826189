import store from '@/store';

export default (to, from, next) => {

  const authenticated = store.state.auth.authenticated;
  const path = window.app.auth.redirectRouteAfterLogin().path;

  if (authenticated && to.path !== path) {

    window.app.d(`[guestMiddleware] Redirected to ${path}`);

    return next({ path });

  } else {

    return next();

  }
}
