<template>
  <div id="shop-merch">
    <Title>Merch</Title>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'Merch',
  computed: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {},
}
</script>

<style scoped lang="scss">
#shop-merch {

}
</style>
