import {
  ResourceService,
} from '@/internal';

export default class ParameterValueService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'sensor-data';
  }
}
