<template>
  <div id="converter-form" class="is-padded">

    <BLoading active v-if="loading" :is-full-page="false"/>

    <Field>
      <SystemTag v-if="converter && converter.isSystemObject"/>
    </Field>

    <Field>
      <Label>Name *</Label>
      <TextInput v-model="form.fields.name"
                 placeholder="Name"
                 :disabled="!editable"
      />
      <FormError field="name" :form="form"/>
    </Field>

    <Field>
      <Label>Type *</Label>
      <SelectInput v-model="form.fields.type"
                   :disabled="!editable"
                   :options="converterTypeOptions"
      />
      <FormError field="type" :form="form"/>

    </Field>

    <Field v-show="form.fields.type === 'unit-to-unit'" v-if="!loading">
      <Label>Measurement *</Label>
      <SelectInput v-model="form.fields.unitType"
                   :options="unitTypeOptions"
                   :disabled="!editable"
      />
      <FormError field="unitType" :form="form"/>
    </Field>

    <div class="columns" v-show="form.fields.type === 'unit-to-unit'" v-if="!loading">

      <div class="column">
        <Field>
          <Label>From *</Label>
          <SelectInput v-model="form.fields.unitFromId"
                       :options="unitOptions"
                       :disabled="!form.fields.unitType || !editable"
          />
          <FormError field="unitFrom" :form="form"/>
        </Field>
      </div>

      <div class="column">
        <Field>
          <Label>To *</Label>
          <SelectInput v-model="form.fields.unitToId"
                       :options="unitOptions"
                       :disabled="!form.fields.unitType || !form.fields.unitFromId || !editable"
          />
          <FormError field="unitTo" :form="form"/>
        </Field>
      </div>

    </div>

    <Field v-show="form.fields.type === 'custom'">
      <Label>Body *</Label>
      <prism-editor class="prism-editor"
                    v-model="form.fields.body"
                    :highlight="highlighter"
                    line-numbers
                    :readonly="!editable"
      />
      <FormError field="body" :form="form"/>
    </Field>

    <template>

      <Label>Test Function</Label>

      <div class="columns">
        <div class="column">
          <Field>
            <Label>Device</Label>
            <ResourceSelect v-model="testDeviceId"
                            :params="deviceResourceSelectParams"
                            placeholder="Search for Device"
                            :resource="deviceResource"
            />
            <FormError field="deviceId" :form="testForm"/>
          </Field>
        </div>
        <div class="column">
          <Field>
            <Label>Parameter</Label>
            <SelectInput v-model="testForm.fields.parameterId" :options="parameterOptions" :disabled="!testDeviceId"/>
            <FormError field="parameterId" :form="testForm"/>
          </Field>
        </div>
      </div>

      <Field>
        <FormError field="error" :form="testForm"/>
      </Field>

      <div class="columns">
        <div class="column">
          <Label>Test Input</Label>
          <JSON :data="testInput"/>
        </div>
        <div class="column">
          <Label>Converted Output</Label>
          <JSON :data="testOutput"/>
        </div>
      </div>

      <div class="has-text-right">
        <Button @click="handleTest" light outlined :disabled="testDisabled" :loading="testing">
          Test Function
        </Button>
      </div>

    </template>

    <Teleport to="#inspector-footer">

      <Controls v-if="editable">

        <template v-slot:left>
          <Button @click="handleDelete" outlined danger :loading="deleting" v-if="converter">
            Delete
          </Button>
        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Device,
  Form,
} from '@/internal';

import {PrismEditor} from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import {highlight, languages} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-coy.css'; // import syntax highlighting styles

const defaultConverterBody = `// Use converters to modify values and units - Javascript Syntax

function converter (context) {

  let unit = context.unit;
  let value = context.value;

  // In this example, we take an incoming value in celsius and convert it to fahrenheit.
  // We also return the converted unit name as a string, in this case 'fahrenheit'.
  // If a calibration value was set on the parameter, the value received by the converter will already be calibrated.
  //
  // convertedValue = (value * 9 / 5) + 32;
  // convertedUnit = 'fahrenheit';'



  let convertedUnit = 'xyz';
  let convertedValue = value;

  return {
    unit: convertedUnit,
    value: convertedValue,
  };
}`;
const defaultParameterValue = {
  unit: '',
  value: '',
};

export default {
  name: 'Form',
  components: {
    PrismEditor
  },
  computed: {
    converter() {
      return this.$route.params.converterUUID ? this.$store.getters['converter/findBy'](this.$route.params.converterUUID, 'uuid') : null;
    },
    converterTypeOptions() {

      let options = [
        {
          label: '- Select Type -',
          value: null,
        },
        {
          label: 'Unit to Unit',
          value: 'unit-to-unit',
          helper: 'This is used when converting between two units that measure the same things, such as temperature or length.',
        },
      ];

      if ((this.converter && this.converter.type === 'custom') || this.user.featureEnabled('converters')) {
        options.push({
          label: 'Custom',
          value: 'custom',
          helper: 'This can be used to perform custom calculations and convert between units that do not measure the same thing.',
        });
      }

      return options;
    },
    device() {
      return this.testDeviceId ? this.$store.getters['device/show'](this.testDeviceId) : null;
    },
    deviceResourceSelectParams() {
      return {
        include: ['parameters'],
      };
    },
    deviceResource() {
      return Device;
    },
    editable() {
      if (!this.converter) {
        return true;
      } else if (this.converter.type === 'custom' && this.user.featureEnabled('converters')) {
        return true;
      } else if (this.converter.type !== 'custom' && this.converter.accountId === this.user.account.id) {
        return true;
      }

      return false;
    },
    parameterOptions() {
      return [
        {
          label: '- Select Parameter -',
          value: null,
        },
        ...(this.device && this.device.parameters ? this.device.parameters.map((parameter) => {
          return {
            label: parameter.title,
            value: parameter.id
          };
        }) : []),
      ];
    },
    selectedParameter() {
      return this.testForm.fields.parameterId ? this.device.parameters.find(p => p.id === parseInt(this.testForm.fields.parameterId)) : null;
    },
    testDisabled() {
      return !this.testDeviceId || !this.testForm.fields.parameterId;
    },
    pageTitle() {
      return 'Converter';
    },
    unitTypeOptions() {
      return [
        {
          label: '- Select a Type -',
          value: null,
        },
        ...new Set(this.units.map(unit => unit.type)).map((type) => {
          return {
            label: type,
            value: type,
          };
        })
      ];
    },
    unitOptions() {
      return [
        {
          label: '- Select a Unit - ',
          value: null,
        },
        ...this.units.filter(unit => unit.type === this.form.fields.unitType).map((unit) => {
          return {
            label: unit.title,
            value: unit.id,
          };
        })
      ];
    },
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  async created() {

    await this.loadUnits();

    // this.loadDevices();

    if (this.$route.params.converterUUID) {
      this.loadConverter();
    }
  },
  data() {
    return {
      deviceIds: [],
      deleting: false,
      form: new Form({
        body: '',
        name: '',
        type: 'unit-to-unit',
        unitFromId: null,
        unitToId: null,
        unitType: null,
      }),
      loading: false,
      testForm: new Form({
        id: null,
        parameterId: null,
        body: null,
        type: null,
        unitFromId: null,
        unitToId: null,
        unitType: null,
      }),
      testDeviceId: null,
      testInput: {
        ...defaultParameterValue,
      },
      testOutput: {
        ...defaultParameterValue,
      },
      testing: false,
      units: [],
    };
  },
  methods: {
    ...mapActions({
      deviceIndex: 'device/index',
      destroy: 'converter/destroy',
      store: 'converter/store',
      show: 'converter/show',
      test: 'converter/test',
      unitsShow: 'system/units',
      update: 'converter/update',
    }),
    highlighter(code) {
      return highlight(code, languages.js);
    },
    handleDelete() {
      this.$deleteDialog({
        target: this.converter.name,
        targetType: 'Function',
        onConfirm: () => {
          this.deleting = true;
          this.destroy({
            id: this.converter.id,
          }).then(() => {
            window.app.snackbar('Converter Deleted');
            this.$router.push({
              name: 'converter/index',
            });
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.deleting = false;
          });
        },
      });
    },
    handleSave() {

      const fields = {
        ...this.form.fields,
      };

      this.form.errors.clear();

      let action = null;

      if (this.converter) {
        action = this.update;
      } else {
        action = this.store;
      }

      if (action) {
        this.loading = true;

        action(fields).then(() => {
          window.app.snackbar('Function Saved');
          this.$router.back();
        }).catch((error) => {
          this.form.recordErrors(error);
          window.app.snackbarError(error)
        }).finally(() => {
          this.loading = false;
        });
      }

    },
    handleTest() {

      const fields = {
        ...this.testForm.fields,
        id: this.device.uuid,
        body: this.form.fields.body,
        type: this.form.fields.type,
        unitFromId: this.form.fields.unitFromId,
        unitToId: this.form.fields.unitToId,
        unitType: this.form.fields.unitType,
      };

      this.testForm.errors.clear();
      this.testOutput = {
        ...defaultParameterValue,
      };

      this.testing = true;

      this.test(fields).then((response) => {
        this.testOutput = response.output;
        window.app.snackbar('Test Complete');
      }).catch((error) => {
        this.testForm.recordErrors(error);
        window.app.snackbarError(error);
        this.testOutput = {
          ...defaultParameterValue,
        };
      }).finally(() => {
        this.testing = false;
      });

    },
    loadConverter() {

      this.loading = true;

      return this.show({
        id: this.$route.params.converterUUID,
      }).then(() => {
        this.form.fields = {
          ...this.converter
        };
      }).catch((error) => {
        window.app.snackbar('Error');
      }).finally(() => {
        this.loading = false;
      });
    },
    loadDevices() {

      this.loading = true;

      return this.deviceIndex({
        params: {
          'include': ['parameters']
        },
      }).then((response) => {
        this.deviceIds = response.ids;
      }).finally(() => {
        this.loading = false;
      });
    },
    loadUnits() {

      this.loading = true;

      return this.unitsShow().then(({data}) => {
        this.units = data.data;
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    ['form.fields.type']() {
      if (this.form.fields.type === 'custom') {

        if (!this.form.fields.body) {
          this.form.fields.body = defaultConverterBody;
        }

      } else {
        this.form.fields.body = '';
      }
    },
    ['testForm.fields.parameterId']() {

      const parameterValue = this.selectedParameter.latestParameterValue;

      if (!parameterValue) {
        return null;
      }

      this.testInput = {
        unit: parameterValue.unit ? parameterValue.unit.slug : '',
        value: parameterValue.value || '',
      };
    },
  },
}
</script>

<style lang="scss">
.prism-editor {
  background: #2d2d2d;
  color: #ffffff;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;

  .number {
    align-items: normal;
    background-color: transparent;
    border-radius: 0;
    display: inline-block;
    font-size: inherit;
    height: inherit;
    justify-content: normal;
    margin-right: 0;
    min-width: 0;
    padding: 0;
    text-align: center;
    vertical-align: center;
  }
}

.prism-editor__editor {
  color: #ffffff;

  .token.operator {
    background-color: transparent;
  }
}

.prism-editor__textarea:focus {
  outline: none;
}

.result-textarea {
  height: 160px !important;
}
</style>
