<template>
    <Page id="reset-password-page">
        <div class="container container-extra-extra-small">
            <div class="columns">
                <div class="column">

                    <Title>Reset Password</Title>

                    <Box flat :black="$themeDark">

                        <Field>
                            <Label>Email</Label>
                            <EmailInput v-model="form.fields.email"/>
                            <FormError field="email" :form="form" />
                        </Field>

                        <Field>
                            <Label>Password</Label>
                            <PasswordInput v-model="form.fields.password"/>
                            <FormError field="password" :form="form" />
                        </Field>

                        <Field>
                            <Label>Confirm Password</Label>
                            <PasswordInput v-model="form.fields.passwordConfirmation"/>
                            <FormError field="passwordConfirmation" :form="form" />
                        </Field>

                        <div class="controls has-margin-top">

                            <Button primary block :loading="loading" @click="handleResetPassword">Reset Password</Button>

                            <div class="has-text-centered is-padded" :class="{ 'has-text-light': $themeDark }">
                                - or -
                            </div>

                            <Button router-link outlined block :light="$themeDark" to="/auth/login">Log In</Button>

                        </div>

                    </Box>

                </div>
            </div>
        </div>
    </Page>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

export default {
  name: 'ResetPassword',
  data() {
    return {
      form: new Form({
        email: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword',
    }),
    handleResetPassword() {

      this.loading = true;

      this.resetPassword({
        device: navigator.userAgent || navigator.vendor,
        token: this.$route.params.resetToken,
        ...this.form.fields,
      }).then(() => {

        window.app.snackbar({
          message: 'Password Reset',
        });

        this.$router.push(window.app.findRouteByName('auth/login'));

      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
