import {
  Model,
  UsageService as Service
} from '@/internal';

export default class Usage extends Model {

  static get key() {
    return 'usage';
  }

  static get service() {
    return Service;
  }

}
