import {
  Account,
  Model,
  Widget,
  DashboardService as Service,
} from '@/internal';

export default class Dashboard extends Model {

  static get key() {
    return 'dashboard';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      widgets: {
        preparePayload: (value) => {
          return value ? value.map((widget, index) => {
            return {
              ...widget,
              rank: index
            }
          }) : [];
        },
        type: Widget,
      },
    };
  }

  get account() {
    return this.hasOne(Account);
  }

  get widgets() {
    return this.hasMany(Widget);
  }

}
