<template>
  <div class="monitor-1">
    <Box flat :black="$themeDark" class="device-box">

      <BLoading active v-if="loading" :isFullPage="false"/>

      <div class="is-flex is-justify-content-space-between is-align-items-center has-margin-bottom">

        <div class="left is-flex is-align-content-space-between">
          <div class="device-name tag is-rounded">
            <StatusDot :device="device" style="margin-right: 10px;"/>
            {{ device.name }} [{{ device.lastFourOfDeviceEui }}]
          </div>
        </div>

        <div class="right has-text-right is-flex is-justify-content-space-between is-align-items-center">

          <template v-if="signalStrengthParameter && signalStrengthParameter.latestParameterValue">
            <BTooltip :label="`Signal: ${signalStrengthParameter.latestParameterValue.formattedValue}`"
                      position="is-bottom">
              <span class="signal-strength has-margin-left">
                <BIcon pack="fa-duotone" :icon="signalStrengthIcon(signalStrengthParameter)"/>
              </span>
            </BTooltip>
          </template>

          <template v-if="batteryParameter && batteryParameter.latestParameterValue">
            <BTooltip :label="`Battery: ${batteryParameter.latestParameterValue.formattedValue}`" position="is-bottom">
              <span class="battery has-margin-left">
                <BIcon pack="fa-duotone" :icon="batteryIcon(batteryParameter)"
                       style="font-size: 1.5em; position: relative; top: 2px;"/>
              </span>
            </BTooltip>
          </template>

          <Button outlined small :light="$themeDark" :dark="$themeLight" class="asset-edit has-margin-left"
                  @click="handleDeviceEdit(device)">
            Manage
          </Button>

        </div>

      </div>

      <div class="has-text-centered" style="padding: 30px 10% 45px;" v-if="shouldShowConnectServiceWarning">

        <Title>
          <BIcon icon="triangle-exclamation" style="margin-right: 5px;" /> Service Required
        </Title>

        <p style="margin-bottom: 30px;">
          Your device has been onboarded, but you still need to purchase your service.
        </p>

        <Help class="has-text-centered" style="margin-bottom: 30px;">
          Coupon for prepaid service can be input at checkout.
        </Help>

        <Button primary outlined @click="handleDeviceEdit(device)">
          Add Service
        </Button>

      </div>

      <div v-else>

        <Instruction v-if="!device.latestUplink">
          Waiting on your Monitor 1 to send a packet. Make sure it's plugged in to power.
        </Instruction>

        <Instruction type="is-danger" v-else-if="device.latestUplink && device.status === 'offline'">
          Your Monitor 1 is offline! Make sure it's plugged in to power. Last packet received
          <BTooltip type="is-light" :label="device.latestUplink.createdAt.format(system.settings.datetimeFormat)">
            <span class="is-underlined">{{ device.latestUplink.createdAt.fromNow() }}</span>.
          </BTooltip>
        </Instruction>

        <Instruction type="is-warning" v-else-if="summary1.asset && !summary1.rule">
          You have an unprotected freezer! Configure your notifications for {{ summary1.asset.name }}.
        </Instruction>

        <Instruction type="is-warning" v-else-if="summary2.asset && !summary2.rule">
          You have an unprotected freezer! Configure your notifications for {{ summary2.asset.name }}.
        </Instruction>

        <Instruction v-else-if="!summary1.active && !summary2.active">
          Connect one or both of the sensors to your Monitor 1. Once you've done that, you should see the port below
          turn green.
        </Instruction>

        <div class="ports columns">

          <div class="column is-half">
            <Summary :summary="summary1" title="Port 1"/>
          </div>

          <div class="column is-half">
            <Summary :summary="summary2" title="Port 2"/>
          </div>

        </div>

      </div>

      <Help v-if="$isLocal" class="has-text-centered has-margin-top">{{lastLoad.toString()}}</Help>

    </Box>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import moment from 'moment-timezone';

import StatusDot from '@/components-V2/Device/StatusDot.vue';
import Summary from '@/_instances/MeatCache/components/Device/Summary.vue';

export default {
  name: 'DeviceBox',
  components: {
    StatusDot,
    Summary,
  },
  computed: {
    batteryParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.key === 'battery') : null;
    },
    isPoweredParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.key === 'is-powered') : null;
    },
    signalStrengthParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.key === 'signal-strength') : null;
    },

    summary1() {
      return {
        active: this.port1ActiveParameter && this.port1ActiveParameter.latestParameterValue ? this.port1ActiveParameter.latestParameterValue.value : false,
        asset: this.port1ActiveTemperatureParameter ? this.port1ActiveTemperatureParameter.asset : null,
        rule: this.port1Rule,
        temperature: this.port1ActiveTemperatureParameter && this.port1ActiveTemperatureParameter.latestParameterValue ? this.port1ActiveTemperatureParameter.latestParameterValue.formattedValue : null,
        temperatureParameter: this.port1ActiveTemperatureParameter,
        latestParameterValueAt: this.port1ActiveTemperatureParameter && this.port1ActiveTemperatureParameter.latestParameterValue ? this.port1ActiveTemperatureParameter.latestParameterValue.createdAt : null,
      };
    },
    port1ActiveParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.key === 'port-1-sensor-active') : null;
    },
    port1ActiveTemperatureParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.key === 'port-1-sensor-temperature') : null;
    },
    port1Rule() {
      return this.port1ActiveTemperatureParameter && this.port1ActiveTemperatureParameter.conditions.length ? this.port1ActiveTemperatureParameter.conditions[0].rule : null;
    },

    summary2() {
      return {
        active: this.port2ActiveParameter && this.port2ActiveParameter.latestParameterValue ? this.port2ActiveParameter.latestParameterValue.value : false,
        asset: this.port2ActiveTemperatureParameter ? this.port2ActiveTemperatureParameter.asset : null,
        rule: this.port2Rule,
        temperature: this.port2ActiveTemperatureParameter && this.port2ActiveTemperatureParameter.latestParameterValue ? this.port2ActiveTemperatureParameter.latestParameterValue.formattedValue : null,
        temperatureParameter: this.port2ActiveTemperatureParameter,
        latestParameterValueAt: this.port2ActiveTemperatureParameter && this.port2ActiveTemperatureParameter.latestParameterValue ? this.port2ActiveTemperatureParameter.latestParameterValue.createdAt : null,
      };
    },
    port2ActiveParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.key === 'port-2-sensor-active') : null;
    },
    port2ActiveTemperatureParameter() {
      return this.parameters.length ? this.parameters.find(parameter => parameter.key === 'port-2-sensor-temperature') : null;
    },
    port2Rule() {
      return this.port2ActiveTemperatureParameter && this.port2ActiveTemperatureParameter.conditions.length ? this.port2ActiveTemperatureParameter.conditions[0].rule : null;
    },

    parameters() {
      return this.device ? this.device.parameters : [];
    },
    shouldShowConnectServiceWarning() {
      return this.device && !this.device.license;
    },
    system() {
      return this.$store.state.system;
    },
  },
  created() {
    window.app.vue.$on('uplinkReceived', this.loadDeviceInBackground);
    this.lastLoadTimer = setInterval(this.checkLastLoad, 10000); // Check every 10 seconds;
  },
  destroyed() {
    window.app.vue.$off('uplinkReceived', this.loadDeviceInBackground);
    clearInterval(this.lastLoadTimer);
  },
  data() {
    return {
      lastLoadTimer: null,
      lastLoad: moment(),
      loading: false,
      loadingInBackground: false,
    };
  },
  methods: {
    ...mapActions({
      deviceShow: 'device/show',
    }),
    batteryIcon(batteryParameter) {

      let batteryIcon = 'battery';
      let batteryVoltage = batteryParameter.latestParameterValue.value;
      let isPowered = this.isPoweredParameter && this.isPoweredParameter.latestParameterValue && this.isPoweredParameter.latestParameterValue.value === 1;

      if (isPowered) {
        batteryIcon = 'battery-bolt';
      } else if (batteryVoltage > 4) {
        batteryIcon = 'battery-full';
      } else if (batteryVoltage > 3.8) {
        batteryIcon = 'battery-three-quarters';
      } else if (batteryVoltage > 3.6) {
        batteryIcon = 'battery-half';
      } else if (batteryVoltage > 3.4) {
        batteryIcon = 'battery-quarter';
      } else if (batteryVoltage > 3.2) {
        batteryIcon = 'battery-low';
      } else {
        batteryIcon = 'battery-empty';
      }

      return batteryIcon;
    },
    checkLastLoad() {
      if (this.lastLoad && moment.duration(moment().diff(this.lastLoad)).asSeconds() > 120) {
        this.loadDevice(true);
      }
    },
    handleDeviceEdit(device) {
      this.$router.push({
        name: 'device/edit',
        params: {
          deviceUUID: device.uuid,
        },
      })
    },
    loadDevice(inBackground = false) {

      if (this.loading || this.loadingInBackground) {
        return new Promise((resolve) => {
          resolve();
        });
      }

      if (!inBackground) {
        this.loading = true;
      } else {
        this.loadingInBackground = true;
      }

      return this.deviceShow({
        id: this.device.uuid,
        params: {
          include: [
            'latestUplink',
            'parameters.asset',
            'parameters.conditions',
            'parameters.conditions.rule',
          ],
        },
      }).then(() => {
        this.lastLoad = moment();
        console.log(this.lastLoad);
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.loading = false;
        this.loadingInBackground = false;
      });
    },
    loadDeviceInBackground(event) {
      if (event.deviceId === this.device.id) {
        return this.loadDevice(true);
      }
    },
    signalStrengthIcon(signalStrengthParameter) {

      if (this.device.protocol === 'wifi') {

        let icon = 'wifi';
        let percentage = signalStrengthParameter.latestParameterValue.value;

        if (percentage > 66) {
          icon = 'wifi';
        } else if (percentage > 33) {
          icon = 'wifi-fair';
        } else {
          icon = 'wifi-weak';
        }

        return icon;

      } else {

        let icon = 'signal-bars';
        let percentage = signalStrengthParameter.latestParameterValue.value;

        if (percentage > 80) {
          icon = 'signal-bars';
        } else if (percentage > 50) {
          icon = 'signal-bars-good';
        } else if (percentage > 20) {
          icon = 'signal-bars-fair';
        } else {
          icon = 'signal-bars-weak';
        }

        return icon;
      }
    },
  },
  props: {
    device: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.monitor-1 {

  .device-box {
    position: relative;

    margin: 30px 0;

    .device-name {

    }

    .ports {
      margin-top: 5px;
    }
  }
}
</style>
