import ActivityMonitor from '@/views/Tools/ActivityMonitor';
import AdvancedChart from '@/views/Tools/AdvancedChart';
import NutrientCalculator from '@/views/Tools/NutrientCalculator';

const routes = [
  {
    name: 'activity-monitor',
    path: '/tools/activity-monitor',
    component: ActivityMonitor,
    meta: {
      containerSize: 'container-small',
      icon: 'tower-broadcast',
      title: 'Activity Monitor',
    },
  },
  {
    name: 'nutrient-calculator',
    path: '/tools/nutrient-calculator',
    component: NutrientCalculator,
    meta: {
      icon: 'farm',
      title: 'Soil Nutrients',
      fullScreen: true,
    },
  },
  {
    name: 'advanced-charting',
    path: '/tools/charts',
    component: AdvancedChart,
    meta: {
      icon: 'chart-mixed',
      middleware: ['auth'],
      title: 'Charts',
      fullScreen: true,
    },
  },
];

export default routes;
