<template>
  <div class="repeater-rule-input">

    <template v-for="(item, i) in value">

      <Field>
        <div class="select is-fullwidth" :class="{ 'is-dark': $themeDark }">
          <select v-model="value[i].parameterId">
            <option :value="null">- Select Parameter -</option>
            <template v-for="parameter in parameters">
              <option :value="parameter.value">{{ parameter.label }}</option>
            </template>
          </select>
        </div>
        <FormError :field="`rules.${i}.parameter_id`" :form="form"/>
      </Field>

      <Field>
        <SelectInput :value="item.operator" :options="ruleOptions" @input="(e) => handleInput('operator', e, i)"/>
        <FormError :field="`rules.${i}.operator`" :form="form"/>
      </Field>

      <Field>
        <div class="value-wrapper">
          <div class="tag is-info" v-if="unit">
            {{ unitLabel }}
          </div>
          <TextInput :value="item.value" placeholder="" @input="(e) => handleInput('value', e, i)"/>
        </div>
        <FormError :field="`rules.${i}.value`" :form="form"/>
        <Help v-if="latestParameterValue">Current: {{ latestParameterValue.formattedValue }}</Help>
      </Field>

    </template>

  </div>
</template>

<script>

const template = {
  parameterId: null,
  operator: null,
  value: null,
};

export default {
  name: 'RepeaterRuleInput',
  computed: {
    latestParameterValue() {
      return this.selectedParameter ? this.selectedParameter.latestParameterValue : null;
    },
    selectedParameter() {
      // We used to support many rules for the same parameter, but we don't anymore, just haven't refactored all the way.
      return this.value[0].parameterId ? this.$store.getters['parameter/show'](this.value[0].parameterId) : null;
    },
    converter() {
      return this.selectedParameter && this.selectedParameter.converterId ? this.$store.getters['converter/show'](this.selectedParameter.converterId) : null;
    },
    convertToUnit() {
      return this.converter ? this.$store.getters['unit/show'](this.converter.unitToId) : null;
    },
    unit() {

      let unit = null;

      if (this.convertToUnit) {
        unit = this.convertToUnit;
      } else if (this.selectedParameter && this.selectedParameter.unitId) {
        unit = this.$store.getters['unit/show'](this.selectedParameter.unitId);
      }

      return unit;
    },
    unitLabel() {

      if (!this.unit) {
        return '';
      } else if (this.unit.title) {
        return this.unit.title.toUpperCase();
      } else if (this.unit.slug) {
        return this.unit.slug.toUpperCase()
      } else {
        return '';
      }
    },
  },
  data() {
    return {
      ruleOptions: [
        {
          'label': '- Select Operator -',
          'value': null
        },
        {
          'label': 'Equal to',
          'value': '=='
        },
        {
          'label': 'Not equal to',
          'value': '!='
        },
        {
          'label': 'Greater than',
          'value': '>'
        },
        {
          'label': 'Greater than or equal to',
          'value': '>='
        },
        {
          'label': 'Less than',
          'value': '<'
        },
        {
          'label': 'Less than or equal to',
          'value': '<='
        },
      ]
    };
  },
  created() {
    if (this.value.length < 1) {
      this.handleAdd();
    }
  },
  methods: {
    handleAdd() {
      this.$emit('input', [
        ...this.value,
        {
          ...template,
        },
      ]);
    },
    handleInput(property, e, i) {

      let value = [
        ...this.value,
      ];

      value[i][property] = e.target !== undefined ? e.target.value : e;

      this.$emit('input', value);
    },
    handleRemove(i) {
      this.$emit('input', this.value.filter((item, j) => i !== j));
    },
  },
  props: {
    addLabel: {
      type: String,
    },
    form: {
      type: Object,
      required: false,
    },
    value: {
      default: () => [],
      required: true,
      type: Array,
    },
    parameters: {
      required: true,
      type: Array
    },
  },
}
</script>

<style scoped lang="scss">
.repeater-rule-input {
  .value-wrapper {
    position: relative;

    .tag {
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }
  }
}
</style>
