import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const {
    domain,
    moreClick,
    user,
  } = payload;

  return new Menu({
    menuItems: [
      {
        route: window.app.findRouteByName('home'),
      },
      {
        route: window.app.findRouteByName('asset/index'),
        when: domain && domain.featureEnabled('assets'),
      },
      {
        route: window.app.findRouteByName('device/index'),
        when: domain && domain.featureEnabled('devices'),
      },
      {
        route: window.app.findRouteByName('alarm/index'),
        when: domain && domain.featureEnabled('alarms'),
      },
      {
        title: 'More',
        icon: 'bars',
        onClick: moreClick,
      },
    ],
  });
};
