import {
  Device,
  Model, Position,
  TripService as Service,
} from '@/internal';

export default class Trip extends Model {

  static get key() {
    return 'trip';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      name: {
        required: true,
        type: String,
      },
      firstPositionAt: {
        type: Date,
      },
      lastPositionAt: {
        type: Date,
      },
    };
  }

  get device() {
    return this.belongsTo(Device);
  }

  get positions() {
    return this.hasMany(Position);
  }
}
