<template>
  <div class="asset-preview">
    <Subtitle>{{asset.name}}</Subtitle>
    <JSON :data="asset" />
  </div>
</template>

<script>
export default {
  name: 'Preview',
  computed: {},
  data() {
    return {};
  },
  props: {
    asset: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
