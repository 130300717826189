<template>
  <div id="domain-product-inventory" class="is-padded">

    <BLoading active v-if="loading"/>

    <div class="has-text-right has-margin-bottom">

      <Button outlined :light="$themeDark" :dark="$themeLight" class="has-margin-right" @click="loadInventory">
        <BIcon icon="refresh" />
      </Button>

      <BDropdown position="is-bottom-left" aria-role="list">
        <template #trigger="{ active }">
          <Button primary>Add Inventory</Button>
        </template>
        <BDropdownItem aria-role="listitem" class="has-text-left" @click="handleInventorySingleAdd">
          <BIcon style="margin-right: 5px;" icon="circle-plus"/>
          Single Product
        </BDropdownItem>
        <!--        <BDropdownItem aria-role="listitem" class="has-text-left">-->
        <!--          <BIcon style="margin-right: 5px;" icon="file-csv"/>-->
        <!--          Import CSV-->
        <!--        </BDropdownItem>-->
      </BDropdown>
    </div>
    <table class="table inventory-table" :class="{
      'is-dark': $themeDark,
    }">
      <tr>
        <th>QR Code</th>
        <th>Key</th>
        <th>Link</th>
        <th>Product</th>
        <th>Dev EUI / Serial Number</th>
        <th>Claimed</th>
        <th>Actions</th>
      </tr>
      <template v-for="inventory in inventories">
        <tr>
          <td class="has-text-centered">
            <span class="pointer" @click="handleQRCodePreview(inventory.qrCode)">
              <BIcon icon="qrcode"/>
            </span>
          </td>
          <td>
            <code v-if="inventory.qrCode">{{ inventory.qrCode.key }}</code>
            <CopyAttribute :model="inventory.qrCode" attribute="key" v-if="inventory.qrCode"/>
          </td>
          <td>
            <div class="is-flex" v-if="inventory.qrCode">
              <div style="width: 300px;" class="truncate">
                {{ inventory.qrCode.url }}
              </div>
              <CopyAttribute :model="inventory.qrCode" attribute="url"/>
            </div>
          </td>
          <td>{{ inventory.config.product }}</td>
          <td>
            {{ inventory.config.devEui }}
            <CopyAttribute :model="inventory.config" attribute="devEui"/>
          </td>
          <td>{{ inventory.claimedAt ? 'Yes' : 'No' }}</td>
          <td class="has-text-centered">
            <BDropdown position="is-bottom-left">

              <template #trigger="{ active }">
                <div class="pointer">
                  <BIcon icon="ellipsis-stroke"/>
                </div>
              </template>

              <BDropdownItem class="has-text-left" aria-role="listitem" @click="handleInventoryUnclaim(inventory)"
                             v-if="inventory.claimedAt">
                <BIcon icon="arrow-rotate-left"/>
                Unclaim
              </BDropdownItem>

              <BDropdownItem class="has-text-left" aria-role="listitem" @click="handleInventoryDelete(inventory)"
                             v-if="!inventory.claimedAt">
                <BIcon icon="trash"/>
                Delete
              </BDropdownItem>

            </BDropdown>

          </td>
        </tr>
      </template>
    </table>

    <BModal :active.sync="inventoryPreviewQRCode">
      <div class="has-text-centered" v-if="qrCodePreview">
        <img style="background: #ffffff;" :src="qrCodePreview.image" alt="QR Code"/><br/>
      </div>
    </BModal>

    <BModal :active.sync="inventoryAddProductModalActive">
      <div class="container container-extra-extra-small">
        <Box flat :black="$themeDark">

          <Title>Add Inventory</Title>

          <Field>
            <Label>Product Type</Label>
            <SelectInput v-model="productForm.fields.config.product" :options="productOptions"/>
            <FormError field="config.product" :form="productForm"/>
          </Field>

          <Field>
            <Label>Dev EUI / Serial Number</Label>
            <TextInput v-model="productForm.fields.config.devEui"/>
            <FormError field="config.devEui" :form="productForm"/>
          </Field>

          <div class="controls has-text-right">

            <Button outlined :dark="$themeLight" :light="$themeDark" class="has-margin-right"
                    @click="handleInventoryCancel">
              Cancel
            </Button>

            <Button primary @click="handleInventorySave">
              Save
            </Button>
          </div>

        </Box>
      </div>
    </BModal>

  </div>
</template>

<script>
import {Form} from '@/internal';

import {
  mapActions,
} from 'vuex';

import copy from 'copy-to-clipboard';

export default {
  name: 'Inventory',
  computed: {
    domainBeingManaged() {
      return this.domainBeingManagedUUID ? this.$store.getters['domain/findBy'](this.domainBeingManagedUUID, 'uuid') : null;
    },
    inventories() {
      return this.$store.getters['inventory/all'].map((inventory) => {
        inventory.config.product = inventory.config.product.replace('App\\Definitions\\', '');
        return inventory;
      }) || [];
    },
    productOptions() {
      let productOptions = [
        {
          label: '-- Select a Product --',
          value: null
        },
      ];

      if (this.domainBeingManaged) {

        if (this.domainBeingManaged.name === 'MeatCache' || this.domainBeingManaged.name === 'LakeStreet') {
          productOptions.push({
            label: 'Lake Street / Monitor 1',
            value: 'App\\Definitions\\LakeStreetMonitor1',
          });
        }

        if (this.domainBeingManaged.name === 'HarmonyAnalytica') {

          // ChirpStack

          productOptions.push({
            label: 'FS1 Flow Sensor [ChirpStack]',
            value: 'App\\Definitions\\HarmonyAnalyticaFS1FlowSensorChirpStack',
          });

          productOptions.push({
            label: 'H2 Soil Sensor [ChirpStack]',
            value: 'App\\Definitions\\HarmonyAnalyticaH2SoilSensorChirpStack',
          });

          productOptions.push({
            label: 'PS1 Pressure Sensor [ChirpStack]',
            value: 'App\\Definitions\\HarmonyAnalyticaPS1PressureSensorChirpStack',
          });

          productOptions.push({
            label: 'S1 Wired Soil Sensor [ChirpStack]',
            value: 'App\\Definitions\\HarmonyAnalyticaS1WiredSoilSensorChirpStack',
          });

          // TTN

          productOptions.push({
            label: 'FS1 Flow Sensor [TTN]',
            value: 'App\\Definitions\\HarmonyAnalyticaFS1FlowSensorTTN',
          });

          productOptions.push({
            label: 'H2 Soil Sensor [TTN]',
            value: 'App\\Definitions\\HarmonyAnalyticaH2SoilSensor',
          });

          productOptions.push({
            label: 'PS1 Pressure Sensor [TTN]',
            value: 'App\\Definitions\\HarmonyAnalyticaPS1PressureSensorTTN',
          });

          productOptions.push({
            label: 'S1 Wired Soil Sensor [TTN]',
            value: 'App\\Definitions\\HarmonyAnalyticaS1WiredSoilSensor',
          });

        }
      }

      return productOptions;
    },
  },
  async created() {
    this.domainBeingManagedUUID = window.app.storage.getItem('domainBeingManaged');
    await this.loadDomainBeingManaged();
    this.loadInventory();
  },
  data() {
    return {
      deleting: false,
      domainBeingManagedUUID: null,
      inventoryAddProductModalActive: false,
      inventoryPreviewQRCode: false,
      loading: false,
      productForm: new Form({
        config: {
          devEui: null,
          product: null,
        },
        type: 'App\\Inventories\\Product',
      }),
      qrCodePreview: null,
    };
  },
  methods: {
    ...mapActions({
      domainShow: 'domain/show',
      inventoryDestroy: 'inventory/destroy',
      inventoryIndex: 'inventory/index',
      inventoryStore: 'inventory/store',
      inventoryUnclaim: 'inventory/unclaim',
      inventoryUpdate: 'inventory/update',
    }),
    handleCopyAttribute(model, attribute) {
      copy(model[attribute]);
      window.app.snackbar('Copied to Clipboard');
    },
    handleInventoryCancel() {
      this.productFormReset();
      this.inventoryAddProductModalActive = false;
    },
    handleInventoryDelete(inventory) {

      if (inventory.claimedAt) {
        return;
      }

      this.$deleteDialog({
        target: inventory.config.devEui,
        targetType: 'Inventory',
        onConfirm: () => {
          this.deleting = true;
          this.inventoryDestroy({
            id: inventory.id,
            params: {
              domain: this.domainBeingManagedUUID,
            },
          }).then(() => {
            window.app.snackbar('Inventory Deleted');
            this.loadInventory()
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.deleting = false;
          });
        },
      });
    },
    handleInventorySingleAdd() {
      this.productFormReset();
      this.inventoryAddProductModalActive = true;
    },
    handleInventorySave() {

      this.loading = true;

      let payload = {
        ...this.productForm.fields,
        params: {
          include: 'qrCode',
        }
      };

      if (this.domainBeingManagedUUID) {
        payload.domain = this.domainBeingManagedUUID;
      }

      return this.inventoryStore(payload).then(() => {
        window.app.snackbar('Product Saved');
        this.inventoryAddProductModalActive = false;
        this.productForm.reset();
      }).catch((error) => {
        this.productForm.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleInventoryUnclaim(inventory) {

      this.loading = true;

      this.inventoryUnclaim({
        id: inventory.uuid,
        params: {
          domain: this.domainBeingManagedUUID,
          include: 'qrCode',
        },
      }).then(() => {
        window.app.snackbar('Inventory Unclaimed');
      }).catch((error) => {
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleQRCodePreview(qrCode) {
      this.inventoryPreviewQRCode = true;
      this.qrCodePreview = qrCode;
    },
    loadDomainBeingManaged() {

      if (!this.domainBeingManagedUUID) {
        return;
      }

      this.loading = true;

      return this.domainShow({
        id: this.domainBeingManagedUUID,
      }).catch((error) => {
        console.error(error);
        window.app.snackbar('Error loading Domain');
      }).finally(() => {
        this.loading = false;
      });
    },
    loadInventory() {
      this.loading = true;

      let payload = {
        params: {
          include: 'qrCode',
          perPage: -1,
        },
      };

      if (this.domainBeingManagedUUID) {
        payload.params.domain = this.domainBeingManagedUUID;
      }

      this.inventoryIndex(payload).finally(() => {
        this.loading = false;
      });
    },
    productFormReset() {

      let product;

      if (this.domainBeingManaged.name === 'MeatCache' || this.domainBeingManaged.name === 'LakeStreet') {
        product = 'App\\Definitions\\LakeStreetMonitor1';
      } else if (this.domainBeingManaged.name === 'HarmonyAnalytica') {
        product = null;
      }

      this.productForm.fields = {
        config: {
          devEui: null,
          product,
        },
        type: 'App\\Inventories\\Product',
      };
      this.productForm.errors.clear();
    },
  },
};
</script>

<style scoped lang="scss">
#domain-product-inventory {
  table.inventory-table {
    width: 100%;

    tr {
      td {
        white-space: nowrap;
      }
    }
  }
}
</style>
