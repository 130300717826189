<template>
  <div class="is-padded-vertical">

    <BLoading active v-if="loading" />

    <Box flat :black="$themeDark">

      <Field>
        <Label>Manufacturer</Label>
        <b>Harmony Analytica</b>
      </Field>

      <Field>
        <Label>Product</Label>
        <b>PS1 Pressure Sensor</b>
      </Field>

      <Field v-if="inventory.config">
        <Label>Device ID</Label>
        <b>{{ inventory.config.devEui }}</b>
      </Field>

    </Box>

    <Teleport to="#inspector-footer" v-if="!inventory.claimedAt">

      <Controls>

        <template v-slot:right>

          <Button primary @click="handleInventoryClaim">
            Claim Device
          </Button>

        </template>

      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'HarmonyAnalyticaPS1PressureSensor',
  computed: {
    account() {
      return this.user && this.user.account ? this.$store.getters['account/findBy'](this.user.account.uuid, 'uuid') : null;
    },
    accountUsers() {
      return this.account ? this.account.users : [];
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      inventoryClaim: 'inventory/claim',
    }),
    handleInventoryClaim() {
      this.loading = true;
      this.inventoryClaim({
        id: this.inventory.uuid,
      }).then((response) => {

        window.app.snackbar('Device Claimed');

        const meta = response.data.meta;

        if (meta && meta.claim.device) {

          const device = meta.claim.device;

          this.$router.push({
            name: 'device/show',
            params:{
              deviceUUID: device.uuid,
            },
          });
        }

      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {
    inventory: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
