import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const {
    user,
  } = payload;

  return new Menu({
    title: 'Shop',
    icon: 'shop',
    open: payload.open !== undefined ? payload.open : false,
    menuItems: [
      {
        route: window.app.findRouteByName('shop/devices'),
      },
      {
        route: window.app.findRouteByName('shop/merch'),
      },
    ],
  });
};
