<template>
  <div class="ui-layout-input">
    <SelectInput :options="options" :value="value" @input="handleInput" />
  </div>
</template>

<script>
export default {
  name: 'SortOrderInput',
  computed: {},
  data() {
    return {
      options: [
        {
          label: '- Select a Theme -',
          value: null,
        },
        {
          label: 'Ascending',
          value: 'asc',
        },
        {
          label: 'Descending',
          value: 'desc',
        },
      ]
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    }
  },
  props: {
    value: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
</style>
