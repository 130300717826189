<template>
  <router-link :to="routerLinkTo" class="default-resource-list-item" :class="{ 'is-dark': $themeDark }">
    <div class="resource-name">{{resource.name}}</div>
  </router-link>
</template>

<script>
export default {
  name: 'DefaultResourceListItem',
  computed: {
    routerLinkTo() {
      return this.resource ? `/${this.resource.vuexModuleKey}/${this.resource.uuid}` : null;
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">
.default-resource-list-item {
  border-radius: 4px;
  display: block;
  padding: 15px;
}
</style>
