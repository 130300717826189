<template>
  <div class="converter-preview">
    <Subtitle>{{converter.name}}</Subtitle>
  </div>
</template>

<script>
export default {
  name: 'Preview',
}
</script>

<style scoped lang="scss">
.converter-preview {

}
</style>
