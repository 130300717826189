import store from '@/store';

export default (to, from, next) => {

  const user = store.getters['auth/user'];

  if (!user || (user.role !== 'owner' && user.role !== 'support')) {

    window.app.snackbar(`You are not authorized.`);

    window.app.d(`[accountOwner] Redirected to /`);

    return next({ path: '/' });

  } else {

    return next();

  }
}
