import {
  Converter as Model,
  ResourceStore,
} from '@/internal';

const service = new Model.service();

const store = new ResourceStore({
  model: Model,
  actions: {
    test ({ commit }, payload = {}) {
      return service.test(payload.id, payload).then(response => {
        return response.data.data;
      });
    },
  },
});

export default store.toObject();
