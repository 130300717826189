<template>
  <div id="device-form" class="is-padded">

    <BLoading active v-if="loading"/>

    <template v-else>

      <Section title="Device" :open="device !== null">

        <Field>
          <Label>ID</Label>
          <FakeInput locked clickToCopy>{{device.deviceEui}}</FakeInput>
        </Field>

        <Field>

          <Label>Name *</Label>

          <TextInput v-model="form.fields.name" placeholder="Name"/>

          <FormError field="name" :form="form"/>

        </Field>

        <Field>
          <div class="is-pulled-right">
            <router-link to="/tag">Manage Tags</router-link>
          </div>
          <Label>Tags</Label>
          <TagInput v-model="form.fields.tags" :max="null"/>
        </Field>

      </Section>

      <Section title="Service">

        <template v-if="device && !device.license">

          <Box flat :black="$themeDark">

            <div class="products">
              <template v-for="product in products">
                <div class="product is-flex is-justify-content-space-between"
                     :class="{ 'is-active': productId === product.id}"
                     @click="productId = product.id"
                >
                  <div class="left">
                    {{product.price}}
                  </div>
                  <div class="right">
                    Data Storage: {{product.quotas.storageDays}} Days
                  </div>
                </div>
              </template>
            </div>

          </Box>

          <Button @click="handleDeviceSubscribe" block primary>
            Add Service
          </Button>

        </template>

        <template v-else>

          <Instruction type="is-warning" v-if="device.license.endsAt">
            Service cancelled. You'll continue to have service until {{ device.license.endsAt.format('ll') }}.
          </Instruction>

          <LabelLineValue label="Device" :value="`${device.name} [${device.lastFourOfDeviceEui}]`"/>

          <LabelLineValue label="Service" :value="serviceStatus"/>

          <div class="buttons has-margin-top">

            <Button :light="$themeDark" :dark="$themeLight" outlined class="is-flex-grow-1" @click="handleBillingManage">
              Manage Billing
            </Button>

          </div>

        </template>

      </Section>

    </template>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>

          <Button danger outlined @click="handleDelete" class="is-pulled-left" :loading="deleting" v-if="device">
            Delete
          </Button>

        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

import DeviceState from '@/components-V2/Device/State.vue';

export default {
  name: 'Form',
  components: {
    DeviceState,
  },
  computed: {
    account() {
      return this.user && this.user.account ? this.$store.getters['account/findBy'](this.user.account.uuid, 'uuid') : null;
    },
    accountUsers() {
      return this.account ? this.account.users : [];
    },
    device() {
      return this.$route.params.deviceUUID ? this.$store.getters['device/findBy'](this.$route.params.deviceUUID, 'uuid') : null;
    },
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    parameters() {
      return this.device ? this.device.parameters : [];
    },
    product() {
      return this.productId ? this.products.find(product => product.id === this.productId) : null;
    },
    products() {
      return this.domain ? this.domain.products.filter(product => product.enabled).sort((a, b) => a.domainStripePrice.unitAmount > b.domainStripePrice.unitAmount ? 1 : -1).map((product) => {

        let price;

        if (product.domainStripePrice.unitAmount === 0) {
          price = 'Free Forever';
        } else {
          price = `${this.$numeral(product.domainStripePrice.unitAmount / 100).format('$0,0.00')}/month`;
        }

        product.price = price;

        return product;
      }) : [];
    },
    serviceStatus() {

      if (this.device.license && !this.device.license.endsAt) {
        return 'Active';
      } else if (this.device.license && this.device.license.endsAt) {
        return 'Cancelled';
      } else if (!this.device.license) {
        return 'Inactive';
      }

    },
    shouldShowConnect() {
      return this.device;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  async created() {

    while(!this.domain) {
      return;
    }

    await this.loadDomain();
    await this.loadUser();

    if (this.$route.params.deviceUUID) {
      await this.loadDevice();
    }

    // This is to account for changes made within the Stripe Checkout/Billing Portal

    if (this.$route.query.refresh !== undefined) {
      await this.handleDeviceSubscriptionLicenseSync();
    } else if (this.$route.query.subscribe === 'success') {
      await this.handleDeviceSubscriptionLicenseSync();
      window.app.snackbar('Service Activated');
    } else if (this.$route.query.unsubscribe === 'success') {
      await this.handleDeviceSubscriptionLicenseSync();
      window.app.snackbar('Service Cancelled');
    }
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        name: '',
        config: {
          pulsesPerUnit: 0,
        },
      }),
      productId: '',
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      accountStripePortal: 'account/stripePortal',
      deviceResubscribe: 'device/resubscribe',
      deviceSubscribe: 'device/subscribe',
      deviceUnsubscribe: 'device/unsubscribe',
      deviceSubscriptionLicenseSync: 'device/subscriptionLicenseSync',
      deviceDestroy: 'device/destroy',
      deviceShow: 'device/show',
      deviceUpdate: 'device/update',
      domainShow: 'domain/show',
      userShow: 'auth/userShow',
    }),
    handleDelete() {
      this.$deleteDialog({
        target: this.device.name,
        targetType: 'device',
        onConfirm: () => {
          this.deleting = true;
          this.deviceDestroy({
            id: this.device.id,
          }).then(() => {
            window.app.snackbar('Device Deleted');
            this.$router.push(window.app.findRouteByName('device/index'));
          }).finally(() => {
            this.deleting = false;
          });
        },
      })
    },
    handleDeviceSubscribe() {
      this.$router.push({
        name: 'account/billing',
        query: {
          device: this.device.uuid,
          price: this.product.domainStripePrice.id,
        },
      });
    },
    handleDeviceSubscriptionLicenseSync() {
      this.loading = true;
      this.deviceSubscriptionLicenseSync({
        id: this.device.uuid,
      }).then(this.loadDevice).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleSave() {

      const fields = {
        ...this.form.fields,
      };

      this.form.errors.clear();

      this.loading = true;

      this.deviceUpdate(fields).then(() => {
        window.app.snackbar('Device Saved');
        this.$router.push({
          name: 'home',
        });
      }).catch((error) => {
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });

    },
    handleBillingManage() {

      this.$router.push({
        name: 'account/billing',
        query: {
          device: this.device.uuid,
        },
      });

    },
    fillForm() {

      if (!this.device) {
        return;
      }

      this.form.fields = {
        config: {
          ...this.form.fields.config,
          ...this.device.config,
        },
        ...this.device,
        id: this.device.uuid,
        tags: this.device.tags || [],
      };
    },
    loadDevice() {
      this.loading = true;
      return this.deviceShow({
        id: this.$route.params.deviceUUID,
        params: {
          include: [
            'parameters',
            'parameters.unit',
            'tags',
          ],
        },
      }).then(this.fillForm).catch((error) => {
        console.error(error);
        window.app.snackbar('Error loading device');
      }).finally(() => {
        this.loading = false;
      });
    },
    loadDomain() {
      this.loading = true;
      return this.domainShow({
        id: this.domain.uuid,
        params: {
          include: ['products'],
        }
      }).then(() => {
        this.productId = this.products.length ? this.products[0].id : null;
      }).finally(() => {
        this.loading = false;
      });
    },
    loadUser() {
      this.loading = true;
      return this.userShow({
        params: {
          include: ['account.users'],
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>

<style scoped lang="scss">
.products {
  .product {
    border: 1px solid $navigation-border-color;
    border-radius: 3px;
    cursor: pointer;
    margin: 15px 0;
    padding: 15px;

    &.is-active {
      border-color: $primary;
    }
  }
}
</style>
