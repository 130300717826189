<template>
  <div id="account-billing" class="is-padded">

    <BLoading active v-if="loading"/>

    <Section title="Summary" open>
      <p>Subscription Summary, Payment Methods, Billing Info and Invoice History are all available via your Stripe
        Portal.</p>
      <div class="has-text-right">
        <Button primary outlined @click="handleStripePortal">
          Launch Billing Portal
        </Button>
      </div>
    </Section>

    <Section title="Device Licenses" open>

      <table class="table device-license-table" :class="{ 'is-dark': $themeDark }">

        <tr>
          <th class="has-text-centered">
            Device
          </th>
          <th class="has-text-centered">
            License
          </th>
        </tr>

        <template v-for="device in devices">

          <tr class="pointer" @click="handleLicensePick(device)">

            <td>
              {{ device.name }}
            </td>

            <td class="has-text-right">

              <div class="tag is-danger" v-if="licenseAssignments[device.id] === null">
                <del>{{ device.license.product.name }}</del>
              </div>

              <div class="tag is-info" v-else-if="licenseChanged(device)">
                {{ productByDomainStripePriceId(licenseAssignments[device.id]).name }}
              </div>

              <div class="tag is-success" v-else-if="!device.license && licenseAssignments[device.id]">
                {{ productByDomainStripePriceId(licenseAssignments[device.id]).name }}
              </div>

              <div class="tag" v-else-if="device.license">
                {{ device.license.product.name }}
              </div>

              <div class="tag is-warning" v-else>
                Unlicensed
              </div>
            </td>

          </tr>

        </template>

      </table>

    </Section>

    <BModal :active.sync="licensePickerModalActive">
      <div class="container container-small">
        <Box flat :black="$themeDark" v-if="licensePickerModalActive">
          <LicensePicker :domain="domain"
                         :currentStripePriceId="licenseAssignments[licensePickerFor.id] || null"
                         :pickerFor="licensePickerFor"
                         @removed="handleLicenseRemoved"
                         @selected="handleLicenseSelected"
          />
        </Box>
      </div>
    </BModal>

    <BModal :active.sync="reviewModalActive">
      <div class="container container-small">
        <Box flat :black="$themeDark">

          <Title>Confirm Changes</Title>

          <table class="table is-fullwidth" :class="{ 'is-dark': $themeDark}">

            <tr>
              <th class="has-text-centered">License</th>
              <th class="has-text-centered">Price / Month</th>
              <th class="has-text-centered">Current Qty</th>
              <th class="has-text-centered">New Qty</th>
            </tr>

            <template v-for="product in products">
              <tr
                v-if="changes.current[product.domainStripePrice.id] > 0 || changes.next[product.domainStripePrice.id]">
                <td>{{ product.name }}</td>
                <td class="has-text-centered">{{ formattedPrice(product.domainStripePrice.unitAmount) }}</td>
                <td class="has-text-centered">{{ changes.current[product.domainStripePrice.id] }}</td>
                <td class="has-text-centered">{{ changes.next[product.domainStripePrice.id] }}</td>
              </tr>
            </template>

            <tr>
              <th colspan="2">Total per Month</th>
              <th class="has-text-centered">{{ formattedPrice(changes.currentTotal) }}</th>
              <th class="has-text-centered">{{ formattedPrice(changes.nextTotal) }}</th>
            </tr>

          </table>

          <Help class="has-margin-vertical">
            Upon confirmation, a Stripe invoice will be generated with a credit or charge, depending on the changes
            made.
            If you have a charge, your default payment method will be charged immediately.
            If you have a credit, it will be saved to your account and applied to future invoices.
            No refunds will be issued.
          </Help>

          <Help class="has-margin-vertical">
            By confirming these changes, you agree to be charged on a monthly recurring basis, until you cancel your
            device license(s).
          </Help>

          <div class="has-text-right has-margin-top">

            <Button outlined :light="$themeDark" @click="handleChangesCancel">
              Cancel
            </Button>

            <Button primary @click="handleChangesConfirm" class="has-margin-left">
              Confirm Changes
            </Button>
          </div>

        </Box>
      </div>
    </BModal>

    <Teleport to="#inspector-footer" v-if="anyChanged">

      <Controls>

        <template v-slot:right>

          <Button @click="$router.back()" outlined light>
            Cancel
          </Button>

          <Button @click="handleChangesReview" primary class="has-margin-left">
            Review Changes
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import LicensePicker from '@/components-V2/LicensePicker/LicensePicker';

export default {
  name: 'Billing',
  components: {
    LicensePicker,
  },
  computed: {
    account() {
      return this.user ? this.$store.getters['account/show'](this.user.account.id) : null;
    },
    anyChanged() {
      let changed = false;

      this.devices.forEach((device) => {
        if (this.licenseAdded(device) || this.licenseChanged(device) || this.licenseRemoved(device)) {
          changed = true;
        }
      });

      return changed;
    },
    changes() {

      let current = {};

      let next = {};

      let currentTotal = 0;

      let nextTotal = 0;

      if (!this.products) {
        return;
      }

      this.products.forEach((product) => {

        let domainStripePriceId = product.domainStripePrice.id;

        // Next

        current[domainStripePriceId] = 0;
        next[domainStripePriceId] = 0;

        // Current

        this.devices.forEach((device) => {
          if (device.license && device.license.product.domainStripePrice.id === domainStripePriceId) {
            current[domainStripePriceId] += 1;
          }
        });

        // Next

        Object.values(this.licenseAssignments).forEach((_domainStripePriceId) => {
          if (_domainStripePriceId === domainStripePriceId) {
            next[domainStripePriceId] += 1;
          }
        });

      });

      // Current Total

      for (const [priceId, quantity] of Object.entries(current)) {
        const product = this.productByDomainStripePriceId(priceId);
        const price = product.domainStripePrice.unitAmount;
        const productTypeTotal = price * quantity;
        currentTotal += productTypeTotal;
      }

      // Next Total

      for (const [priceId, quantity] of Object.entries(next)) {
        const product = this.productByDomainStripePriceId(priceId);
        const price = product.domainStripePrice.unitAmount;
        const productTypeTotal = price * quantity;
        nextTotal += productTypeTotal;
      }

      return {
        current,
        currentTotal,
        next,
        nextTotal,
      }
    },
    devices() {
      return this.$store.getters['device/all'] ? this.$store.getters['device/all'].sort((a, b) => a.name > b.name ? 1 : -1) : [];
    },
    domain() {
      return this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid');
    },
    products() {
      return this.domain ? this.domain.products
          .sort((a, b) => a.domainStripePrice.unitAmount < b.domainStripePrice.unitAmount ? 1 : -1)
          .sort((a, b) => a.group > b.group ? 1 : -1)
        : [];
    },
    paymentMethods() {
      return this.account && this.account.stripePaymentMethods ? this.account.stripePaymentMethods : [];
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    this.loadAccount().then(() => {
      if (this.$route.query.las) {
        this.loadLicenseAssignmentsSession().then(() => {
          this.reviewModalActive = true;
        });
      }

      if (this.$route.query.price && this.$route.query.device) {

        const device = this.devices.find(device => device.uuid === this.$route.query.device);

        this.licenseAssignments = {
          ...this.licenseAssignments,
          [device.id]: this.$route.query.price,
        };

        this.reviewModalActive = true;

      } else if (this.$route.query.device) {
        this.licensePickerFor = this.$store.getters['device/findBy'](this.$route.query.device, 'uuid');
        this.licensePickerModalActive = true;
      }

    });
  },
  data() {
    return {
      licenseAssignments: {},
      licensePickerFor: null,
      licensePickerModalActive: false,
      loading: false,
      reviewModalActive: false,
    };
  },
  methods: {
    ...mapActions({
      accountStripePortal: 'account/stripePortal',
      accountLicenseAssignmentsSession: 'account/licenseAssignmentsSession',
      accountPaymentMethodUpdate: 'account/paymentMethodUpdate',
      accountShow: 'account/show',
      accountSubscriptionUpdateOrCreate: 'account/subscriptionUpdateOrCreate',
      deviceIndex: 'device/index',
      domainShow: 'domain/show',
    }),
    formattedPrice(cents) {
      let price = cents / 100;
      return this.$numeral(price).format('$0,0.00');
    },
    handleStripePortal() {

      this.loading = true;

      return this.accountStripePortal({
        licenseAssignments: this.licenseAssignments,
      }).then((response) => {

        const url = response.data.data.session.url;

        if (url) {
          window.location.replace(url);
        } else {
          window.app.snackbarError('Unable to launch Stripe Billing Portal');
        }

      }).catch((error) => {
        window.app.snackbar({
          message: error.response.data,
          type: 'is-danger',
        });
        this.loading = false;
      });
    },
    handleLicensePick(device) {
      this.licensePickerFor = device;
      this.licensePickerModalActive = true;
    },
    handleLicenseRemoved() {
      if (this.licensePickerFor.license) {
        this.licenseAssignments = {
          ...this.licenseAssignments,
          [this.licensePickerFor.id]: null,
        };
      } else {
        delete this.licenseAssignments[this.licensePickerFor.id];
      }

      this.licensePickerFor = null;
      this.licensePickerModalActive = false;
    },
    handleLicenseSelected(product) {

      this.licenseAssignments = {
        ...this.licenseAssignments,
        [this.licensePickerFor.id]: product.domainStripePrice.id,
      };

      this.licensePickerFor = null;
      this.licensePickerModalActive = false;
    },
    handlePaymentMethodUpdate() {

      this.loading = true;

      return this.accountPaymentMethodUpdate({
        licenseAssignments: this.licenseAssignments,
      }).then((response) => {

        const url = response.data.data.session.url;

        if (url) {
          window.location.replace(url);
        } else {
          window.app.snackbar({
            message: 'Unable to launch Stripe Billing Portal',
            type: 'is-danger',
          });
        }

      }).catch((error) => {
        window.app.snackbar({
          message: error.response.data,
          type: 'is-danger',
        });
        this.loading = false;
      });
    },
    handleChangesCancel() {
      this.reviewModalActive = false;
    },
    handleChangesReview() {
      this.reviewModalActive = true;
    },
    handleChangesConfirm() {

      if (!this.paymentMethods.length) {
        window.app.snackbar({
          message: 'You need to add a payment method before you can proceed.',
          type: 'is-warning',
          duration: 10000,
          actionText: 'Add Payment Method',
          onAction: () => {
            this.handlePaymentMethodUpdate();
          },
        })
        return;
      }

      this.loading = true;
      this.accountSubscriptionUpdateOrCreate({
        licenseAssignments: this.licenseAssignments,
      }).then(this.loadAccount).then(() => {
        this.reviewModalActive = false;
        window.app.snackbar('License changes complete.')
      }).catch((error) => {
        window.app.snackbar({
          message: error.response.data,
          type: 'is-danger',
        });
        this.loading = false;
      }).finally(() => {
        this.loading = false;
      });
    },
    licenseAdded(device) {
      return !device.license && this.licenseAssignments[device.id] && this.licenseAssignments[device.id] !== null;
    },
    licenseChanged(device) {
      return device.license
        && this.licenseAssignments[device.id]
        && device.license.product.domainStripePrice.id !== this.licenseAssignments[device.id];
    },
    licenseRemoved(device) {
      return device.license && this.licenseAssignments[device.id] === null;
    },
    loadAccount() {
      this.loading = true;
      return this.accountShow({
        id: this.user.account.uuid,
        params: {
          include: [
            'devices',
            'domain',
            'domain.products'
          ],
          with: [
            'stripePaymentMethods',
          ],
        },
      }).then(() => {

        let licenseAssignments = {};

        this.devices.forEach((device) => {
          if (device.license) {
            licenseAssignments[device.id] = device.license.product.domainStripePrice.id;
          }
        });

        this.licenseAssignments = {
          ...licenseAssignments,
        };

      }).finally(() => {
        this.loading = false;
      });
    },
    loadDevices() {
      this.loading = true;
      return this.deviceIndex().finally(() => {
        this.loading = false;
      });
    },
    loadLicenseAssignmentsSession() {

      this.loading = true;

      return this.accountLicenseAssignmentsSession({
        id: this.$route.query.las,
      }).then((response) => {

        const licenseAssignments = response.data.data.license_assignments;

        if (licenseAssignments) {
          this.licenseAssignments = {
            ...licenseAssignments,
          }
        }

      }).finally(() => {
        this.loading = false;
      });
    },
    productByDomainStripePriceId(domainStripePriceId) {
      return this.products.find(product => product.domainStripePrice.id === domainStripePriceId);
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">
#account-billing {
  .device-license-table {
    width: 100%;

    tr:hover {
      background: $grey;
    }

    td {
      padding: 10px;
      vertical-align: center;
    }
  }
}
</style>
