import VerificationCodeService from './VerificationCodeService';

const service = new VerificationCodeService();

export default {
  key: 'verificationCode',
  namespaced: true,
  state: {},
  actions: {
    store({commit}, payload = {}) {
      return service.store(payload);
    },
    resend({dispatch}, payload = {}) {
      return dispatch('store');
    },
    destroy({commit}, payload = {}) {
      return service.destroy(payload);
    },
  },
  mutations: {},
};
