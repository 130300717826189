<template>
  <div class="map-single-device">

    <div class="map-controls" v-if="position">

      <BTooltip class="fit-tracks" label="Fly to current position" position="is-left">
        <Button class="is-small" @click="handleFlyTo">
          <BIcon icon="location"/>
        </Button>
      </BTooltip>

      <BTooltip class="fit-tracks" label="Zoom to fit all" position="is-left" v-if="path.length">
        <Button class="is-small" @click="handleZoomToFitPath">
          <BIcon icon="vector-square"/>
        </Button>
      </BTooltip>

      <BTooltip class="lock-on-device" label="Lock map to device on update" position="is-left" v-if="position">
        <Button class="is-small" :primary="lockOnDevice" :light="!lockOnDevice" @click="handleLockOnDeviceToggle">
          <BIcon icon="lock"/>
        </Button>
      </BTooltip>

    </div>

    <div v-if="mapId" :id="mapId"></div>

  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';

export default {
  name: 'MapSingleDevice',
  computed: {
    path() {
      return this.device && this.device.path ? this.device.path.sort((a, b) => a.age > b.age ? 1 : -1) : [];
    },
    position() {

      // Authenticated
      if (this.device && this.device.position) {
        return this.device.position;
      }

      // Public Share
      return this.device && this.device.latest_uplink ? this.device.latest_uplink.position : null;
    },
  },
  mounted() {
    this.initMap();
  },
  destroyed() {
    this.removeMapContent();
  },
  data() {
    return {
      lockOnDevice: window.app.storage.getItem('deviceShowLockOnDevice') || false,
      map: null,
      mapId: `mapbox${this._uid}`,

      // The order of these layer ids will matter when removing sources/layers. Layers that require a source other than their own id should be higher in the list.
      mapLayerIds: {
        deviceMarker: 'deviceMarker',
        devicePathLineString: 'devicePathLineString',
        devicePathPoints: 'devicePathPoints',
      },
    };
  },
  methods: {
    handleFlyTo() {
      if (this.map && this.position) {
        this.map.flyTo({
          center: [this.position.lng, this.position.lat],
          essential: true,
          zoom: 10,
        });
      }
    },
    handleLockOnDeviceToggle() {
      let lockOnDevice = !this.lockOnDevice;

      if (lockOnDevice) {
        this.handleFlyTo();
      }

      this.lockOnDevice = lockOnDevice;
    },
    handleZoomToFitPath() {

      const coordinates = this.path.map(point => [point.lng, point.lat]);

      const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

      for (const coord of coordinates) {
        bounds.extend(coord);
      }

      this.map.fitBounds(bounds, {
        padding: 50
      });
    },
    initMap() {

      const zoom = 15;
      const bearing = 0;
      const pitch = 0;

      let center;

      if (this.position) {
        center = [this.position.lng, this.position.lat]
      }

      const style = this.$themeDark ? `mapbox://styles/mapbox/dark-v10` : `mapbox://styles/mapbox/light-v10`;

      mapboxgl.accessToken = window.app.env.mapboxToken;

      this.map = new mapboxgl.Map({
        container: this.mapId,
        style,
        center,
        zoom,
        bearing,
        pitch,
        maxZoom: 22,
        minZoom: 0,
      });

      this.map.on('load', () => {
        this.loadMapContent();
      });
    },
    loadDeviceMarker() {

      if (!this.position) {
        return;
      }

      let data = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [
            this.position.lng, this.position.lat,
          ]
        }
      };

      let deviceColor;

      switch (this.device.status) {
        case 'online':
          deviceColor = '#23d160';
          break;
        case 'offline':
          deviceColor = '#ff3d1f';
          break;
        default:
          deviceColor = '#ffdd57';
          break;
      }

      if (this.map.getSource(this.mapLayerIds.deviceMarker)) {
        this.map.getSource(this.mapLayerIds.deviceMarker).setData(data);
      } else {
        this.map.addSource(this.mapLayerIds.deviceMarker, {
          type: 'geojson',
          data,
        });
      }

      if (!this.map.getLayer(this.mapLayerIds.deviceMarker)) {
        this.map.addLayer({
          id: this.mapLayerIds.deviceMarker,
          source: this.mapLayerIds.deviceMarker,
          type: 'circle',
          paint: {
            'circle-color': deviceColor,
            'circle-pitch-alignment': 'map',
            'circle-radius': 6,
          },
        });
      }

      if (this.lockOnDevice) {
        this.handleFlyTo();
      }

    },
    loadDevicePath() {

      if (!this.path.length) {
        return;
      }

      let lastPoint = this.path[0];
      const oldestPointAge = Math.max(...this.path.map(point => point.age));
      const youngestPointAge = Math.min(...this.path.map(point => point.age));
      const delta = oldestPointAge - youngestPointAge;

      let data = {
        type: 'FeatureCollection',
        features: [
          ...this.path.map((point) => {

            const opacity = (oldestPointAge - point.age) / delta;

            const feature = {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [
                  [lastPoint.lng, lastPoint.lat],
                  [point.lng, point.lat],
                ],
              },
              properties: {
                opacity,
              },
            }

            lastPoint = point;

            return feature;
          }),
        ],
      };

      let pathColor = '#209CEE';

      if (this.map.getSource(this.mapLayerIds.devicePathPoints)) {
        this.map.getSource(this.mapLayerIds.devicePathPoints).setData(data);
      } else {
        this.map.addSource(this.mapLayerIds.devicePathPoints, {
          type: 'geojson',
          data,
        });
      }

      if (!this.map.getLayer(this.mapLayerIds.devicePathLineString)) {
        this.map.addLayer({
          id: this.mapLayerIds.devicePathLineString,
          source: this.mapLayerIds.devicePathPoints,
          type: 'line',
          paint: {
            'line-color': pathColor,
            'line-opacity': ['get', 'opacity'], //0.8,
            'line-width': 3,
          },
        });
      }

      if (!this.map.getLayer(this.mapLayerIds.devicePathPoints)) {
        this.map.addLayer({
          id: this.mapLayerIds.devicePathPoints,
          source: this.mapLayerIds.devicePathPoints,
          type: 'circle',
          paint: {
            'circle-color': pathColor,
            'circle-opacity': ['get', 'opacity'],
            'circle-pitch-alignment': 'map',
            'circle-radius': 6,
          },
        });
      }

    },
    loadMapContent() {
      this.loadDevicePath();
      this.loadDeviceMarker();
    },
    removeMapContent() {

      Object.values(this.mapLayerIds).forEach((layerId) => {

        if (this.map.getLayer(layerId)) {
          this.map.removeLayer(layerId);
        }

        if (this.map.getSource(layerId)) {
          this.map.removeSource(layerId);
        }

      });

    },
  },
  props: {
    device: {
      required: true,
    }
  },
  watch: {
    device: {
      deep: true,
      handler() {
        this.loadMapContent();
      },
    },
    lockOnDevice() {
      window.app.storage.setItem('deviceShowLockOnDevice', this.lockOnDevice);
    },
  }
}
</script>

<style scoped lang="scss">
.map-single-device {
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  .map-controls {
    right: 5px;
    position: absolute;
    top: 5px;
    z-index: 2;

    .button {
      margin-left: 5px;
    }
  }

  .mapboxgl-map {
    height: 350px;
  }
}
</style>
