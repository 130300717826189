import {
  Domain,
  DomainService,
  ResourceStore,
} from '@/internal';

const service = new DomainService();

const store = new ResourceStore({
  model: Domain,
  actions: {
    accountUpdate: ({commit}, payload) => {
      return service.accountUpdate(payload.id, payload);
    },
    planUpdate: ({commit}, payload) => {
      return service.planUpdate(payload.id, payload);
    },
    stripeOnboard: ({commit}, payload) => {
      return service.stripeOnboard(payload.id, payload);
    },
    subscription: ({commit}, payload) => {
      return service.subscription(payload.id, payload);
    },
  },
});

export default store;
