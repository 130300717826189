import {
  ResourceService,
} from '@/internal';

export default class ParameterService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'parameter';
  }

  chartData(payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'get',
      url: `parameter/chart-data`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }
}
