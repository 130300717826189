<template>
  <div class="box"
       :class="{
           'is-dark': $themeDark,
           'has-text-light': $themeDark,
           'is-black': black,
           'is-flat': flat,
         }"
       @click="$emit('click')"
  >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Box',
  props: {
    black: {
      default: false,
      type: Boolean,
    },
    flat: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style scoped lang="scss">
.box {
  &.is-black {
    background: $black;
  }

  &.is-flat {
    border: none;
  }
}
</style>
