export default {
  id: 'assets',
  group: 'map',
  enabled: true,
  label: 'Assets',
  cursorHover: 'pointer',
  source({store}) {

    const features = [];

    store.getters['asset/all'].forEach((asset) => {

      if (asset.geojson) {
        asset.geojson.properties.id = asset.uuid;
        features.push(asset.geojson);
      }

      if (asset.children) {
        asset.children.forEach((child) => {
          if (child.geojson) {
            child.geojson.properties.id = child.uuid;
            features.push(child.geojson);
          }
        });
      }

    });

    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    };
  },
  layout() {
    return {
      type: 'fill-extrusion',
      paint: {
        // 'fill-extrusion-color': [
        //   'interpolate',
        //   ['linear'],
        //   ['get', 'temperature'],
        //   0, '#cccccc',
        //   40, '#9D0000',
        // ],
        'fill-extrusion-color': '#666666',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-base': ['get','base'],
        'fill-extrusion-opacity': 0.5
      },
    };
  },
  onClick({e, router}) {

    const uuid = e.features[0].properties.id;

    let route = window.app.findRouteByName(`asset/edit`, [], {
      params: {
        'assetUUID': uuid
      }
    });

    router.push(route);
  },
};
