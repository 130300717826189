<template>
  <div id="report-form">

    <BLoading active v-if="loading"/>

    <div class="is-padded">

      <Field>
        <Label>Name *</Label>
        <TextInput v-model="form.fields.name"/>
        <FormError :form="form" field="name"/>
      </Field>

      <Field>
        <Label class="has-margin-bottom">Interval *</Label>
        <SelectInput v-model="form.fields.interval" :options="intervalOptions"/>
        <FormError :form="form" field="interval"/>
      </Field>

      <Field>
        <Label class="has-margin-bottom">Time *</Label>
        <BTimepicker v-model="form.fields.sendAtTime" placeholder="Select time" hour-format="12"
                     :class="{ 'is-dark': $themeDark }"/>
        <FormError :form="form" field="sendAtTime"/>
      </Field>

      <Field>
        <Label class="has-margin-bottom">Timezone *</Label>
        <TimezoneInput v-model="form.fields.sendAtTimezone"/>
        <FormError :form="form" field="sendAtTimezone"/>
      </Field>

      <hr/>

      <Label>Report Contents</Label>

      <Field>

        <div>
          <BRadio v-model="form.fields.config.devices" native-value="all">
            All Devices
          </BRadio>
        </div>
        <div class="has-margin-top">
          <BRadio v-model="form.fields.config.devices" native-value="specific">
            Specific Devices
          </BRadio>
        </div>

        <Box v-if="form.fields.config.devices === 'specific'" class="has-margin-top">
          <template v-for="device in devices">
            <div>
              <BCheckbox v-model="form.fields.config.deviceIds" :native-value="device.id">
                {{ device.name }}
              </BCheckbox>
            </div>
          </template>
        </Box>

        <div class="has-margin-top">
          <BCheckbox v-model="form.fields.config.deviceParameters" v-if="form.fields.config.devices">
            Include Parameters
          </BCheckbox>
        </div>

        <div class="has-margin-top">
          <BCheckbox v-model="form.fields.config.deviceAlarms" v-if="form.fields.config.devices">
            Include Alarms
          </BCheckbox>
        </div>

      </Field>

      <hr/>

      <Label>Send To</Label>

      <Field>
        <div v-for="user in users">
          <BCheckbox :native-value="user.id" v-model="form.fields.users">
            {{ user.fullName }} ({{ user.email }})
          </BCheckbox>
        </div>
      </Field>


      <Teleport to="#inspector-footer">
        <Controls>
          <template v-slot:left>
            <Button danger outlined @click="handleDelete" :loading="deleting" v-if="report">
              Delete
            </Button>
          </template>
          <template v-slot:right>
            <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
              Cancel
            </Button>
            <Button primary @click="handleSave" :loading="loading" class="has-margin-left">
              Save
            </Button>
          </template>
        </Controls>
      </Teleport>

    </div>

  </div>
</template>

<script>
import {Form, Report} from '@/internal';
import {mapActions} from 'vuex';

import moment from 'moment-timezone';

export default {
  name: 'ReportForm',
  computed: {
    defaultTime() {
      let defaultTime = new Date();

      defaultTime.setMinutes(0);

      return defaultTime;
    },
    devices() {
      return this.$store.getters['device/all'].sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    },
    report() {
      return this.$route.params.reportUUID ? this.$store.getters['report/findBy'](this.$route.params.reportUUID, 'uuid') : null;
    },
    timezone() {
      return this.$store.state.system.timezone || moment.tz.guess() || 'utc';
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    users() {
      return this.$store.getters['user/all'] || [];
    },
  },
  created() {
    if (this.$route.params.reportUUID) {
      this.loadReport();
    } else {
      this.form.fields.sendAtTime = this.defaultTime;
      this.form.fields.sendAtTimezone = this.timezone;
    }

    this.loadDevices();
    this.loadUsers();
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        name: '',
        sendAtTime: null,
        sendAtTimezone: null,
        interval: 'day',
        users: [],
        config: {
          deviceIds: [],
          devices: 'all',
          deviceAlarms: false,
          deviceParameters: false,
        },
      }),
      intervalOptions: [
        {
          label: 'Daily',
          value: 'day',
        },
        {
          label: 'Weekly',
          value: 'week',
        },
        {
          label: 'Monthly',
          value: 'month',
        },
        {
          label: 'Quarterly',
          value: 'quarter',
        }
      ],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      deviceIndex: 'device/index',
      reportDestroy: 'report/destroy',
      reportShow: 'report/show',
      reportStore: 'report/store',
      reportUpdate: 'report/update',
      reportIndex: 'report/index',
      accountShow: 'account/show'
    }),
    fillForm() {
      this.form.fields = {
        ...this.report,
        config: this.report.config ? this.report.config : {},
        sendAtTime: this.report.sendAtTime ? moment(this.report.sendAtTime, 'HH:mm').toDate() : this.defaultTime,
        users: this.report.users ? this.report.users.map(u => u.id) : [],
      };
    },
    handleCancel() {
      this.form.reset();
      this.$router.back();
    },
    handleDelete() {
      this.$deleteDialog({
        target: this.report.name,
        targetType: 'Report',
        onConfirm: () => {

          this.loading = true;
          this.reportDestroy({
            id: this.report.id,
          }).then(() => {
            this.$router.push({
              name: 'report/index',
            });
            window.app.snackbar('Report Deleted');
          }).finally(() => {
            this.loading = false;
          });

        },
      });
    },
    handleSave() {

      const fields = {
        ...this.form.fields,
      };

      this.loading = true;

      let action = null;

      if (this.report) {
        action = this.reportUpdate;
      } else {
        action = this.reportStore;
      }

      if (action) {
        action(Report.preparePayload(fields)).then(() => {
          window.app.snackbar('Report Saved');
          this.form.reset();
          this.$router.back();
        }).catch((error) => {

          this.form.recordErrors(error);

        }).finally(() => {
          this.loading = false;
        });
      }

    },
    loadDevices() {
      this.loading = true;
      return this.deviceIndex({
        perPage: -1,
      }).finally(() => {
        this.loading = false;
      });
    },
    loadReport() {
      this.loading = true;
      return this.reportShow({
        id: this.$route.params.reportUUID,
      }).then(() => {
        this.fillForm();
      }).catch(() => {
        window.app.snackbar('Error Loading Report');
      }).finally(() => {
        this.loading = false;
      });
    },
    loadUsers() {
      this.loading = true;
      this.accountShow({
        id: this.user.account.id,
        params: {
          include: ['users'],
        },
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  props: {},
};
</script>

<style lang="scss">

</style>
