<template>
  <div id="account-switch" class="is-padded">

    <BLoading active v-if="loading"/>

    <div class="is-padded-vertical" v-else>

      <Title>Accounts</Title>

      <div class="accounts">
        <template v-for="account in accounts">
          <Button outlined :dark="$themeLight" :light="$themeDark" block class="account has-margin-vertical" @click="handleAccountSwitch(account)">
            {{ account.name }}
          </Button>
        </template>
      </div>

      <Button primary block @click="handleAccountCreate">
        Create New Account
      </Button>

    </div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'Switch',
  computed: {
    accounts() {
      return this.user ? this.user.accounts.sort((a, b) => a.name > b.name ? 1 : -1) : [];
    },
    user() {
      return this.$store.getters['auth/user'] || null;
    },
  },
  created() {
    this.loadUser();
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      switchAccount: 'auth/switchAccount',
      userShow: 'auth/userShow',
    }),
    handleAccountCreate() {
      this.$router.push({
        name: 'account/create',
      });
    },
    handleAccountSwitch(account) {

      if (this.user.account.id === account.id) {
        window.app.snackbar('Already on that account');
        return;
      }

      this.loading = true;

      const payload = {
        accountId: account.id,
      };

      this.switchAccount(payload).then(() => {

        if (this.$route.name.includes('region')) {
          window.location.href = '/region';
        } else if (this.$route.name.includes('device')) {
          window.location.href = '/device';
        } else if (this.$route.name.includes('alarm')) {
          window.location.href = '/alarm';
        } else {
          window.location.href = '/';
        }
      });

    },
    loadUser() {
      this.loading = true;
      this.userShow({
        params: {
          include: [
            'accounts'
          ],
        },
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
#account-switch {
  .accounts {
    margin-bottom: 45px;
  }
}
</style>
