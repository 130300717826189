<template>
  <div id="converter-index" class="is-padded">
    <ConverterResourceList />
  </div>
</template>

<script>
import ConverterResourceList from '@/components-V2/Converter/ResourceList';

export default {
  name: 'Index',
  components: {
    ConverterResourceList,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
