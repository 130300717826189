import {
  ResourceService,
} from '@/internal';

export default class UsageService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'usage';
  }
}
