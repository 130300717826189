import Index from '@/views/Report/Index';
import Form from '@/views/Report/Form';

const routes = [
  {
    name: 'report/index',
    path: '/report',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'file',
      middleware: ['auth'],
      title: 'Reports',
    },
  },
  {
    name: 'report/create',
    path: '/report/create',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('report/index');
      },
      containerSize: 'container-extra-small',
      icon: 'vector-square',
      middleware: ['auth'],
      title: 'New Report',
    },
  },
  {
    name: 'report/show',
    path: '/report/:reportUUID',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('report/index');
      },
      containerSize: 'container-extra-small',
      icon: 'vector-square',
      middleware: ['auth'],
      title: 'Report',
    },
  },
  {
    name: 'report/edit',
    path: '/report/:reportUUID/edit',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('report/index');
      },
      containerSize: 'container-extra-small',
      icon: 'vector-square',
      middleware: ['auth'],
      title: 'Edit Report',
    },
  },
];

export default routes;
