import {
  ApiService,
} from '@/internal';

export default class SubscriptionService extends ApiService {

  simCredits (payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: '/subscription/sim-credits',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  start (payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: '/subscription/start',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  manage (payload = {}) {

    const { params } = payload;

    return this.request({
      method: 'post',
      url: '/subscription/manage',
      params: this.prepareParams(params),
    });
  }

}
