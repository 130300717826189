<template>
  <SelectInput @input="handleInput"
               :options="options"
               :value="value"
  />
</template>

<script>
import {parseBoolean} from '@/helpers';

export default {
  name: 'YesNoInput',
  computed: {},
  data() {
    return {
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value === 'true');
    },
  },
  props: {
    value: {}
  },
}
</script>

<style scoped lang="scss">

</style>
