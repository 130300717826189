<template>
  <router-link :to="`/function/${converter.uuid}`" class="converter resource-list-item" :class="{ 'is-dark': $themeDark }">
    {{converter.name}}
  </router-link>
</template>

<script>
import Preview from '@/components-V2/Converter/Preview';

export default {
  name: 'ResourceListItem',
  components: {
    Preview,
  },
  computed: {
    converter() {
      return this.resource;
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
