<template>
  <div id="domain-account-index" class="is-padded">
    <AccountResourceList :query="query" hideAddButton />
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import AccountResourceList from '@/components-V2/Account/ResourceList';

export default {
  name: 'Index',
  components: {
    AccountResourceList,
  },
  computed: {
    accounts() {
      return this.ids ? this.$store.getters['account/collection'](this.ids).sort((a, b) => a.name > b.name ? 1 : -1) : [];
    },
  },
  created() {
    this.query.domain = window.app.storage.getItem('domainBeingManaged');
  },
  data() {
    return {
      domainUUID: null,
      ids: [],
      loading: false,
      query: {},
    };
  },
  methods: {
    ...mapActions({
      accountIndex: 'account/index',
    }),
    loadAccounts() {
      this.loading = true;
      this.accountIndex().then((ids) => {
        this.ids = ids;
      }).finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style scoped lang="scss">

</style>
