<template>
  <div class="converter-resource-list">
    <ResourceList addLabel="Add Function"
                  :addRoute="addRoute"
                  :params="params"
                  :query="query"
                  :resource="resource"
                  :renderAs="renderAs"
                  :sortByOptions="sortByOptions"
    />
  </div>
</template>

<script>
import {Converter} from '@/internal';
import ConverterResourceListItem from '@/components-V2/Converter/ResourceListItem';

export default {
  name: 'ConverterResourceList',
  data() {
    return {
      addRoute: !this.hideAddButton ? window.app.findRouteByName('converter/create') : null,
      params: {},
      renderAs: ConverterResourceListItem,
      resource: Converter,
      sortByOptions: [
        {
          label: 'Name',
          value: 'name',
        },
        {
          label: 'Created Date',
          value: 'created_at',
        },
      ],
    };
  },
  props: {
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {},
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
