<template>
  <div class="is-padded">
    <DeviceResourceList :addRoute="addRoute"
                        ref="deviceResourceList"
    />
  </div>
</template>

<script>
import DeviceResourceList from '@/components-V2/Device/ResourceList';

export default {
  name: 'Index',
  components: {
    DeviceResourceList,
  },
  computed: {
    addRoute() {
      return window.app.findRouteByName('device/claim');
    },
    devices() {
      return this.$store.getters['device/all'].sort((a, b) => a.name > b.name ? 1 : -1) || [];
    },
  },
  created() {
    window.app.vue.$on('uplinkReceived', this.loadDevices);
  },
  destroyed() {
    window.app.vue.$off('uplinkReceived', this.loadDevices);
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    loadDevices() {
      return this.$refs.deviceResourceList.$refs.resourceList.loadData(null, true);
    },
  },
}
</script>

<style scoped lang="scss">

</style>
