<template>
    <ResourceList :params="params"
                  :query="query"
                  ref="resourceList"
                  :resource="resource"
                  :renderAs="renderAs"
                  :useRouter="false"
    />
</template>

<script>
import {Activity} from '@/internal';
import ActivityResourceListItem from '@/components-V2/Activity/ResourceListItem.vue';

export default {
  name: 'ActivityResourceList',
  data() {
    return {
      params: {
        has_activities_id: this.hasActivitiesId,
        has_activities_type: this.hasActivitiesType,
        include: [],
      },
      resource: Activity,
    };
  },
  props: {
    addRoute: {
      default() {
        return null;
      },
      required: false,
    },
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {
      },
      required: false,
    },
    renderAs: {
      default: () => {
        return ActivityResourceListItem;
      },
      required: false,
    },
    hasActivitiesId: {
      required: true,
    },
    hasActivitiesType: {
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
