import Show from '@/views/Trip/Show';

const routes = [
  {
    name: 'trip/show',
    path: '/trip/:tripUUID',
    component: Show,
    meta: {
      middleware: ['auth'],
      title: 'Trip',
    },
  },
];

export default routes;
