import {
  UplinkImport as Model,
  UplinkImportService,
  ResourceStore,
} from '@/internal';

const service = new UplinkImportService();

const store = new ResourceStore({
  model: Model,
  actions: {
    headers: ({commit}, payload) => {
      return service.headers(payload);
    },
    // delete ({commit}, payload = {}) {
    //   return service.delete(payload.id, payload).then((response) => {
    //     if (response && response.data && response.data.data) {
    //       return response.data.data;
    //     }
    //
    //     return null;
    //   });
    // }
  },
});

export default store.toObject();
