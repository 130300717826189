<template>
  <div class="password-input" :class="{ 'has-text-light': $themeDark }">
    <TextInput :type="type" :value="value" @input="handleInput" :placeholder="placeholder" />
    <div class="toggle-visibility" @click="handleToggleVisibility">
      <BIcon pack="fal" :icon="type === 'password' ? 'eye-slash' : 'eye'" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordInput',
  data() {
    return {
      type: 'password',
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    handleToggleVisibility() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
  },
  props: {
    placeholder: {
      default: '',
      required: false,
    },
    value: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">
.password-input {
  position: relative;

  .toggle-visibility {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
