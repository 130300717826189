import ForgotPassword from '../../views/Auth/ForgotPassword';
import Login from '../../views/Auth/Login';
import ResetPassword from '../../views/Auth/ResetPassword';
import SignUp from '../../views/Auth/SignUp';

const routes = [
  {
    name: 'auth/login',
    path: '/auth/login',
    component: Login,
    meta: {
      title: 'Log In',
    },
  },
  {
    name: 'auth/sign-up',
    path: '/auth/sign-up',
    component: SignUp,
    meta: {
      middleware: ['guest'],
      title: 'Sign Up',
    },
  },
  {
    name: 'auth/forgot-password',
    path: '/auth/forgot-password',
    component: ForgotPassword,
    meta: {
      middleware: ['guest'],
      title: 'Forgot Password',
    },
  },
  {
    name: 'auth/reset-password',
    path: '/auth/reset-password/:resetToken',
    component: ResetPassword,
    meta: {
      middleware: ['guest'],
      title: 'Reset Password',
    },
  },
];

export default routes;
