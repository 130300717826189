<template>
  <div class="color-input">
    <div class="color-swatch"
         :class="{
                pointer: !disabled,
             }"
         :style="`background: ${localValue.hex};`"
         @click="openColorPicker"
    />
    <div class="color-input-widget" v-if="active">
      <div v-click-outside="closeColorPicker">
        <Sketch v-model="localValue"/>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import {Sketch} from 'vue-color';

export default {
  name: 'ColorInput',
  components: {
    Sketch,
  },
  created() {
    if (this.value) {
      this.localValue.hex = this.value;
    }
  },
  data() {
    return {
      active: false,
      localValue: {},
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    closeColorPicker() {
      this.active = false;
    },
    openColorPicker() {
      if (this.disabled) {
        return;
      }

      this.active = true;
    },
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
    }
  },
  watch: {
    localValue() {
      if (this.value !== this.localValue.hex) {
        this.$emit('input', this.localValue.hex);
      }
    },
    value() {
      if (this.value !== this.localValue.hex) {
        this.localValue = {
          hex: this.value,
        };
      }
    },
  }
};
</script>

<style scoped lang="scss">
$size: 16px;

.color-input {
  display: flex;
  position: relative;

  .color-swatch {
    border: 0.5px solid #444;
    border-radius: 3px;
    height: $size;
    width: $size;
    z-index: 10;
  }

  .color-input-widget {
    position: absolute;
    left: $size + 2;
    top: 0;
    z-index: 50;
  }
}
</style>
