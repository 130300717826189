<template>
  <div class="subtitle" :class="{ 'has-text-light': $themeDark || light }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Subtitle',
  props: {
    light: {
      default: false,
      type: Boolean,
    }
  }
}
</script>

<style scoped lang="scss">

</style>
