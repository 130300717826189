import Index from '@/views/Device/Index';
import Show from '@/views/Device/Show';
import Form from '@/views/Device/Form';
import Claim from '@/views/Device/Claim';

import MeatCacheDeviceForm from '@/_instances/MeatCache/views/Device/Form.vue';

import HarmonyAnalyticaDeviceForm from '@/_instances/HarmonyAnalytica/views/Device/Form';
import HarmonyAnalyticaDeviceIndex from '@/_instances/HarmonyAnalytica/views/Device/Index';
import HarmonyAnalyticaDeviceShow from '@/_instances/HarmonyAnalytica/views/Device/Show';
import {isHarmonyAnalytica, isLakeStreet, isMeatCache} from '@/helpers';

let editRoute;
let indexRoute;
let showRoute;

if (isMeatCache() || isLakeStreet()) {

  indexRoute = {
    name: 'device/index',
    path: '/device',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'router',
      middleware: ['auth'],
      title: 'Devices',
    },
  };

  editRoute = {
    name: 'device/edit',
    path: '/device/:deviceUUID/edit',
    component: MeatCacheDeviceForm,
    meta: {
      containerSize: 'container-extra-small',
      icon: 'router',
      middleware: ['auth'],
      title: 'Edit Device',
    },
  };

  showRoute = {
    name: 'device/show',
    path: '/device/:deviceUUID',
    component: Show,
    meta: {
      backRoute: (route) => {
        return window.app.findRouteByName('device/index');
      },
      containerSize: 'container-extra-small',
      icon: 'router',
      middleware: ['auth'],
      title: 'Device',
      editRoute: (route) => {
        return window.app.findRouteByName('device/edit', {
          deviceUUID: route.params.deviceUUID,
        });
      },
    },
  };

} else if (isHarmonyAnalytica()) {

  indexRoute = {
    name: 'device/index',
    path: '/device',
    component: HarmonyAnalyticaDeviceIndex,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      hideMap: true,
      icon: 'router',
      middleware: ['auth'],
      title: 'Dashboard',
    },
  };

  editRoute = {
    name: 'device/edit',
    path: '/device/:deviceUUID/edit',
    component: HarmonyAnalyticaDeviceForm,
    meta: {
      backRoute: (route) => {
        return window.app.findRouteByName('device/show', {
          deviceUUID: route.params.deviceUUID,
        });
      },
      containerSize: 'container-extra-small',
      icon: 'router',
      middleware: ['auth'],
      title: 'Edit Device',
    },
  };

  showRoute = {
    name: 'device/show',
    path: '/device/:deviceUUID',
    component: HarmonyAnalyticaDeviceShow,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('home');
      },
      containerSize: 'container-extra-small',
      icon: 'router',
      middleware: ['auth'],
      title: 'Device',
    },
  };

} else {

  indexRoute = {
    name: 'device/index',
    path: '/device',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'router',
      middleware: ['auth'],
      title: 'Devices',
    },
  };

  editRoute = {
    name: 'device/edit',
    path: '/device/:deviceUUID/edit',
    component: Form,
    meta: {
      backRoute: (route) => {
        return window.app.findRouteByName('device/show', {
          deviceUUID: route.params.deviceUUID,
        });
      },
      containerSize: 'container-extra-small',
      icon: 'router',
      middleware: ['auth'],
      title: 'Edit Device',
    },
  };

  showRoute = {
    name: 'device/show',
    path: '/device/:deviceUUID',
    component: Show,
    meta: {
      backRoute: (route) => {
        return window.app.findRouteByName('device/index');
      },
      containerSize: 'container-extra-small',
      icon: 'router',
      middleware: ['auth'],
      title: 'Device',
      editRoute: (route) => {
        return window.app.findRouteByName('device/edit', {
          deviceUUID: route.params.deviceUUID,
        });
      },
    },
  };

}

const routes = [
  {
    ...indexRoute,
  },
  {
    name: 'device/create',
    path: '/device/create',
    component: Form,
    meta: {
      containerSize: 'container-extra-small',
      icon: 'router',
      middleware: ['auth'],
      title: 'New Device',
    },
  },
  {
    name: 'device/claim',
    path: '/device/claim',
    component: Claim,
    meta: {
      containerSize: 'container-extra-extra-small',
      icon: 'router',
      middleware: ['auth'],
      title: 'Claim Device',
    },
  },
  {
    ...showRoute,
  },
  {
    ...editRoute,
  }
];

export default routes;
