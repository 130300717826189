import Show from '@/views/QRCode/Show.vue';

const routes = [
  {
    name: 'qr-code/show',
    path: '/qr-code/:qrCodeKey',
    component: Show,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('home');
      },
      containerSize: 'container-extra-extra-small',
      fullScreen: true,
      icon: 'qrcode',
      middleware: ['auth'],
      title: 'QR Code',
    },
  },
];

export default routes;
