import {
  ResourceService,
} from '@/internal';

export default class NoteService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'note';
  }

}
