<template>
  <div id="domain-analytics" class="is-padded">

    <BLoading active v-if="loading"/>

    <Field>
      <Label>Provider</Label>
      <SelectInput :options="analyticsProviderOptions" v-model="form.fields.analyticsProvider"/>
    </Field>

    <template v-if="form.fields.analyticsProvider === 'fathom'">

      <Field>
        <Label>Fathom Site ID</Label>
        <TextInput v-model="form.fields.analyticsConfig.fathomSiteId" placeholder="ABCDEFGH"/>
        <FormError field="fathomSiteId" :form="form"/>
      </Field>

    </template>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:right>

          <Button @click="$router.back()" outlined light>
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';
import {Form} from '@/internal';

export default {
  name: 'Analytics',
  computed: {
    baseUrl() {
      return this.domain && this.domain.url ? this.domain.url.replace('https://', '').replace('http://', '') : '';
    },
    domain() {
      return this.domainUUID ? this.$store.getters['domain/findBy'](this.domainUUID, 'uuid') : null;
    },
  },
  created() {
    this.domainUUID = window.app.storage.getItem('domainBeingManaged');
    this.loadDomain();
  },
  data() {
    return {
      analyticsProviderOptions: [
        {
          label: '- Select a Provider -',
          value: null,
        },
        {
          label: 'Fathom Analytics',
          value: 'fathom',
        },
        // {
        //   label: 'Google Analytics',
        //   value: 'google',
        // },
      ],
      domainUUID: null,
      form: new Form({
        analyticsProvider: null,
        analyticsConfig: {},
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      domainShow: 'domain/show',
      domainUpdate: 'domain/update',
    }),
    handleSave() {

      this.loading = true;
      this.form.errors.clear();

      this.domainUpdate({
        ...this.domain,
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar('Domain Saved');
      }).catch((error) => {
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    fillForm() {

      if (!this.domain) {
        return;
      }

      this.form.fields = {
        analyticsProvider: this.domain.analyticsProvider,
        analyticsConfig: this.domain.analyticsConfig,
        fathomSiteId: this.domain.fathomSiteId,
        fathomUrl: this.domain.fathomUrl,
      };
    },
    loadDomain() {

      if (!this.domainUUID) {
        console.error('No domainUUID');
        return;
      }

      this.loading = true;

      return this.domainShow({
        id: this.domainUUID,
      }).then(() => {
        this.fillForm();
      }).catch((e) => {
        console.error(e);
        window.app.snackbar('Error loading Domain');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
