<template>
  <div id="ui">
    <BLoading active v-if="loading"/>

    <template v-else>

      <template v-if="authenticated">

        <PrimaryMenu/>

        <div id="content">
          <Map v-if="showMap"/>
          <Inspector v-if="showInspector"
                     :fullScreen="inspectorFullScreen"
                     :hideControls="hideInspectorControls"
          >
            <router-view/>
          </Inspector>
        </div>

        <MobileTabs v-if="isMobile"/>

      </template>

      <template v-else>
        <Navbar/>
        <div id="content" class="has-navbar-fixed">
          <router-view/>
        </div>
      </template>

    </template>

  </div>

</template>

<script>
import Map from '@/components-V2/Map/Map.vue';
import MobileTabs from '@/components-V2/MobileTabs/MobileTabs.vue';
import Navbar from '@/components/Navigation/Navbar.vue';
import PrimaryMenu from '@/components/Navigation/PrimaryMenu.vue';

export default {
  name: 'DefaultUI',
  components: {
    Map,
    MobileTabs,
    Navbar,
    PrimaryMenu,
  },
  computed: {
    authenticated() {
      return this.$store.state && this.$store.state.auth && this.$store.state.auth.authenticated;
    },
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    hideInspectorControls() {
      return this.$route.meta && this.$route.meta.hideInspectorControls;
    },
    inspectorFullScreen() {
      if (this.domain && this.domain.features && this.domain.features.mapUi) {
        return !this.domain.features.mapUi.enabled;
      }

      return false;
    },
    isManagingDomain() {
      return window.location.origin.includes(process.env.VUE_APP_DOMAIN_MANAGEMENT_URL);
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    showInspector() {

      if (this.$route.meta) {
        return !this.$route.meta.hideInspector;
      }

      return true;
    },
    showMap() {

      if (this.isManagingDomain) {
        return false;
      }

      if (this.domain && this.domain.features && this.domain.features.mapUi) {
        return this.domain.features.mapUi.enabled;
      }

      if (this.$route.meta) {
        return !this.$route.meta.hideMap;
      }

      return true;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style scoped lang="scss">
#ui {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
}
</style>
