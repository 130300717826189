<template>
    <div id="verifier">
        <Field>
            <div class="is-pulled-right">
                <div class="button is-outlined is-small"
                     :class="{ 'is-light': $themeDark, 'is-loading': generatingCode }"
                     @click="handleGenerateVerificationCode"
                     style="margin-bottom: 5px; border: none;"
                     v-if="authenticated && resendLabel"
                     :disabled="resendDisabled"
                >
                    {{ resendLabel }}
                </div>
            </div>
            <Label>Verification Code *</Label>
            <TextInput :value="value" @input="handleInput" class="verifier-input" maxlength="6" placeholder="000000" />
            <FormError field="verificationCode" :form="form"/>
        </Field>
        <div class="controls" v-if="showControls">
            <Button block primary @click="$emit('verify')" :loading="loading">Verify</Button>
            <Button block outlined class="has-margin-top" @click="handleCancel" v-if="cancelable">Cancel</Button>
        </div>
    </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import moment from 'moment';

export default {
  name: 'Verifier',
  computed: {
    authenticated() {
      return this.$store.state.auth.authenticated;
    },
    resendDisabled() {
      return this.resendAt !== null;
    },
  },
  created() {
    this.timer = setInterval(() => {
      if (this.resendAt !== null && this.resendAt.isBefore(moment())) {
        this.resendAt = null;
      }

      this.resendLabel = this.resendDisabled ? `Resend in ${this.resendAt.diff(moment(), 'second')} seconds` : 'Resend Code';

    }, 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  data() {
    return {
      generatingCode: false,
      resendAt: moment().add(15, 'second'),
      resendLabel: null,
      timer: null,
    };
  },
  methods: {
    ...mapActions({
      destroyVerificationCode: 'verificationCode/destroy',
      generateVerificationCode: 'verificationCode/store',
    }),
    handleCancel() {
      this.destroyVerificationCode({
        uuid: this.form.fields.verificationUUID,
      }).then(() => {
        this.$emit('cancel');
      });
    },
    handleGenerateVerificationCode() {
      if (this.resendDisabled) {
        return;
      }

      this.generatingCode = true;
      this.generateVerificationCode({
        channel: this.channel,
        connectionString: this.connectionString,
        reason: this.reason,
      }).then((response) => {
        window.app.snackbar({
          message: this.connectionString ? `Verification Code sent to ${this.connectionString}` : 'Verification Code Sent',
        });
        this.form.errors.clear();
        this.$emit('uuid', response.data.data.uuid);
        this.$emit('input', '');
        this.resendAt = moment().add(15, 'second');
      }).finally(() => {
        this.generatingCode = false;
      });
    },
    handleInput(value) {
      this.$emit('input', value);
    },
  },
  props: {
    showControls: {
      require: false,
      type: Boolean,
    },
    cancelable: {
      default: true,
      type: Boolean,
    },
    channel: {
      required: false,
      type: String,
    },
    connectionString: {
      required: false,
      type: String,
    },
    form: {
      required: true,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    reason: {
      required: false,
      type: String,
    },
    value: {
      required: true,
    }
  },
}
</script>

<style lang="scss">
#verifier {
    .verifier-input input {
        font-size: 2em;
        letter-spacing: 30px;
        padding-left: 30px;
        padding-right: 0;
        text-align: center;
    }
}
</style>
