import {
  ResourceService,
} from '@/internal';

export default class QRCodeService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'qr-code';
  }
}
