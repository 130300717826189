<template>
  <div id="asset-form" class="is-padded">

    <Section title="Basics" open>

      <Field>
        <Label>Name *</Label>
        <TextInput v-model="form.fields.name" placeholder="Name"/>
        <FormError field="name" :form="form"/>
      </Field>

      <Field>
        <Label>Tags</Label>
        <TagInput v-model="form.fields.tags" :max="null" />
      </Field>

      <Field v-if="parameters.length">
        <Label>Parameters</Label>
        <ul>
          <template v-for="parameter in parameters">
            <li class="parameter">{{parameter.title}}</li>
          </template>
        </ul>
      </Field>

    </Section>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>

          <Button danger outlined @click="handleDelete" class="is-pulled-left" :loading="deleting" v-if="asset">
            Delete
          </Button>

        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
  Device,
} from '@/internal';

export default {
  name: 'Form',
  components: {},
  computed: {
    asset() {
      return this.$route.params.assetUUID ? this.$store.getters['asset/findBy'](this.$route.params.assetUUID, 'uuid') : null;
    },
    device() {
      return this.deviceId ? this.$store.getters['device/show'](this.deviceId) : null;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    parameters() {
      return this.asset ? this.asset.parameters : [];
    },
    pageTitle() {
      return this.$label('Assets', true);
    },
  },
  created() {
    if (this.$route.params.assetUUID) {
      this.loadAsset();
    }
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        name: '',
        rendering: 'none',
        tags: [],
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      assetDestroy: 'asset/destroy',
      assetIndex: 'asset/index',
      assetShow: 'asset/show',
      assetStore: 'asset/store',
      assetUpdate: 'asset/update',
    }),
    handleDelete() {
      this.$deleteDialog({
        target: this.asset.name,
        targetType: 'asset',
        onConfirm: () => {
          this.deleting = true;
          this.assetDestroy({
            id: this.asset.id,
          }).then(() => {
            window.app.snackbar('Asset Deleted');
            this.$router.push(window.app.findRouteByName('asset/index'));
          }).finally(() => {
            this.deleting = false;
          });
        },
      })
    },
    handleSave() {

      const fields = {
        ...this.form.fields,
      };

      this.form.errors.clear();

      let action = null;

      if (this.asset) {
        action = this.assetUpdate;
      } else {
        action = this.assetStore;
      }

      if (action) {
        this.loading = true;

        action(fields).then(() => {
          window.app.snackbar('Asset Saved');
          this.$router.back();
        }).catch((error) => {
          this.form.recordErrors(error);
        }).finally(() => {
          this.loading = false;
        });
      }

    },
    fillForm() {

      this.form.fields = {
        ...this.asset,
        tags: this.asset.tags,
      };

    },
    loadAsset() {
      this.loading = true;
      return this.assetShow({
        id: this.$route.params.assetUUID,
        params: {
          include: [
            'parameters',
            'tags',
          ],
        },
      }).then(this.fillForm).catch((error) => {
        window.app.snackbar('Error loading asset');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>

<style scoped lang="scss">

</style>
