import {
  Model,
  UplinkImportService as Service,
} from '@/internal';

export default class UplinkImport extends Model {

  static get key() {
    return 'uplinkImport';
  }

  static get service() {
    return Service;
  }
}
