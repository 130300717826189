<template>
  <div class="how-to-add-an-uplink">

    <Label>1. Create an API Key</Label>
    <p>
      If you don't have one yet or you'd like to use a new one for this integration, you'll need to <router-link to="/api-key/create">create an API Key</router-link>.
    </p>

    <Label>2. Send an Uplink</Label>
    <p>
      Wherever you route your Uplinks/packets from, you'll need to create an integration that sends a <code>POST</code> request.
    </p>

    <p>
      HTTP Request: <code>POST</code><br/>
      Endpoint URL: <code>{{ uplinkEndpoint }}</code> <a @click="handleCopy(uplinkEndpoint)"><BIcon icon="copy"/></a><br/>
    </p>

    <p>
      HTTP Headers<br/>
      Authorization: <code>Bearer {{ apiKey }}</code> <a v-if="apiKeys.length" @click="handleCopy(`Bearer ${apiKey}`)"><BIcon icon="copy"/></a><br/>
    </p>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import copy from 'copy-to-clipboard';

export default {
  name: 'HowToAddAnUplink',
  computed: {
    apiKey () {
      return this.apiKeys.length ? this.apiKeys[0].uuid : 'putYourApiKeyHere';
    },
    apiKeys () {
      return this.$store.getters['apiKey/all'];
    },
    uplinkEndpoint() {
      return `${window.app.env.apiBaseUrl}/v1/uplink`;
    },
  },
  created() {
    this.apiKeyIndex();
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      apiKeyIndex: 'apiKey/index',
    }),
    handleCopy (text) {
      copy(text);
      window.app.snackbar('Copied to clipboard');
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
