<template>
  <div class="ui-layout-input">
    <SelectInput :options="options" :value="value" @input="handleInput" />
  </div>
</template>

<script>
export default {
  name: 'UILayoutInput',
  computed: {},
  data() {
    return {
      options: [
        {
          label: '- Select a Layout -',
          value: null,
        },
        {
          label: 'Floating',
          value: 'floating',
        },
        {
          label: 'Anchored',
          value: 'anchored',
        },
      ]
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    }
  },
  props: {
    value: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
</style>
