import {
  ResourceService,
} from '@/internal';
import axios from 'axios';

export default class UplinkService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'uplink';
  }

  convert(payload = {}) {
    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: this.baseUrl + `/convert`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  async raw(id, payload = {}) {

    const { params, ...rest } = payload;
    const baseUrl = window.app.env.apiBaseUrl || '';

    let token = await window.app.auth.getToken();

    return axios.request({
      headers: {
        Authorization: token ? `Bearer ${token}` : null,
      },
      method: 'get',
      url:`${baseUrl}/uplink/${id}/raw`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }
}
