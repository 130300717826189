<template>
  <div style="min-height: 650px">
    <BLoading active v-if="loading" :isFullPage="false"/>

    <Box flat>

      <Title>Export uplinks</Title>

      <Field>
        <Label>From *</Label>
        <BDatepicker
          v-model="form.fields.from"
          placeholder="From"
          icon="calendar"
          :icon-right="form.fields.from ? 'close-circle' : ''"
          icon-right-clickable
          @icon-right-click="clearDateFrom"
          trap-focus>
        </BDatepicker>
      </Field>

      <Field>
        <Label>To *</Label>
        <BDatepicker
          v-model="form.fields.to"
          placeholder="To"
          icon="calendar"
          position="is-bottom-left"
          :icon-right="form.fields.to ? 'close-circle' : ''"
          icon-right-clickable
          @icon-right-click="clearDateTo"
          trap-focus>
        </BDatepicker>
      </Field>

      <div class="controls has-text-right">
        <Button primary @click="handleExport">Export</Button>
      </div>

    </Box>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

import moment from 'moment';

export default {
  name: 'Form',
  components: {
    open() {
      return this.show;
    }
  },
  data() {
    return {
      form: new Form({
        deviceId: this.deviceId,
        from: moment().subtract(1, 'months').toDate(),
        to: moment().toDate()
      }),
      loading: false
    };
  },
  methods: {
    ...mapActions({
      uplinkExport: 'uplinkExport/store',
    }),
    clearDateFrom() {
      this.form.fields.from = null
    },
    clearDateTo() {
      this.form.fields.to = null
    },
    handleExport() {
      this.loading = true;

      return this.uplinkExport({
        ...this.form.fields,
        device_id: this.deviceId,
        from: this.form.fields.from ? moment(this.form.fields.from).format('YYYY-MM-DD') : null,
        to: this.form.fields.to ? moment(this.form.fields.to).format('YYYY-MM-DD') : null
      }).then(() => {

        window.app.snackbar({
          message: 'Export Queued',
          type: 'is-success',
        });

        this.$emit('success');

      }).catch((e) => {
        if (e.response && e.response.data) {
          window.app.snackbar({
            message: e.response.data.message,
            type: 'is-danger',
          });
        }
      }).finally(() => {
        this.loading = false;
      });

    },
  },
  props: {
    deviceId: {
      required: true
    }
  },
};
</script>

<style lang="scss">

</style>
