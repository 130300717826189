<template>
  <div id="device-share" class="is-padded">

    <BLoading active v-if="loading"/>

    <div class="container container-extra-small">

      <template v-if="notPublic">
        <div class="has-text-centered">

          <Box black flat>

            <div class="is-padded">
              <BIcon icon="warning" custom-class="fa-4x"/>
            </div>
            <Title class="has-margin-top">Access Denied</Title>

            <div>
              WHAM! You hit a wall, dawg. The owner of this device has decided to keep this data stream and details
              private. No public viewing. If you think this is an error, go ahead and contact the site owner. Rock on!
            </div>

          </Box>

        </div>
      </template>

      <template v-if="device">

        <Title class="is-hidden-mobile">{{device.name}}</Title>

        <Instruction>Bookmark this page to keep track of this Device.</Instruction>

        <Box flat :black="$themeDark">

          <div class="columns">

            <div class="column">
              <div class="tags">
                <div class="tag is-info" v-if="device.is_virtual">VIRTUAL</div>
                <StatusTag :device="device"/>
              </div>
            </div>

            <div class="column has-text-right" v-if="latestUplinkAt">
              <a :href="csvUrl" target="_blank" class="button is-small is-outlined" :class="{ 'is-light': $themeDark }">
                Download CSV
              </a>
            </div>

          </div>

          <MapSingleDevice class="has-margin-bottom" :device="device" v-if="position"/>

          <template v-for="parameter in device.state">
            <LabelLineValue :label="parameter.name"
                            :value="parameter.latest_parameter_value ? parameter.latest_parameter_value.formattedValue : 'No Data'"/>
          </template>

          <LabelLineValue label="Last Uplink" :value="latestUplinkAt || 'None Yet'"/>

        </Box>

      </template>

      <!--      <JSON :deep="1" :data="device"/>-->

    </div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import moment from 'moment-timezone';

import MapSingleDevice from '@/components-V2/Map/MapSingleDevice';
import StatusTag from '@/components-V2/Device/StatusTag';

export default {
  name: 'Share',
  components: {
    MapSingleDevice,
    StatusTag,
  },
  computed: {
    csvUrl() {
      return this.device ? `${window.app.env.apiBaseUrl}/device/${this.device.uuid}/csv` : null;
    },
    latestUplinkAt() {
      return this.device && this.device.latest_uplink ? moment(this.device.latest_uplink.created_at).format('lll z') : null;
    },
    position() {
      return this.device && this.device.latest_uplink ? this.device.latest_uplink.position : null;
    },
    notPublic() {
      return Array.isArray(this.errors) ? this.errors.map(error => error.key).includes('device_not_public') : true;
    }
  },
  created() {
    this.loadDevice();
    window.app.vue.$on('UplinkReceivedPublic', this.handleReloadInBackground);

    this.mobileHeader();
  },
  destroyed() {
    window.app.vue.$off('UplinkReceivedPublic', this.handleReloadInBackground);
  },
  data() {
    return {
      device: null,
      errors: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      deviceShare: 'device/share',
    }),
    handleReloadInBackground() {
      this.loadDevice(true);
    },
    loadDevice(inBackground = false) {

      if (!inBackground) {
        this.device = null;
        this.loading = true;
      }

      this.errors = [];

      this.deviceShare({
        id: this.$route.params.deviceUUID,
      }).then((response) => {
        this.device = response.data.data;
      }).catch((e) => {
        this.device = null;
        this.errors = e.response.data.errors;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">
.page {
  padding: 10px;
}
</style>
