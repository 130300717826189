<template>
  <div id="rule-show" class="is-padded" v-if="rule">
    <RulePreview :rule="rule"/>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import RulePreview from '@/components-V2/Rule/Preview';

export default {
  name: 'Show',
  components: {
    RulePreview,
  },
  computed: {
    fullPath() {
      return this.$route.fullPath;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    pageTitle() {
      return `Rule: ${this.rule ? this.rule.name : ''}`;
    },
    rule() {
      return this.$route.params.ruleUUID ? this.$store.getters['rule/findBy'](this.$route.params.ruleUUID, 'uuid') : null;
    },
  },
  created() {
    this.loadRule();
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      show: 'rule/show'
    }),
    handleEdit() {
      const route = window.app.findRouteByName('rule/edit', [], {
        params: {
          ruleUUID: this.rule.uuid
        },
      });

      this.$router.push(route);
    },
    loadRule() {
      this.loading = true;
      return this.show({
        id: this.$route.params.ruleUUID,
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    fullPath() {
      this.loadRule();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
