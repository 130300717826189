import Channels from '@/views/My/Channels';
import Notifications from '@/views/My/Notifications';
import Profile from '@/views/My/Profile';
import Password from '@/views/My/Password';
import TwoFactor from '@/views/My/TwoFactor';

const routes = [
  {
    name: 'profile',
    path: '/my/profile',
    component: Profile,
    meta: {
      containerSize: 'container-extra-small',
      icon: 'user-circle',
      middleware: ['auth'],
      title: 'Profile',
    },
  },
  {
    name: 'password',
    path: '/my/password',
    component: Password,
    meta: {
      containerSize: 'container-extra-small',
      icon: 'key-skeleton',
      middleware: ['auth'],
      title: 'Password',
    },
  },
  {
    name: 'two-factor',
    path: '/my/two-factor',
    component: TwoFactor,
    meta: {
      containerSize: 'container-extra-small',
      icon: 'lock',
      middleware: ['auth'],
      title: 'Two Factor',
    },
  },
  {
    name: 'channels',
    path: '/my/channels',
    component: Channels,
    meta: {
      containerSize: 'container-extra-small',
      icon: 'mailbox',
      middleware: ['auth'],
      title: 'Channels',
    },
  },
  {
    name: 'notifications',
    path: '/my/notifications',
    component: Notifications,
    meta: {
      containerSize: 'container-extra-small',
      icon: 'bell',
      middleware: ['auth'],
      title: 'Notifications',
    },
  },
];

export default routes;
