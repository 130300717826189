<template>
  <div class="resource-list-paginator" :class="{ 'is-mobile': isMobile }" v-if="paginator">
    <div class="left">
      {{ paginator.count }} {{ $pluralize('Result', paginator.count) }} | Page {{ paginator.currentPage }} of
      {{ paginator.totalPage }}
    </div>
    <div class="right">
      <div class="resource-list-paginator-controls">
        <Button :light="$themeLight" :dark="$themeDark" @click="handlePrevious">
          <BIcon icon="chevron-left"/>
        </Button>
        <Button :light="$themeLight" :dark="$themeDark" @click="handleNext">
          <BIcon icon="chevron-right"/>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResourceListPaginator',
  computed: {
    hasMorePages() {
      return this.paginator.totalPage > this.paginator.currentPage;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
  },
  data() {
    return {};
  },
  methods: {
    handleNext() {
      if (this.hasMorePages) {
        this.$emit('next');
      }
    },
    handlePrevious() {
      if (this.paginator.currentPage > 1) {
        this.$emit('previous');
      }
    },
  },
  props: {
    paginator: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">
.resource-list-paginator {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  &.is-mobile {
    font-size: 0.8em;
  }

  .left {
    flex: 1;
    padding:  0 15px;
  }

  .right {
    text-align: right;

    .resource-list-paginator-controls {
      .button {
        background: none;
        border: none;
        margin-left: 7.5px;

        &:hover {
          background: $grey-dark;
        }
      }
    }
  }
}
</style>
