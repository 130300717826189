<template>
  <div class="ue-section">
    <BLoading active v-if="loading" :isFullPage="false"/>
    <div class="ue-section-header" @click="handleToggle" :class="{ pointer: collapsable }">
      <div class="ue-section-title">{{ title }}</div>
      <div class="ue-section-header-toggle" v-if="collapsable">
        <BIcon :icon="show ? 'minus' : 'plus'" v-if="!loading"/>
      </div>
    </div>
    <div v-if="show" class="ue-section-content">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Section',
  computed: {},
  created() {
    this.show = this.open;
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    close() {
      this.show = false;
    },
    handleToggle() {
      if (this.collapsable) {
        this.$emit(this.show ? 'closed' : 'opened');
        this.show = !this.show;
      }
    },
  },
  props: {
    collapsable: {
      default: true,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    open: {
      default: false,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ue-section {

  border-top: 1px solid $navigation-border-color;
  padding: 15px 0;
  position: relative;

  .loading-icon {
    font-size: 0.5em;
  }

  &:first-child {
    border-top: none;
  }

  .ue-section-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: -15px;
    padding: 15px;

    .ue-section-title {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .ue-section-content {
    padding: 15px 0;
  }
}
</style>
