<template>
  <div class="integration-preview">
    <Subtitle>{{integration.name}}</Subtitle>
  </div>
</template>

<script>
export default {
  name: 'Preview',
  props: {
    integration: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
