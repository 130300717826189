<template>
  <div class="color-input">
    <ColorPicker ref="colorPicker" :value="value" @input="handleInput" />
    <span class="is-flex-grow-1" @click="handleTextInputClick">
      <TextInput :value="value"  disabled/>
    </span>
  </div>
</template>

<script>
import ColorPicker from '@/components-V2/Inputs/ColorInput';

export default {
  name: 'ColorInput',
  components: {
    ColorPicker,
  },
  data () {
    return {};
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value);
    },
    handleTextInputClick () {
      this.$refs.colorPicker.openColorPicker();
    },
  },
  props: {
    value: {
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .color-input {
    display: flex;

    & > .color-input {
      .color-swatch {
        border: none;
        border-radius: 3px 0 0 3px;
        height: 100%;
        width: 40px;
      }
    }

    .input {
      border-radius: 0 3px 3px 0;
    }
  }
</style>
