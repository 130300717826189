<template>
  <div class="select is-fullwidth" :class="{ 'is-dark': $themeDark }">
    <select :value="value" @input="handleInput($event.target.value)" :disabled="disabled">
      <template v-for="option in options">
        <option :value="option.value">{{ option.label }}</option>
      </template>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectInput',
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
  props: {
    options: {
      required: true,
      type: Array,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    value: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">
select[disabled] {
  color: $grey;
}
</style>
