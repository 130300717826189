<template>
  <div id="dashboard-form" class="is-padded">

    <BLoading active v-if="loading"/>

    <Field>
      <Label>Name *</Label>
      <TextInput v-model="form.fields.name"/>
      <FormError :form="form" field="name"/>
    </Field>

    <div class="columns">
      <div class="column">
        <Field>
          <Label>Widgets</Label>
          <RepeaterWidgetInput v-model="form.fields.widgets" :devices="devices" :form="form" addLabel="Add Widget"/>
        </Field>
      </div>
    </div>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>
          <Button danger outlined @click="handleDelete" :loading="deleting" v-if="dashboard">
            Delete
          </Button>
        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button primary @click="handleSave" :loading="loading" class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import { Form, Dashboard } from '@/internal';
import { mapActions } from 'vuex';

import RepeaterWidgetInput from '@/components/Inputs/RepeaterWidgetInput';


export default {
  name: 'DashboardForm',
  components: {
    RepeaterWidgetInput
  },
  computed: {
    dashboard () {
      return this.$route.params.dashboardUUID ? this.$store.getters['dashboard/findBy'](this.$route.params.dashboardUUID, 'uuid') : null;
    },
    devices() {
      return this.$store.getters['device/all'];
    },
    user () {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (this.$route.params.dashboardUUID) {
      this.loadDashboard();
    }

    this.loadDevices();
  },
  data () {
    return {
      deleting: false,
      form: new Form({
        name: '',
        widgets: []
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      dashboardDestroy: 'dashboard/destroy',
      dashboardShow: 'dashboard/show',
      dashboardStore: 'dashboard/store',
      dashboardUpdate: 'dashboard/update',
      deviceIndex: 'device/index'
    }),
    fillForm() {
      this.form.fields = {
        ...this.dashboard,
        widgets: this.dashboard ? this.dashboard.widgets : []
      };
    },
    handleCancel () {
      this.form.reset();
      this.$router.back();
    },
    handleDelete() {
      window.app.confirm({
        title: 'Delete Dashboard',
        message: 'Are you sure you want to delete this dashboard?',
        onConfirm: () => {
          this.deleting = true;

          this.dashboardDestroy({
            id: this.dashboard.id,
          }).then(() => {
            window.app.snackbar(`Dashboard was deleted`);

            this.$router.push({
              name: 'dashboard/index',
            });

          }).finally(() => {
            this.deleting = false;
          });
        },
      });
    },
    handleSave () {
      const fields = {
        ...this.form.fields,
      };

      this.loading = true;

      let action = null;

      if (this.dashboard) {
        action = this.dashboardUpdate;
      } else {
        action = this.dashboardStore;
      }

      if(action) {
        action(Dashboard.preparePayload(fields)).then(() => {
          window.app.snackbar('Dashboard saved');
          this.form.reset();
          this.$router.back();
        }).catch((error) => {

          this.form.recordErrors(error);

        }).finally(() => {
          this.loading = false;
        });
      }

    },
    loadDashboard() {
      this.loading = true;
      return this.dashboardShow({
        id: this.$route.params.dashboardUUID,
      }).then(() => {
        this.fillForm();
      }).catch(() => {
        window.app.snackbar('Error loading dashboard');
      }).finally(() => {
        this.loading = false;
      });
    },
    loadDevices() {
      this.loading = true;
      this.deviceIndex({
        params: {
          include: ['parameters'],
        },
      }).then(() => {
        this.loading = false;
      });
    }
  },
  props: {},
};
</script>

<style scoped lang="scss">

</style>
