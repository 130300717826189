import Devices from '@/views/Shop/Devices';
import Merch from '@/views/Shop/Merch';

const routes = [
  {
    name: 'shop/devices',
    path: '/shop/devices',
    component: Devices,
    meta: {
      icon: 'router',
      middleware: ['auth'],
      title: 'Devices',
    },
  },
  {
    name: 'shop/merch',
    path: '/shop/merch',
    component: Merch,
    meta: {
      icon: 'shirt',
      middleware: ['auth'],
      title: 'Merch',
    },
  },
];

export default routes;
