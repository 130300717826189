<template>

  <div class="alarms">

    <template v-for="alarm in alarms" v-if="alarms.length > 0">

      <div class="alarm">

        <div class="columns is-flex is-align-items-center">

          <div class="column is-flex-grow-2">
            <SelectInput :disabled="!alarm.editing"
                         :options="parameterOptions"
                         v-model="alarm.rules[0].parameterId"
            />
          </div>

          <div class="column is-flex-grow-2">
            <SelectInput :disabled="!alarm.editing"
                         :options="ruleOptions"
                         v-model="alarm.rules[0].operator"
            />
          </div>

          <div class="column is-flex-grow-1">
            <NumberInput :disabled="!alarm.editing"
                         v-model="alarm.rules[0].value"
                         placeholder="3.0"
            />
          </div>

          <template v-if="!alarm.editing">

            <div class="column is-flex-grow-0">
              <BTooltip label="Edit">
                <Button light outlined @click="handleAlarmEdit(alarm)">
                  <BIcon icon="pencil"/>
                </Button>
              </BTooltip>
            </div>

            <div class="column is-flex-grow-0">
              <BTooltip label="Delete">
                <Button danger outlined @click="handleAlarmDelete(alarm)">
                  <BIcon icon="minus-circle"/>
                </Button>
              </BTooltip>
            </div>

          </template>

          <template v-else>

            <div class="column is-flex-grow-0">
              <BTooltip label="Save">
                <Button light outlined @click="handleAlarmSave(alarm)">
                  <BIcon icon="save"/>
                </Button>
              </BTooltip>
            </div>

            <div class="column is-flex-grow-0">
              <BTooltip label="Cancel">
                <Button light outlined @click="handleAlarmEditCancel(alarm)">
                  <BIcon icon="times"/>
                </Button>
              </BTooltip>
            </div>

          </template>

        </div>

      </div>

    </template>

    <div class="controls has-text-right">
      <Button primary outlined @click="handleAlarmAdd">
        Add Alarm
      </Button>
    </div>

  </div>

</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'AlarmsInput',
  computed: {
    parameterOptions() {
      return this.device && this.device.parameters ? [
        {
          label: '-- Select a Parameter --',
          value: null,
        },
        ...this.device.parameters.map((parameter) => {
          return {
            label: parameter.title,
            value: parameter.id
          };
        }),
      ] : [];
    },
  },
  created() {
    this.alarms = [
      ...this.device.alarms.map((alarm) => {
        return {
          ...alarm,
          editing: false,
        };
      }),
    ];
  },
  data() {
    return {
      alarms: [],
      ruleOptions: [
        {
          label: '-- Select operator --',
          value: null
        },
        {
          label: 'equal to',
          value: '=='
        },
        {
          label: 'not equal to',
          value: '!='
        },
        {
          label: 'greater than',
          value: '>'
        },
        {
          label: 'greater than or equal to',
          value: '>='
        },
        {
          label: 'less than',
          value: '<'
        },
        {
          label: 'less than or equal to',
          value: '<='
        },
      ]
    };
  },
  methods: {
    ...mapActions({
        alarmIndex: 'alarm/index',
        alarmDestroy: 'alarm/destroy',
        alarmStore: 'alarm/store',
        alarmUpdate: 'alarm/update',
        deviceShow: 'device/show',
      }
    ),
    handleAlarmAdd() {
      this.alarms = [
        ...this.alarms.map((alarm) => {
          alarm.editing = false;
          return alarm;
        }),
        {
          alarmableType: 'App\\Models\\Device',
          alarmableId: this.device.id,
          editing: true,
          name: '',
          rules: [
            {
              parameter_id: null,
              operator: '',
              value: '',
            },
          ],
        },
      ];
    },
    handleAlarmDelete(alarm) {

      if (!alarm.id) {

        this.alarms = [
          ...this.alarms.filter(_a => _a !== alarm),
        ];

        return;
      }

      window.app.confirm({
        title: 'Delete Alarm',
        message: 'Are you sure you want to delete this alarm?',
        onConfirm: () => {
          this.loading = true;
          this.alarmDestroy({
            id: alarm.id,
          }).then(() => {
            this.$emit('changed');
          }).then(() => {
            window.app.snackbar('Alarm Deleted');
            this.alarms = [
              ...this.alarms.filter(_a => _a !== alarm),
            ];
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.loading = false;
          });
        },
      });
    },
    handleAlarmEdit(alarm) {
      alarm.editing = true;
      this.alarms = [
        ...this.alarms.map(_a => _a === alarm ? alarm : _a),
      ];
    },
    handleAlarmEditCancel(alarm) {
      alarm.editing = false;
      this.alarms = [
        ...this.alarms.map(_a => _a === alarm ? alarm : _a),
      ];
    },
    handleAlarmSave(alarm) {

      let action;

      if (alarm.id) {
        action = this.alarmUpdate;
      } else {
        action = this.alarmStore;
      }

      this.loading = true;

      if (!alarm.name) {
        let parameter = this.parameterOptions.find(parameter => parameter.value === parseInt(alarm.rules[0].parameterId));
        alarm.name = `${parameter.label} ${alarm.rules[0].operator} ${alarm.rules[0].value}`;
      }

      return action({
        ...alarm,
      }).then(() => {
        window.app.snackbar('Alarm Saved');
        this.handleAlarmEditCancel(alarm);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {
    device: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.alarm {
  background: $black-ter;
  border: 1px solid $grey-dark;
  border-radius: 3px;
  margin-bottom: 15px;
  padding: 15px;
}
</style>
