export default {
  getToken() {
    return window.app.storage.getItem('token') ? window.app.storage.getItem('token') : null;
  },
  setToken(token) {
    return window.app.storage.setItem('token', token);
  },
  removeToken() {
    return window.app.storage.removeItem('token');
  },
  redirectRouteAfterLogin() {
    return window.app.findRouteByName('home');
  },
  unauthenticated() {

    console.log('Unauthenticated, removing token');

    window.app.auth.removeToken();

    window.location.replace('/auth/login');
  },
};
