<template>
  <div>

    <div class="advanced-chart-controls">

      <div class="left">

        <div class="range-options">
          <template v-for="option in dateRangeOptions">
            <div class="range-option pointer" :class="{ 'is-active': controls.dateRange === option.value }"
                 @click="handleRangeOptionClick(option)">
              {{ option.label }}
            </div>
          </template>
        </div>

        <div class="spacer"></div>

        <div class="datepickers">

          <div class="datepicker-control pointer" @click="handleDateRangePrev">
            <BIcon icon="chevron-left"/>
          </div>

          <div class="pointer" @click="handleDateRangeCustom('startDate')">
            {{ startDateLabel }}
          </div>

          <div class="is-padded-horizontal">
            -
          </div>

          <div class="pointer" @click="handleDateRangeCustom('endDate')">
            {{ endDateLabel }}
          </div>

          <div class="datepicker-control pointer" @click="handleDateRangeNext">
            <BIcon icon="chevron-right"/>
          </div>

        </div>

      </div>

      <div class="right">
        <div class="sampling-control">
          <div class="tag is-warning pointer" @click="handleSamplingControlActiveToggle">
            Sampling: {{ controls.amount }}%
            <template v-if="controls.roundingEnabled && controls.rounding">/ {{ controls.rounding }}</template>
          </div>
        </div>
      </div>

    </div>

    <BModal :active.sync="samplingControlActive" style="z-index: 41;">
      <div class="container container-extra-extra-small">
        <Box flat>

          <Title>Sampling</Title>

          <Field>

            <Label>Amount (%)</Label>

            <div class="has-margin-bottom">
              <Help>
                Why use sampling? Sampling will reduce the number of data points your browser is trying to render,
                which will improve the performance of the chart. Sampling can also reduce the "noise" in the chart.
              </Help>
            </div>

            <SelectInput :options="amountOptions" v-model="controls.amount"/>

          </Field>

          <hr/>

          <Field v-if="controls.roundingEnabled">

            <Label>Timestamp Rounding</Label>

            <div class="has-margin-bottom">
              <Help>
                <p>
                  Rounding timestamps can help related data "line up" better in charts when comparing multiple Device
                  Parameters.
                </p>
                <p>
                  When rounding causes multiple Uplinks to share the same timestamp, the <b>last</b> Uplink for the
                  timestamp is returned.
                </p>
                <p>
                  Too much rounding can start to make the chart differ from reality. Use cautiously.
                </p>
              </Help>
            </div>

            <!--          <Field>-->
            <!--            <BCheckbox v-model="controls.roundingEnabled">Enabled</BCheckbox>-->
            <!--          </Field>-->


            <TimeIntervalInput label="Rounding" v-model="controls.rounding"/>

          </Field>


          <div class="controls has-text-right">
            <Button primary @click="handleSamplingControlActiveToggle">Done</Button>
          </div>
        </Box>
      </div>
    </BModal>

    <BModal :active.sync="dateRangeControlActive" style="z-index: 41;">
      <div class="container container-extra-extra-small">
        <Box flat>

          <Title>Date Range</Title>

          <Field v-if="dateRangeControlInput === 'startDate'">
            <Label>Start Date</Label>
            <div class="has-text-centered">
              <BDatepicker dark inline v-model="controls.startDate" :max-date="controls.endDate"/>
            </div>
          </Field>

          <Field v-if="dateRangeControlInput === 'endDate'">
            <Label>End Date</Label>
            <div class="has-text-centered">
              <BDatepicker inline v-model="controls.endDate" :max-date="now"/>
            </div>
          </Field>

          <div class="controls has-text-right">
            <Button primary @click="handleDateRangeCustom(null)">Done</Button>
          </div>
        </Box>
      </div>

    </BModal>

  </div>


</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'AdvancedChartControls',
  computed: {
    amountOptions() {
      return [
        {
          label: '10%',
          value: 10,
        },
        {
          label: '20%',
          value: 20,
        },
        {
          label: '30%',
          value: 30,
        },
        {
          label: '40%',
          value: 40,
        },
        {
          label: '50%',
          value: 50,
        },
        {
          label: '60%',
          value: 60,
        },
        {
          label: '70%',
          value: 70,
        },
        {
          label: '80%',
          value: 80,
        },
        {
          label: '90%',
          value: 90,
        },
        {
          label: '100%',
          value: 100,
        },
      ];
    },
    dateRangeDiffInSeconds() {
      return this.controls.startDate && this.controls.endDate ? moment(this.controls.endDate).diff(moment(this.controls.startDate), 'seconds') : null;
    },
    dateRangeOptions() {
      return [
        {
          label: '1D',
          value: '1d',
          period: {
            amount: 1,
            unit: 'days'
          }
        },
        {
          label: '1W',
          value: '1w',
          period: {
            amount: 1,
            unit: 'weeks'
          }
        },
        {
          label: '1M',
          value: '1m',
          period: {
            amount: 1,
            unit: 'months'
          }
        },
        {
          label: '3M',
          value: '3m',
          period: {
            amount: 3,
            unit: 'months'
          }
        },
        {
          label: '1Y',
          value: '1y',
          period: {
            amount: 1,
            unit: 'years'
          }
        },
        {
          label: 'CUSTOM',
          value: 'custom',
          period: {
            amount: 1,
            unit: 'week',
          }
        },
      ];
    },
    endDateLabel() {
      return this.controls.endDate ? moment(this.controls.endDate).format('ll') : '';
    },
    now() {
      return new Date();
    },
    startDateLabel() {

      let dateRangeFormat = 'MMM D';

      if (moment(this.controls.startDate).year() !== moment(this.controls.endDate).year()) {
        dateRangeFormat = 'll';
      }

      return this.controls.startDate ? moment(this.controls.startDate).format(dateRangeFormat) : '';
    },
  },
  data() {

    const defaultControls = {
      amount: 100,
      dateRange: '1w',
      endDate: moment().toDate(),
      rounding: 300,
      roundingEnabled: true,
      startDate: moment().subtract(1, 'week').toDate(),
    };

    const stored = window.app.storage.getItem(this.storageKey) || defaultControls;

    return {
      dateRangeControlActive: false,
      dateRangeControlInput: null,
      samplingControlActive: false,
      controls: {
        amount: stored.amount,
        dateRange: stored.dateRange,
        endDate: moment(stored.endDate).toDate(),
        rounding: stored.rounding,
        roundingEnabled: stored.roundingEnabled,
        startDate: moment(stored.startDate).toDate(),
      },
    };
  },
  methods: {
    handleDateRangeCustom(input) {
      this.controls.dateRange = 'custom';
      this.dateRangeControlActive = !this.dateRangeControlActive;
      this.dateRangeControlInput = input;
    },
    handleDateRangePrev() {

      if (!this.dateRangeDiffInSeconds) {
        return;
      }

      let startDate = moment(this.controls.startDate).subtract(this.dateRangeDiffInSeconds, 'seconds');
      let endDate = moment(this.controls.endDate).subtract(this.dateRangeDiffInSeconds, 'seconds');
      this.controls.startDate = startDate.toDate();
      this.controls.endDate = endDate.toDate();
    },
    handleDateRangeNext() {

      if (!this.dateRangeDiffInSeconds) {
        return;
      }

      let startDate = moment(this.controls.startDate).add(this.dateRangeDiffInSeconds, 'seconds');
      let endDate = moment(this.controls.endDate).add(this.dateRangeDiffInSeconds, 'seconds');

      if (endDate.isAfter(moment())) {
        endDate = moment();
        startDate = moment().subtract(this.dateRangeDiffInSeconds, 'seconds');
      }

      this.controls.startDate = startDate.toDate();
      this.controls.endDate = endDate.toDate();
    },
    handleRangeOptionClick(option) {

      this.controls.dateRange = option.value;

      if (option.value === 'custom') {
        this.handleDateRangeCustom('startDate');
        return;
      }

      this.controls.startDate = moment().subtract(option.period.amount, option.period.unit).toDate();
      this.controls.endDate = moment().toDate();
    },
    handleSamplingControlActiveToggle() {
      this.samplingControlActive = !this.samplingControlActive;
    },
  },
  props: {
    storageKey: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  watch: {
    controls: {
      deep: true,
      handler() {

        window.app.storage.setItem(this.storageKey, {
          ...this.controls,
        })

        this.$emit('input', {
          ...this.controls,
        });
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.controls)) {
          this.controls = {
            ...this.controls,
            ...this.value,
          }
        }
      },
    }
  },
}
</script>

<style scoped lang="scss">
.advanced-chart-controls {
  align-items: center;
  border-bottom: 1px solid $grey-dark;
  display: flex;
  justify-content: space-between;

  .left, .right {
    align-items: center;
    display: flex;
  }

  .spacer {
    border-left: 1px solid $grey-dark;
    display: inline-block;
    height: 35px;
    margin: 0 5px;
    width: 1px;
  }

  .range-options {
    display: flex;
    margin: 0 15px;

    .range-option {
      padding: 15px;

      &.is-active {
        border-bottom: 1px solid $info;
      }
    }
  }

  .datepickers {
    display: flex;

    .datepicker-control {
      padding: 0 5px;
    }
  }

  .sampling-control {
    padding: 0 15px;
    text-transform: uppercase;
  }
}
</style>
