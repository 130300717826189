import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const {
    domain,
  } = payload;

  return new Menu({
    title: 'Visualize',
    icon: 'tv-retro',
    open: payload.open !== undefined ? payload.open : false,
    menuItems: [
      {
        route: window.app.findRouteByName('activity-monitor'),
        when: domain.featureEnabled('activity_monitor'),
      },
      {
        route: window.app.findRouteByName('advanced-charting'),
        when: domain.featureEnabled('charts'),
      },
      {
        route: window.app.findRouteByName('report/index'),
        when: domain.featureEnabled('reports'),
      },
      {
        route: window.app.findRouteByName('dashboard/index'),
        when: domain.featureEnabled('dashboards'),
      },
    ],
  });
};
