<template>
  <div id="device-show" class="is-padded">

    <BLoading active v-if="loading"/>

    <template v-else-if="shouldShowConnectServiceWarning">

      <Box flat :black="$themeDark">

        <div class="has-text-centered" style="padding: 30px 10% 45px;">

          <Title>
            <BIcon icon="triangle-exclamation" style="margin-right: 5px;" /> Service Required
          </Title>

          <p style="margin-bottom: 30px;">
            Your device has been onboarded, but you still need to purchase your service.
          </p>

          <Button primary outlined @click="handleDeviceEdit(device)">
            Add Service
          </Button>

        </div>

      </Box>

      <div class="has-text-centered">
        <Button routerLink :to="`/device/${device.uuid}/edit`" outlined :light="$themeDark" :dark="$themeLight">
          Manage Device
        </Button>
      </div>

    </template>

    <template v-else-if="!device.latestUplink">
      <Box flat :black="$themeDark" class="has-margin-vertical has-text-centered">
        <div style="margin: 45px 0;">
          <BIcon icon="tower-cell" size="fa-5x"/>
        </div>
        <Title class="">Awaiting data...</Title>
      </Box>

      <div class="has-text-centered">
        <Button routerLink :to="`/device/${device.uuid}/edit`" outlined :light="$themeDark" :dark="$themeLight">
          Manage Device
        </Button>
      </div>

    </template>

    <template v-else-if="device">
      <FS1FlowSensor v-if="isFS1FlowSensor" :device="device" />
      <H2SoilSensor v-else-if="isH2SoilSensor" :device="device"/>
      <PS1PressureSensor v-else-if="isPS1PressureSensor" :device="device"/>
      <S1WiredSoilSensor v-else-if="isS1SoilSensor" :device="device"/>
      <Default v-else :device="device"/>
    </template>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import Default from '@/_instances/HarmonyAnalytica/components/Device/Default';
import FS1FlowSensor from '@/_instances/HarmonyAnalytica/components/Device/FS1FlowSensor';
import H2SoilSensor from '@/_instances/HarmonyAnalytica/components/Device/H2SoilSensor';
import PS1PressureSensor from '@/_instances/HarmonyAnalytica/components/Device/PS1PressureSensor';
import S1WiredSoilSensor from '@/_instances/HarmonyAnalytica/components/Device/S1WiredSoilSensor';

export default {
  name: 'Show',
  components: {
    Default,
    FS1FlowSensor,
    H2SoilSensor,
    PS1PressureSensor,
    S1WiredSoilSensor,
  },
  computed: {
    device() {
      return this.$route.params.deviceUUID && this.updateAt ? this.$store.getters['device/findBy'](this.$route.params.deviceUUID, 'uuid') : null;
    },
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    isFS1FlowSensor() {
      return this.device.definition === 'App\\Definitions\\HarmonyAnalyticaFS1FlowSensorTTN' || this.device.definition === 'App\\Definitions\\HarmonyAnalyticaFS1FlowSensorChirpStack';
    },
    isH2SoilSensor() {
      return this.device.definition === 'App\\Definitions\\HarmonyAnalyticaH2SoilSensor' || this.device.definition === 'App\\Definitions\\HarmonyAnalyticaH2SoilSensorChirpStack';
    },
    isPS1PressureSensor() {
      return this.device.definition === 'App\\Definitions\\HarmonyAnalyticaPS1PressureSensorTTN' || this.device.definition === 'App\\Definitions\\HarmonyAnalyticaPS1PressureSensorChirpStack';
    },
    isS1SoilSensor() {
      return this.device.definition === 'App\\Definitions\\HarmonyAnalyticaS1WiredSoilSensor' || this.device.definition === 'App\\Definitions\\HarmonyAnalyticaS1WiredSoilSensorChirpStack';
    },
    shouldShowConnectServiceWarning() {
      return false; // this.device && !this.device.license;
    },
  },
  created() {
    this.loadDevice();
    window.app.vue.$on('uplinkReceived', this.handleUplinkReceived);
  },
  destroyed() {
    window.app.vue.$off('uplinkReceived', this.handleUplinkReceived);
  },
  data() {
    return {
      loading: false,
      updateAt: null,
    };
  },
  methods: {
    ...mapActions({
      deviceShow: 'device/show',
    }),
    handleDeviceEdit(device) {
      this.$router.push({
        name: 'device/edit',
        params: {
          deviceUUID: device.uuid,
        },
      });
    },
    handleUplinkReceived(e) {
      if (this.device && parseInt(e.deviceId) === this.device.id) {
        return this.reloadDevice();
      }
    },
    loadDevice(inBackground = false) {
      this.loading = !inBackground;
      return this.deviceShow({
        id: this.$route.params.deviceUUID,
        params: {
          include: [
            'latestUplink',
            'parameters',
          ],
        },
      }).then(() => {
        this.updateAt = new Date();
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    reloadDevice() {
      return this.loadDevice(true);
    },
  },
};
</script>

<style scoped lang="scss">
#device-show {

  .device-name {
    font-size: 18px;
  }

  .device-edit {

  }

  .moisture {
    margin: 30px 0 15px;

    .title {
      font-size: 5em;
    }
  }

  .parameter-value-created-at {
    font-size: 0.8em;
  }

  .chart-wrapper {
    border-radius: 0 0 6px 6px;
    overflow: hidden;
  }
}
</style>
