<template>
  <router-link :to="editRoute" class="resource-list-item" :class="{ 'is-dark': $themeDark }">
    {{tag.name}}
  </router-link>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    editRoute() {
      let route = `/tag/${this.tag.uuid}/edit`;

      if (this.domainUUID) {
        return `/domain${route}`;
      }

      return route;
    },
    tag() {
      return this.resource;
    },
  },
  created() {
    this.domainUUID = window.app.storage.getItem('domainBeingManaged');
  },
  data() {
    return {
      domainUUID: null,
    };
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
