<template>
  <div class="resource-search-input">

    <Field>
      <Label v-if="label">{{ label }}</Label>
      <div class="is-relative">
        <TextInput v-model="searchTerm"
                   :placeholder="placeholder"
        />
        <div class="resource-search-input-addon" v-if="loading">
          <BIcon icon="loader" custom-class="fa-spin"/>
        </div>
        <div class="resource-search-input-addon pointer" @click="handleClearSearchTerm" v-else-if="searchTerm.length">
          <BIcon icon="times"/>
        </div>
      </div>
    </Field>

    <template v-if="hasSearched">

      <template v-if="options.length">
        <Field>
          <Label>Results</Label>
          <template v-for="option in options">
            <div style="padding: 2.5px 0;">
              <BCheckbox :class="{ 'has-text-light': $themeDark }" v-model="selected" :native-value="option.id">{{ option[field] }}</BCheckbox>
            </div>
          </template>
        </Field>

        <div class="has-margin-top">
          <Button primary block @click="handleAddSelected">Add Selected</Button>
        </div>
      </template>

      <template v-else>
        <Field class="has-text-centered">
          <Label>No Results</Label>
        </Field>
      </template>

    </template>

  </div>
</template>

<script>
import {debounce} from 'lodash';

export default {
  name: 'ResourceSearch',
  computed: {
    options() {
      return this.vuexKey && this.optionIds.length ? this.$store.getters[`${this.vuexKey}/collection`](this.optionIds) : [];
    },
  },
  data() {
    return {
      hasSearched: false,
      loading: false,
      optionIds: [],
      performSearch: debounce(() => {
        this.loading = true;
        this.action({
          params: {
            ...this.params,
            search: this.searchTerm,
          },
        }).then((response) => {
          this.optionIds = response.ids;
          this.hasSearched = true;
        }).finally(() => {
          this.loading = false;
        });
      }, 1000),
      searchTerm: '',
      selected: [],
    };
  },
  methods: {
    handleClearSearchTerm() {
      this.searchTerm = '';
      this.hasSearched = false;
      this.optionIds = [];
    },
    handleSearch() {

      if (this.searchTerm.length < 2) {
        return;
      }

      this.performSearch();
    },
    handleAddSelected() {
      this.$emit('select', this.options.filter(option => this.selected.includes(option.id)));
    },
  },
  props: {
    action: {
      required: true,
    },
    disabled: {
      default: false,
      required: false,
      type: Boolean,
    },
    field: {
      default: 'name',
      required: false,
      type: String,
    },
    label: {
      default: '',
      required: false,
      type: String,
    },
    openOnFocus: {
      default: false,
      required: false,
      type: Boolean,
    },
    params: {
      required: false,
    },
    placeholder: {
      default: '',
      required: false,
      type: String,
    },
    vuexKey: {
      type: String,
    },
  },
  watch: {
    searchTerm() {
      if (this.searchTerm.length) {
        this.handleSearch();
      } else {
        this.optionIds = [];
      }
    },
  }
}
</script>

<style lang="scss">
.resource-search-input {

  position: relative;

  input {
    padding-right: 30px;
  }

  .resource-search-input-addon {
    position: absolute;
    top: 8px;
    right: 5px;
  }
}
</style>
