import {
  Account,
  Model,
  AlarmService as Service,
} from '@/internal';

export default class Alarm extends Model {

  static get key() {
    return 'alarm';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      rules: {
        required: true,
        type: Array,
      }
    };
  }

  get account() {
    return this.hasOne(Account)
  }

}
