<template>
  <div class="trip-resource-list">
    <ResourceList :params="params"
                  :query="query"
                  :resource="resource"
                  :renderAs="renderAs"
                  sortBy="created_at"
                  sortOrder="desc"
                  :sortByOptions="sortByOptions"
    />
  </div>
</template>

<script>

import TripResourceListItem from '@/components-V2/Trip/ResourceListItem';
import {Trip} from '@/internal';

export default {
  name: 'TripResourceList',
  computed: {},
  data() {
    return {
      params: {
        include: [],
      },
      renderAs: TripResourceListItem,
      resource: Trip,
      sortByOptions: [
        {
          label: 'Created Date',
          value: 'created_at',
        },
      ],
    };
  },
  methods: {},
  props: {
    query: {
      default: () => {
        return {};
      },
      required: false,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
