import {
  ResourceService,
} from '@/internal';

export default class WidgetService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'widget';
  }

}
