import {
  Model,
  Account,
  Alarm,
  DeviceService as Service,
  File,
  License,
  Note,
  Parameter,
  Position,
  Tag,
  Trip,
  Uplink,
  UplinkImport,
  UplinkExport,
} from '@/internal';

import moment from 'moment';
import pluralize from 'pluralize';

export default class Device extends Model {

  static get key() {
    return 'device';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      uuid: {
        type: String,
      },
      name: {
        required: true,
        type: String,
      },
      position: {
        preparePayload: (value) => {
          return value ? {
            id: value.id ? value.id : null,
            lat: value.lat,
            lng: value.lng,
            altitude: value.altitude
          } : null;
        },
        type: Position,
      },
      tags: {
        preparePayload: (value) => {
          return value ? value.map(tag => tag.id) : [];
        },
        type: Tag,
      },
    };
  }

  static get class() {
    return 'App\\Models\\Device';
  }

  get alarms () {
    return this.hasMany(Alarm);
  }

  get account() {
    return this.hasOne(Account)
  }

  get heartbeatIntervalFormatted() {

    let approximate;
    let divisor;
    let unit;

    if (this.heartbeatInterval < 3600) {
      divisor = 60;
      unit = 'minute';
    } else if (this.heartbeatInterval < 86400) {
      divisor = 3600;
      unit = 'hour';
    } else if (this.heartbeatInterval < 604800) {
      divisor = 86400;
      unit = 'day';
    } else if (this.heartbeatInterval < 2592000) {
      divisor = 604800;
      unit = 'week';
    } else {
      return moment.duration(this.heartbeatInterval, 'second').humanize();
    }

    approximate = this.heartbeatInterval % divisor !== 0;
    return `${approximate ? '~' : ''}${(this.heartbeatInterval / divisor).toFixed(0)} ${pluralize(unit, this.heartbeatInterval / divisor)}`;
  }

  get lastFourOfDeviceEui() {
    return this.deviceEui.substring(this.deviceEui.length - 4);
  }

  get latestUplink() {
    return this.hasOne(Uplink, null, null, 'latestUplink');
  }

  get license() {
    return this.hasOne(License);
  }

  get manufacturerLabel() {
    return this.belongsTo(File, 'manufacturerLabelFileId');
  }

  get notes () {
    return this.hasMany(Note);
  }

  get parameters() {
    return this.hasMany(Parameter);
  }

  get position() {
    return this.hasOne(Position)
  }

  get publicUrl() {
    return `${window.location.protocol}//${window.location.host}/device/${this.uuid}/share`;
  }

  get statusColor() {

    if (!this.active) {
      return '#FFB427';
    } else if (this.status === null || this.status === 'pending') {
      return '#FFDD57';
    } else if (this.status === 'online') {
      return '#29D391';
    } else if (this.status === 'offline') {
      return '#E52B2B';
    } else {
      return '#a62eff';
    }
  }

  get tags () {
    return this.hasMany(Tag);
  }

  get trips () {
    return this.hasMany(Trip);
  }

  get uplinks() {
    return this.hasMany(Uplink);
  }

  get uplinkImports() {
    return this.hasMany(UplinkImport)
  }

  get uplinkExports() {
    return this.hasMany(UplinkExport)
  }
}
