<template>
  <Page id="maintenance-splash">
    <div class="container container-extra-small">
      <div class="has-text-centered">
        <Logo/>
      </div>
      <p class="has-text-centered has-margin-vertical">
        We'll be back shortly.
      </p>
    </div>
  </Page>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import Logo from "@/components/Logo";

export default {
  name: 'MaintenanceSplash',
  components: {
    Logo,
  },
  computed: {},
  data () {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {},
};
</script>

<style scoped lang="scss">
#maintenance-splash {
  align-items: center;
  background: $dark;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zindex-screen-block;
}
</style>
