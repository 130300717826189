import {
  Menu,
} from '@/internal';

export default (payload = {}) => {
  return new Menu({
    title: 'Support',
    icon: 'lightbulb-exclamation',
    open: payload.open !== undefined ? payload.open : false,
    menuItems: [
      {
        route: window.app.findRouteByName('support/faqs'),
      },
    ],
  });
};
