<template>
  <div class="device-resource-list">
    <ResourceList addLabel="Add Device"
                  :addRoute="!hideAddButton ? addRoute : null"
                  :params="params"
                  :query="query"
                  ref="resourceList"
                  :resource="resource"
                  :renderAs="renderAs"
                  :sortByOptions="sortByOptions"
    />
  </div>
</template>

<script>
import {Device} from '@/internal';
import DeviceResourceListItem from '@/components-V2/Device/ResourceListItem';

export default {
  name: 'DeviceResourceList',
  data() {
    return {
      // addRoute: !this.hideAddButton ? window.app.findRouteByName('device/create') : null,
      params: {
        include: [
          'license',
        ],
      },
      resource: Device,
      sortByOptions: [
        {
          label: 'Name',
          value: 'name',
        },
        {
          label: 'Dev EUI',
          value: 'device_eui',
        },
        {
          label: 'Status',
          value: 'status',
        },
        {
          label: 'Created Date',
          value: 'created_at',
        },
      ],
    };
  },
  props: {
    addRoute: {
      default() {
        return window.app.findRouteByName('device/create');
      },
      required: false,
    },
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {
      },
      required: false,
    },
    renderAs: {
      default: () => {
        return DeviceResourceListItem;
      },
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
