<template>
  <div id="mobile-navbar-footer" :class="{ 'is-standalone': $isStandalone }" v-if="domain">

    <ul v-if="mobileTabsAuthenticatedMenu">
      <li v-for="item in mobileTabsAuthenticatedMenu.menuItems"
          :class="{ active: item.active }"
          @click="handleTabClick(item)"
      >
        <router-link :to="item.route" v-if="item.route" @click.native="item.onClick ? item.onClick() : () => {}">
          <i :class="{
                            fal: !item.active,
                            fas: item.active,
                            'fa-fw': true,
                            [`fa-${item.icon}`]: true,
                        }"></i>
        </router-link>
        <div v-else-if="item.onClick" @click="item.onClick">
          <i :class="{
                            fal: !item.active,
                            fas: item.active,
                            'fa-fw': true,
                            [`fa-${item.icon}`]: true,
                        }"></i>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import MobileTabsAuthenticated from '@/menus/MobileTabsAuthenticated';

export default {
  name: 'MobileTabs',
  components: {},
  computed: {
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    mobileTabsAuthenticatedMenu() {
      return MobileTabsAuthenticated({
        domain: this.domain,
        moreClick: () => {
          this.primaryMenuUpdate({
            active: !this.primaryMenu.active,
          });
        },
        user: this.user,
      });
    },
    primaryMenu() {
      return this.$store.state.ui.primaryMenu;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  data() {
    return {
      mobileMenuActive: false,
    };
  },
  methods: {
    ...mapActions({
      primaryMenuUpdate: 'ui/primaryMenu',
    }),
    handleTabClick(item) {
      if (!item.onClick) {
        this.primaryMenuUpdate({
          active: false,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
#mobile-navbar-footer {
  background: $black;
  border-top: 1px solid $grey;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: $zindex-navigation;

  &.is-standalone {
    padding-bottom: $standalone-padding-bottom;
  }

  ul {
    display: flex;
    padding: 5px;
    padding-bottom: $standalone-padding-bottom;

    li {
      border-radius: 4px;
      display: flex;
      flex: 1;
      position: relative;

      & > * {
        align-items: center;
        color: white;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-around;
        padding: 15px 0;
      }

      .fal, .fas {
        font-size: 1.2em;
      }

      .item-label {
        font-size: .6rem;
        margin-top: 5px;
        text-transform: uppercase;
      }
    }
  }
}

#update-available-button {
  margin: 5px 5px 10px;
}

.menu-section {

  padding: 5px;
  margin-bottom: 15px;

  .menu-section-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 5px;

    .label {
      margin: 0;
    }
  }

  .menu-section-content {

  }
}
</style>
