<template>
  <div id="profile" class="is-padded">

    <BLoading active v-if="loading" />

    <template v-if="form.fields.verificationUUID">

      <p class="has-text-light">
        An email with a verification code has been sent.
      </p>

      <Verifier :form="form"
                v-model="form.fields.verificationCode"
                :cancelable="false"
                channel="mail"
                :connection-string="form.fields.email"
                :loading="loading"
                reason="Primary Email Verification"
                showControls
                @verify="handleSave"
                @cancel="handleVerificationCodeCancel"
                @uuid="uuid => form.fields.verificationUUID = uuid"

      />

    </template>

    <template v-else>

      <Field v-if="false && user">
        <Label>ID</Label>
        <TextInput :value="user.id" disabled />
      </Field>

      <Field>
        <Label>First Name *</Label>
        <TextInput v-model="form.fields.firstName"/>
        <FormError field="firstName" :form="form"/>
      </Field>

      <Field>
        <Label>Last Name *</Label>
        <TextInput v-model="form.fields.lastName"/>
        <FormError field="lastName" :form="form"/>
      </Field>

      <Field>
        <Label>
          Email *
          <div class="is-pulled-right" v-if="user">
            <div class="tag has-margin-right-xs pointer" v-if="!user.emailVerified" @click="handleSave">Resend Verification Email</div>
            <div class="tag is-warning" v-if="!user.emailVerified">UNVERIFIED</div>
            <div class="tag is-success" v-else>VERIFIED</div>
          </div>
        </Label>
        <EmailInput v-model="form.fields.email" :disabled="emailInputDisabled" />
        <FormError field="email" :form="form"/>
      </Field>

    </template>

    <Teleport to="#inspector-footer">
      <Controls>
        <template v-slot:left>
          <Button danger block outlined v-if="user && !isAuthUser" @click="handleDelete" style="border: none;"
                  :loading="deleting">
            Delete User
          </Button>
        </template>
        <template v-slot:right>
          <Button outlined :light="$themeDark" :dark="$themeLight" @click="handleCancel" class="has-margin-right">
            Cancel
          </Button>
          <Button primary :loading="loading" @click="handleSave">Save</Button>
        </template>
      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

export default {
  name: 'Profile',
  computed: {
    authUser () {
      return this.$store.getters['auth/user'];
    },
    emailInputDisabled() {
      return this.user && !this.isAuthUser;
    },
    isAuthUser () {
      return this.user && parseInt(this.user.id) === parseInt(this.authUser.id);
    },
    pageTitle () {
      if (this.isAuthUser) {
        return 'Profile';
      } else {
        return this.$route.name === 'user/create' ? 'Add User' : 'Edit User';
      }
    },
    user () {

      if (this.$route.name === 'profile') {
        return this.authUser;
      }

      return this.$route.params.userId ? this.$store.getters['user/show'](this.$route.params.userId) : null;
    },
  },
  created () {

    let action = null;
    const id = this.user ? this.user.id : this.$route.params.userId;

    if (this.isAuthUser) {
      action = this.authUserShow;
    } else if (this.$route.name === 'user/edit') {
      action = this.userShow;
    }

    if (action) {
      this.loading = true;
      this.userShow({
        id,
      }).then(() => {
        this.form = new Form({
          ...this.form.fields,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          verificationUUID: this.user.verificationCode && this.user.verificationCode.key === 'email-update' ? this.user.verificationCode.uuid : '',
        });
      }).finally(() => {
        this.loading = false;
      });
    }

  },
  data () {
    return {
      form: new Form({
        id: this.$route.params.userId || null,
        firstName: '',
        lastName: '',
        email: '',
        verificationKey: 'email-update',
        verificationUUID: '',
        verificationCode: '',
      }),
      deleting: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      authUserShow: 'auth/userShow',
      authUserUpdate: 'auth/userUpdate',
      userShow: 'user/show',
      userStore: 'user/store',
      userUpdate: 'user/update',
      userDestroy: 'user/destroy',
    }),
    handleVerificationCodeCancel () {
      this.form.reset();
      this.userShow();
    },
    handleCancel () {
      this.$router.back();
    },
    handleDelete () {
      window.app.confirm({
        title: 'Delete User',
        message: 'Are you sure you want to delete this user?',
        onConfirm: () => {
          this.deleting = true;

          const user = this.user;

          this.userDestroy({
            id: this.user.id,
          }).then(() => {
            window.app.snackbar(`${user.fullName} was deleted`);
            this.$router.back();
          }).finally(() => {
            this.deleting = false;
          });
        },
      });
    },
    handleSave () {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.form.errors.clear();

      let action;
      let payload = {
        ...this.form.fields,
      };

      const isAuthUser = this.isAuthUser;

      if (isAuthUser) {
        action = this.authUserUpdate;
        delete payload.id;
      } else if (this.$route.params.userId) {
        action = this.userUpdate;
      } else {
        action = this.userStore;
      }

      action({
        ...payload,
      }).then(() => {

        this.form.fields.verificationUUID = null;
        this.form.fields.verificationCode = null;

        if (isAuthUser) {

          window.app.snackbar({
            message: 'Profile Updated',
          });

          const redirectRoute = window.app.storage.getItem('redirectRoute');

          if (redirectRoute) {
            this.$router.push(redirectRoute);
          }

        } else {

          window.app.snackbar({
            message: this.$route.params.userId ? 'User Updated' : 'User Added',
          });

          this.$router.back();
        }

      }).catch((error) => {

        if (window.app.verificationRequiredError(error)) {
          const verificationRequired = window.app.verificationRequiredError(error);
          this.form.fields.verificationUUID = verificationRequired ? verificationRequired.uuid : null;
        }

        this.form.recordErrors(error);

      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
};
</script>

<style scoped lang="scss">

</style>
