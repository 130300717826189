import {
  ApiService,
} from '@/internal';

export default class VerificationCodeService extends ApiService {

  store(payload) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/verification-code`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  destroy(payload) {
    return this.request({
      method: 'delete',
      url: `/verification-code/${payload.uuid}`,
    });
  }

}
