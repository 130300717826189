<template>
  <div class="is-padded">
    <BLoading active v-if="loading" />
    <Box flat :black="$themeDark" v-if="error && error.status === 404">
      <Title class="has-text-centered">Not Found</Title>
      <div class="has-text-centered has-margin-bottom">
        <code>{{$route.params.qrCodeKey}}</code>
      </div>
      <p class="has-text-centered">
        Unable to retrieve a resource associated with that code.
      </p>
    </Box>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'Show',
  computed: {
    qrCode() {
      return this.$route.params.qrCodeKey ? this.$store.getters['qrCode/findBy'](this.$route.params.qrCodeKey, 'key') : null;
    },
  },
  created() {
    this.loadQRCode();
  },
  data() {
    return {
      error: null,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      qrCodeShow: 'qrCode/show',
    }),
    loadQRCode() {
      this.loading = true;
      this.qrCodeShow({
        id: this.$route.params.qrCodeKey,
      }).then(() => {
        if (this.qrCode.type === 'App\\QRCodes\\Inventory\\Claim') {
          let inventoryUUID = this.qrCode.result.inventory.uuid;
          this.$router.push( {
            name: 'inventory/claim',
            params: {
              inventoryUUID,
            },
          });
        }
      }).catch((error) => {
        this.error = error.response;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
