<template>
  <div class="time-interval-picker">
    <Label>{{ label }}<template v-if="required"> *</template> <span v-if="selectLocalValue === 'custom'"> (seconds)</span></Label>
    <SelectInput :options="options" @input="handleSelectChange" v-model="selectLocalValue"
                 v-if="selectLocalValue !== 'custom'"/>
    <NumberInput v-else class="has-text-centered" :min="min" :max="max" v-model="localValue" placeholder="60"/>
  </div>
</template>

<script>
export default {
  name: 'TimeIntervalInput',
  computed: {},
  data() {
    return {
      localValue: null,
      selectLocalValue: null,
    };
  },
  methods: {
    handleSelectChange() {

    },
  },
  props: {
    label: {
      required: true,
    },
    min: {
      default: 60,
    },
    max: {
      default: null,
    },
    options: {
      default() {
        return [
          {
            label: '- Select a Value -',
            value: null,
          },
          {
            label: '1 minute',
            value: 60,
          },
          {
            label: '5 minutes',
            value: 300,
          },
          {
            label: '10 minutes',
            value: 600,
          },
          {
            label: '15 minutes',
            value: 900,
          },
          {
            label: '30 minutes',
            value: 1800,
          },
          {
            label: '1 hour',
            value: 3600,
          },
          {
            label: '6 hours',
            value: 21600,
          },
          {
            label: '12 hours',
            value: 43200,
          },
          {
            label: '1 day',
            value: 86400,
          },
          {
            label: '1 week',
            value: 604800,
          },
          {
            label: '1 month',
            value: 2592000,
          },
          {
            label: 'Custom',
            value: 'custom',
          }
        ];
      },
    },
    required: {
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
    },
  },
  watch: {
    localValue: {
      handler(value) {
        this.$emit('input', value);

        if (this.options.map(option => option.value).includes(parseInt(value))) {
          this.selectLocalValue = value;
        }
      },
    },
    selectLocalValue(value) {
      if (value !== 'custom') {
        this.localValue = value;
      }
    },
    value: {
      immediate: true,
      handler() {

        let value = parseInt(this.value) || null;

        if (value !== this.localValue) {
          this.localValue = value;
        }

        if (this.options.map(option => option.value).includes(parseInt(value))) {
          this.selectLocalValue = value;
        } else if (this.localValue) {
          this.selectLocalValue = 'custom';
        }
      },
    }
  },
}
</script>

<style scoped lang="scss">
.time-interval-picker {

}
</style>
