<template>
  <div class="tag-input is-clearfix" :class="{ 'is-dark': $themeDark }">
    <BTaginput v-model="localValue"
               @input="handleInput"
               :before-adding="handleCheckForDuplicates"
               :data="filteredTags"
               autocomplete
               openOnFocus
               field="name"
               icon="tag"
               icon-pack="fal"
               :maxtags="max"
               placeholder="Search for Tags"
               ref="taginput"
               @typing="getFilteredTags"
    >
      <template #selected="props">
        <Tag v-for="(tag, index) in props.tags"
             :key="index"
             :tag="tag"
             :clickable="false"
             closable
             @close="handleRemoveTag(tag)"
        />
      </template>
    </BTaginput>
  </div>
</template>

<script>
import {debounce} from 'lodash';

import {
  Tag,
  TagService,
} from '@/internal';

const tagService = new TagService();

export default {
  name: 'TagInput',
  data() {
    return {
      filteredTags: [],
      localValue: [],
      performSearch: debounce((search) => {

        tagService.index({
          params: {
            categories: this.categories,
            search
          },
        }).then((response) => {
          this.filteredTags = response.data.data.map((tag) => {
            return new Tag({
              id: tag.id,
              ...tag.attributes,
            });
          });
        });

      }, 500),
    };
  },
  methods: {
    getFilteredTags(search) {
      this.performSearch(search);
    },
    handleCheckForDuplicates(tag) {
      return !this.value || !this.value.map(_tag => _tag.id).includes(tag.id);
    },
    handleInput(tags) {
      this.localValue = [
        ...tags,
      ]
    },
    handleRemoveTag(tag) {
      this.localValue = [
        ...this.localValue.filter(_tag => _tag.id !== tag.id),
      ];
    },
  },
  props: {
    categories: {
      default: '',
      required: false,
      type: String,
    },
    max: {
      default: 5,
      required: false,
      type: Number,
    },
    value: {
      default: () => [],
      required: true,
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
          this.$emit('input', this.localValue);
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
          this.localValue = [
            ...this.value,
          ];
        }
      },
    },
  }
};
</script>

<style lang="scss">
.tag-input {

  &.is-dark {
    .taginput {
      .taginput-container {
        background: $dark !important;
        border: 1px solid $grey-darker !important;
      }
    }
  }

  .taginput {

    .taginput-container {
      padding-top: 0 !important;

      .autocomplete.control {
        background: transparent;

        input.input {
          background: transparent;
          color: $white;

          &::placeholder {
            color: $grey-dark;
          }
        }
      }

      .tag {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
