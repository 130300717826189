import {
  Position as Model,
  ResourceStore,
} from '@/internal';

const store = new ResourceStore({
  model: Model,
});

export default store.toObject();
