<template>
  <div id="two-factor-auth-form" class="is-padded">

    <Instruction>
      Two-factor authentication is an extra layer of security designed to ensure that
      you're the only person who can access your account, even if someone knows your password.
    </Instruction>

    <Instruction>
      You can manage which
      <router-link :to="{ name: 'channels' }">Channels</router-link>
      the verification code will be sent to.
    </Instruction>

    <div>
      <Button block primary outlined :loading="loading" @click="handleEnable" v-if="!user.twoFactorEnabled">Enable 2FA</Button>
      <Button block danger outlined :loading="loading" @click="handleDisable" v-else>Disable 2FA</Button>
    </div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

import Box from '@lakestreetsoftware/vue-components/src/Layout/Box';
import Button from '@lakestreetsoftware/vue-components/src/Form/Button';
import Field from '@lakestreetsoftware/vue-components/src/Form/Field';
import FormError from '@lakestreetsoftware/vue-components/src/Form/FormError';
import Label from '@lakestreetsoftware/vue-components/src/Content/Label';
import PasswordInput from '@lakestreetsoftware/vue-components/src/Inputs/PasswordInput';
import Subtitle from '@lakestreetsoftware/vue-components/src/Content/Subtitle';

export default {
  name: 'TwoFactorAuthForm',
  components: {
    Box,
    Button,
    Field,
    FormError,
    Label,
    PasswordInput,
    Subtitle,
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  data() {
    return {
      form: new Form({
        passwordCurrent: '',
        password: '',
        passwordConfirmation: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      update: 'security/twoFactor',
    }),
    handleEnable() {
      this.save(true);
    },
    handleDisable() {
      this.save();
    },
    save(twoFactorEnabled = false) {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.form.errors.clear();
      this.update({
        twoFactorEnabled,
      }).then(() => {
        window.app.snackbar({
          message: `Verification ${twoFactorEnabled ? 'Enabled' : 'Disabled'}`,
        });
        this.form.reset();
      }).catch((error) => {
        if (error.response && error.response.data) {
          this.form.errors.record(error.response.data.errors);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
};
</script>

<style scoped lang="scss">

</style>
