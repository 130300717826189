var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inspector-controls",class:{
         'is-active': _vm.primaryMenu.active,
         'is-anchored': _vm.$layoutAnchored,
         'is-dark': _vm.$themeDark,
         'is-floating': _vm.$layoutFloating,
         'is-full-screen': _vm.isFullScreen,
         'is-inactive': !_vm.primaryMenu.active,
         'is-mobile': _vm.isMobile,
     }},[_c('div',{staticClass:"left"},[(_vm.backRoute)?_c('BTooltip',{attrs:{"label":"Back","position":"is-right","active":!_vm.isMobile}},[_c('div',{staticClass:"inspector-control inspector-back",on:{"click":_vm.handleInspectorBack}},[_c('BIcon',{attrs:{"icon":"long-arrow-left","custom-class":"fa-xl fa-fw"}})],1)]):_vm._e(),(_vm.editRoute)?_c('BTooltip',{attrs:{"label":"Edit","position":"is-right","active":!_vm.isMobile}},[_c('router-link',{staticClass:"inspector-control",attrs:{"to":_vm.editRoute},on:{"click":_vm.handleInspectorBack}},[_c('BIcon',{attrs:{"icon":"pencil","custom-class":"fa-xl fa-fw"}})],1)],1):_vm._e()],1),(_vm.shouldUseDomainLogo)?_c('div',{staticClass:"center"},[_c('Logo',{staticStyle:{"font-size":"1.1em","color":"#ffffff"}})],1):_c('div',{staticClass:"center"},[_c('Title',{staticClass:"has-text-centered truncate"},[_vm._v(_vm._s(_vm.$label(_vm.title)))])],1),_c('div',{staticClass:"right"},[(!_vm.$route.meta.fullScreen && !_vm.isMobile)?_c('BTooltip',{attrs:{"label":"Full Screen","position":"is-left"}},[_c('div',{staticClass:"inspector-control",on:{"click":_vm.handleInspectorFullScreenToggle}},[_c('BIcon',{attrs:{"icon":_vm.isFullScreen ? 'compress-wide' : 'expand-wide',"custom-class":"fa-xl fa-fw"}})],1)]):_vm._e(),(!_vm.isMobile)?_c('BTooltip',{attrs:{"label":"Move","position":"is-left"}},[(!_vm.isFullScreen)?_c('div',{staticClass:"inspector-control",on:{"click":_vm.handleInspectorPositionToggle}},[_c('BIcon',{attrs:{"icon":_vm.inspector.position === 'right' ? 'square-arrow-down-left' : 'square-arrow-up-right',"custom-class":"fa-xl fa-fw"}})],1):_vm._e()]):_vm._e(),(!_vm.isFullScreen)?_c('BTooltip',{attrs:{"label":"Close","position":"is-left","active":!_vm.isMobile}},[_c('div',{staticClass:"inspector-control inspector-close",on:{"click":_vm.handleInspectorClose}},[_c('BIcon',{attrs:{"icon":"times","custom-class":"fa-xl fa-fw"}})],1)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }