<template>
  <div class="default-lorawan-network-server-device-input">

    <Box>

      <Field>
        <Label>Name *</Label>
        <TextInput v-model="local.name"/>
        <FormError :form="form" field="defaultDeviceName"/>
        <Help>Variables will be replaced when the device is onboarded.</Help>
      </Field>

      <Field>
        <Label>LoRaWAN Network Server Labels</Label>

        <template v-for="label in labels">
          <div style="margin-bottom: 5px;">
            <BCheckbox v-model="local.labelIds" :native-value="label.id">{{ label.name }}</BCheckbox>
          </div>
        </template>

        <Help>
          These labels will be automatically added to your device within the LNS.
        </Help>
      </Field>

    </Box>

  </div>
</template>

<script>
export default {
  name: '',
  computed: {
    lorawanNetworkServer() {
      return this.form.fields.lorawanNetworkServerId ? this.$store.getters['lorawanNetworkServer/show'](this.form.fields.lorawanNetworkServerId) : null;
    },
  },
  data() {
    return {
      local: {
        name: '',
        labelIds: [],
      },
    };
  },
  props: {
    form: {
      required: true,
    },
    labels: {
      default: () => [],
      type: Array,
    },
    value: {
      required: true,
    }
  },
  watch: {
    local: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.local) !== JSON.stringify(this.value)) {
          this.$emit('input', this.local);
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.local) !== JSON.stringify(this.value)) {
          this.local = {
            ...this.value,
          };
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">

</style>
