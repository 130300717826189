import {
  AccountService as Service,
  Domain,
  LorawanNetworkServer,
  Model,
  User,
} from '@/internal';

export default class Account extends Model {

  static get key() {
    return 'account';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      name: {
        required: true,
        type: String,
      },
    };
  }

  // Relationships

  get domain () {
    return this.belongsTo(Domain);
  }

  get lorawanNetworkServers() {
    return this.hasMany(LorawanNetworkServer);
  }

  get users() {
    return this.hasMany(User);
  }

  // Misc.

  shouldShowBilling() {
    return this.billing && this.billing.type !== null && this.billing.type !== 'none';
  }
}
