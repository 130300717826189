<template>
  <div id="device-index" class="is-padded">
    <DeviceResourceList/>
  </div>
</template>

<script>
import DeviceResourceList from '@/components-V2/Device/ResourceList';

export default {
  name: 'Index',
  components: {
    DeviceResourceList,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
