<template>
  <div class="lorawan-network-server">

    <BLoading active :is-full-page="false" v-if="loading"/>

    <template v-else>

      <template v-if="device.lorawanNetworkServerProviderId && providerDevice">

        <LabelLineValue label="Provider Device ID" :value="device.lorawanNetworkServerProviderId"/>
        <LabelLineValue label="Name" :value="providerDevice.name"/>
        <LabelLineValue label="Active" :value="providerDevice.active ? 'Yes' : 'No'"/>
        <LabelLineValue label="Onboarded" :value="providerDevice.in_xor_filter ? 'Yes' : 'Pending'"/>

        <div class="is-flex is-align-items-center">

          <span class="has-margin-right">Labels</span>

          <div class="tags">
            <template v-for="label in providerDeviceLabels">
              <span class="tag is-light">{{ label.name }}</span>
            </template>
          </div>

        </div>

        <div class="controls has-text-right">
          <BDropdown aria-role="list" position="is-top-left">
            <template #trigger="{ active }">
              <div class="button is-outlined is-light">Manage</div>
            </template>
            <BDropdownItem class="has-text-left" v-if="providerDevice" aria-role="listitem" @click="handleDeviceManageLabels">
              <BIcon icon="tag"/>
              Sync Labels
            </BDropdownItem>
            <BDropdownItem class="has-text-left" v-if="providerDevice" aria-role="listitem" @click="handleDeviceLoad">
              <BIcon icon="refresh"/>
              Refresh Device
            </BDropdownItem>
            <BDropdownItem class="has-text-left" aria-role="listitem" @click="handleDeviceDisconnect">
              <BIcon icon="link-slash"/>
              Disconnect Device
            </BDropdownItem>
            <BDropdownItem class="has-text-left" aria-role="listitem" @click="handleDeviceDelete">
              <BIcon icon="circle-minus"/>
              Delete Device
            </BDropdownItem>
          </BDropdown>
        </div>

      </template>

      <template v-else>

        <Instruction type="is-danger" v-if="!lorawanNetworkServers.length">
          You don't have any LoRaWAN Network Servers to connect with.<br/>
          <router-link to="/lorawan-network-server">Manage LoRaWAN Network Servers Connections</router-link>
        </Instruction>

        <template v-else>

          <Instruction type="is-info">
            If the Device already exists on this LoRaWan Network Server, it will be connected, but won't be duplicated.
          </Instruction>

          <Field>
            <Label>LoRaWAN Network Server</Label>
            <SelectInput :options="lorawanNetworkServersOptions" v-model="form.fields.id"/>
          </Field>

          <template>

            <Field>
              <Label>Name *</Label>
              <TextInput v-model="form.fields.name"/>
            </Field>

            <Field>
              <Label>Dev EUI *</Label>
              <TextInput v-model="form.fields.devEui" disabled/>
            </Field>

            <Field>
              <Label>App EUI *</Label>
              <TextInput v-model="form.fields.appEui" disabled/>
            </Field>

            <Field>
              <Label>App Key *</Label>
              <TextInput v-model="form.fields.appKey" disabled/>
            </Field>

            <Instruction v-if="lorawanNetworkServerInstruction" :type="lorawanNetworkServerInstruction.type">
              {{ lorawanNetworkServerInstruction.message }}
            </Instruction>

            <div class="controls has-text-right" v-if="readyToOnboard">
              <Button primary @click="handleDeviceOnboard">Onboard to LoRaWAN Network Server</Button>
            </div>

          </template>

        </template>

      </template>

    </template>

    <BModal :active.sync="labelModalActive" style="z-index: 41;">
      <div class="container container-extra-small">
        <Box flat black>
          <div class="is-pulled-right" v-if="labelsSaving">
            <div class="tag is-warning">Syncing</div>
          </div>
          <Title>Manage Labels</Title>
          <div class="provider-label-list">
            <template v-for="label in providerLabels">
              <div class="provider-label-list-checkbox">
                <BCheckbox v-model="form.fields.labelIds" :native-value="label.id"
                           @change.native="handleDeviceLabelsSync">
                  <span class="tag is-light">{{ label.name }}</span>
                </BCheckbox>
              </div>
            </template>
          </div>

          <div class="controls has-text-right">
            <Button primary @click="handleDeviceManageLabels">
              Done
            </Button>
          </div>

        </Box>
      </div>
    </BModal>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';
import {Form} from '@/internal';
import {DialogProgrammatic as Dialog} from 'buefy';

export default {
  name: 'LorawanNetworkServer',
  computed: {
    account() {
      return this.user ? this.user.account : null;
    },
    lorawanNetworkServerInstruction() {
      if (!this.device.deviceEui || !this.device.appEui || !this.device.appKey) {
        return {
          type: 'is-danger',
          message: `Your Device must have the App EUI and App Key set in order to onboard.`,
        };
      }

      return null;
    },
    lorawanNetworkServers() {
      return (this.$store.getters['lorawanNetworkServer/all'] || []);
    },
    lorawanNetworkServersOptions() {
      return [
        {
          label: '- Select a LoRaWAN Network Server - ',
          value: null,
        },
        ...this.lorawanNetworkServers.map((lorawanNetworkServer) => {
          return {
            label: lorawanNetworkServer.name,
            value: lorawanNetworkServer.id,
          };
        })
      ];
    },
    providerDeviceLabels() {
      return this.providerDevice ? this.providerDevice.labels.sort((a, b) => a.name > b.name ? 1 : -1) : [];
    },
    readyToOnboard() {
      return this.form.fields.id && this.device.deviceEui && this.device.appEui && this.device.appKey;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  async created() {
    await this.loadLorawanNetworkServers();

    if (this.device.lorawanNetworkServerId) {
      this.loadLorawanNetworkServerProviderDevice()
        .then(this.loadLorawanNetworkServerProviderLabels);
    }
  },
  data() {
    return {
      labelModalActive: false,
      labelsSaving: false,
      loading: false,
      form: new Form({
        id: null,
        deviceId: this.device.id,
        name: this.device.name,
        devEui: this.device.deviceEui,
        appEui: this.device.appEui,
        appKey: this.device.appKey,
        labelsIds: [],
      }),
      providerDevice: null,
      providerLabels: [],
    };
  },
  methods: {
    ...mapActions({
      deviceShow: 'device/show',
      lorawanNetworkServerIndex: 'lorawanNetworkServer/index',
      lorawanNetworkServerDeviceDisconnect: 'lorawanNetworkServer/deviceDisconnect',
      lorawanNetworkServerProviderDevice: 'lorawanNetworkServer/providerDevice',
      lorawanNetworkServerProviderDeviceDelete: 'lorawanNetworkServer/providerDeviceDelete',
      lorawanNetworkServerProviderDeviceLabelsSync: 'lorawanNetworkServer/providerDeviceLabelsSync',
      lorawanNetworkServerProviderDeviceOnboard: 'lorawanNetworkServer/providerDeviceOnboard',
      lorawanNetworkServerProviderLabels: 'lorawanNetworkServer/providerLabels',
    }),
    handleDeviceDelete() {

      let title = 'Delete Device from LNS';

      let message = `<p>Are you sure you want to delete this device from the LoRaWAN Network Server?</p>
                 <p style="background: #eee; border-radius: 4px; padding: 10px 0; text-align: center;">
                    <b>${this.providerDevice.name}</b>
                 </p>
                 <p class="has-text-centered">This action is permanent on the LNS.</p>
                 <p class="has-text-centered">This will not delete the device from ${this.$domainName}.</p>`;

      Dialog.confirm({
          title,
          message,
          type: 'is-danger',
          confirmText: 'Delete',
          onConfirm: () => {
            this.loading = true;
            this.lorawanNetworkServerProviderDeviceDelete({
              id: this.device.lorawanNetworkServerId,
              deviceId: this.device.id,
            }).then(() => {
              return this.deviceShow({
                id: this.device.id,
              });
            }).then(() => {
              window.app.snackbar('Device Deleted');
            }).finally(() => {
              this.loading = false;
            })
          },
        }
      );

    },
    handleDeviceDisconnect() {

      let title = 'Disconnect Device from LNS';

      let message = `<p>Are you sure you want to disconnect this device from the LoRaWAN Network Server?</p>
                 <p style="background: #eee; border-radius: 4px; padding: 10px 0; text-align: center;">
                    <b>${this.providerDevice.name}</b>
                 </p>
                 <p class="has-text-centered">This will not delete the device from the LNS, nor from ${this.$domainName}.</p>`;

      Dialog.confirm({
          title,
          message,
          type: 'is-danger',
          confirmText: 'Delete',
          onConfirm: () => {
            this.loading = true;
            this.lorawanNetworkServerDeviceDisconnect({
              id: this.device.lorawanNetworkServerId,
              deviceId: this.device.id,
            }).then(() => {
              return this.deviceShow({
                id: this.device.id,
              });
            }).then(() => {
              window.app.snackbar('Device Disconnected');
            }).finally(() => {
              this.loading = false;
            })
          },
        }
      );

    },
    handleDeviceLabelsSync(e) {
      let labelId = e.target.value;
      let checked = e.target.checked;
      this.labelsSaving = true;
      return this.lorawanNetworkServerProviderDeviceLabelsSync({
        id: this.device.lorawanNetworkServerId,
        deviceId: this.device.id,
        label: {
          id: labelId,
          checked,
        },
      }).then(() => {
        window.app.snackbar('Label Synced');
      }).then(this.loadLorawanNetworkServerProviderDevice).finally(() => {
        this.labelsSaving = false;
      });
    },
    handleDeviceLoad() {
      if (this.device.lorawanNetworkServerId) {
        this.loadLorawanNetworkServerProviderDevice();
      }
    },
    handleDeviceManageLabels() {
      this.labelModalActive = !this.labelModalActive;
    },
    handleDeviceOnboard() {
      this.loading = true;
      this.lorawanNetworkServerProviderDeviceOnboard({
        ...this.form.fields,
      }).then(() => {
        return this.deviceShow({
          id: this.device.id,
        });
      }).then(() => {
        return this.loadLorawanNetworkServerProviderDevice();
      }).then(() => {
        if (this.providerDevice.in_xor_filter) {
          window.app.snackbar('Device Onboarded');
        } else {
          window.app.snackbar('Device Onboarding Started');
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    loadLorawanNetworkServers() {
      this.loading = true;
      return this.lorawanNetworkServerIndex().finally(() => {
        this.loading = false;
      })
    },
    loadLorawanNetworkServerProviderDevice() {
      this.loading = true;
      return this.lorawanNetworkServerProviderDevice({
        id: this.device.lorawanNetworkServerId,
        deviceId: this.device.id,
      }).then((response) => {
        this.providerDevice = response.data;
        this.form.fields.labelIds = [
          ...this.providerDevice.labels.map(label => label.id),
        ];
      }).finally(() => {
        this.loading = false;
      });
    },
    loadLorawanNetworkServerProviderLabels() {
      this.loading = true;
      return this.lorawanNetworkServerProviderLabels({
        id: this.device.lorawanNetworkServerId,
      }).then((response) => {
        this.providerLabels = response.data ? response.data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {
    device: {
      required: true,
    }
  },
}
</script>

<style lang="scss">
.lorawan-network-server {

  .provider-label-list {
    background: $black-ter;
    border: 1px solid $grey;
    border-radius: 4px;
    padding: 10px;
    height: 300px;
    overflow-y: auto;

    .provider-label-list-checkbox {
      margin: 5px;
    }
  }
}
</style>
