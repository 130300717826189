<template>
  <div id="account-user-form" class="is-padded">

    <BLoading active
              :isFullPage="false"
              v-if="loading"
    />

    <Field v-if="user">
      <Label>ID</Label>
      <FakeInput :disabled="user !== null">{{ user.id }}</FakeInput>
    </Field>

    <Field>
      <Label>First Name *</Label>
      <TextInput v-model="form.fields.firstName" :disabled="!editable"/>
      <FormError field="firstName" :form="form"/>
    </Field>

    <Field>
      <Label>Last Name *</Label>
      <TextInput v-model="form.fields.lastName" :disabled="!editable"/>
      <FormError field="lastName" :form="form"/>
    </Field>

    <Field>
      <Label>
        Email *
      </Label>
      <EmailInput v-model="form.fields.email" :disabled="!editable"/>
      <FormError field="email" :form="form"/>
    </Field>

    <Field>
      <Label>Role *</Label>
      <SelectInput :options="roleOptions" v-model="form.fields.role" :disabled="!canEditRole" />
      <FormError field="role" :form="form"/>
    </Field>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>
          <Button @click="handleDelete" outlined danger :loading="deleting" v-if="user && user.id !== authUser.id">
            Remove from Account
          </Button>
        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button primary :loading="loading" @click="handleSave" class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

export default {
  name: 'Form',
  components: {},
  computed: {
    accounts() {
      return this.user ? this.user.accounts : [];
    },
    authenticated() {
      return this.$store.state.auth.authenticated;
    },
    authUser() {
      return this.authenticated ? this.$store.getters['auth/user'] : null;
    },
    canEditRole() {
      return !this.user || (this.user && this.authUser.id !== this.user.id);
    },
    editable() {
      return !this.user || (this.user && this.authUser.id === this.user.id);
    },
    user() {
      return this.$route.params.userId ? this.$store.getters['user/show'](this.$route.params.userId) : null;
    },
  },
  created() {
    if (this.$route.params.userId) {
      this.loadUser();
    }
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        id: this.$route.params.userId || null,
        accountId: null,
        firstName: '',
        lastName: '',
        email: '',
        role: 'user',
      }),
      loading: false,
      roleOptions: [
        {
          label: '- Select Role -',
          value: null,
        },
        {
          label: 'Owner',
          value: 'owner',
        },
        {
          label: 'Support',
          value: 'support',
        },
        {
          label: 'User',
          value: 'user',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      userShow: 'user/show',
      userStore: 'user/store',
      userUpdate: 'user/update',
      userDestroy: 'user/destroy',
    }),
    handleCancel() {
      this.$router.back();
    },
    handleDelete() {
      this.$deleteDialog({
        target: this.user.fullName,
        targetType: 'user',
        onConfirm: () => {
          this.deleting = true;
          this.userDestroy({
            id: this.user.id,
          }).then(() => {
            window.app.snackbar('User Removed');
            this.$router.push(window.app.findRouteByName('account/user/index'));
          }).finally(() => {
            this.deleting = false;
          })
        },
      });
    },
    handleSave() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.form.errors.clear();

      let action;
      let payload = {
        ...this.form.fields,
      };

      if (this.$route.params.userId) {
        action = this.userUpdate;
      } else {
        action = this.userStore;
      }

      action({
        ...payload,
      }).then(() => {

        window.app.snackbar({
          message: this.$route.params.userId ? 'User Updated' : 'User Added',
        });

        this.$router.back();

      }).catch((error) => {

        this.form.recordErrors(error);

      }).finally(() => {
        this.loading = false;
      });
    },
    loadUser() {
      this.userShow({
        id: this.$route.params.userId,
      }).then(() => {
        this.form.fields = {
          ...this.user,
        };
      });
    },
  }
};
</script>

<style scoped lang="scss">

</style>
