import {
  Model,
  IntegrationService as Service,
} from '@/internal';

export default class Integration extends Model {

  static get class() {
    return 'App\\Models\\Integration';
  }

  static get key() {
    return 'integration';
  }

  static get service() {
    return Service;
  }

}
