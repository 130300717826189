<template>

  <div class="conditions-form">

    <div class="resource-list">
      <template v-for="(condition, i) in localValue">
        <div class="conditions-logic" v-if="i > 0">
          <div class="line"></div>
          <div class="tag is-rounded is-primary">{{ ruleForm.fields.conditionsLogic }}</div>
          <div class="line"></div>
        </div>
        <div class="resource-list-item is-flex is-justify-content-space-between" :class="{ 'is-dark': $themeDark }">
          <div class="left">
            <ConditionLabel :condition="condition"/>
          </div>
          <div class="right is-flex">
            <div class="condition-edit pointer" @click="handleConditionEdit(i)">
              <BIcon icon="pencil"/>
            </div>
            <div class="condition-remove pointer has-margin-left-xs" @click="handleConditionDelete(i)">
              <BIcon icon="times-circle"/>
            </div>
          </div>
        </div>
      </template>
    </div>

    <BModal :active.sync="modalActive" @close="handleConditionCancel">
      <div class="container container-extra-extra-small">
        <Box flat :black="$themeDark">

          <Title>Condition</Title>

          <Field>
            <Label>Target *</Label>
            <SelectInput v-model="form.fields.config.targetCategory" :options="targetCategoryOptions"/>
            <FormError field="target" :form="form"/>
          </Field>

          <Field v-if="shouldShowResourceSelect">
            <Label>{{resourceLabel}} *</Label>
            <ResourceSelect :resource="resource"
                            :placeholder="placeholder"
                            v-model="form.fields.config.targetParentId"
                            :key="form.fields.config.targetCategory"
                            :params="params"
                            clearable
                            v-if="resource"
            />
          </Field>

          <Field v-if="form.fields.config.targetParentId">
            <Label>Type *</Label>
            <SelectInput v-model="form.fields.type" :options="types"/>
          </Field>

          <template v-if="shouldShowParameterFields">

            <Field>
              <Label>Parameter *</Label>
              <SelectInput v-model="form.fields.targetId" :options="deviceParameterOptions"/>
            </Field>

            <Field>
              <Label>Operator *</Label>
              <SelectInput v-model="form.fields.config.operator" :options="ruleOptions"/>
            </Field>

            <Field>
              <Label>Compare to *</Label>
              <SelectInput v-model="form.fields.config.compareTo" :options="compareToOptions"/>
            </Field>

            <Field v-if="form.fields.config.compareTo === 'value'">
              <Label>Threshold *</Label>
              <div class="value-wrapper">
                <div class="tag is-info" v-if="unit">
                  {{ unitLabel }}
                </div>
                <TextInput :disabled="!form.fields.targetId" v-model="form.fields.config.value"/>
              </div>
              <Help v-if="latestParameterValue">Current: {{ latestParameterValue.formattedValue }}</Help>
            </Field>

          </template>

          <div class="has-margin-top has-text-right">
            <Button @click="handleConditionCancel" outlined :light="$themeDark" :dark="$themeLight">
              Cancel
            </Button>
            <Button @click="handleSave" primary class="has-margin-left">
              Save
            </Button>
          </div>

        </Box>
      </div>
    </BModal>

    <div class="has-text-right has-margin-top">
      <Button outlined :light="$themeDark" @click="handleConditionAdd">
        Add Condition
      </Button>
    </div>

  </div>

</template>

<script>
import {
  Device,
  Form, Parameter,
  Tag,
} from '@/internal';

import ConditionLabel from '@/components-V2/ConditionLabel/ConditionLabel.vue';

export default {
  name: 'ConditionsForm',
  components: {
    ConditionLabel
  },
  computed: {
    converter() {
      return this.selectedParameter && this.selectedParameter.converterId ? this.$store.getters['converter/show'](this.selectedParameter.converterId) : null;
    },
    convertToUnit() {
      return this.converter ? this.$store.getters['unit/show'](this.converter.unitToId) : null;
    },
    deviceParameterOptions() {
      return this.parent && this.targetCategoryHasParameters ? [
        {
          label: '- Select a Parameter -',
          value: null,
        },
        ...this.parent.parameters.map((parameter) => {
          return {
            label: parameter.title,
            value: parameter.id,
          };
        }),
      ] : [];
    },
    latestParameterValue() {
      return this.selectedParameter ? this.selectedParameter.latestParameterValue : null;
    },
    params() {
      return {
        include: [
          'latestUplink',
          'parameters',
        ],
      }
    },
    placeholder() {
      switch (this.form.fields.targetType) {
        case Device.class:
          return 'Search for a Device';
        case Tag.class:
          return 'Search for a Tag';
        default:
          return null;
      }
    },
    resource() {
      switch (this.form.fields.targetType) {
        case Parameter.class:
          return Device;
        case Tag.class:
          return Tag;
        default:
          return null;
      }
    },
    resourceLabel() {
      switch (this.form.fields.targetType) {
        case Parameter.class:
          return 'Device';
        case Tag.class:
          return 'Tag';
        default:
          return null;
      }
    },
    selectedParameter() {
      return this.form.fields.targetId ? this.$store.getters['parameter/show'](this.form.fields.targetId) : null;
    },
    shouldShowResourceSelect() {
      return this.targetCategoriesThatNeedResource.includes(this.form.fields.config.targetCategory);
    },
    shouldShowParameterFields() {
      return this.form.fields.targetType === Parameter.class && this.form.fields.config.targetParentId;
    },
    parent() {

      if (this.form.fields.targetType === Parameter.class && this.form.fields.config.targetParentId) {
        return this.$store.getters['device/show'](this.form.fields.config.targetParentId);
      } else if (this.form.fields.targetType === Tag.class && this.form.fields.config.targetParentId) {
        return this.$store.getters['tag/show'](this.form.fields.config.targetParentId);
      }

      return null;
    },
    targetCategoriesThatNeedResource() {
      return [
        'device',
        'devices_with_tag',
        'parameters_with_tag',
      ];
    },
    targetCategoryHasStatus() {
      return [
        'devices',
        'device',
        'devices_with_tag',
      ].includes(this.form.fields.config.targetCategory);
    },
    targetCategoryHasParameters() {

      /**
       * Obviously 'devices' have parameters, but we need to be able to select the
       * specific parameter (or tagged parameter) to associate the condition with.
       */

      return [
        'device',
        'parameters_with_tag',
      ].includes(this.form.fields.config.targetCategory);
    },
    targetCategoryHasPosition() {
      return [
        'devices',
        'device',
        'devices_with_tag',
      ].includes(this.form.fields.config.targetCategory);
    },
    types() {
      let types = [{
        label: '- Select Event -',
        value: null,
      }];

      // if (this.targetCategoryHasStatus) {
      //   types.push({
      //     default: {
      //       operator: '==',
      //       value: 'any',
      //     },
      //     icon: 'wifi',
      //     label: 'Status Change',
      //     value: 'App\\Conditions\\DeviceStatusChange',
      //   });
      // }

      if (this.targetCategoryHasParameters) {
        types.push({
          icon: 'calculator-simple',
          label: 'Last Parameter Value',
          value: 'App\\Conditions\\ParameterValue',
        });
      }

      // if (this.targetCategoryHasPosition) {
      //   types.push({
      //     icon: 'map-location-dot',
      //     label: 'Position Change',
      //     value: 'position',
      //   });
      // }

      return types;
    },
    unit() {

      let unit = null;

      if (this.convertToUnit) {
        unit = this.convertToUnit;
      } else if (this.selectedParameter) {
        unit = this.$store.getters['unit/show'](this.selectedParameter.unitId);
      }

      return unit;
    },
    unitLabel() {

      if (!this.unit) {
        return '';
      } else if (this.unit.title) {
        return this.unit.title.toUpperCase();
      } else if (this.unit.slug) {
        return this.unit.slug.toUpperCase()
      } else {
        return '';
      }
    },
  },
  data() {
    return {
      compareToOptions: [
        {
          label: '- Select Comparison -',
          value: null,
        },
        {
          label: 'Specific Value',
          value: 'value',
        }
      ],
      deviceStatusOptions: [
        {
          label: 'Any',
          value: 'any',
        },
        {
          label: 'Offline',
          value: 'offline',
        },
        {
          label: 'Online',
          value: 'online',
        },
      ],
      form: new Form({
        type: null,
        config: {},
      }),
      loading: false,
      localValue: [],
      modalActive: false,
      ruleOptions: [
        {
          'label': '- Select Operator -',
          'value': null
        },
        {
          'label': 'Equal to',
          'value': '=='
        },
        {
          'label': 'Not equal to',
          'value': '!='
        },
        {
          'label': 'Greater than',
          'value': '>'
        },
        {
          'label': 'Greater than or equal to',
          'value': '>='
        },
        {
          'label': 'Less than',
          'value': '<'
        },
        {
          'label': 'Less than or equal to',
          'value': '<='
        },
      ],
      targetCategoryOptions: [
        {
          label: '- Select Target -',
          value: null,
        },
        // {
        //   label: 'All Devices',
        //   value: 'devices',
        // },
        {
          label: 'Specific Device',
          value: 'device',
        },
        // {
        //   label: 'Devices with Tag',
        //   value: 'devices_with_tag',
        // },
        // {
        //   label: 'Parameters with Tag',
        //   value: 'parameters_with_tag',
        // },
      ]
    };
  },
  methods: {
    formReset() {

      // Not sure white this.form.reset() isn't working

      this.form.fields = {
        type: null,
        config: {},
      };
    },
    handleConditionAdd() {
      this.formReset();
      this.modalActive = true;
    },
    handleConditionCancel() {
      this.modalActive = false;
      this.formReset();
    },
    handleConditionDelete(index) {
      this.localValue = [
        ...this.localValue.filter((condition, i) => i !== index),
      ];
    },
    handleConditionEdit(index) {
      let condition = this.localValue[index];
      condition.editing = index;
      this.form.fields = {
        ...condition,
      };
      this.modalActive = true;
    },
    handleSave() {

      if (this.form.fields.editing !== undefined) {
        this.localValue = [
          ...this.localValue.map(((condition, i) => this.form.fields.editing === i ? this.form.fields : condition)),
        ];
      } else {
        this.localValue = [
          ...this.localValue,
          {
            ...this.form.fields,
          },
        ];
      }

      this.form.reset();

      this.modalActive = false;

    },
  },
  props: {
    ruleForm: {
      required: true,
    },
    value: {
      required: true,
    }
  },
  watch: {
    localValue: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
          this.$emit('input', this.localValue);
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
          this.localValue = [
            ...this.value,
          ];
        }
      },
    },
    ['form.fields.config.targetCategory']() {

      switch (this.form.fields.config.targetCategory) {
        case 'device':
          this.form.fields = {
            ...this.form.fields,
            targetType: Parameter.class,
          };
          break;
        case 'assets_with_tag':
        case 'devices_with_tag':
        case 'parameters_with_tag':
          this.form.fields = {
            ...this.form.fields,
            targetType: Tag.class,
          };
          break;
        default:
      }
    },
  },
}
</script>

<style scoped lang="scss">
.value-wrapper {
  position: relative;

  .tag {
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
}

.conditions-logic {

  .line {
    background: $navigation-border-color;
    height: 20px;
    width: 1px;
  }

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -7px 0;
}
</style>
