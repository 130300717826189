<template>
  <div class="is-padded">

    <BLoading v-if="loading" active />

    <Box flat :black="$themeDark" class="asset-box">

      <div class="is-flex is-justify-content-space-between has-margin-bottom is-padded">
        <div class="left is-flex is-align-content-space-between">
          <div class="asset-name tag is-rounded">{{ asset.name }}</div>
        </div>
        <div class="right has-text-right">
          <Button outlined small :light="$themeDark" :dark="$themeLight" @click="handleAssetEdit(asset)">
            Manage
          </Button>
        </div>
      </div>

      <div class="has-text-centered">

        <template v-for="parameter in asset.parameters">

          <div @click="handleParameterValueReset">
            <Title class="temperature">
              {{parameterValue(parameter)}}
            </Title>
            <Subtitle class="parameter-value-created-at">
              {{ parameterValueCreatedAt(parameter) }}
            </Subtitle>
          </div>

          <div class="chart-wrapper">
            <highcharts :ref="parameter.uuid"
                        :options="chartOptions(parameter)"
                        v-if="chartOptions(parameter)"
            />
          </div>

        </template>

      </div>

    </Box>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';
import {Chart} from 'highcharts-vue';
import moment from 'moment-timezone';
import StatusDot from '@/components-V2/Device/StatusDot.vue';

const system = window.app.storage.getItem('system') || {
  settings: {
    datetimeFormat: 'lll z',
    timezone: moment.tz.guess() || 'utc'
  }
};

export default {
  name: 'Show',
  components: {
    StatusDot,
    highcharts: Chart,
  },
  computed: {
    asset() {
      return this.$route.params.assetUUID ? this.$store.getters['asset/findBy'](this.$route.params.assetUUID, 'uuid') : null;
    },
    deviceId() {
      return this.parameters.length ? this.parameters[0].deviceId : null;
    },
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    parameters() {
      return this.asset.parameters || [];
    },
  },
  created() {
    this.loadDevice();
  },
  data() {
    return {
      createdAt: null,
      loading: false,
      value: null,
    };
  },
  methods: {
    ...mapActions({
      deviceShow: 'device/show',
    }),
    chartOptions(parameter) {

      const system = window.app.storage.getItem('system') || {
        settings: {
          datetimeFormat: 'lll z',
          timezone: moment.tz.guess() || 'utc'
        }
      };

      if (!parameter || !parameter.chartData) {
        return null;
      }

      parameter.chartData.chartData.data = parameter.chartData.chartData.data.map((point) => {
        point.x = moment.utc(point.x).tz(system.settings.timezone).valueOf();

        return {
          ...point,
        };
      });

      return {
        id: `chart-${parameter.uuid}`,
        chart: {
          type: 'areaspline',
          backgroundColor: null, //this.$themeDark ? '#121212' : '#EBEBEB',
          spacing: [30, -5, 0, -5],
          height: 200,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          areaspline: {
            animation: false,
            connectNulls: true,
          },
          series: {
            marker: {
              enabled: false,
            },
            point: {
              events: {
                mouseOver: (event) => {
                  this.createdAt = moment.utc(event.target.x).tz(system.settings.timezone);
                  this.value = `${event.target.y}${event.target.unit}`;
                },
              }
            },
            events: {
              load: (event) => {

              },
              mouseOut: (event) => {
                // let parameterId = event.target.options.id;
                // this.parameterChartInspectedValue = {
                //   ...this.parameterChartInspectedValue,
                //   [parameterId]: null,
                // }
              },
            }
          },
        },
        series: [
          parameter.chartData.chartData,
        ],
        tooltip: {
          enabled: false,
        },
        title: {
          align: 'left',
          text: '', //parameter.title,
          style: {
            color: this.$themeDark ? '#EBEBEB' : '#121212',
          },
        },
        xAxis: {
          tickLength: 0,
          minorTickLength: 0,
          crosshair: [
            false,
          ],
          labels: {
            enabled: false,
          },
          title: {
            text: '',
          },
          type: 'datetime',
        },
        yAxis: {
          gridLineColor: this.$themeDark ? '#333' : '#CCC',
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
          title: {
            text: '',
          },
          startOnTick: false,
          endOnTick: false,
        },
      };
    },
    handleAssetEdit(asset) {
      this.$router.push({
        name: 'asset/edit',
        params: {
          assetUUID: asset.uuid,
        },
      });
    },
    handleParameterValueReset() {
      this.createdAt = null;
      this.value = null;
    },
    hasRulesTriggered(parameter) {

      let hasRulesTriggered = false;

      parameter.conditions.forEach((condition) => {
        if (condition.rule.fired) {
          hasRulesTriggered = true;
        }
      });

      return hasRulesTriggered;
    },
    loadDevice() {
      this.loading = true;
      this.deviceShow({
        id: this.deviceId,
        params: {
          include: [
            'parameters.asset',
            'parameters.conditions',
            'parameters.conditions.rule',
          ],
          with: [
            'parameters.chartData'
          ],
        },
      }).finally(() => {
        this.loading = false;
      })
    },
    parameterValue(parameter) {
      if (this.value) {
        return this.value;
      } else if (parameter.latestParameterValue && parameter.latestParameterValue.formattedValue) {
        return parameter.latestParameterValue.formattedValue;
      } else {
        return 'No Data Yet';
      }
    },
    parameterValueCreatedAt(parameter) {

      let createdAt = null;

      if (this.createdAt) {
        createdAt = this.createdAt;
      } else if (parameter.latestParameterValue) {
        createdAt = parameter.latestParameterValue.createdAt;
      }

      if (createdAt) {
        return createdAt.format('MMM Do ・ h:mm a');
      }

      return '';
    }
  },
};
</script>

<style scoped lang="scss">
.asset-box {
  padding: 0;

  .asset-name {

  }

  .asset-edit {

  }

  .temperature {
    font-size: 5em;
    margin: 30px 0 15px;
  }

  .parameter-value-created-at {
    font-size: 0.8em;
    margin-bottom: 30px;
  }

  .no-rules-triggered {
    color: $green;
    font-size: 1.3em;
  }

  .rules-triggered {
    color: $red;
    font-size: 1.3em;
  }

  .chart-wrapper {
    border-radius: 0 0 6px 6px;
    overflow: hidden;
  }
}
</style>
