<template>
  <div id="onboarding-form" class="is-padded">

    <BLoading active v-if="loading" :isFullPage="false"/>

    <Field>
      <Label>Enabled *</Label>
      <BSwitch v-model="form.fields.enabled"/>
    </Field>

    <Field>
      <Label>Name *</Label>
      <TextInput v-model="form.fields.name" placeholder="Widget, Soil Moisture Sensor, etc."/>
      <FormError :form="form" field="name"/>
    </Field>

    <Field v-if="onboarding">
      <Label>Code *</Label>
      <TextInput v-model="form.fields.code" placeholder="Auto generated, but customizable"/>
      <FormError :form="form" field="code"/>
    </Field>

    <Field v-if="onboarding">
      <Label>URL</Label>
      <FakeInput clickToCopy locked>{{ onboarding.url }}</FakeInput>
    </Field>

    <Field>
      <Label>Google Sheet ID *</Label>
      <TextInput v-model="form.fields.googleSheetId" placeholder="1Md7yfkr_j1v9AmumX-Uiz9JqAEyNuczHeCOUYZm1Iog"/>
      <FormError :form="form" field="googleSheetId"/>
      <Help>
        <a href="https://docs.google.com/spreadsheets/d/1ZFU0JcHcthNS9jExzza0j-2_NW1K6Kcs-tjx_-Xg5qs" target="_blank">Google
          Sheets Template</a>.
        This is how you'll manage the device credentials.<br/>
        You must share your Sheet with: uplinkengine@uplinkengine.iam.gserviceaccount.com
      </Help>
    </Field>

    <Field>
      <Label>{{ $domainName }} Device Template *</Label>
      <DefaultDeviceInput v-model="form.fields.defaultDevice" :form="form"/>
      <FormError :form="form" field="defaultDevice"/>
    </Field>

    <Field>
      <Label>LoRaWAN Network Server *</Label>

      <FakeInput locked v-if="lorawanNetworkServer">
        {{ lorawanNetworkServer.name }}
      </FakeInput>

      <template v-else>
        <ResourceSelect v-model="form.fields.lorawanNetworkServerId"
                        placeholder="Search for LNS"
                        :resource="lorawanNetworkServerResource"

        />
        <FormError :form="form" field="lorawanNetworkServerId"/>
      </template>

    </Field>

    <Field v-if="form.fields.lorawanNetworkServerId">
      <Label>LoRaWAN Network Server Template *</Label>
      <DefaultLorawanNetworkServerDeviceInput v-model="form.fields.defaultLnsProviderDevice"
                                              :form="form"
                                              :labels="providerLabels"
      />
      <FormError :form="form" field="defaultDevice"/>
    </Field>

    <Teleport to="#inspector-footer">
      <Controls>

        <template v-slot:left v-if="onboarding">
          <Button @click="handleDelete" outlined danger :loading="deleting">
            Delete
          </Button>
        </template>

        <template v-slot:right>
          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>
        </template>
      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
  LorawanNetworkServer,
} from '@/internal';

import DefaultDeviceInput from '@/components-V2/Inputs/DefaultDeviceInput';
import DefaultLorawanNetworkServerDeviceInput from '@/components-V2/Inputs/DefaultLorawanNetworkServerDeviceInput';

export default {
  name: 'Form',
  components: {
    DefaultDeviceInput,
    DefaultLorawanNetworkServerDeviceInput,
  },
  computed: {
    onboarding() {
      return this.$route.params.onboardingUUID ? this.$store.getters['onboarding/findBy'](this.$route.params.onboardingUUID, 'uuid') : null;
    },
    lorawanNetworkServer() {
      return this.form.fields.lorawanNetworkServerId ? this.$store.getters['lorawanNetworkServer/show'](this.form.fields.lorawanNetworkServerId) : null;
    },
  },
  async created() {
    if (this.$route.params.onboardingUUID) {
      await this.loadOnboarding();
    }

    if (this.onboarding) {
      this.fillForm();
    }
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        defaultDevice: {
          name: 'Acme Device {{serialNumberLastFour}}',
          parameters: [],
          positionsEnabled: true,
          public: true,
          tripsEnabled: true,
          tripsDownTime: 86400,
        },
        defaultLnsProviderDevice: {
          name: 'Acme Device {{serialNumberLastFour}}',
          labelIds: [],
        },
        enabled: true,
        lorawanNetworkServerId: null,
        name: '',
      }),
      lorawanNetworkServerResource: LorawanNetworkServer,
      loading: false,
      providerLabels: [],
    };
  },
  methods: {
    ...mapActions({
      lorawanNetworkServerProviderLabels: 'lorawanNetworkServer/providerLabels',
      onboardingDestroy: 'onboarding/destroy',
      onboardingShow: 'onboarding/show',
      onboardingStore: 'onboarding/store',
      onboardingUpdate: 'onboarding/update',
    }),
    fillForm() {
      this.form.fields = {
        ...this.onboarding,
      }
    },
    handleDelete() {
      this.$deleteDialog({
        target: this.onboarding.name,
        targetType: 'onboarding',
        onConfirm: () => {
          this.deleting = true;
          this.onboardingDestroy({
            id: this.onboarding.id,
          }).then(() => {
            window.app.snackbar('Onboarding Deleted');
            this.$router.push(window.app.findRouteByName('account/onboarding/index'));
          }).finally(() => {
            this.deleting = false;
          });
        },
      })
    },
    handleSave() {

      let action;

      if (this.onboarding) {
        action = this.onboardingUpdate;
      } else {
        action = this.onboardingStore;
      }

      this.loading = true;

      action(this.form.fields).then(() => {
        window.app.snackbar('Onboarding Saved');
        this.$router.push(window.app.findRouteByName('account/onboarding/index'));
      }).catch((error) => {
        if (error.response && error.response.data) {
          this.form.errors.record(error.response.data.errors);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    loadLorawanNetworkServerProviderLabels() {

      if (!this.lorawanNetworkServer.id) {
        return;
      }

      this.loading = true;
      return this.lorawanNetworkServerProviderLabels({
        id: this.lorawanNetworkServer.id,
      }).then((response) => {
        this.providerLabels = response.data ? response.data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : [];
      }).finally(() => {
        this.loading = false;
      });
    },
    loadOnboarding() {
      this.loading = true;
      return this.onboardingShow({
        id: this.$route.params.onboardingUUID,
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
  watch: {
    lorawanNetworkServer: {
      immediate: true,
      handler() {
        if (this.lorawanNetworkServer) {
          this.loadLorawanNetworkServerProviderLabels();
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">

</style>
