<template>
  <router-link :to="`/device/${device.uuid}`"
               class="device resource-list-item is-flex is-align-items-center is-justify-content-space-between"
               :class="{ 'is-dark': $themeDark }">
    <div class="left is-flex">
      <StatusDot :device="device"/>
      <div class="device-name has-margin-left">{{ device.name }} [{{device.lastFourOfDeviceEui}}]</div>
    </div>
    <div class="right" v-if="shouldShowUnlicensedTag">
      <div class="tag is-warning">Unlicensed</div>
    </div>
  </router-link>
</template>

<script>
import StatusDot from '@/components-V2/Device/StatusDot';

export default {
  name: 'ResourceListItem',
  components: {
    StatusDot,
  },
  computed: {
    account() {
      return this.user && this.user.account ? this.$store.getters['account/findBy'](this.user.account.uuid, 'uuid') : null;
    },
    device() {
      return this.resource;
    },
    shouldShowUnlicensedTag() {
      // Hide this for now - NBH - 06/28/24
      return false && this.account && this.account.billing.type === 'license' && !this.device.license;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">
.device.resource-list-item {

}
</style>
