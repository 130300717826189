<template>
  <div class="config">

    <div class="localValue">
      <Field>
        <Label>URL *</Label>
        <TextInput v-model="localValue.url" placeholder="https://example.com"/>
        <FormError :form="form" field="url"/>
      </Field>

      <Field>
        <Label>Method *</Label>
        <div class="buttons has-addons">
          <template v-for="method in methodOptions">
            <Button class="is-flex-grow-1"
                    :outlined="localValue.method !== method.value"
                    :primary="localValue.method === method.value"
                    :light="$themeDark && localValue.method !== method.value"
                    :dark="$themeLight && localValue.method !== method.value"
                    @click="localValue.method = method.value"
            >
              {{ method.label }}
            </Button>
          </template>
        </div>
        <FormError :form="form" field="method"/>
      </Field>

      <Field>
        <Label>Authorization (Bearer Token)</Label>
        <PasswordInput v-model="localValue.token" />
      </Field>

      <Field>
        <Label>Headers</Label>
        <template v-for="(header, i) in localValue.headers">
          <div class="header-row is-flex">
            <TextInput class="is-flex-grow-1" v-model="header.key" placeholder="Key"/>
            <TextInput class="is-flex-grow-1" style="margin-left: 10px;" v-model="header.value" placeholder="Value"/>
            <div style="margin-left: 10px;" class="is-flex is-align-items-center is-justify-content-center pointer"
                 @click="handleHeaderRemove(i)">
              <BIcon icon="minus-circle"/>
            </div>
          </div>
        </template>
        <div class="has-text-right">
          <Button style="width: 200px;" outlined :light="$themeDark" :dark="$themeLight" @click="handleHeaderAdd">Add
            Header
          </Button>
        </div>
      </Field>

      <Field>
        <Label>Query Parameters</Label>
        <template v-for="(param, i) in localValue.params">
          <div class="header-row is-flex">
            <TextInput class="is-flex-grow-1" v-model="param.key" placeholder="Key"/>
            <TextInput class="is-flex-grow-1" style="margin-left: 10px;" v-model="param.value" placeholder="Value"/>
            <div style="margin-left: 10px;" class="is-flex is-align-items-center is-justify-content-center pointer"
                 @click="handleParamRemove(i)">
              <BIcon icon="minus-circle"/>
            </div>
          </div>
        </template>
        <div class="has-text-right">
          <Button style="width: 200px;" outlined :light="$themeDark" :dark="$themeLight" @click="handleParamAdd">Add URL
            Parameter
          </Button>
        </div>
      </Field>

      <Field>
        <Label>Body</Label>
        <prism-editor class="prism-editor"
                      v-model="localValue.body"
                      :highlight="highlighter"
                      line-numbers
        />
        <Help>Valid JSON only</Help>
      </Field>

      <Controls>
        <template v-slot:right>
          <Button outlined primary @click="handleTest" :loading="loading">Test Webhook</Button>
        </template>
      </Controls>

      <Field v-if="response || error">
        <Label>Response</Label>
        <JSON :data="response" v-if="response" />
        <JSON :data="error" v-else-if="error" />
      </Field>

    </div>

  </div>
</template>

<script>
import {mapActions} from 'vuex';

import {PrismEditor} from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import {highlight, languages} from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-coy.css';

const defaultBody = `{
  hello: 'world',
}`;

export default {
  name: 'WebhookForm',
  components: {
    PrismEditor
  },
  data() {
    return {
      error: null,
      loading: false,
      localValue: {
        body: defaultBody,
        headers: [],
        method: 'GET',
        params: [],
        url: '',
        token: '',
      },
      methodOptions: [
        {
          label: 'GET',
          value: 'GET',
        },
        {
          label: 'POST',
          value: 'POST',
        },
        {
          label: 'PUT',
          value: 'PUT',
        },
        {
          label: 'PATCH',
          value: 'PATCH',
        },
      ],
      response: '',
    };
  },
  methods: {
    ...mapActions({
      webhookTest: 'integration/webhookTest',
    }),
    handleHeaderAdd() {
      this.localValue.headers.push({
        key: '',
        value: '',
      });
    },
    handleHeaderRemove(index) {
      this.localValue.headers = [
        ...this.localValue.headers.filter((h, i) => i !== index),
      ];
    },
    handleParamAdd() {
      this.localValue.params.push({
        key: '',
        value: '',
      });
    },
    handleParamRemove(index) {
      this.localValue.params = [
        ...this.localValue.params.filter((h, i) => i !== index),
      ];
    },
    handleTest() {
      this.error = null;
      this.loading = true;
      this.response = '';

      return this.webhookTest({
        config: {
          ...this.localValue,
        }
      }).then((response) => {
        this.response = response.data;
      }).catch((error) => {
        this.error = error.response.data;
      }).finally(() => {
        this.loading = false;
      });
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
  },
  props: {
    value: {},
    form: {}
  },
  watch: {
    localValue: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
          this.$emit('input', this.localValue);
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.value)) {
          this.localValue = {
            ...this.localValue,
            ...this.value,
          };
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
.config {
  .localValue {
    min-height: 200px;
  }

  .header-row {
    margin-bottom: 10px;
  }
}
</style>
