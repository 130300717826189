export default (to, from, next) => {

  let title;

  if (to && typeof to.meta.title === 'function') {
    title = to.meta.title();
  } else if (to && to.meta.title) {
    title = to.meta.title;
  } else {
    title = window.app.env.appName;
  }

  document.title = title;
  return next();
}
