<template>
  <div class="date-range-input">

    <div class="range-options">
      <template v-for="option in dateRangeOptions">
        <div class="range-option pointer" :class="{ 'is-active': localValue.dateRange === option.value }"
             @click="handleRangeOptionClick(option)">
          {{ option.label }}
        </div>
      </template>
    </div>

    <div class="spacer"></div>

    <div class="datepickers">

      <div class="datepicker-control pointer" @click="handleDateRangePrev">
        <BIcon icon="chevron-left"/>
      </div>

      <div class="date-range-input-date pointer" @click="handleDateRangeCustom('start')">
        {{ startLabel }}
      </div>

      <div class="is-padded-horizontal">
        -
      </div>

      <div class="date-range-input-date pointer" @click="handleDateRangeCustom('end')">
        {{ endLabel }}
      </div>

      <div class="datepicker-control pointer" @click="handleDateRangeNext">
        <BIcon icon="chevron-right"/>
      </div>

    </div>


    <BModal :active.sync="dateRangeControlActive" style="z-index: 41;">
      <div class="container container-extra-extra-small">
        <Box flat>

          <Title>Date Range</Title>

          <Field v-if="dateRangeControlInput === 'start'">
            <Label>Start Date</Label>
            <div class="has-text-centered">
              <BDatepicker dark inline v-model="localValue.start" :max-date="localValue.end"/>
            </div>
          </Field>

          <Field v-if="dateRangeControlInput === 'end'">
            <Label>End Date</Label>
            <div class="has-text-centered">
              <BDatepicker inline v-model="localValue.end" :min-date="localValue.start" :max-date="now"/>
            </div>
          </Field>

          <div class="controls has-text-right">
            <Button primary @click="handleDateRangeCustom(null)">Done</Button>
          </div>
        </Box>
      </div>

    </BModal>

  </div>

</template>

<script>
import moment from 'moment-timezone';

export default {
  name: 'DateRangeInput',
  computed: {
    dateRangeDiffInSeconds() {
      return this.localValue.start && this.localValue.end ? moment(this.localValue.end).diff(moment(this.localValue.start), 'seconds') : null;
    },
    dateRangeOptions() {
      return [
        {
          label: '1D',
          value: '1d',
          period: {
            amount: 1,
            unit: 'days'
          }
        },
        {
          label: '1W',
          value: '1w',
          period: {
            amount: 1,
            unit: 'weeks'
          }
        },
        {
          label: '1M',
          value: '1m',
          period: {
            amount: 1,
            unit: 'months'
          }
        },
        {
          label: '3M',
          value: '3m',
          period: {
            amount: 3,
            unit: 'months'
          }
        },
        {
          label: '1Y',
          value: '1y',
          period: {
            amount: 1,
            unit: 'years'
          }
        },
        {
          label: 'CUSTOM',
          value: 'custom',
          period: {
            amount: 1,
            unit: 'week',
          }
        },
      ];
    },
    endLabel() {
      return this.localValue.end ? moment(this.localValue.end).format('ll') : '';
    },
    now() {
      return new Date();
    },
    startLabel() {

      let dateRangeFormat = 'MMM D';

      if (moment(this.localValue.start).year() !== moment(this.localValue.end).year()) {
        dateRangeFormat = 'll';
      }

      return this.localValue.start ? moment(this.localValue.start).format(dateRangeFormat) : '';
    },
  },
  data() {

    const defaultLocalValue = {
      dateRange: '1w',
      end: moment().toDate(),
      start: moment().subtract(1, 'week').toDate(),
    };

    const stored = this.storageKey ? window.app.storage.getItem(this.storageKey) : defaultLocalValue;

    return {
      dateRangeControlActive: false,
      dateRangeControlInput: null,
      samplingControlActive: false,
      localValue: {
        ...stored,
        end: moment(stored.end).toDate(),
        start: moment(stored.start).toDate(),
      },
    };
  },
  methods: {
    handleDateRangeCustom(input) {
      this.localValue.dateRange = 'custom';
      this.dateRangeControlActive = !this.dateRangeControlActive;
      this.dateRangeControlInput = input;
    },
    handleDateRangePrev() {

      if (!this.dateRangeDiffInSeconds) {
        return;
      }

      let start = moment(this.localValue.start).subtract(this.dateRangeDiffInSeconds, 'seconds');
      let end = moment(this.localValue.end).subtract(this.dateRangeDiffInSeconds, 'seconds');
      this.localValue.start = start.toDate();
      this.localValue.end = end.toDate();
    },
    handleDateRangeNext() {

      if (!this.dateRangeDiffInSeconds) {
        return;
      }

      let start = moment(this.localValue.start).add(this.dateRangeDiffInSeconds, 'seconds');
      let end = moment(this.localValue.end).add(this.dateRangeDiffInSeconds, 'seconds');

      if (end.isAfter(moment())) {
        end = moment();
        start = moment().subtract(this.dateRangeDiffInSeconds, 'seconds');
      }

      this.localValue.start = start.toDate();
      this.localValue.end = end.toDate();
    },
    handleRangeOptionClick(option) {

      this.localValue.dateRange = option.value;

      if (option.value === 'custom') {
        this.handleDateRangeCustom('start');
        return;
      }

      this.localValue.start = moment().subtract(option.period.amount, option.period.unit).toDate();
      this.localValue.end = moment().toDate();
    },
  },
  props: {
    storageKey: {
      required: false,
    },
    value: {
      required: true,
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler() {

        if (this.storageKey) {
          window.app.storage.setItem(this.storageKey, {
            ...this.localValue,
          })
        }

        this.$emit('input', {
          ...this.localValue,
        });
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.value) !== JSON.stringify(this.localValue)) {
          this.localValue = {
            ...this.localValue,
            ...this.value,
          }
        }
      },
    }
  },
}
</script>

<style scoped lang="scss">
.date-range-input {

  align-items: center;
  display: flex;
  justify-content: center;

  .spacer {
    border-left: 1px solid $grey-dark;
    display: inline-block;
    height: 35px;
    margin: 0 10px;
    width: 1px;
  }

  .range-options {
    display: flex;

    .range-option {
      padding: 10px 15px;

      &.is-active {
        border-bottom: 1px solid $info;
      }
    }
  }

  .datepickers {
    display: flex;

    .datepicker-control {
      padding: 0 5px;
    }

    .date-range-input-date {
      margin: 0 5px;
      white-space: nowrap;
    }
  }
}
</style>
