<template>
  <div class="alarm-resource-list">
    <ResourceList addLabel="Add Alarm"
                  :addRoute="addRoute"
                  :params="params"
                  :query="query"
                  :resource="resource"
                  :renderAs="renderAs"
                  :sortByOptions="sortByOptions"
    />
  </div>
</template>

<script>
import {Alarm} from '@/internal';
import AlarmResourceListItem from '@/components-V2/Alarm/ResourceListItem';

export default {
  name: 'AlarmResourceList',
  data() {
    return {
      addRoute: !this.hideAddButton ? window.app.findRouteByName('alarm/create') : null,
      params: {
        include: [],
      },
      renderAs: AlarmResourceListItem,
      resource: Alarm,
      sortByOptions: [
        {
          label: 'Name',
          value: 'name',
        },
        {
          label: 'Created Date',
          value: 'created_at',
        },
      ],
    };
  },
  props: {
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {},
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
