<template>
  <div class="unit-resource-list">
    <ResourceList addLabel="Add Unit"
                  :addRoute="addRoute"
                  :params="params"
                  :query="query"
                  :resource="resource"
                  :renderAs="renderAs"
                  sortBy="title"
                  :sortByOptions="sortByOptions"
    />
  </div>
</template>

<script>
import {Unit} from '@/internal';
import UnitResourceListItem from '@/components-V2/Unit/ResourceListItem';

export default {
  name: 'UnitResourceList',
  data() {
    return {
      addRoute: !this.hideAddButton ? window.app.findRouteByName('unit/create') : null,
      params: {},
      renderAs: UnitResourceListItem,
      resource: Unit,
      sortByOptions: [
        {
          label: 'Title',
          value: 'title',
        },
        {
          label: 'Created Date',
          value: 'created_at',
        },
      ],
    };
  },
  props: {
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {},
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
