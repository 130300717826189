<template>
  <div id="account-form" class="is-padded">

    <BLoading active v-if="loading"/>

    <div class="is-padded-vertical" v-else>

      <Field>
        <Label>ID</Label>
        <FakeInput clickToCopy locked>{{ user.account.uuid }}</FakeInput>
      </Field>

      <Field>
        <Label>Name</Label>
        <TextInput v-model="form.fields.name" placeholder="Acme Co."/>
        <FormError field="name" :form="form"/>
      </Field>

    </div>

    <Teleport to="#inspector-footer">

      <Controls>
        <template v-slot:left>
          <Button danger block outlined v-if="user.hasRole('owner')" @click="handleDelete" :loading="deleting">
            Delete
          </Button>
        </template>
        <template v-slot:right>
          <Button primary :loading="loading" @click="handleSave">Save</Button>
        </template>
      </Controls>

    </Teleport>


  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';


import {
  Form,
} from '@/internal';

export default {
  name: 'Account',
  components: {},
  computed: {
    account() {
      return this.user ? this.$store.getters['account/show'](this.user.account.id) : null;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    this.loadAccount();
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        name: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      accountShow: 'account/show',
      destroy: 'account/destroy',
      userShow: 'auth/userShow',
      update: 'auth/accountUpdate',
    }),
    fillForm() {
      this.form.fields.name = this.account.name;
    },
    handleDelete() {
      window.app.confirm({
        title: 'Delete Account',
        message: `Are you REALLY sure you want to delete your account? This will delete all your data. There's undo button.`,
        onConfirm: () => {
          this.deleting = true;

          const account = this.user.account;

          this.destroy({
            id: account.id,
          }).then(() => {

            window.app.snackbar(`${account.name} was deleted`);
            window.location.href = '/auth/sign-up';

          }).finally(() => {
            this.deleting = false;
          });
        },
      });
    },
    handleSave() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.form.errors.clear();
      this.update({
        ...this.form.fields,
      }).then(() => {

        window.app.snackbar({
          message: 'Account Updated',
        });

      }).catch((error) => {

        this.form.recordErrors(error);

      }).finally(() => {
        this.loading = false;
      });
    },
    loadAccount() {
      this.loading = true;
      this.accountShow({
        id: this.user.account.uuid,
      }).then(() => {
        this.fillForm();
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
};
</script>

<style scoped lang="scss">

</style>
