<template>
  <div class="tag"
       :class="{
        'has-text-light': hasTextLight,
        pointer: clickable,
       }"
       :style="`background: ${tag.color};`"
  >
    <span class="is-uppercase" @click="handleClick">{{ tag.name }}</span>
    <span class="close" @click="handleClose" v-if="closable">
      <BIcon icon="times"/>
    </span>
  </div>
</template>

<script>
import {
  Tag,
} from '@/internal';

export default {
  name: 'Tag',
  computed: {
    hasTextLight() {
      return this.getColorBrightness < 128;
    },
    getColorBrightness() {

      let hexCode = this.tag.color;

      hexCode = hexCode.replace('#', '');

      let c_r = parseInt(hexCode.substr(0, 2), 16);
      let c_g = parseInt(hexCode.substr(2, 2), 16);
      let c_b = parseInt(hexCode.substr(4, 2), 16);

      return ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    },
  },
  methods: {

    handleClick() {

      if (!this.clickable) {
        return;
      }

      let query = {
        ...this.$route.query,
      };

      delete query.page;
      query.search = this.tag.name;

      this.$router.push({
        ...this.$route,
        name: this.routeName,
        query,
      });
    },
    handleClose() {
      this.$emit('close', this.tag);
    },
  },
  props: {
    clickable: {
      default: true,
      type: Boolean,
    },
    closable: {
      default: false,
      type: Boolean,
    },
    routeName: {
      type: String,
    },
    tag: {
      required: true,
      type: Tag,
    },
  },
};
</script>

<style scoped lang="scss">
.tag {
  align-items: center;
  background: $darkBlue;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  .close {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    margin-right: -2.5px;
  }
}
</style>
