import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const {
    user,
  } = payload;

  return new Menu({
    id: 'user',
    title: user.fullName,
    icon: 'circle-user',
    open: false,
    menuItems: [
      {
        route: window.app.findRouteByName('profile'),
      },
      {
        route: window.app.findRouteByName('channels'),
      },
      {
        route: window.app.findRouteByName('notifications'),
      },
      {
        route: window.app.findRouteByName('password'),
      },
      {
        route: window.app.findRouteByName('two-factor'),
      },
      {
        route: window.app.findRouteByName('auth/logout'),
      },
    ],
  });
};
