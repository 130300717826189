import RegionsMapLayer from './RegionsMapLayer';

export default {
  id: 'regionLabels',
  syncedWith: 'regions',
  enabled: false,
  label: 'Labels',
  cursorHover: 'pointer',
  source({store}) {
    return RegionsMapLayer.source({store});
  },
  layout() {
    return {
      type: 'symbol',
      minzoom: 8,
      layout: {
        'text-field': ['get', 'title'],
        'text-anchor': 'top',
        'text-size': 17,
        'text-justify': 'center',
        'text-radial-offset': 0.75,
        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      },
      paint: {
        'text-color': '#111111',
        'text-halo-color': '#ffffff',
        'text-halo-width': 1,
      },
      before: 'zIndex1',
    };
  },
  onClick({e, router}) {

    const uuid = e.features[0].properties.uuid;

    let route = window.app.findRouteByName(`region/show`, [], {
      params: {
        'regionUUID': uuid
      }
    });

    router.push(route);
  },
};
