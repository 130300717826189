<template>
  <div class="summary" :class="{
    'is-active': summary.active,
    'is-inactive': summary.asset && !summary.active,
    'is-not-fired': summary.rule && !summary.rule.fired,
    'is-fired': summary.rule && summary.rule.fired,
    'is-unprotected': summary.asset && !summary.rule,
  }">

    <Title>
      {{title}}
    </Title>

    <BTooltip :label="summary.active ? 'Sensor Detected' : 'Sensor Not Detected'" type="is-light">
      <div class="ethernet-icon">
        <BIcon icon="ethernet"/>
      </div>
    </BTooltip>

    <div class="connection">

      <div class="cable"></div>

      <BTooltip :label="ruleLabel" type="is-light">
        <div class="rule-status" @click="handleRuleClick">
          <BIcon icon="bell-ring"/>
        </div>
      </BTooltip>
    </div>

    <div class="asset is-flex" :class="{ 'is-available': !summary.asset }">

      <template v-if="summary.asset">

        <div
          class="is-flex-grow-1 is-flex is-flex-direction-column is-align-items-center is-justify-content-space-around">

          <div class="asset-name tag is-rounded truncate">
            {{ summary.asset.name }}
          </div>

          <div class="asset-temperature" v-if="summary.temperature">
            {{ summary.temperature }}
          </div>

          <div v-else>
            Awaiting Data
          </div>

          <div class="asset-last-parameter-value-at has-margin-bottom" v-if="summary.latestParameterValueAt">
            {{ summary.latestParameterValueAt.format('lll') }}
          </div>

        </div>

        <Button outlined :dark="$themeLight" :light="$themeDark" @click="handleAssetShow">
          View Freezer
        </Button>

      </template>

      <template v-else>

        <div class="asset-name is-flex-grow-1 is-flex is-align-items-center is-justify-content-center">
          Freezer<br />
          Available
        </div>

        <div v-if="!summary.temperatureParameter">
          Contact Support!
        </div>

        <Button outlined :light="$themeDark" :dark="$themeLight" @click="handleAssetCreate" v-else>
          Add Freezer
        </Button>
      </template>

    </div>
  </div>
</template>

<script>
export default {
  computed: {
    ruleLabel() {
      if (this.summary.asset && !this.summary.rule) {
        return 'Configuration Required';
      } else if (this.summary.rule && this.summary.rule.fired) {
        return 'Rule Fired!';
      } else {
        return 'Rule Settings';
      }
    },
  },
  methods: {
    handleAssetCreate() {
      this.$router.push({
        name: 'asset/create',
        query: {
          parameterId: this.summary.temperatureParameter.id,
        },
      });
    },
    handleAssetShow() {
      this.$router.push({
        name: 'asset/show',
        params: {
          assetUUID: this.summary.asset.uuid,
        },
      });
    },
    handleRuleClick() {
      if (this.summary.rule) {
        this.$router.push({
          name: 'rule/edit',
          params: {
            ruleUUID: this.summary.rule.uuid,
          },
        });
      } else {
        this.$router.push({
          name: 'rule/create',
          query: {
            deviceId: this.summary.temperatureParameter.deviceId,
            parameterId: this.summary.temperatureParameter.id,
          },
        });
      }
    },
  },
  props: {
    summary: {
      required: true,
    },
    title: {
      required: true,
      type: String,
    },
  }
};
</script>

<style scoped lang="scss">
.summary {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 10px;

  &.is-unprotected {
    .connection {
      .rule-status {
        background: $warning;
        border-color: $warning;
        color: $dark;
      }
    }
  }

  &.is-not-fired {
    .connection {
      .rule-status {
        border-color: $green;
      }
    }
  }

  &.is-fired {

    .connection {
      .rule-status {
        background: $danger;
        border-color: $danger;
      }
    }

    .asset {
      border-color: $danger !important;
    }
  }

  &.is-inactive {

    .title {

    }

    .ethernet-icon {
      border-color: $warning;
    }

    .connection {
      .cable {
        background: $warning;
      }

      .port-status {
        background: $warning;
        color: $black;
      }
    }

    .asset {
      border-color: $warning;
    }
  }

  &.is-active {

    .title {

    }

    .ethernet-icon {
      border-color: $green;
    }

    .connection {
      .cable {
        background: $green;
      }

      .port-status {
        border-color: $green;
      }

      .rule-status {

      }
    }

    .asset {
      border-color: $green;
    }
  }

  $size: 45px;

  .title {
    border-radius: 3px;
    font-size: 1.5rem;
    margin: 0;
    padding: 15px;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .ethernet-icon {
    align-items: center;
    background: $black;
    border: 1px solid $navigation-border-color;
    border-radius: 3px;
    display: inline-flex;
    font-size: 1.3em;
    height: $size;
    justify-content: center;
    width: $size;

    .icon {
      margin: 0;
    }
  }

  .connection {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    position: relative;
    text-align: center;

    .cable {
      $width: 4px;
      background: $navigation-border-color;
      bottom: 0;
      left: 50%;
      margin-left: $width / -2;
      position: absolute;
      top: 0;
      width: $width;
      z-index: 1;
    }

    .port-status, .rule-status {
      align-items: center;
      background: $dark;
      border: 1px solid $navigation-border-color;
      border-radius: 30px;
      display: flex;
      height: $size;
      justify-content: center;
      margin-bottom: 30px;
      padding: 10px;
      position: relative;
      text-align: center;
      width: $size;
      z-index: 2;

      .icon {
        font-size: 1.3em;
      }
    }

    .rule-status {
      cursor: pointer;
    }
  }

  .asset {
    align-items: center;
    background: $black-bis;
    border: 1px solid $navigation-border-color;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 250px;
    justify-content: center;
    padding: 20px;
    width: 100%;

    &.is-available {
      border-style: dashed;

    }

    .asset-name {
      width: 100%;
    }

    .asset-temperature {
      font-size: 3em;
    }

    .asset-last-parameter-value-at {
      color: $grey;
      font-size: 0.7em;
    }

    .button {
      width: 100%;
    }
  }
}
</style>
