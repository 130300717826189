import {
  ResourceService,
} from '@/internal';

export default class TagService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'tag';
  }
}
