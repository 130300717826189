export default {
  id: 'zIndex3',
  hidden: true,
  enabled: true,
  source() {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    };
  },
  layout() {
    return {
      type: 'symbol',
      before: 'zIndex4',
    };
  }
};
