<template>
  <div class="device-charts">

    <BLoading active :is-full-page="false" v-if="loading"/>

    <RangePickerInput storageKey="device/show" v-model="dateRange" @input="handleDateRangeChange"/>

    <Field>

      <BCheckbox v-model="sampling">Sample Data</BCheckbox>

      <SampleSizeInput v-model="sample" v-if="sampling"/>
      <Help style="white-space: nowrap;" v-if="sampling && uplinksCountForDateRange">
        Showing {{ Math.floor(uplinksCountForDateRange / sample) }} of {{ uplinksCountForDateRange }}
        Uplinks within Date Range
      </Help>

    </Field>

    <template v-if="charts.length">
      <div v-for="chart in charts" class="ue-chart">
        <highcharts :ref="chart.id" :options="chart"></highcharts>
      </div>
    </template>

    <div class="notification is-warning" v-else-if="!loading">No charts available.</div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {Chart} from 'highcharts-vue';
import moment from 'moment-timezone';

import SampleSizeInput from '@/components/SampleSizeInput';

const defaultSampleMap = {
  '1h': 1,
  '1d': 2,
  '1w': 7,
  '1m': 25,
  '3m': 75,
  '1y': 300,
  'all': 300,
};

export default {
  name: 'Charts',
  components: {
    highcharts: Chart,
    SampleSizeInput,
  },
  computed: {
    system() {
      return this.$store.state.system;
    },
  },
  created() {
    window.app.vue.$on('uplinkReceived', this.reloadChartData);
    this.loadChartData();
  },
  destroyed() {
    window.app.vue.$off('uplinkReceived', this.reloadChartData);
  },
  data() {

    let parameterCharts = window.app.storage.getItem('parameterCharts') || {
      sampleMap: defaultSampleMap,
      sampling: true,
    };

    if (!window.app.storage.getItem('parameterCharts')) {
      window.app.storage.setItem('parameterCharts', parameterCharts)
    }

    let dateRange = window.app.storage.getItem('range-picker.device/show') || '1w';
    let sample = parameterCharts.sampleMap[dateRange];

    return {
      alarms: [],
      charts: [],
      dateRange,
      loading: false,
      sample,
      sampleMap: {
        ...parameterCharts.sampleMap,
      },
      sampling: parameterCharts.sampling,
      parameters: [],
      uplinksCountForDateRange: null,
    };
  },
  mounted() {
    if (this.$refs.chartsInspectorSection && this.$refs.chartsInspectorSection.show) {
      this.handleDateRangeChange();
    }
  },
  methods: {
    ...mapActions({
      deviceChartData: 'device/chartData',
    }),
    reloadChartData(e) {
      if (this.device && parseInt(e.deviceId) === this.device.id) {
        return this.loadChartData();
      }
    },
    createCharts() {
      this.charts = [];

      this.parameters.forEach((parameter, i) => {

        if (parameter.chart === 'map' || parameter.chart === 'none') {
          return;
        }

        let plotLines = [];
        let series = parameter.chartData; //this.seriesData(parameter);
        let minX = Math.min(...series.data.map(point => point.x));
        let maxX = Math.max(...series.data.map(point => point.x));
        let minY = parameter.min_y !== undefined ? parameter.min_y : null;
        let maxY = parameter.max_y !== undefined ? parameter.max_y : null;

        this.alarms.forEach((alarm) => {
          alarm.rules.forEach((rule) => {

            if (parseInt(rule.parameter_id) === parseInt(parameter.id)) {

              plotLines.push({
                color: '#9D0000',
                width: 1,
                value: parseFloat(rule.value),
                label: {
                  className: 'plotOptions-label',
                  text: `ALARM @ ${rule.value}${(parameter.unit.symbol || parameter.unit.title)}`,
                  align: 'left',
                  style: {
                    color: this.$themeDark ? '#EBEBEB' : '#121212',
                    fontSize: 10,
                  },
                },
              });
            }
          });
        });

        this.charts.push({
          id: `chart-${i}`,
          chart: {
            type: parameter.chart,
            backgroundColor: this.$themeDark ? '#121212' : '#EBEBEB',
            spacing: [20, 15, 20, 15],
            height: 200,
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            area: {
              animation: false,
            },
            bubble: {
              animation: false,
            },
            column: {
              animation: false,
            },
            line: {
              animation: false,
            },
            spline: {
              animation: false,
            },
            series: {
              dataLabels: {
                enabled: parameter.chart === 'bubble',
                format: '{point.y}'
              },
              marker: {
                enabled: parameter.chart === 'bubble',
              },
              point: {
                events: {
                  mouseOver: (event) => {
                    this.synchronizeCrosshair(event);
                  },
                }
              },
              events: {
                load: (event) => {
                  this.charts.forEach((chart) => {

                    const chartInstance = this.$refs[chart.id] ? this.$refs[chart.id][0].chart : null;

                    if (chartInstance) {

                      let min = chartInstance.yAxis[0].min;
                      let max = chartInstance.yAxis[0].max;
                      let pLine = chartInstance.yAxis[0].chart.options.yAxis[0].plotLines[0].value;

                      if (pLine >= max) {
                        chartInstance.yAxis[0].setExtremes(null, pLine * 1.5);
                      }
                      if (pLine < min) {
                        chartInstance.yAxis[0].setExtremes(pLine * 0.95, null);
                      }
                    }

                  });
                },
                mouseOut: (event) => {
                  this.charts.forEach((chart) => {
                    const chartInstance = this.$refs[chart.id] ? this.$refs[chart.id][0].chart : null;

                    if (chartInstance) {

                      if (chartInstance.tooltip) {
                        chartInstance.tooltip.hide();
                      }

                      if (chartInstance.customCrosshair) {
                        chartInstance.customCrosshair.element.remove();
                      }
                    }
                  });
                },
              }
            },
          },
          tooltip: {
            backgroundColor: '#FFFFFF',
            borderColor: 'transparent',
            borderRadius: 0,
            borderWidth: 0,
            style: {
              color: '#111111',
            },
            shadow: false,
            className: 'harvest-hawk-tooltip',
            useHTML: true,
            formatter: function () {
              const system = window.app.storage.getItem('system') || {
                settings: {
                  datetimeFormat: 'lll z',
                  timezone: moment.tz.guess() || 'utc'
                }
              };

              let date = moment.tz(this.x, system.settings.timezone).format(system.settings.datetimeFormat);

              return `<div style="text-transform: uppercase;">
                ${date}<br />
                ${parameter.title}: ${this.y}${this.points ? this.points[0].point.unit : ''}
              </div>`;
            },
            enabled: true,
            pointFormat: '',
            valueDecimals: parameter.precision || 0,
            split: true,
            units: parameter.unit,
          },
          series: [series],
          title: {
            align: 'left',
            text: parameter.title,
            style: {
              color: this.$themeDark ? '#EBEBEB' : '#121212',
            },
          },
          xAxis: {
            min: minX || null,
            max: maxX || null,
            tickLength: 0,
            minorTickLength: 0,
            crosshair: [false],
            labels: {
              enabled: false,
            },
            title: {
              text: '',
            },
            type: 'datetime',
          },
          yAxis: {
            gridLineColor: this.$themeDark ? '#333' : '#CCC',
            labels: {
              enabled: true,
            },
            title: {
              text: '',
            },
            min: minY,
            max: maxY,
            startOnTick: false,
            endOnTick: false,
            plotLines,
          },
        });

      });
    },
    loadChartData() {

      if (this.loading) {
        return new Promise((resolve) => {
          resolve();
        });
      }

      this.loading = true;

      return this.deviceChartData({
        id: this.device.id,
        params: {
          range: this.dateRange,
          sample: this.sampling ? this.sample : null,
        },
      }).then((response) => {
        this.alarms = response.data.alarms;
        this.parameters = response.data.parameters;
        this.uplinksCountForDateRange = response.data.uplinksCountForDateRange;
      }).finally(() => {
        this.loading = false;
      });
    },
    handleDateRangeChange() {
      this.sample = this.sampleMap[this.dateRange];
      return this.loadChartData();
    },
    synchronizeCrosshair(event) {

      let point = {
        x: event.target.x,
        y: event.target.y,
      };

      this.charts.forEach((chart) => {

        const chartInstance = this.$refs[chart.id] ? this.$refs[chart.id][0].chart : null;

        if (chartInstance) {

          const pointsIndex = chartInstance.series[0].xData.indexOf(point.x);

          if (chartInstance.customCrosshair) {
            chartInstance.customCrosshair.element.remove();
          }

          const pointData = chartInstance.series[0].points[pointsIndex];

          if (pointData && pointData.y !== null) {
            chartInstance.tooltip.refresh([pointData]);
          }

          // render crosshairs
          chartInstance.customCrosshair = chartInstance.renderer
            .rect(
              chartInstance.xAxis[0].toPixels(point.x),
              chartInstance.plotBox.y,
              1,
              chartInstance.plotBox.height
            )
            .attr({
              fill: 'white'
            }).add();

        }

      });
    }
  },
  props: {
    device: {
      required: true,
    }
  },
  watch: {
    parameters: {
      deep: true,
      handler() {
        this.createCharts();
      },
    },
    sample: {
      handler() {
        let parameterCharts = window.app.storage.getItem('parameterCharts');
        parameterCharts.sampleMap[this.dateRange] = parseInt(this.sample);
        this.sampleMap = {
          ...parameterCharts.sampleMap,
        };
        window.app.storage.setItem('parameterCharts', parameterCharts);
        return this.loadChartData();
      },
    },
    sampling: {
      handler() {
        let parameterCharts = window.app.storage.getItem('parameterCharts');
        parameterCharts.sampling = !!this.sampling;
        window.app.storage.setItem('parameterCharts', parameterCharts);
        return this.loadChartData();
      }
    },
  },
}
</script>

<style scoped lang="scss">
.device-charts {
  .ue-chart {
    border: 1px solid $grey-dark;
    border-radius: 4px;
    margin-bottom: 10px;
    overflow: hidden;
  }
}
</style>
