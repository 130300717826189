<template>
  <div id="map"
       :class="{
           'is-mobile': isMobile,
       }"
  >
    <BLoading active v-if="loading || !mapLoaded"/>
    <MapControls/>
    <MapLayers v-if="mapLoaded"/>
    <div id="credits">
      <div id="ue-credit">
        <a href="https://uplinkengine.com" target="_blank">
          <img :src="ueMark" alt="UplinkEngine">
          <div class="brand-name">
            <span class="thin">Uplink</span><span class="thick">Engine</span>
          </div>
        </a>
      </div>
    </div>
    <div id="mapbox"></div>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import mapboxgl from 'mapbox-gl';
import {MapLayer} from '@/internal';

import MapControls from '@/components-V2/Map/MapControls';
import MapLayers from '@/components-V2/Map/MapLayers';

export default {
  name: 'Map',
  components: {
    MapControls,
    MapLayers,
  },
  computed: {
    devices() {
      return this.$store.getters['device/all'];
    },
    inspectorActive() {
      return this.$route.name !== 'explorer';
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    layers() {
      return this.$store.getters['mapLayer/layers'] || [];
    },
    layersMap() {
      return this.$store.getters['mapLayer/layersMap'] || {};
    },
    map() {
      return this.$store.state.map.map || null;
    },
    userLocation() {
      return this.$store.state.map.userLocation || null;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      mapLoaded: false,
      ueMark: require('@/assets/images/ue-mark.png'),
      windowWidth: window.innerWidth,
    }
  },
  methods: {
    ...mapActions({
      assetIndex: 'asset/index',
      deviceIndex: 'device/index',
      regionIndex: 'region/index',
      setMap: 'map/setMap',
    }),
    initMap() {

      if(!document.getElementById('mapbox')) {
        return;
      }

      const mapStorage = window.app.storage.getItem('map') || null;
      const position = mapStorage ? mapStorage.position : null

      const mapCenter = position ? position.center : null;
      const zoom = position ? position.zoom : 14; // default
      const bearing = position ? position.bearing : 0;
      const pitch = position ? position.pitch : 0;

      let center;

      if (mapCenter) {
        center = [mapCenter.lng, mapCenter.lat];
      } else if (this.userLocation) {
        center = [this.userLocation.lng, this.userLocation.lat];
      } else {
        center = [-122.439, 37.755];
      }

      const mapStyle = this.$store.state.map.mapStyles[this.$store.state.map.mapStyle];
      const style = mapStyle.custom ? mapStyle.url : `mapbox://styles/${mapStyle.url}`;

      mapboxgl.accessToken = window.app.env.mapboxToken;

      const map = new mapboxgl.Map({
        antialias: true,
        bearing,
        center,
        container: 'mapbox',
        maxZoom: 26,
        minZoom: 0,
        pitch,
        style,
        zoom,
      });

      this.setMap(map);

      const scale = new mapboxgl.ScaleControl({
        maxWidth: 80,
        unit: 'metric'
      });
      this.map.addControl(scale, 'bottom-right');

      this.map.getCanvas().style.cursor = 'default';

      this.map.on('drag', () => {
        this.storeMapPosition();
      });

      this.map.on('rotate', () => {
        this.storeMapPosition();
      });

      this.map.on('zoom', () => {
        this.storeMapPosition();
      });

      this.map.on('click', (e) => {
        // if (e.features === undefined && this.$route.path !== '/') {
        //   this.$router.push({
        //     name: 'explorer',
        //     query: this.$route.query,
        //   });
        // }
      });

      this.map.on('mousemove', (e) => {
        const features = this.map.queryRenderedFeatures(e.point);
        if (features.length) {
          const topFeature = features[0];
          const layer = this.layersMap[topFeature.layer.id] ? new MapLayer(this.layersMap[topFeature.layer.id]) : null;
          this.map.getCanvas().style.cursor = layer && layer.cursorHover ? layer.cursorHover : 'default';
        }
      });

      this.map.on('load', () => {

        this.mapLoaded = true;

        const mapboxLogo = document.getElementsByClassName('mapboxgl-ctrl-logo')[0];
        mapboxLogo.remove()
        const credits = document.getElementById('credits');
        credits.prepend(mapboxLogo);
      });
    },
    loadData() {

      if (!this.user || !this.user.account) {
        return;
      }

      this.loading = true;

      Promise.all([
        this.assetIndex({
          params: {
            include: ['children']
          }
        }),
        this.deviceIndex({
          params: {
            include: [
              'license',
              'position',
            ],
          },
        }),
        this.regionIndex(),
      ]).then(() => {
        this.initMap();
        this.loading = false;
      });
    },
    storeMapPosition() {

      const map = window.app.storage.getItem('map') || {};

      map.position = {
        center: this.map.getCenter(),
        zoom: this.map.getZoom(),
        pitch: this.map.getPitch(),
        bearing: this.map.getBearing(),
      };

      window.app.storage.setItem('map', map);
    },
  },
};
</script>

<style lang="scss">
#map {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: $zindex-map-base;

  &.is-mobile {
    #credits {
      left: 15px;
      transform: none;
    }
  }

  #mapbox.mapboxgl-map {
    flex: 1;
    height: 100%;
    position: relative;
    visibility: visible;
    width: 100%;

    *:focus {
      outline: none;
    }
  }

  #credits {
    align-items: center;
    background: $black-ter;
    border-radius: 25px;
    bottom: 15px;
    display: flex;
    left: 50%;
    padding: 5px 10px 5px 12px;
    position: absolute;
    transform: translateX(-50%);;
    z-index: $zindex-map-controls;

    .mapboxgl-ctrl-logo {
      position: relative;
      top: -2px;
    }
  }

  #ue-credit {
    margin-left: 15px;

    a {
      align-items: center;
      color: $white;
      display: flex;
      justify-content: space-between;
      padding: 2.5px 5px;
      text-shadow: 0 0 10px $black;
    }

    img {
      height: 22px;
      margin-right: 2.5px;
    }

    .brand-name {
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      text-transform: uppercase;

      .thin {
        font-weight: 300;
      }

      .thick {
        font-weight: 600;
      }
    }
  }

  .mapboxgl-popup {
    max-width: 400px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;

    .mapboxgl-popup-content {
      color: #000 !important;
    }
  }

  #mapbox .mapboxgl-control-container .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-scale {
    background-color: transparent;
    border-top: none;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
    border-left: 3px solid white;
    border-image: initial;
    border-radius: 0;
    color: white;
    font-size: 12px;
    font-weight: 700;
    height: 10px;
    line-height: 0;
    text-align: center;
    text-shadow: 0 0 2px #000;
  }
}
</style>
