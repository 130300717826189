import {
  DomainService as Service,
  Product,
  Model,
} from '@/internal';
import {camelCase} from 'lodash';

export default class Domain extends Model {

  static get key() {
    return 'domain';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      uuid: {
        type: String,
      },
      name: {
        required: true,
        type: String,
      },
      logo: {
        required: false,
        type: String,
      },
      tagline: {
        required: false,
        type: String,
      },
      backgroundImage: {
        required: false,
        type: String,
      },
      url: {
        required: true,
        type: String,
      },
      appUrl: {
        required: true,
        type: String,
      },
      apiUrl: {
        required: true,
        type: String,
      },
      mailFrom: {
        required: true,
        type: String,
      },
    };
  }

  get products() {
    return this.hasMany(Product);
  }

  featureEnabled(key) {

    key = camelCase(key);

    if (!this.features) {
      return false;
    } else if (!this.features[key]) {
      return false;
    } else if (!this.features[key].enabled) {
      return false;
    }

    return true;
  }

  shouldShowBilling() {
    return this.billing && this.billing.type !== null && this.billing.type !== 'none';
  }
}
