<template>
  <router-link :to="`/region/${region.uuid}`" class="region resource-list-item" :class="{ 'is-dark': $themeDark }">
    <div class="region-name">{{region.name}}</div>
  </router-link>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    region() {
      return this.resource;
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
