<template>
  <router-link :to="`/alarm/${alarm.uuid}/edit`" class="alarm resource-list-item is-flex is-align-items-center is-justify-content-space-between" :class="{ 'is-dark': $themeDark }">
    {{alarm.name}}
    <BTag type="is-danger" v-if="alarm.lastFailedAt">TRIGGERED</BTag>
  </router-link>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    alarm() {
      return this.resource;
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
