<template>
    <Page class="four-zero-four">
        <section>
            <div class="container">
                <div class="has-text-centered">
                    <h1 class="title has-margin-bottom has-text-light">
                        404 - You're Lost
                    </h1>
                    <p>We can't find the page you requested.</p>
                </div>
            </div>
        </section>
    </Page>
</template>

<script>
  export default {
    name: 'FourZeroFour',
    created() {
      window.app.d('404: We may want to log this to find bad links.', this.$route);
    }
  };
</script>

<style lang="scss" scoped>
    .four-zero-four {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;

        .logo {
            height: 150px;
        }
    }
</style>
