<template>
  <div id="map-controls"
       :class="{
           'is-anchored': $layoutAnchored || isMobile,
           'is-dark': $themeDark,
           'is-floating': $layoutFloating && !isMobile,
           'is-mobile': isMobile,
       }"
       v-hotkey="keymap"
       @flyTo="handleFlyTo"
  >

    <MapLayersControl/>

    <MapStylesControl/>

    <div class="map-control" @click="handleZoomToAll">
      <BTooltip label="Zoom to All [a]" position="is-bottom" :active="!isMobile">
        <BIcon icon="expand" size="is-medium"/>
      </BTooltip>
    </div>

    <div class="map-control" @click="handleFlattenOrientation">
      <BTooltip label="Flatten Map [f]" position="is-bottom" :active="!isMobile">
        <BIcon icon="compass" size="is-medium"/>
      </BTooltip>
    </div>

    <div class="map-control" @click="handleRecenterMap">
      <BTooltip label="Center on Your Location [c]" position="is-bottom" :active="!isMobile">
        <BIcon icon="location-arrow" size="is-medium"/>
      </BTooltip>
    </div>

    <div class="map-control" :class="{ 'is-active': lockedOnInspected }" @click="handleSetLockedOnInspected">
      <BTooltip label="Lock Map on Inspected Object" position="is-bottom" :active="!isMobile">
        <BIcon icon="crosshairs-simple" size="is-medium"/>
      </BTooltip>
    </div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import MapLayersControl from '@/components-V2/Map/MapLayersControl';
import MapStylesControl from '@/components-V2/Map/MapStylesControl';

export default {
  name: 'MapControls',
  components: {
    MapLayersControl,
    MapStylesControl,
  },
  computed: {
    keymap() {
      return {
        c: this.handleRecenterMap,
        f: this.handleFlattenOrientation,
      };
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    lockedOnInspected() {
      return this.$store.state.map.lockedOnInspected;
    },
    map() {
      return this.$store.state.map.map;
    },
    mapStyle() {
      return this.$store.state.map.mapStyle;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    userLocation() {
      return this.$store.state.map.userLocation;
    },
  },
  created() {
    window.app.vue.$on('flyTo', this.handleFlyTo);

    if (navigator && navigator.permissions) {
      navigator.permissions.query({name: 'geolocation'}).then((response) => {
        if (response.state === 'granted') {
          navigator.geolocation.getCurrentPosition((position) => {
            return this.setUserLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          });
        }
      });
    }
  },
  destroyed() {
    window.app.vue.$off('flyTo', this.handleFlyTo);
  },
  data() {
    return {
      isLocal: process.env.VUE_APP_ENV === 'local',
      layersMenuEnabled: false,
      mapStylePreviewsEnabled: false,
      savingMapStyle: false,
      settingsModalActive: false
    };
  },
  methods: {
    ...mapActions({
      mapCenterOnUser: 'map/centerOnUser',
      mapFlattenOrientation: 'map/flattenOrientation',
      setLockedOnInspected: 'map/setLockedOnInspected',
      setUserLocation: 'map/setUserLocation',
      mapZoomToAll: 'map/zoomToAll',
    }),
    handleFlattenOrientation() {
      this.mapFlattenOrientation();
    },
    handleFlyTo(center) {

      if (!this.map) {
        return false;
      }

      const primaryMenu = this.$store.state.ui.primaryMenu;
      const inspector = this.$store.state.ui.inspector;
      const inspectorWidth = inspector.width;
      const primaryMenuWidth = primaryMenu.active ? primaryMenu.width : 0;

      let defaultPadding = 100;

      let left = primaryMenuWidth + defaultPadding;
      let right = inspectorWidth + defaultPadding;

      let padding = {
        top: defaultPadding,
        bottom: defaultPadding,
        left,
        right,
      }

      let flyTo = {
        center,
        essential: true,
        padding,
      };

      let zoomThreshold = 18;

      if (this.map.getZoom() < zoomThreshold) {
        flyTo.zoom = zoomThreshold;
      }

      this.map.flyTo(flyTo);
    },
    handleSetLockedOnInspected() {
      this.setLockedOnInspected(!this.lockedOnInspected);
    },
    handleRecenterMap() {

      if (this.userLocation) {
        this.mapCenterOnUser();
        return;
      }

      navigator.geolocation.getCurrentPosition((position) => {
        return this.setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }).then(() => {
          this.mapCenterOnUser();
        });
      });
    },
    handleRemoveCustomStyle(hash) {
      window.app.confirm({
        title: 'Remove Custom Map Style',
        message: 'Are you sure you want to remove this style?',
        onConfirm: () => {
          const map = window.app.storage.getItem('map');
          delete map.customMapStyles[hash];
          map.style = 'default';
          window.app.storage.setItem('map', map);
          window.location.reload();
        },
      });
    },
    handleZoomToAll() {
      this.mapZoomToAll();
    },
  },
  props: {},
};
</script>

<style lang="scss">
#map-controls {
  align-items: center;
  background: $theme-light-background-color;
  display: flex;
  justify-content: space-between;
  padding: 9px 7.5px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: $zindex-map-controls;

  &.is-mobile {
    border-left: none !important;
    border-right: none !important;
    left: 0;
    right: 0;
    transform: none;
  }

  &.is-anchored {
    border: 1px solid $navigation-border-color;
    border-top: none;
  }

  &.is-floating {
    border-radius: 30px;
    top: $layout-floating-spacing;

    .map-control {
      border-radius: 50%;
    }
  }

  &.is-dark {
    background: $black-bis;
  }

  .map-control {
    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;
    padding: 3px;

    .is-dark & {
      color: $white;
    }

    &.is-active {
      background: $primary;
      color: $white;
    }
  }

  .sub-map-controls {
    background: $grey-lighter;
    border-radius: 4px;
    padding: 15px;
    position: absolute;
    top: calc(100% + 5px);
  }

  &.is-dark .sub-map-controls {
    background: $black-bis;
  }
}
</style>
