import { render, staticRenderFns } from "./InspectorFooter.vue?vue&type=template&id=26d1e984&scoped=true&"
import script from "./InspectorFooter.vue?vue&type=script&lang=js&"
export * from "./InspectorFooter.vue?vue&type=script&lang=js&"
import style0 from "./InspectorFooter.vue?vue&type=style&index=0&id=26d1e984&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26d1e984",
  null
  
)

export default component.exports