<template>
  <div class="ui-layout-input">
    <SelectInput :options="optionsWithNullDefault" :value="value" @input="handleInput" />
  </div>
</template>

<script>
export default {
  name: 'SortOrderInput',
  computed: {
    optionsWithNullDefault() {
      return [
        {
          label: '- Select an Option -',
          value: null,
        },
        ...this.options,
      ];
    },
  },
  data() {
    return {};
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    }
  },
  props: {
    options: {
      default: () => [],
      required: true,
    },
    value: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
</style>
