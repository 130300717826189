<template>
  <div v-if="uplink" class="is-padded">

    <Uplink :uplink="uplink"/>

    <Section title="Estimated Location" v-if="!device.isVirtual && !isHarmonyAnalytica">
      <Instruction>This estimate is based on where the Device was when this specific Uplink was received.</Instruction>
      <EstimatedLocation :uplink="uplink"/>
    </Section>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>
          <Button @click="handleDelete" outlined danger :loading="deleting" v-if="device">
            Delete
          </Button>
        </template>

        <template v-slot:right>

        </template>

      </Controls>
    </Teleport>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import Uplink from '@/components-V2/Uplink/Preview';
import EstimatedLocation from '@/components-V2/Uplink/EstimatedLocation';

import {
  isHarmonyAnalytica,
} from '@/helpers';

export default {
  name: 'Show',
  components: {
    EstimatedLocation,
    Uplink,
  },
  computed: {
    device() {
      return this.uplink ? this.$store.getters['device/show'](this.uplink.deviceId) : null;
    },
    isHarmonyAnalytica() {
      return isHarmonyAnalytica();
    },
    parameterValues() {
      return this.uplink ? this.uplink.parameterValues : [];
    },
    uplink() {
      return this.$route.params.uplinkUUID ? this.$store.getters['uplink/findBy'](this.$route.params.uplinkUUID, 'uuid') : null;
    },
    uplinks() {
      return this.$store.getters['uplink/all'];
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (!this.uplink) {
      this.uplinkShow({
        id: this.$route.params.uplinkUUID,
      });
    }
  },
  data() {
    return {
      deleting: false,
    };
  },
  methods: {
    ...mapActions({
      uplinkShow: 'uplink/show',
      uplinkDestroy: 'uplink/destroy',
    }),
    handleDelete() {
      this.$deleteDialog({
        target: this.uplink.uuid,
        targetType: 'Uplink',
        onConfirm: () => {
          this.deleting = true;
          this.uplinkDestroy({
            id: this.uplink.uuid,
          }).then(() => {
            window.app.snackbar('Uplink Deleted');
            this.$router.back();
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.deleting = false;
          });
        },
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
