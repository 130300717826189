<template>
  <div class="position resource-list-item" :class="{ 'is-dark': $themeDark }">
    <div class="position-title">
      {{position.lat.toFixed(6)}}, {{position.lng.toFixed(6)}}<br />
      {{ position.createdAt.format(system.settings.datetimeFormat) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    system() {
      return this.$store.state.system;
    },
    position() {
      return this.resource;
    },
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
