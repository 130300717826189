import Index from '@/views/Alarm/Index';
import Form from '@/views/Alarm/Form';

const routes = [
  {
    name: 'alarm/index',
    path: '/alarm',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'triangle-exclamation',
      middleware: ['auth'],
      title: 'Alarms',
    },
  },
  {
    name: 'alarm/create',
    path: '/alarm/create',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('alarm/index');
      },
      containerSize: 'container-extra-small',
      icon: 'triangle-exclamation',
      middleware: ['auth'],
      title: 'New Alarm',
    },
  },
  {
    name: 'alarm/edit',
    path: '/alarm/:alarmUUID/edit',
    component: Form,
    meta: {
      backRoute: (route) => {
        return window.app.findRouteByName('alarm/index');
      },
      containerSize: 'container-extra-small',
      icon: 'triangle-exclamation',
      middleware: ['auth'],
      title: 'Edit Alarm',
    }
  },
  {
    name: 'alarm/show',
    path: '/alarm/:alarmUUID',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('alarm/index');
      },
      containerSize: 'container-medium',
      icon: 'triangle-exclamation',
      middleware: ['auth'],
      title: 'Alarm',
    },
  },
];

export default routes;
