import {
  ResourceService,
} from '@/internal';

export default class ProductService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'product';
  }
}
