<template>
  <div class="map-layer-manager-row-wrapper">
    <div class="map-layer-manager-row"
         :class="{ 'is-disabled': !layer.enabled }"
    >
      <div class="map-layer-manager-row-label"
           :class="{ pointer: !layer.alwaysEnabled }"
           @click="handleLayerToggle">
        {{ layer.label }}
      </div>
      <div class="map-layer-manager-row-controls">
        <div class="map-layer-manager-row-enabled" @click="handleLayerToggle" v-if="!layer.alwaysEnabled">
          <BIcon icon="eye-slash"/>
        </div>
        <div class="map-layer-manager-row-color">
          <ColorInput :disabled="!layer.enabled || layer.colorLocked"
                      v-model="layer.color"
                      v-if="layer.color"
                      :style="`opacity: ${layer.enabled ? 1 : 0.1};`"
          />
        </div>
        <div class="map-layer-manager-row-color" v-if="layer.colorEnd">
          <ColorInput :disabled="!layer.enabled || layer.colorLocked"
                      v-model="layer.colorEnd"
                      :style="`opacity: ${layer.enabled ? 1 : 0.1};`"
          />
        </div>
      </div>
    </div>

    <div class="children" v-if="layer.children && layer.children.length">
      <template v-for="childLayer in layer.children">
        <MapLayerManagerRow :value="childLayer" v-if="!childLayer.hidden" />
      </template>
    </div>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'MapLayerManagerRow',
  computed: {
    authenticated () {
      return this.$store.state.auth.authenticated;
    },
  },
  created () {
    this.layer = this.value;
    this.original = {
      ...this.value,
    };
  },
  data () {
    return {
      original: null,
      layer: null,
    };
  },
  methods: {
    ...mapActions({
      setLayer: 'mapLayer/setLayer',
    }),
    handleLayerToggle() {
      if (!this.authenticated && this.layer.requiresAuth) {
        window.app.snackbar({
          message: 'You must be logged in.',
        });
        return;
      }

      if (this.layer.alwaysEnabled) {
        this.layer.enabled = true;
      } else {
        this.layer.enabled = !this.layer.enabled;
      }

      if (JSON.stringify(this.layer) !== JSON.stringify(this.original)) {
        this.setLayer(this.layer);
        this.original = {
          ...this.layer,
        };
      }
    },
  },
  props: {
    value: {
      required: true,
    },
  },
  watch: {
    [`layer.color`]() {
      if (this.layer.color !== this.original.color) {
        this.setLayer(this.layer);
        this.original = {
          ...this.layer,
        };
      }
    },
    [`layer.colorEnd`]() {
      if (this.layer.colorEnd !== this.original.colorEnd) {
        this.setLayer(this.layer);
        this.original = {
          ...this.layer,
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.map-layer-manager-row-wrapper {

  .map-layer-manager-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2px 0;

    &:hover,
    &.is-disabled {
      .map-layer-manager-row-controls .map-layer-manager-row-enabled {
        visibility: visible;
      }
    }

    &.is-disabled {
      color: $grey-dark;
    }

    .map-layer-manager-row-label {
      white-space: nowrap;
    }

    .map-layer-manager-row-controls {
      align-items: center;
      display: flex;
      margin-left: 15px;

      & > div {
        margin-left: 5px;
      }

      .map-layer-manager-remove-custom-layer {
        cursor: pointer;
      }

      .map-layer-manager-row-color {
        width: 16px;
      }

      .map-layer-manager-row-enabled {
        cursor: pointer;
        visibility: hidden;
      }
    }
  }

  .children {
    margin-left: 2px;
    padding-left: 20px;
    position: relative;

    &:before {
      border-left: 1px dotted $primary;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      bottom: 14px;
    }

    & > .map-layer-manager-row-wrapper {
      position: relative;

      &:before {
        border-top: 1px dotted $primary;
        content: '';
        left: -19px;
        position: absolute;
        top: 50%;
        width: 10px;
      }
    }

  }

}
</style>
