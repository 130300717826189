<template>
  <div class="address-input">
    <div class="manual-input-toggle is-pulled-right" @click="handleManualInputToggle" v-if="allowAutocomplete">
      {{ manualInput ? 'Autocomplete' : 'Manual Input' }}
    </div>
    <Field v-if="!manualInput">
      <Label>{{ this.label }}</Label>
      <BAutocomplete :class="{ 'is-dark': $themeDark }"
                     icon="search"
                     v-model="searchTerm"
                     :loading="searching"
                     placeholder="Search by Address"
                     group-field="type"
                     group-options="items"
                     field="label"
                     :data="searchResults"
                     @input="handleSearch"
                     @select="handleSearchResultClicked"
                     autocomplete="nope"
      >
        <template #empty>No results found</template>
      </BAutocomplete>
    </Field>

    <template v-else>
      <Field>
        <Label>Street</Label>
        <TextInput v-model="address.street" placeholder="1663 Mission"/>
      </Field>
      <Field>
        <Label>Street 2</Label>
        <TextInput v-model="address.street2" placeholder="Suite 250"/>
      </Field>
      <Field>
        <Label>City</Label>
        <TextInput v-model="address.city" placeholder="San Francisco"/>
      </Field>
      <Field>
        <Label>State</Label>
        <TextInput v-model="address.state" placeholder="CA"/>
      </Field>
      <Field>
        <Label>Country</Label>
        <CountryInput v-model="address.country"/>
      </Field>
      <Field>
        <Label>Post Code</Label>
        <TextInput v-model="address.postCode" placeholder="94103"/>
      </Field>
    </template>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  debounce,
} from 'lodash';

import {
  Address,
} from '@/internal';

export default {
  name: 'AddressInput',
  components: {},
  data () {
    return {
      address: new Address(this.value),
      manualInput: !this.allowAutocomplete,
      performSearch: debounce(() => {
        this.searching = true;
        this.search({
          params: {
            q: this.searchTerm,
          },
        }).then((response) => {
          this.searchResults = response.data.results;
        }).finally(() => {
          this.searching = false;
        });
      }, 1000),
      searching: false,
      searchResults: [],
      searchTerm: this.value && this.value.name ? this.value.name : '',
    };
  },
  methods: {
    ...mapActions({
      search: 'system/search',
    }),
    handleManualInputToggle () {
      this.manualInput = !this.manualInput;
    },
    handleSearch () {
      if (!this.searchTerm.length) {
        this.address = {};
      }
      if (this.searchTerm.length <= 2) {
        this.address = {};
        return;
      }

      this.performSearch();
    },
    handleSearchResultClicked (result) {
      if (result && result.physical_address) {
        this.address = new Address({
          street: result.physical_address.street,
          street2: result.physical_address.street_2,
          city: result.physical_address.city,
          state: result.physical_address.state,
          postCode: result.physical_address.post_code,
          // country: countryAlpha2ToAlpha3(result.physical_address.country),
          lng: result.center ? result.center[0] : null,
          lat: result.center ? result.center[1] : null
        });
      }
    },
  },
  props: {
    allowAutocomplete: {
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
    },
    label: {
      default: 'Address',
      type: String
    }
  },
  watch: {
    address: {
      deep: true,
      handler () {
        this.$emit('input', this.address);
      },
    },
  },
};
</script>

<style lang="scss">
.address-input {
  position: relative;

  .manual-input-toggle {
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
  }

  .autocomplete {

    width: 100%;

    &.is-dark {
      input {
        background: $dark;
        border: 1px solid $grey-darker;
        color: $white;

        &::placeholder {
          color: $grey-dark;
          opacity: 1;
        }
      }
    }
  }
}
</style>
