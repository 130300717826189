import storage from '@/drivers/LocalStorageDriver';

import {
  getLayerFromStorage,
  saveLayerToStorage,
  saveLayerToStorageIfDoesNotExist,
} from '@/helpers';

let map = storage.getItem('map') || {};
// let savedLayers = map && map.layers ? map.layers : [];

import AssetsMapLayer from '@/components-V2/Map/MapLayers/AssetsMapLayer';
import RegionsMapLayer from '@/components-V2/Map/MapLayers/RegionsMapLayer';
import RegionLabelsMapLayer from '@/components-V2/Map/MapLayers/RegionLabelsMapLayer';
import DevicesMapLayer from '@/components-V2/Map/MapLayers/DevicesMapLayer';
import DeviceLabelsMapLayer from '@/components-V2/Map/MapLayers/DeviceLabelsMapLayer';
// import BuildingMapLayer from '@/components-V2/Map/MapLayers/BuildingsMapLayer';
// import OfiStorageMapLayer from '@/components-V2/Map/MapLayers/OfiStorageMapLayer';
// import OfiStorageLabelsMapLayer from '@/components-V2/Map/MapLayers/OfiStorageLabelsMapLayer';
import TerrainMapLayer from '@/components-V2/Map/MapLayers/TerrainMapLayer';
import UserLocationMapLayer from '@/components-V2/Map/MapLayers/UserLocationMapLayer';
import zIndex1MapLayer from '@/components-V2/Map/MapLayers/zIndex1MapLayer';
import zIndex2MapLayer from '@/components-V2/Map/MapLayers/zIndex2MapLayer';
import zIndex3MapLayer from '@/components-V2/Map/MapLayers/zIndex3MapLayer';
import zIndex4MapLayer from '@/components-V2/Map/MapLayers/zIndex4MapLayer';

let availableLayers = [
  zIndex4MapLayer,
  zIndex3MapLayer,
  zIndex2MapLayer,
  zIndex1MapLayer,
  // BuildingMapLayer,
  TerrainMapLayer,
  AssetsMapLayer,
  RegionsMapLayer,
  RegionLabelsMapLayer,
  DevicesMapLayer,
  DeviceLabelsMapLayer,
  // OfiStorageMapLayer,
  // OfiStorageLabelsMapLayer,
  UserLocationMapLayer,
];

if (!map.layers) {
  map.layers = availableLayers;
  storage.setItem('map', map);
}

let layers = availableLayers.map((layer) => {
  saveLayerToStorageIfDoesNotExist(layer);
  return {
    ...layer,
    ...getLayerFromStorage(layer.id),
  };
});

export default {
  key: 'mapLayer',
  namespaced: true,
  state: {
    layers,
    reloadLayers: [],
  },
  actions: {
    reloadLayers({commit}, payload) {
      return commit('reloadLayers', Array.isArray(payload) ? payload : [payload]);
    },
    reloadAllLayers({commit, state}) {
      return commit('reloadLayers', [
        ...state.layers.map(layer => layer.id),
      ]);
    },
    setLayer({commit}, payload) {
      return commit('layer', payload);
    },
  },
  mutations: {
    layer(state, payload) {

      // Get Synced Layers & Update Variables

      const syncedLayers = state.layers.filter(layer => layer.syncedWith === payload.id);

      // Update

      state.layers = [
        ...state.layers.map((layer) => {

          if (layer.id === payload.id) {
            return payload;
          }

          const syncedLayer = syncedLayers.find(_layer => _layer.id === layer.id);

          if (syncedLayer) {
            return syncedLayer;
          }

          return layer;
        }),
      ];

      state.layers.forEach((layer) => {
        saveLayerToStorage(layer);
      });

      // Set the changed layers for reloading

      state.reloadLayers = [
        payload.id,
        ...syncedLayers.map(layer => layer.id),
      ];
    },
    reloadLayers(state, payload) {
      state.reloadLayers = [
        ...payload,
      ];
    },
  },
  getters: {
    layer: (state, getters) => (layerId) => {
      return getters.layersMap[layerId] || null;
    },
    layers: (state) => {
      return state.layers;
    },
    layersMap: (state) => {
      const layersMap = {};
      state.layers.forEach((layer) => {
        layersMap[layer.id] = layer;
      });
      return layersMap;
    },
    reloadLayers: (state) => {
      return state.reloadLayers;
    },
  },
};
