<template>
  <div class="licenses-explorer">
    <BLoading active v-if="loading"/>

    <Title class="has-text-centered has-margin-bottom">
      Per Device License / month
    </Title>

    <div class="group-picker">
      <template v-for="group in groups">
        <div class="group" :class="{ 'is-active': selectedGroup === group.id }" @click="selectedGroup = group.id">
          {{ group.name }}
        </div>
      </template>
    </div>

    <table class="table licenses-table" :class="{ 'is-dark': $themeDark }">

      <tr>
        <th>License</th>
        <th>Ingest / Day</th>
        <th>Storage</th>
        <th>Cost / Mo</th>
        <th>Suggested Price / Mo</th>
      </tr>

      <template v-for="product in groupProducts">
        <tr>
          <td>{{ product.name }}</td>
          <td>{{ $numeral(product.quotas.ingest_per_day).format('0,0') }}</td>
          <td>{{ $daysToHumanReadable(product.quotas.storage_days) }}</td>
          <td>{{ $numeral(product.uplink_engine_price_per_month).format('$0,0.00') }}</td>
          <td>{{ $numeral(product.suggested_retail_price).format('$0,0.00') }}</td>
        </tr>
      </template>

    </table>

  </div>
</template>

<script>
import _ from 'lodash';

import {
  mapActions,
} from 'vuex';

export default {
  name: 'LicensesExplorer',
  computed: {
    groups() {
      return this.products ? _.uniq(this.products.map(p => p.group).filter(g => g)).map((id) => {
        return {
          id,
          name: this.$titleize(id),
        };
      }) : [];
    },
    groupProducts() {
      return this.selectedGroup ? this.products.filter(product => product.group === this.selectedGroup) : [];
    },
  },
  created() {
    this.loadProducts();
  },
  data() {
    return {
      loading: false,
      products: [],
      selectedGroup: 'balanced',
    };
  },
  methods: {
    ...mapActions({
      systemProducts: 'system/products',
    }),
    loadProducts() {
      this.loading = true;
      this.systemProducts().then((response) => {
        this.products = response.data.data;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">
.licenses-explorer {

  .group-picker {
    border-radius: 4px;
    display: flex;
    margin-bottom: 30px;
    overflow: hidden;

    .group {
      background: $grey;
      color: $white;
      cursor: pointer;
      flex: 1;
      padding: 5px 0;
      text-align: center;

      &.is-active {
        background: $primary;
      }
    }
  }

  .licenses-table {

    border-radius: 4px;
    overflow: hidden;
    width: 100%;

    tr {
      th {
        background: $grey-dark;
        color: $white;
        text-align: center;
        white-space: nowrap;
      }

      td {
        padding: 20px 0;
        text-align: center;
        white-space: nowrap;

        &:first-child {
          text-align: left;
        }
      }
    }
  }
}
</style>
