<template>
  <p class="help">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'Help',
}
</script>

<style scoped lang="scss">
.help {

  color: #666 !important;

  margin-bottom: 0;
}
</style>
