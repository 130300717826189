import {
  ResourceService,
} from '@/internal';

export default class DashboardService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'dashboard';
  }

}
