<template>
  <div class="controls">
    <div class="left">
      <slot name="left" />
    </div>
    <div class="right has-text-right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Controls',
}
</script>

<style scoped lang="scss">
.controls {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
</style>
