import {
  camelCase,
  snakeCase,
  startCase,
} from 'lodash';

const base58Encode = (string) => {

  const base58 = require('bs58');

  const bytes = Buffer.from(string);

  return base58.encode(bytes);

};

import countries from '@/assets/data/countries.json';

import storage from '@/drivers/LocalStorageDriver';

const countryAlpha2ToAlpha3 = (alpha2) => {

  let country = countries.find(_country => _country['alpha-2'].toLowerCase() === alpha2.toLowerCase()) || null;

  if (country) {
    country = country['alpha-3'];
  }

  return country;
};

const featureEnabled = (key, defaultValue = false) => {

  // Removed launchDarkly

  return true;
};

const feetToMeters = (feet) => {
  return feet / 3.281;
};

const geoJsonFeature = (coordinates) => {
  return {
    type: 'geojson',
    data: {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates,
      },
    },
  };
};

const getLayerFromStorage = (layerId) => {
  const map = storage.getItem('map') || {};
  const savedLayers = map && map.layers ? map.layers : [];
  return savedLayers.find(savedLayer => savedLayer.id === layerId) || null;
};

const isDomain = (url) => {
  return window.location.hostname.includes(url);
};

const isHarmonyAnalytica = () => {
  return isDomain('harmonyanalytica.com');
};

const isLakeStreet = () => {
  return isDomain('lakestreet.xyz');
};

const isLocal = () => {
  return process.env.VUE_APP_ENV === 'local';
};

const isMeatCache = () => {
  return isDomain('meatcache.com');
};

const isMobile = () => {
  return window.innerWidth < 1200;
};

const randomColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

const saveLayerToStorageIfDoesNotExist = (layer) => {
  if (!getLayerFromStorage(layer.id)) {
    saveLayerToStorage(layer);
  }
};

const saveLayerToStorage = (layer) => {

  let map = storage.getItem('map') || {};
  let savedLayers = map && map.layers ? map.layers : [];
  const savedLayer = getLayerFromStorage(layer.id);

  const layerWithSavedProps = {};
  const dontSaveTheseProps = [
    'hidden',
    'label',
    'parent',
    'syncedWith',
  ];

  for (const [key, value] of Object.entries(layer)) {
    layerWithSavedProps[key] = savedLayer && savedLayer[key] !== undefined && !dontSaveTheseProps.includes(key) ? savedLayer[key] : value;
  }

  if (savedLayer) {
    savedLayers = savedLayers.map(_layer => _layer.id === layer.id ? layer : _layer);
  } else {
    savedLayers.push(layer);
  }

  map.layers = savedLayers;
  storage.setItem('map', map);
};

const snakeCaseKeys = (object) => {

  let newObject = {};

  for (const [key, value] of Object.entries(object)) {
    newObject[snakeCase(key)] = value;
  }

  return newObject;
}

const metersToDegrees = (meters) => {
  return meters / .11 * 0.000001;
};

const titleize = (text) => {
  return startCase(camelCase(text));
};

const label = (appLabel, plural = false) => {

  let labels = window.app.env.labels;

  let obj = labels.find(el => el.application_label.singular === appLabel);

  if (plural) {
    obj = labels.find(el => el.application_label.plural === appLabel);
  }

  if (obj) {
    if (plural) {
      return obj.label.plural || appLabel;
    }

    return obj.label.singular || appLabel;
  }

  return appLabel;
};

const nth = (inputArray = [], nth) => {

  let nthArray = [];

  if (!inputArray.length) {
    return nthArray;
  }

  for (let i = 0; i < inputArray.length; i += nth) {
    nthArray.push(inputArray[i]);
  }

  return nthArray;
};

const parseBoolean = (truthyValue) => {
  return truthyValue === true || (typeof truthyValue === String && truthyValue.toLowerCase() === 'true') || truthyValue === 1;
};

export {
  base58Encode,
  countryAlpha2ToAlpha3,
  featureEnabled,
  feetToMeters,
  geoJsonFeature,
  getLayerFromStorage,
  isDomain,
  isHarmonyAnalytica,
  isLakeStreet,
  isLocal,
  isMeatCache,
  isMobile,
  label,
  metersToDegrees,
  nth,
  parseBoolean,
  randomColor,
  saveLayerToStorage,
  saveLayerToStorageIfDoesNotExist,
  snakeCaseKeys,
  titleize,
};
