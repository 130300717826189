import {
  ResourceStore,
} from '@/internal';

import NotificationChannel from '../NotificationChannel/NotificationChannel';

const store = new ResourceStore({
  model: NotificationChannel,
});

export default store.toObject();
