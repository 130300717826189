import {
  Region as Model,
  ResourceStore,
} from '@/internal';

const service = new Model.service();

const store = new ResourceStore({
  model: Model,
  actions: {
    devices({commit}, payload = {}) {
      return service.devices(payload.id, payload).then((response) => {
        return {
          ids: ResourceStore.processData('device', commit, response.data),
        };
      });
    },
  },
});

export default store.toObject();
