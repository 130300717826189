import {
  Model,
  Parameter,
  ParameterValueService as Service, Unit,
} from '@/internal';

export default class ParameterValue extends Model {

  static get key() {
    return 'parameterValue';
  }

  static get service() {
    return Service;
  }

  get parameter() {
    return this.belongsTo(Parameter);
  }

  // set parameter(value) {} // I don't think we should need this - NBH

  get unitLabel() {

    if (!this.unit) {
      return '';
    } else if (this.unit.symbol) {
      return this.unit.symbol;
    } else {
      return ` ${this.unit.title}`;
    }
  }

  get valueWithPrecision() {

    if (this.value && this.parameter.precision !== null) {
      return parseFloat(this.value.toFixed(this.parameter.precision));
    }

    return this.value;
  }

  get formattedValue() {
    return `${this.valueWithPrecision}${this.unitLabel}`;
  }

  get unit() {
    return this.belongsTo(Unit);
  }
}
