<template>
  <div class="uplink-parameter-values">

    <template v-for="parameterValue in uplink.parameterValues">
      <LabelLineValue :label="parameterValue.parameter.title" :value="parameterValue.formattedValue" />
    </template>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'ParameterValues',
  computed: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {
    uplink: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
</style>
