import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const {
    domain,
    $label,
    user,
  } = payload;

  return new Menu({
    title: 'Core',
    icon: 'wave-pulse',
    open: payload.open !== undefined ? payload.open : true,
    menuItems: [
      {
        route: window.app.findRouteByName('asset/index'),
        title: $label('Assets', true),
        when: domain.featureEnabled('assets'),
      },
      {
        route: window.app.findRouteByName('device/index'),
        title: $label('Devices', true),
        when: domain.featureEnabled('devices'),
      },
      {
        route: window.app.findRouteByName('region/index'),
        title: $label('Regions', true),
        when: domain.featureEnabled('regions'),
      },
      {
        route: window.app.findRouteByName('integration/index'),
        title: $label('Integrations', true),
        when: domain.featureEnabled('integrations'),
      },
      {
        route: window.app.findRouteByName('rule/index'),
        title: $label('Rules', true),
        when: domain.featureEnabled('rules'),
      },
      {
        route: window.app.findRouteByName('alarm/index'),
        title: $label('Alarms', true),
        when: domain.featureEnabled('alarms'),
      },
      {
        route: window.app.findRouteByName('tag/index'),
        title: $label('Tags', true),
        when: domain.featureEnabled('tags'),
      },
      {
        route: window.app.findRouteByName('converter/index'),
        when: domain.featureEnabled('converters'),
      },
      {
        route: window.app.findRouteByName('unit/index'),
        when: domain.featureEnabled('units'),
      },
    ],
  });
};
