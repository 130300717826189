import {
  OnboardingService as Service,
  Model,
} from '@/internal';

export default class Onboarding extends Model {

  static get key() {
    return 'onboarding';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      uuid: {
        type: String,
      },
    };
  }
}
