<template>
  <div class="region-preview">
    <Subtitle>{{region.name}}</Subtitle>
    <LabelLineValue label="Device Count" :value="region.deviceCount" />
  </div>
</template>

<script>
export default {
  name: 'Preview',
  props: {
    region: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
