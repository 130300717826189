<template>
  <div id="alarm-form" class="is-padded">

    <Field v-if="alarm && alarm.lastFailedAt">
      <BTag type="is-danger">TRIGGERED</BTag>
    </Field>

    <Field>
      <Label>Name *</Label>
      <TextInput v-model="form.fields.name" placeholder="Name"/>
      <FormError field="name" :form="form"/>
    </Field>

    <Field>
      <Label>{{ $label('Device') }} *</Label>

      <FakeInput v-if="device">
        {{ device.name }}
      </FakeInput>

      <ResourceSelect v-model="form.fields.alarmableId"
                      :params="params"
                      placeholder="Search"
                      :resource="deviceResource"
                      v-else
      />
      <FormError field="alarmable_id" :form="form"/>
    </Field>

    <template v-if="device">

      <Field>
        <Label>Rule</Label>
        <RepeaterRuleInput v-model="form.fields.rules" :form="form" :parameters="parameterOptions"/>
        <FormError field="rules" :form="form"/>
      </Field>

      <hr />

      <Field>
        <BCheckbox v-model="form.fields.notificationThrottle">
          Throttle Notifications
        </BCheckbox>
        <Help>
          Only notify every X, even if the alarm has recovered and triggered again.
          This affects both trigger and recovery notifications.
        </Help>
      </Field>

      <Field v-if="form.fields.notificationThrottle">
        <TimeIntervalInput label="Throttle Delay" v-model="form.fields.notificationFrequency" />
        <FormError field="notificationFrequency" :form="form"/>
      </Field>

    </template>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>

          <Button @click="handleDelete" outlined danger :loading="deleting" v-if="alarm">
            Delete
          </Button>

        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Device,
  Form,
} from '@/internal';

import RepeaterRuleInput from '@/components/Inputs/RepeaterRuleInput';

export default {
  name: 'Form',
  components: {
    RepeaterRuleInput
  },
  computed: {
    alarm() {
      return this.$route.params.alarmUUID ? this.$store.getters['alarm/findBy'](this.$route.params.alarmUUID, 'uuid') : null;
    },
    device() {
      return this.form.fields.alarmableId ? this.$store.getters['device/show'](this.form.fields.alarmableId) : null;
    },
    parameterOptions() {
      return this.device && this.device.parameters ? this.device.parameters.map((parameter) => {
        return {
          label: parameter.title,
          value: parameter.id
        };
      }) : [];
    },
    pageTitle() {
      return this.$label('Alarm');
    },
  },
  created() {
    if (this.$route.params.alarmUUID) {
      this.loadAlarm();
    }

    if (this.alarm) {
      this.fillForm();
    }
  },
  data() {
    return {
      deleting: false,
      deviceResource: Device,
      deviceIds: [],
      deviceId: null,
      form: new Form({
        alarmableType: 'App\\Models\\Device',
        alarmableId: null,
        name: '',
        rules: [],
        notificationThrottle: false,
        notificationFrequency: 3600,
      }),
      loading: false,
      payloadMappingIds: [],
      params: {
        include: [
          'parameters',
          'parameters.unit',
        ],
        limit: 10,
      },
    };
  },
  methods: {
    ...mapActions({
      destroy: 'alarm/destroy',
      store: 'alarm/store',
      show: 'alarm/show',
      update: 'alarm/update',
    }),
    handleDelete() {
      this.$deleteDialog({
        target: this.alarm.name,
        targetType: 'Alarm',
        onConfirm: () => {
          this.loading = true;
          this.destroy({
            id: this.alarm.id,
          }).then(() => {
            window.app.snackbar('Alarm Deleted');
            this.$router.push({
              name: 'alarm/index',
            });
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.loading = false;
          });
        },
      })
    },
    handleSave() {

      this.form.errors.clear();

      let action = null;

      if (this.alarm) {
        action = this.update;
      } else {
        action = this.store;
      }

      if (action) {
        this.loading = true;

        delete this.form.fields.lastFailedAt;

        action({
          ...this.form.fields,
          notificationThrottle: this.form.fields.notificationThrottle ? 1 : 0,
        }).then(() => {
          window.app.snackbar('Alarm Saved');
          this.$router.back();
        }).catch((error) => {
          this.form.recordErrors(error);
        }).finally(() => {
          this.loading = false;
        });
      }

    },
    fillForm() {
      this.form.fields = {
        ...this.alarm
      };
    },
    loadAlarm() {
      this.loading = true;
      return this.show({
        id: this.$route.params.alarmUUID,
      }).catch((error) => {
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  watch: {
    alarm() {
      this.fillForm();
    },
  }
}
</script>

<style scoped lang="scss">

</style>
