<template>
  <TextInput type="number"
             :placeholder="placeholder"
             :value="localValue"
             @blur="handleBlur"
             class="number-input"
             :class="{ 'is-dark': $themeDark }"
             :max="max"
             :min="min"
             :step="step"
  />
</template>

<script>
import TextInput from './TextInput';

export default {
  name: 'NumberInput',
  components: {
    TextInput,
  },
  data() {
    return {
      localValue: null,
    };
  },
  methods: {
    processValue(value) {

      if (this.min !== null && value < this.min) {
        value = this.min;
      }

      if (this.max !== null && value > this.max) {
        value = this.max;
      }

      if (this.precision) {
        value = parseFloat(value).toFixed(this.precision);
      }

      this.localValue = value;
    },
    handleBlur(e) {
      this.processValue(e.target.value);
      this.$emit('blur');
    },
  },
  props: {
    max: {
      default: null,
      required: false,
      type: Number,
    },
    min: {
      default: null,
      required: false,
      type: Number,
    },
    placeholder: {
      required: false,
      type: String,
    },
    precision: {
      required: false,
      type: Number,
    },
    step: {
      default: null,
      required: false,
      type: Number,
    },
    value: {
      required: true,
    },
  },
  watch: {
    localValue() {
      if (parseFloat(this.value) !== parseFloat(this.localValue)) {
        this.$emit('input', !isNaN(parseFloat(this.localValue)) ? parseFloat(this.localValue) : null);
      }
    },
    value: {
      immediate: true,
      handler() {
        if (parseFloat(this.value) !== parseFloat(this.localValue)) {
          this.processValue(this.value);
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.number-input {
  text-align: right;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
}
</style>
