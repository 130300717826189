<template>
  <div id="device-form" class="is-padded">

    <BLoading active v-if="loading" :is-full-page="false"/>

    <Section title="Basics" open>

      <div class="is-pulled-right is-flex">
        <div class="is-flex is-align-items-center" v-if="!device">
          <BCheckbox v-model="form.fields.isVirtual" :disabled="device !== null">
            <div class="label" :class="{ 'has-text-light': $themeDark }">Virtual?</div>
          </BCheckbox>
          <BTooltip multilined position="is-left" label="Virtual Devices are driven by 3rd party API data">
            <BIcon icon="question-circle"/>
          </BTooltip>
        </div>
      </div>

      <Field>
        <Label>Name *</Label>
        <TextInput v-model="form.fields.name" placeholder="Temperature Sensor"/>
        <FormError field="name" :form="form"/>
      </Field>

      <template v-if="!form.fields.isVirtual">

        <Field>
          <Label>Manufacturer Label</Label>
          <ImageFileInput v-model="form.fields.manufacturerLabelFileId" :types="['.jpeg', '.png']"/>
        </Field>

        <Field>
          <Label>{{ $label('Device') }} EUI *</Label>
          <TextInput v-model="form.fields.deviceEui" :placeholder="$label('Device') + ' EUI'"/>
          <FormError field="device_eui" :form="form"/>
        </Field>

        <Field>
          <Label>App EUI
            <template v-if="form.fields.addToHeliumConsole">*</template>
          </Label>
          <TextInput v-model="form.fields.appEui" placeholder="App EUI"/>
          <FormError field="app_eui" :form="form"/>
        </Field>

        <Field>
          <Label>App Key
            <template v-if="form.fields.addToHeliumConsole">*</template>
          </Label>
          <PasswordInput v-model="form.fields.appKey" placeholder="************************"/>
          <FormError field="app_key" :form="form"/>
        </Field>

      </template>

      <Field v-if="!loading">
        <TimeIntervalInput label="Heartbeat Interval" v-model="form.fields.heartbeatInterval" :min="60" required/>
        <FormError field="heartbeatInterval" :form="form"/>
      </Field>

      <Field v-if="device">
        <BSwitch v-model="form.fields.active">Active</BSwitch>
      </Field>

      <Field>
        <BSwitch v-model="form.fields.public">Allow Public Viewing</BSwitch>
      </Field>

      <Field>
        <Label>Tags</Label>
        <TagInput v-model="form.fields.tags" :max="null"/>
      </Field>

    </Section>

    <Section title="Virtual" v-if="form.fields.isVirtual">

      <Instruction v-if="!device">
        Virtual Devices are driven by 3rd party API data, rather than physical Devices.
      </Instruction>

      <Field>
        <Label>Type *</Label>
        <SelectInput :options="typeOptions" v-model="form.fields.virtual.type" :disabled="device !== null"/>
        <FormError field="virtualType" :form="form"/>
      </Field>

      <Field>
        <TimeIntervalInput label="Uplink Frequency" v-model="form.fields.virtual.uplinkFrequency" :min="60"
                           required/>
        <FormError field="virtualUplinkFrequency" :form="form"/>
        <Help>
          How often should a new Uplink be generated?
        </Help>
      </Field>

      <template v-if="!device">

        <template v-if="form.fields.isVirtual && form.fields.virtual.type === 'weather'">
          <Field>
            <Label>Parameters</Label>
            <BCheckbox @input="handleToggleAllWeatherParameters">Toggle All</BCheckbox>
            <template v-for="parameter in weatherParameterOptions">
              <div>
                <BCheckbox v-model="form.fields.virtual.config.parameters" :native-value="parameter.value">
                  {{ parameter.label }}
                </BCheckbox>
              </div>
            </template>
          </Field>
        </template>

      </template>

    </Section>

    <Section title="LoRaWAN Network Server" v-if="shouldShowLorawanNetworkServer">
      <LorawanNetworkServer :device="device"/>
    </Section>

    <Section title="Latest Uplink" v-if="device && device.latestUplink">
      <Uplink :uplink="device.latestUplink"/>
    </Section>

    <Section title="Parameters" v-if="!form.fields.isVirtual || device">
      <Instruction>
        Parameters are how you define which data points {{ $domainName }} should expect to receive from your Devices.
      </Instruction>
      <ParametersInput v-model="form.fields.parameters"
                       :form="form"
                       :isVirtual="form.fields.isVirtual"
      />
    </Section>

    <Section title="Position">

      <Field>
        <BSwitch v-model="form.fields.positionsEnabled" :disabled="form.fields.isVirtual">Enable
          Position Tracking
        </BSwitch>
      </Field>

      <template v-if="!form.fields.positionsEnabled">

        <Instruction>
          Position is where the Device is physically located.
        </Instruction>

        <Instruction type="is-warning">
          Required for the Device to be rendered on the map or to track Trips.
        </Instruction>

      </template>

      <template v-else>

        <Field>
          <div class="has-margin-bottom">
            <Label class="has-margin-bottom">Current Position</Label>
          </div>
          <PositionInput v-model="form.fields.position" :form="form"></PositionInput>
        </Field>

      </template>

    </Section>

    <Section title="Position Auto Update" v-if="form.fields.positionsEnabled">

      <Instruction type="is-warning" v-if="!device || !device.parameters.length">
        You must save your Device Parameters before you can enable Position auto updating.
      </Instruction>

      <Field v-else>
        <BSwitch v-model="form.fields.autoUpdatePosition" :disabled="!parameterOptions.length">
          Enable Auto Updates
        </BSwitch>
        <FormError field="autoUpdatePosition" :form="form"/>
        <Help>
          Should a Position automatically be created when a new Uplink with Position data is received?
        </Help>
      </Field>

      <template v-show="!form.fields.isVirtual" v-if="form.fields.autoUpdatePosition">

        <Field>
          <Label class="has-margin-bottom">Latitude *</Label>
          <SelectInput v-model="form.fields.latParameterId" :options="parameterOptions"/>
          <FormError field="latParameterId" :form="form"/>
        </Field>

        <Field>
          <Label class="has-margin-bottom">Longitude *</Label>
          <SelectInput v-model="form.fields.lngParameterId" :options="parameterOptions"/>
          <FormError field="lngParameterId" :form="form"/>
        </Field>

        <Field>
          <Label class="has-margin-bottom">Altitude</Label>
          <SelectInput v-model="form.fields.altitudeParameterId" :options="parameterOptions"/>
          <FormError field="altitudeParameterId" :form="form"/>
        </Field>

      </template>
    </Section>

    <Section title="Trips" v-if="user.featureEnabled('trips') && form.fields.positionsEnabled">

      <Field>
        <BSwitch v-model="form.fields.tripsEnabled" :disabled="form.fields.isVirtual">Enable Trip Tracking</BSwitch>
      </Field>

      <Instruction v-if="!form.fields.tripsEnabled">
        Trips are a tool to automatically group Positions and visualize them in paths.
      </Instruction>

      <Field v-if="form.fields.tripsEnabled">
        <TimeIntervalInput label="Down Time" v-model="form.fields.tripsDownTime" :min="60" required/>
        <FormError field="tripDownTime" :form="form"/>
        <Help>
          <p>If a new Position is not received within this number of seconds, we close the current trip.</p>
          <p>The next time a new position is received, a new trip will be started.</p>
        </Help>
      </Field>

    </Section>

    <Section title="Alarms" v-if="!loading && device && device.alarms" :loading="loading">
      <AlarmsInput :device="device" @changed="loadDevice"/>
    </Section>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>
          <Button @click="handleDelete" outlined danger :loading="deleting" v-if="device">
            Delete
          </Button>
        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left" :loading="saving">
            Save
          </Button>

        </template>

      </Controls>
    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

import AlarmsInput from '@/components/Inputs/AlarmsInput';
import HeartbeatIntervalInput from '@/components/Inputs/HeartbeatIntervalInput';
import HowToAddAnUplink from '@/components/HowToAddAnUplink';
import LorawanNetworkServer from '@/components-V2/Device/LorawanNetworkServer';
import ParametersInput from '@/components-V2/Inputs/ParametersInput';
import PositionInput from '@/components-V2/Inputs/PositionInput';
import Uplink from '@/components-V2/Uplink/Preview';

export default {
  name: 'Form',
  components: {
    AlarmsInput,
    HeartbeatIntervalInput,
    HowToAddAnUplink,
    LorawanNetworkServer,
    ParametersInput,
    PositionInput,
    Uplink,
  },
  computed: {
    device() {
      return this.$route.params.deviceUUID ? this.$store.getters['device/findBy'](this.$route.params.deviceUUID, 'uuid') : null;
    },
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    pageTitle() {
      return 'Device';
    },
    parameterOptions() {
      return this.device ? [
        {
          label: '-- Select a Parameter --',
          value: null,
        },
        ...this.device.parameters.map((parameter) => {
          return {
            label: parameter.title,
            value: parameter.id,
          };
        })
      ] : [];
    },
    shouldShowLorawanNetworkServer() {
      return this.domain.featureEnabled('lorawan_network_server')
        && this.device
        && !this.device.isVirtual;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (this.$route.params.deviceUUID) {
      this.loadDevice();
    }
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        active: true,
        altitudeParameterId: null,
        addToHeliumConsole: false,
        appEui: '',
        appKey: '',
        autoUpdatePosition: false,
        deviceEui: '',
        heartbeatInterval: 3600,
        isVirtual: false,
        latParameterId: null,
        lngParameterId: null,
        manufacturerLabelFileId: null,
        name: '',
        parameters: [],
        position: null,
        positionsEnabled: false,
        public: false,
        publicUplinks: false,
        tags: [],
        tripsEnabled: false,
        tripsDownTime: 3600,
        virtual: {
          config: {
            mode: 'weather',
            parameters: [],
            target: null,
          },
          type: null,
          uplinkFrequency: 900,
        },
      }),
      modeOptions: [
        {
          label: '- Select a Mode -',
          value: null,
        },
        {
          label: 'Driving',
          value: 'driving',
        },
        // {
        //   label: 'Flying',
        //   value: 'flying',
        // },
      ],
      loading: false,
      saving: false,
      targetOptions: [
        {
          label: '- Select a Target -',
          value: null,
        },
        {
          label: 'Coffee Shops',
          value: 'coffee',
        },
        {
          label: 'Parks',
          value: 'park',
        },
        {
          label: 'Post Offices',
          value: 'post-office',
        },
        {
          label: 'Restaurants',
          value: 'restaurant',
        },
        {
          label: 'Schools',
          value: 'school',
        },
      ],
      typeOptions: [
        {
          label: '- Select Virtual Device Type -',
          value: null,
        },
        {
          label: 'Weather',
          value: 'weather',
        },
      ],
      weatherParameterOptions: [
        {
          label: 'Temperature',
          value: 'temperature',
        },
        {
          label: 'Humidity',
          value: 'humidity',
        },
        {
          label: 'Wind Speed',
          value: 'wind_speed',
        },
        {
          label: 'Wind Gust',
          value: 'wind_gust',
        },
        {
          label: 'Wind Direction',
          value: 'wind_direction',
        },
        {
          label: 'Barometric Pressure',
          value: 'barometric_pressure',
        },
        {
          label: 'Precipitation',
          value: 'precipitation',
        },
        {
          label: 'Visibility',
          value: 'visibility',
        },
        {
          label: 'UV Index',
          value: 'uv_index',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      destroy: 'device/destroy',
      show: 'device/show',
      store: 'device/store',
      update: 'device/update',
    }),
    handleDelete() {

      this.$deleteDialog({
        target: this.device.name,
        targetType: 'Device',
        onConfirm: () => {
          this.deleting = true;
          this.destroy({
            id: this.device.id,
          }).then(() => {
            window.app.snackbar('Device Deleted');
            this.$router.push({
              name: 'device/index',
            });
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.deleting = false;
          });
        },
      });

    },
    handleSave() {

      const fields = {
        ...this.form.fields
      };
      this.form.errors.clear();

      let action = null;

      if (this.device) {
        action = this.update;
      } else {
        action = this.store;
      }

      if (action) {

        this.saving = true;

        action({
          ...fields,
          params: {
            include: ['parameters'],
          },
        }).then((deviceId) => {

          const device = this.$store.getters['device/show'](deviceId);

          window.app.snackbar('Device Saved');

          this.$router.push({
            name: 'device/show',
            params: {
              deviceUUID: device.uuid,
            },
          });

          this.fillForm();

        }).catch((error) => {
          window.app.snackbarError(error);
          this.form.recordErrors(error);
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    handleToggleAllWeatherParameters(input) {
      if (input) {
        this.form.fields.virtual.config = {
          ...this.form.fields.virtual.config,
          parameters: [
            ...this.weatherParameterOptions.map(option => option.value),
          ]
        };
      } else {
        this.form.fields.virtual.config = {
          parameters: [],
        };
      }
    },
    loadDevice() {
      this.loading = true;
      this.show({
        id: this.$route.params.deviceUUID,
        params: {
          include: [
            'alarms',
            'latestUplink',
            'manufacturerLabel',
            'parameters',
            'parameters.asset',
            'parameters.tags',
            'parameters.unit',
            'tags',
          ],
        },
      }).then(() => {
        this.fillForm();
      }).finally(() => {
        this.loading = false;
      })
    },
    fillForm() {
      this.form.fields = {
        ...this.form.fields,
        ...this.device,
        autoUpdatePosition: !!this.device.autoUpdatePosition,
        parameters: this.device.parameters ? this.device.parameters.map(parameter => parameter.toPlainObject()) : [],
        position: this.device.position ? this.device.position : null,
        tags: this.device.tags || [],
      };
    },
  },
  watch: {
    ['form.fields.isVirtual']() {
      if (this.form.fields.isVirtual) {
        this.form.fields.addToHeliumConsole = false;
      }
    },
    ['form.fields.virtual.type']() {
      if (this.form.fields.virtual.type === 'weather') {
        this.form.fields = {
          ...this.form.fields,
          virtual: {
            ...this.form.fields.virtual,
            config: {
              ...this.form.fields.virtual.config,
              mode: null,
              target: null,
            },
          },
          positionsEnabled: true,
          tripsEnabled: false,
        };
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
