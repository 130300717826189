import {
  Model,
  ParameterValue,
  UplinkService as Service
} from '@/internal';

export default class Uplink extends Model {

  static get key() {
    return 'uplink';
  }

  static get service() {
    return Service;
  }

  get parameterValues() {
    return this.hasMany(ParameterValue);
  }
}
