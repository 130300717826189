import {
  ResourceService,
} from '@/internal';

export default class DomainService extends ResourceService {

  constructor() {
    super();
    this.baseUrl = 'domain';
  }

  accountUpdate(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `${this.baseUrl}/${id}/account-update`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  planUpdate(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `${this.baseUrl}/${id}/plan-update`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  stripeOnboard(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `${this.baseUrl}/${id}/stripe/onboard`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  subscription(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `${this.baseUrl}/${id}/stripe/subscription`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }
}
