<template>
  <div
    id="inspector-footer"
    :class="{
      'has-content': hasContent,
      'is-anchored': $layoutAnchored || isMobile,
      'is-dark': $themeDark,
      'is-floating': $layoutFloating && !isMobile,
      'is-fullscreen': isFullScreen,
      'is-mobile': isMobile,
      'primary-menu-is-active': primaryMenu.active,
    }"
    ref="inspectorFooter"
  ></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'InspectorFooter',
  data() {
    return {
      observer: null,
    };
  },
  computed: {
    hasContent() {
      return this.inspector?.hasFooterContent;
    },
    inspector() {
      return this.$store.state.ui.inspector;
    },
    isFullScreen() {
      return this.$route.meta.fullScreen || this.inspector?.fullScreen;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    primaryMenu() {
      return this.$store.state.ui.primaryMenu;
    },
  },
  mounted() {
    this.$emit('mounted');
    this.setupMutationObserver();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    ...mapActions({
      inspectorUpdate: 'ui/inspector',
    }),
    setupMutationObserver() {
      const el = this.$refs.inspectorFooter;
      if (!el) return;

      this.observer = new MutationObserver((mutations) => {
        let hasContent = false;
        mutations.forEach((mutation) => {
          if (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0) {
            hasContent = el.hasChildNodes();
          }
        });
        this.inspectorUpdate({
          hasFooterContent: hasContent,
        });
      });

      this.observer.observe(el, {
        childList: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
#inspector-footer {
  background: $theme-light-background-color;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  z-index: $zindex-inspector + 2;

  &.has-content {
    border-top: 1px solid $navigation-border-color;
    padding: 15px;
  }

  &.is-dark {
    background: $theme-dark-background-color;
  }

  &.is-floating {
    border-radius: 0 0 $layout-floating-border-radius $layout-floating-border-radius;
  }
}
</style>
