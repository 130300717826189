import {
  Parameter as Model,
  ParameterService,
  ResourceStore,
} from '@/internal';

const service = new ParameterService();

const store = new ResourceStore({
  model: Model,
  actions: {
    chartData ({ commit }, payload = {}) {
      return service.chartData(payload);
    },
  }
});

export default store.toObject();
