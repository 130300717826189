import moment from 'moment-timezone';

import SystemService from './SystemService';
import storage from '@/drivers/LocalStorageDriver';

const service = new SystemService();

import appData from '../../../package.json';

const defaultSettings = {
  datetimeFormat: 'll LTS z',
  layout: 'floating',
  theme: 'dark',
  timezone: moment.tz.guess() || 'America/Los_Angeles',
  userLocation: null,
};

const stored = storage.getItem('system') || {};

const updateRequired = (state) => {
  const appVersion = state.version.app.split('.');
  const apiVersion = state.version.api.split('.');
  return apiVersion[0] !== appVersion[0] || apiVersion[1] !== appVersion[1];
};

export default {
  key: 'system',
  namespaced: true,
  state: {
    featuresAvailable: {},
    licenseRequiredDate: null,
    maintenance: false,
    settings: {
      ...defaultSettings,
      ...stored.settings,
    },
    updateAvailable: false,
    updateRequired: false,
    version: {
      app: appData.version,
      api: null,
    },
  },
  actions: {
    index({commit}) {
      return service.index().then((response) => {
        if (response && response.data && response.data.data) {
          const system = response.data.data;
          commit('system', system);
          return system;
        }

        return null;
      });
    },
    products({}, payload) {
      return service.products(payload);
    },
    search({}, payload) {
      return service.search(payload);
    },
    settings({state, commit, dispatch, rootState}, payload) {

      if (state.settings.theme !== payload.theme) {

        let newMapStyle = null;

        if (rootState.map.mapStyle === 'light' && payload.theme === 'dark') {
          newMapStyle = 'dark';
        } else if (rootState.map.mapStyle === 'dark' && payload.theme === 'light') {
          newMapStyle = 'light';
        }

        if (newMapStyle) {
          const map = storage.getItem('map') || {};
          map.style = newMapStyle;
          storage.setItem('map', map);
        }
      }

      return commit('settings', payload);
    },
    setUserLocation({commit}, payload) {
      return commit('settings', {
        userLocation: payload,
      });
    },
    units({}, payload = {}) {
      return service.units(payload);
    },
  },
  mutations: {
    system(state, payload = {}) {
      state.featuresAvailable = payload.features_available;
      state.licenseRequiredDate = moment.utc(payload.license_required_date, 'YYYY-MM-DD');
      state.maintenance = payload.maintenance;
      state.version.api = payload.version;
      state.updateRequired = updateRequired(state);
      state.updateAvailable = state.version.app !== payload.version;
    },
    settings(state, payload) {

      const nextSettings = {
        ...state.settings,
        ...payload,
      };

      state.settings = {
        ...nextSettings,
      };

      storage.setItem('system', {
        ...state,
        settings: {
          ...nextSettings,
        }
      });
    },
  },
};
