// Account
import Billing from '@/views/Account/Billing';
import Create from '@/views/Account/Create';
import Edit from '@/views/Account/Edit';

// Onboarding
import OnboardingIndex from '@/views/Account/Onboarding/Index';
import OnboardingForm from '@/views/Account/Onboarding/Form';

// Users

import UserIndex from '@/views/Account/User/Index';
import UserForm from '@/views/Account/User/Form';

import Switch from '@/views/Account/Switch.vue';

const routes = [
  {
    name: 'account/billing',
    path: '/account/billing',
    component: Billing,
    meta: {
      containerSize: 'container-small',
      fullScreen: true,
      icon: 'credit-card',
      middleware: ['auth', 'accountOwner'],
      title: 'Billing',
    },
  },
  {
    name: 'account/edit',
    path: '/account/edit',
    component: Edit,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      icon: 'building',
      middleware: ['auth', 'accountOwner'],
      title: 'Profile',
    },
  },
  {
    name: 'account/onboarding/index',
    path: '/account/onboarding',
    component: OnboardingIndex,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      icon: 'hexagon-plus',
      middleware: ['auth', 'accountOwner'],
      title: 'Device Onboarding',
    },
  },
  {
    name: 'account/onboarding/create',
    path: '/account/onboarding/create',
    component: OnboardingForm,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      icon: 'hexagon-plus',
      middleware: ['auth', 'accountOwner'],
      title: 'New Onboarding',
    },
  },
  {
    name: 'account/onboarding/edit',
    path: '/account/onboarding/:onboardingUUID/edit',
    component: OnboardingForm,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      icon: 'hexagon-plus',
      middleware: ['auth', 'accountOwner'],
      title: 'Edit Onboarding',
    },
  },
  {
    name: 'account/create',
    path: '/account/create',
    component: Create,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      icon: 'circle-plus',
      title: 'Create Account',
    },
  },
  {
    name: 'account/user/index',
    path: '/account/user',
    component: UserIndex,
    meta: {
      containerSize: 'container-medium',
      fullScreen: true,
      icon: 'users',
      middleware: ['auth', 'accountOwner'],
      title: 'Users',
    },
  },
  {
    name: 'account/user/create',
    path: '/account/user/create',
    component: UserForm,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      icon: 'users',
      title: 'Create User',
    },
  },
  {
    name: 'account/user/edit',
    path: '/account/user/:userId',
    component: UserForm,
    meta: {
      containerSize: 'container-extra-small',
      icon: 'users',
      middleware: ['auth', 'accountOwner'],
      title: 'Edit User',
    },
  },
  {
    name: 'account/switch',
    path: '/account/switch',
    component: Switch,
    meta: {
      containerSize: 'container-extra-small',
      fullScreen: true,
      icon: 'shuffle',
      middleware: ['auth'],
      title: 'Switch',
    },
  },
];

export default routes;
