<template>
  <div id="domain-configuration" class="is-padded">

    <BLoading active v-if="loading"/>

    <Field>
      <Label>From Email Address *</Label>
      <EmailInput v-model="form.fields.mailFrom" placeholder="What email should notifications come from?"/>
      <FormError field="mail_from" :form="form"/>
      <Help>
        When UplinkEngine sends outbound emails, this is the email address that the emails will be from.
      </Help>
    </Field>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:right>

          <Button @click="$router.back()" outlined light>
            Cancel
          </Button>

          <Button @click="handleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';
import {Form} from '@/internal';

export default {
  name: 'Mail',
  computed: {
    domain() {
      return this.domainUUID ? this.$store.getters['domain/findBy'](this.domainUUID, 'uuid') : null;
    },
  },
  created() {
    this.domainUUID = window.app.storage.getItem('domainBeingManaged');
    this.loadDomain();
  },
  data() {
    return {
      domainUUID: null,
      form: new Form({
        mailFrom: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      domainShow: 'domain/show',
      domainUpdate: 'domain/update',
    }),
    handleSave() {

      this.loading = true;
      this.form.errors.clear();

      this.domainUpdate({
        ...this.domain,
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar('Domain Saved');
      }).catch((error) => {
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    fillForm() {

      if (!this.domain) {
        return;
      }

      this.form.fields = {
        mailFrom: this.domain.mailFrom,
      };
    },
    loadDomain() {

      if (!this.domainUUID) {
        console.error('No domainUUID');
        return;
      }

      this.loading = true;

      return this.domainShow({
        id: this.domainUUID,
      }).then(() => {
        this.fillForm();
      }).catch((e) => {
        console.error(e);
        window.app.snackbar('Error loading Domain');
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
