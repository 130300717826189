import {
  LorawanNetworkServerService,
  LorawanNetworkServer as Model,
  ResourceStore,
} from '@/internal';

const service = new LorawanNetworkServerService();

const store = new ResourceStore({
  model: Model,
  actions: {
    deviceDisconnect({commit}, payload = {}) {
      return service.deviceDisconnect(payload.id, payload);
    },
    providerDevice({commit}, payload = {}) {
      return service.providerDevice(payload.id, payload);
    },
    providerDeviceDelete({commit}, payload = {}) {
      return service.providerDeviceDelete(payload.id, payload);
    },
    providerDeviceOnboard({commit}, payload = {}) {
      return service.providerDeviceOnboard(payload.id, payload);
    },
    providerDeviceLabelsSync({commit}, payload = {}) {
      return service.providerDeviceLabelsSync(payload.id, payload);
    },
    providerLabels({commit}, payload = {}) {
      return service.providerLabels(payload.id);
    },
  },
});

export default store.toObject();
