import {
  Model,
  ProductService as Service
} from '@/internal';

export default class Product extends Model {

  static get key() {
    return 'product';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      name: {
        required: true,
        type: String,
      },
    };
  }
}
