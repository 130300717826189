export default {
  id: 'devices',
  group: 'map',
  enabled: true,
  label: 'Devices',
  cursorHover: 'pointer',
  source({store}) {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: store.getters['device/all'] ? store.getters['device/all'].filter(device => device.position).map((device) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [device.position.lng, device.position.lat]
            },
            properties: {
              id: device.id,
              uuid: device.uuid,
              title: device.name,
              status: device.status,
              color: device.statusColor,
            }
          };
        }) : [],
      },
    };
  },
  layout() {
    return {
      type: 'circle',
      paint: {
        'circle-pitch-alignment': 'map',
        'circle-radius': 6,
        'circle-color': ['get', 'color'],
      },
      before: 'zIndex3',
    };
  },
  onClick({e, router}) {

    const uuid = e.features[0].properties.uuid;

    let route = window.app.findRouteByName(`device/show`, [], {
      params: {
        'deviceUUID': uuid
      }
    });

    router.push(route);
  },
};
