import {
  Action,
  Condition,
  Model,
  RuleService as Service,
} from '@/internal';

export default class Rule extends Model {

  static get key() {
    return 'rule';
  }

  static get service() {
    return Service;
  }

  get actions() {
    return this.hasMany(Action);
  }

  get conditions() {
    return this.hasMany(Condition);
  }
}
