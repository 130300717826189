import Index from '@/views/Asset/Index';
import Show from '@/views/Asset/Show';
import Form from '@/views/Asset/Form';

import MeatCacheAssetForm from '@/_instances/MeatCache/views/Asset/Form';
import MeatCacheAssetShow from '@/_instances/MeatCache/views/Asset/Show';
import {isLakeStreet, isMeatCache} from '@/helpers';

let createRoute;
let editRoute;
let showRoute;

if (isMeatCache() || isLakeStreet()) {

  createRoute = {
    name: 'asset/create',
    path: '/asset/create',
    component: MeatCacheAssetForm,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('home');
      },
      containerSize: 'container-extra-small',
      icon: 'acorn',
      middleware: ['auth'],
      title: 'Add Freezer',
    },
  };

  editRoute = {
    name: 'asset/edit',
    path: '/asset/:assetUUID/edit',
    component: MeatCacheAssetForm,
    meta: {
      backRoute: (route) => {
        return window.app.findRouteByName('asset/show', {
          assetUUID: route.params.assetUUID,
        });
      },
      containerSize: 'container-extra-small',
      icon: 'acorn',
      middleware: ['auth'],
      title: 'Manage Freezer',
    },
  };

  showRoute = {
    name: 'asset/show',
    path: '/asset/:assetUUID',
    component: MeatCacheAssetShow,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('home');
      },
      containerSize: 'container-extra-small',
      icon: 'acorn',
      middleware: ['auth'],
      title: 'Freezer',
    },
  };

} else {

  createRoute = {
    name: 'asset/create',
    path: '/asset/create',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('asset/index');
      },
      containerSize: 'container-extra-small',
      icon: 'acorn',
      middleware: ['auth'],
      title: 'New Asset',
    },
  };

  editRoute = {
    name: 'asset/edit',
    path: '/asset/:assetUUID/edit',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('asset/index');
      },
      containerSize: 'container-extra-small',
      icon: 'acorn',
      middleware: ['auth'],
      title: 'Edit Asset',
    },
  };

  showRoute = {
    name: 'asset/show',
    path: '/asset/:assetUUID',
    component: Show,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('asset/index');
      },
      containerSize: 'container-extra-small',
      icon: 'acorn',
      middleware: ['auth'],
      title: 'Asset',
      editRoute: (route) => {
        return window.app.findRouteByName('asset/edit', {
          assetUUID: route.params.assetUUID,
        });
      },
    },
  };
}

const routes = [
  {
    name: 'asset/index',
    path: '/asset',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'acorn',
      middleware: ['auth'],
      title: 'Assets',
    },
  },
  {
    ...createRoute,
  },
  {
    ...showRoute,
  },
  {
    ...editRoute
  },
];

export default routes;
