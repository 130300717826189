import Vue from 'vue';
import VueRouter from 'vue-router';

import RouterBuilder from '@/domain/RouterBuilder';

import AccountOwnerMiddleware from '@/router/middleware/AccountOwnerMiddleware';
import AuthMiddleware from '@/router/middleware/AuthMiddleware';
import EmailVerifiedMiddleware from '@/router/middleware/EmailVerifiedMiddleware';
import GuestMiddleware from '@/router/middleware/GuestMiddleware';
import HasAccountMiddleware from '@/router/middleware/HasAccountMiddleware';
import IsDomainOwner from '@/router/middleware/IsDomainOwner';
import IsManagingDomain from '@/router/middleware/IsManagingDomain';
import TitleMiddleware from '@/router/middleware/TitleMiddleware';
import TitleEnvMiddleware from '@/router/middleware/TitleEnvMiddleware';

import AccountRoutes from './routes/AccountRoutes';
import AlarmRoutes from './routes/AlarmRoutes';
import ApiKeyRoutes from './routes/ApiKeyRoutes';
import AssetRoutes from './routes/AssetRoutes';
import AuthRoutes from './routes/AuthRoutes';
import AuthenticatedRoutes from './routes/AuthenticatedRoutes';
import ConverterRoutes from './routes/ConverterRoutes';
import DashboardRoutes from './routes/DashboardRoutes';
import DeviceRoutes from './routes/DeviceRoutes';
import DomainRoutes from '@/router/routes/DomainRoutes';
import IntegrationRoutes from '@/router/routes/IntegrationRoutes';
import InventoryRoutes from '@/router/routes/InventoryRoutes';
import LorawanNetworkServerRoutes from './routes/LorawanNetworkServerRoutes';
import MyRoutes from './routes/MyRoutes';
import NoteRoutes from '@/router/routes/NoteRoutes';
import PublicRoutes from './routes/PublicRoutes';
import QRCodeRoutes from '@/router/routes/QRCodeRoutes';
import RegionRoutes from './routes/RegionRoutes';
import ReportRoutes from './routes/ReportRoutes';
import RuleRoutes from '@/router/routes/RuleRoutes';
import ShopRoutes from '@/router/routes/ShopRoutes';
import SupportRoutes from '@/router/routes/SupportRoutes';
import TagRoutes from '@/router/routes/TagRoutes';
import ToolsRoutes from '@/router/routes/ToolsRoutes';
import TripRoutes from '@/router/routes/TripRoutes';
import UplinkRoutes from '@/router/routes/UplinkRoutes';
import UnitRoutes from './routes/UnitRoutes';

import FourZeroFour from '../views/FourZeroFour';

export const routerBuilder = new RouterBuilder();

routerBuilder.registerGlobalMiddleware('title', TitleMiddleware);
routerBuilder.registerGlobalMiddleware('titleEnv', TitleEnvMiddleware);
routerBuilder.registerGlobalMiddleware('emailVerified', EmailVerifiedMiddleware);
routerBuilder.registerRouteMiddleware('accountOwner', AccountOwnerMiddleware);
routerBuilder.registerRouteMiddleware('auth', AuthMiddleware);
routerBuilder.registerRouteMiddleware('guest', GuestMiddleware);
routerBuilder.registerRouteMiddleware('hasAccount', HasAccountMiddleware);
routerBuilder.registerRouteMiddleware('isDomainOwner', IsDomainOwner);
routerBuilder.registerRouteMiddleware('isManagingDomain', IsManagingDomain);

// Authorized
routerBuilder.addRoutes(AccountRoutes, ['auth']);
routerBuilder.addRoutes(AlarmRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(ApiKeyRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(AssetRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(AuthenticatedRoutes, ['auth']);
routerBuilder.addRoutes(ConverterRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(DashboardRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(DeviceRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(DomainRoutes, ['auth', 'isManagingDomain']);
routerBuilder.addRoutes(IntegrationRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(InventoryRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(LorawanNetworkServerRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(MyRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(NoteRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(QRCodeRoutes, ['auth']);
routerBuilder.addRoutes(RegionRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(ReportRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(RuleRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(ShopRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(SupportRoutes, ['auth']);
routerBuilder.addRoutes(TagRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(ToolsRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(TripRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(UplinkRoutes, ['auth', 'hasAccount']);
routerBuilder.addRoutes(UnitRoutes, ['auth', 'hasAccount']);

// Authorized or Not
routerBuilder.addRoutes(PublicRoutes);

// Guest
routerBuilder.addRoutes(AuthRoutes, ['guest']);

// 404
routerBuilder.addRoute({
  name: 'four-zero-four',
  component: FourZeroFour,
  path: '*',
});

Vue.use(VueRouter);

routerBuilder.resetRouter = () => {

  const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: routerBuilder.getRoutes(),
  });

  const originalPush = router.__proto__.push;

  router.__proto__.push = function push(location) {
    return originalPush.call(this, location).catch((error) => {
      if (!error.message.includes('Redirected when going from')
        && !error.message.includes('NavigationDuplicated')
        && !error.message.includes('Avoided redundant navigation')) {
        throw error;
      }
    });
  };

  router.beforeEach((to, from, next) => routerBuilder.beforeEach(to, from, next));

  router.afterEach((to, from) => {

    const redirectRoute = window.app.storage.getItem('redirectRoute');

    if (redirectRoute && redirectRoute.name === to.name) {
      window.app.d('Remove redirectRoute');
      window.app.storage.removeItem('redirectRoute');
    }

  });

  return router;
};

const router = routerBuilder.resetRouter();

export default router;
