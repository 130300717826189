<template>
  <Page>
    <BLoading active v-if="loading"/>
    <div class="container container-extra-extra-small">
      <Title class="has-text-centered">Onboard Device</Title>
      <Box flat black>
        <Field>
          <Label>Serial Number</Label>
          <TextInput class="has-text-centered" v-model="form.fields.serialNumber" placeholder="NT-022-245-000000"/>
        </Field>
        <div class="controls">
          <Button primary block @click="handleDeviceOnboard">
            Onboard
          </Button>
        </div>
      </Box>
    </div>
  </Page>
</template>

<script>
import {
  mapActions,
} from 'vuex';
import {Form} from '@/internal';

export default {
  name: 'Onboard',
  computed: {},
  created() {
    if (process.env.VUE_APP_ENV === 'local') {
      this.form.fields.serialNumber = 'NT-022-245-001628';
    }
  },
  data() {
    return {
      device: null,
      form: new Form({
        serialNumber: '',
      }),
      loading: false,
      lorawanNetworkServerProviderDevice: null,
    };
  },
  methods: {
    ...mapActions({
      deviceOnboard: 'device/onboard',
    }),
    handleDeviceOnboard() {
      this.loading = true;
      this.form.errors.clear();
      return this.deviceOnboard({
        code: this.$route.params.code,
        ...this.form.fields,
      }).then((response) => {
        if (response.data.data.device) {
          this.$router.push(`/device/${response.data.data.device.uuid}/share`);
        }
      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
