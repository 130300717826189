import {
  Onboarding,
  ResourceStore,
} from '@/internal';

const store = new ResourceStore({
  model: Onboarding,
});

export default store;
