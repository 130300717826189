<template>
  <div id="rule-form" class="is-padded">

    <BLoading active v-if="loading"/>

    <Section title="Basics" open>

      <Field>
        <Label>Name *</Label>
        <TextInput v-model="form.fields.name" placeholder="Name"/>
        <FormError field="name" :form="form"/>
      </Field>

    </Section>

    <Section title="Conditions" id="conditions" open>

      <Field>
        <Label>Logic *</Label>
        <div class="buttons has-addons is-full-width">
          <Button :outlined="form.fields.conditionsLogic !== 'or'"
                  :light="$themeDark"
                  class="is-flex-grow-1"
                  :primary="form.fields.conditionsLogic === 'or'"
                  @click="form.fields.conditionsLogic = 'or'"
                  small
          >
            Match Any
          </Button>
          <Button :outlined="form.fields.conditionsLogic !== 'and'"
                  :light="$themeDark"
                  class="is-flex-grow-1"
                  :primary="form.fields.conditionsLogic === 'and'"
                  @click="form.fields.conditionsLogic = 'and'"
                  small
          >
            Match All
          </Button>
        </div>
      </Field>

      <Field>
        <Label>Conditions *</Label>
        <ConditionsForm v-model="form.fields.conditions" :ruleForm="form"/>
        <FormError field="conditions" :form="form"/>
      </Field>

    </Section>

    <Section title="Actions" open>
      <ActionsForm v-model="form.fields.actions"
                   :ruleForm="form"
      />
      <FormError field="actions" :form="form"/>
    </Section>

    <Section title="Activity">
      <ActivityResourceList :hasActivitiesId="rule.id"
                            hasActivitiesType="App\Models\Rule"
      />
    </Section>

    <Section title="Repetition">

      <Instruction>
        If the rule has been triggered, it will only trigger again after it has recovered from the original trigger.
      </Instruction>

<!--      <Field>-->
<!--        <BCheckbox v-model="form.fields.repeat.enabled">Enable Repeating</BCheckbox>-->
<!--      </Field>-->

<!--      <template v-if="form.fields.repeat.enabled">-->

<!--        <Field>-->
<!--          <Label>Repeat When *</Label>-->
<!--          <SelectInput v-model="form.fields.repeat.when" :options="repeatWhenOptions"/>-->
<!--        </Field>-->

<!--        <template v-if="form.fields.repeat.when === 'uplink'">-->

<!--          <Field>-->
<!--            <Label>Repeat If *</Label>-->
<!--            <SelectInput v-model="form.fields.repeat.if" :options="repeatIfOptions"/>-->
<!--          </Field>-->

<!--          <Field>-->
<!--            <TimeIntervalInput label="Delay *" v-model="form.fields.repeat.delay"/>-->
<!--            <Help>-->
<!--              What's the minimum delay that should exist between Actions firing?-->
<!--            </Help>-->
<!--          </Field>-->

<!--        </template>-->

<!--        <template v-else-if="form.fields.repeat.when === 'interval'">-->

<!--          <Field>-->
<!--            <TimeIntervalInput label="Interval *" v-model="form.fields.repeat.interval"/>-->
<!--          </Field>-->

<!--        </template>-->

<!--      </template>-->

    </Section>

    <Teleport to="#inspector-footer">

      <Controls>

        <template v-slot:left>

          <Button @click="handleRuleDelete" outlined danger :loading="deleting" v-if="rule">
            Delete
          </Button>

        </template>

        <template v-slot:right>

          <Button @click="$router.back()" outlined :light="$themeDark" :dark="$themeLight">
            Cancel
          </Button>

          <Button @click="handleRuleSave" primary class="has-margin-left">
            Save
          </Button>

        </template>

      </Controls>

    </Teleport>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

import ActionsForm from '@/components-V2/Rule/ActionsForm';
import ConditionsForm from '@/components-V2/Rule/ConditionsForm';
import ActivityResourceList from '@/components-V2/Activity/ResourceList.vue';
import {snakeCaseKeys} from '@/helpers';

export default {
  name: 'Form',
  components: {
    ActionsForm,
    ActivityResourceList,
    ConditionsForm,
  },
  computed: {
    pageTitle() {
      return this.$label('Rule');
    },
    rule() {
      return this.$route.params.ruleUUID ? this.$store.getters['rule/findBy'](this.$route.params.ruleUUID, 'uuid') : null;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (this.$route.params.ruleUUID) {
      this.loadRule();
    }

    this.loadUser();
  },
  data() {
    return {
      deleting: false,
      form: new Form({
        name: '',
        actions: [],
        conditions: [],
        conditionsLogic: 'or',
        repeat: {
          delay: null,
          enabled: false,
          if: null,
          interval: null,
          when: null,
        },
      }),
      loading: false,
      repeatIfOptions: [
        {
          label: '- Select an Options -',
          value: null,
        },
        {
          label: 'Rule has recovered and subsequently re-fired',
          value: 'recovered',
        },
        {
          label: 'Regardless of Rule recovery status',
          value: 'always',
        },
      ],
      repeatWhenOptions: [
        {
          label: '- Select an Option -',
          value: null,
        },
        {
          label: 'Uplink Received (Only repeat Actions when a new Uplink is received)',
          value: 'uplink',
        },
        {
          label: 'Interval (Repeatedly fire Actions until Rule has recovered)',
          value: 'interval',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      ruleDestroy: 'rule/destroy',
      ruleShow: 'rule/show',
      ruleStore: 'rule/store',
      ruleUpdate: 'rule/update',
      userShow: 'auth/userShow',
    }),
    fillForm() {

      if (!this.rule) {
        return;
      }

      this.form.fields = {
        id: this.rule.id,
        uuid: this.rule.uuid,
        name: this.rule.name,
        repeat: this.rule.repeat,
        conditionsLogic: this.rule.conditionsLogic,
        actions: [
          ...this.rule.actions,
        ],
        conditions: [
          ...this.rule.conditions,
        ],
      };
    },
    handleRuleDelete() {
      this.$deleteDialog({
        target: this.rule.name,
        targetType: this.$label('Rule'),
        onConfirm: () => {
          this.loading = true;
          this.ruleDestroy({
            id: this.rule.id,
          }).then(() => {
            window.app.snackbar('Rule Deleted');
            this.$router.push({
              name: 'rule/index',
            });
          }).catch((e) => {
            if (e.response && e.response.data) {
              window.app.snackbar({
                message: e.response.data.message,
                type: 'is-danger',
              });
            }
          }).finally(() => {
            this.loading = false;
          });
        },
      });
    },
    handleRuleSave() {

      this.form.errors.clear();

      let action = null;

      if (this.rule) {
        action = this.ruleUpdate;
      } else {
        action = this.ruleStore;
      }

      if (action) {

        this.loading = true;

        action({
          ...this.form.fields,
          id: this.form.fields.uuid,
          conditions: [
            ...this.form.fields.conditions.map((condition) => {

              delete condition.editing;

              return {
                ...condition,
                config: snakeCaseKeys(condition.config),
              };
            }),
          ],
          actions: [
            ...this.form.fields.actions.map((action) => {

              delete action.editing;

              return {
                ...action,
                config: snakeCaseKeys(action.config),
              };
            }),
          ],
        }).then(() => {
          window.app.snackbar('Rule Saved');
          this.$router.push({
            name: 'rule/index',
          });
        }).catch((error) => {
          this.form.recordErrors(error);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    loadRule() {
      this.loading = true;
      this.ruleShow({
        id: this.$route.params.ruleUUID,
        params: {
          include: [
            'actions',
            'conditions',
          ],
        }
      }).then(this.fillForm).finally(() => {
        this.loading = false;
      });
    },
    loadUser() {
      this.loading = true;
      this.userShow({
        params: {
          include: ['account.users'],
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>

<style scoped lang="scss">
#conditions {
  .condition-row {
    padding: 15px 0;
  }
}
</style>
