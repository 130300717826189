import {
  ResourceService,
} from '@/internal';

export default class LorawanNetworkServerService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'lorawan-network-server';
  }

  deviceDisconnect(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `/lorawan-network-server/${id}/device-disconnect`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  providerDevice(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `/lorawan-network-server/${id}/provider-device`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  providerDeviceDelete(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `/lorawan-network-server/${id}/provider-device-delete`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  providerDeviceOnboard(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `/lorawan-network-server/${id}/provider-device-onboard`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  providerDeviceLabelsSync(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'put',
      url: `/lorawan-network-server/${id}/provider-device-labels-sync`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  providerLabels(id, payload = {}) {

    const {params, ...rest} = payload;

    return this.request({
      method: 'get',
      url: `/lorawan-network-server/${id}/provider-labels`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

}
