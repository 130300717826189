<template>
<div class="copy-attribute pointer is-inline-block" @click="handleCopyAttribute">
  <BIcon icon="copy" />
</div>
</template>

<script>
import copy from 'copy-to-clipboard';

export default {
  name: 'CopyAttribute',
  methods: {
    handleCopyAttribute() {
      copy(this.model[this.attribute]);
      window.app.snackbar('Copied to Clipboard');
    },
  },
  props: {
    model: {
      required: true,
    },
    attribute: {
      required: true,
    },
  }
};
</script>

<style scoped lang="scss">
.copy-attribute {
  margin: 0 2.5px;
}
</style>
