<template>
  <NavbarDropdownSwitcher :label="currentEnv.label" position="is-right">
    <template v-slot:header>
      {{ currentEnv.label }}
    </template>
    <template v-slot:default>
      <template v-for="environment in environments" v-if="environment !== currentEnv">
        <section @click="handleSwitchEnvironment(environment.value)">
          {{ environment.label }}
        </section>
      </template>
    </template>
  </NavbarDropdownSwitcher>
</template>

<script>
export default {
  name: 'EnvironmentSwitcher',
  computed: {
    currentEnv() {
      return this.environments.find(env => env.key === process.env.VUE_APP_ENV);
    },
  },
  data() {
    return {
      loading: false,
      environments: [
        {
          key: 'local',
          label: 'Local',
          value: 'https://app.harvesthawk.io.test:5020',
        },
        {
          key: 'staging',
          label: 'Staging',
          value: 'https://app.harvesthawk.io.lss.dev',
        },
        {
          key: 'production',
          label: 'Production',
          value: 'https://app.harvesthawk.io',
        }
      ]
    };
  },
  methods: {
    handleSwitchEnvironment(environment) {
      if (this.currentEnv === environment) {
        return;
      }

      window.location.href = environment;
    }
  }
};
</script>

<style scoped lang="scss">

</style>
