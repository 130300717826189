<template>
  <div class="uplinks-resource-list">
    <ResourceList :params="params"
                  :query="query"
                  :resource="resource"
                  :renderAs="renderAs"
                  sortBy="created_at"
                  sortOrder="desc"
                  :sortByOptions="sortByOptions"
                  openInModal
    />
  </div>
</template>

<script>

import UplinkResourceListItem from '@/components-V2/Uplink/ResourceListItem';
import {Uplink} from '@/internal';

export default {
  name: 'UplinkResourceList',
  computed: {},
  data() {
    return {
      params: {
        include: [],
      },
      renderAs: UplinkResourceListItem,
      resource: Uplink,
      sortByOptions: [
        {
          label: 'Created Date',
          value: 'created_at',
        },
      ],
    };
  },
  methods: {},
  props: {
    openInModal: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {},
      required: false,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
