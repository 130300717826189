import {
  Model,
  LicenseService as Service, Product
} from '@/internal';

export default class License extends Model {

  static get key() {
    return 'license';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      name: {
        required: true,
        type: String,
      },
      endsAt: {
        type: Date,
      },
      startsAt: {
        type: Date,
      },
    };
  }

  get product()
  {
    return this.belongsTo(Product);
  }
}
