import {
  FileService as Service,
  Model,
} from '@/internal';

export default class File extends Model {

  static get key() {
    return 'file';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      name: {
        required: true,
        type: String,
      },
    };
  }
}
