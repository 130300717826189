<template>
  <nav id="navigator" class="navbar is-fixed-top is-dark">

    <div class="navbar-brand">

      <a :href="$domainUrl || '#'" class="navbar-item">
        <Logo withoutMark/>
      </a>

    </div>

    <div id="navbar-items"
         class="navbar-menu"
         :class="{ 'is-active': mobileMenuActive }"

    >
      <div class="navbar-end" v-if="authenticated">
        <router-link class="navbar-item" to="/auth/logout">
          Logout
        </router-link>
      </div>

    </div>
  </nav>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  debounce,
} from 'lodash';

import changeLogItems from '@/../change-log.json';

import EnvironmentSwitcher from '@/components/EnvironmentSwitcher';
import Logo from '@/components/Logo';
import UserMenu from '@/components-V2/UserMenu/UserMenu';

export default {
  name: 'Navbar',
  components: {
    EnvironmentSwitcher,
    Logo,
    UserMenu,
  },
  computed: {
    authenticated() {
      return this.$store.state.auth.authenticated;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    navbarDisabled() {
      return this.$route.meta.navbarDisabled || false;
    },
    routeFullPath() {
      return this.$route.fullPath;
    },
    routes() {
      return {
        alarms: {
          name: 'alarm/index',
        },
        apiKeys: {
          name: 'api-key/index',
        },
        assets: {
          name: 'asset/index',
        },
        advancedCharts: {
          name: 'advanced-charting',
        },
        devices: {
          name: 'device/index',
        },
        domains: {
          name: 'domain/edit',
        },
        soilNutrientCalculator: {
          name: 'nutrient-calculator',
        },
        users: {
          name: 'user/index',
        },
        regions: {
          name: 'region/index',
        },
        root: {
          name: 'explorer',
          query: this.$route.query,
        },

      }
    },
    user() {
      return this.authenticated ? this.$store.getters['auth/user'] : null;
    },
  },
  created() {
    this.lastRouteFullPath = this.$route.fullPath;
  },
  data() {
    return {
      changeLogItems,
      filtersManagerActive: false,
      lastRouteFullPath: null,
      mobileMenuActive: false,
      performSearch: debounce(() => {
        this.searching = true;
        this.search({
          params: {
            q: this.searchTerm,
          },
        }).then((response) => {
          this.searchResults = response.data.results;
        }).finally(() => {
          this.searching = false;
        });
      }, 1000),
      searching: false,
      searchResults: [],
      searchTerm: '',
      userMenuActive: false,
    };
  },
  methods: {
    ...mapActions({
      search: 'system/search',
      toggleTheme: 'system/toggleTheme',
    }),
    handleSearch() {

      if (this.searchTerm.length <= 2) {
        return;
      }

      this.performSearch();
    },
    handleToggleUserMenu() {
      this.userMenuActive = !this.userMenuActive;
    },
  },
  watch: {
    routeFullPath() {
      if (this.routeFullPath !== this.lastRouteFullPath) {
        this.mobileMenuActive = false;
        this.lastRouteFullPath = this.routeFullPath;
      }
    },
  },
};
</script>

<style lang="scss">
#navigator {
  border-bottom: 1px solid $grey-darker;
  height: 3.5rem;
  z-index: 41;

  .navbar-brand {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.is-dark {

    .navbar-dropdown {
      background: $dark;
      border: 1px solid $grey;
      border-top-width: 0;
    }

    .navbar-divider {
      background: $grey;
    }

    .navbar-item {
      background: $dark;
      color: $white;
    }
  }

  .navbar-header {
    font-size: 0.9em;
    font-weight: bold;
    padding: 1em 1em 0.25em;
    text-transform: uppercase;
  }

  .navbar-divider {
    height: 1px;
  }

  .navbar-dropdown {
    border-bottom-left-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
  }

  .navbar-item {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    padding-right: .75rem;
    text-transform: uppercase;

    & > .icon {
      margin-right: 10px;
      margin-top: 2px;
    }

    .switch {
      margin-left: 3rem;
    }
  }

  .navbar-start,
  .navbar-end {
    flex: 1;
  }

  #search {
    display: flex;

    .autocomplete {
      flex: 1;
      width: 380px;

      input {
        background: $black-bis;
        border: none;
        color: $white;

        &::placeholder {
          color: $grey-dark;
          opacity: 1;
        }
      }
    }
  }

  .filters-manager {
    &.has-filters {
      background: $grey-dark !important;
    }

    & > div > .icon {
      margin-right: 5px;
    }
  }

  .user-initials {
    $size: 34px;
    align-items: center;
    background: $primary;
    border-radius: 50%;
    display: flex;
    font-size: 0.9em;
    height: $size;
    justify-content: center;
    line-height: 1;
    text-transform: uppercase;
    width: $size;
  }

  @include until($desktop) {
    #navbar-items {
      background: $black-bis;
      border-lefT: 1px solid $black;
      position: fixed;
      top: 3.5rem;
      bottom: 0;
      right: 0;
      width: 100%;

      .navbar-item {
        background: $black-bis;
      }
    }

    #search {
      .navbar-item {

        width: 100%;

        .autocomplete {
          width: 100%;
        }

        input {
          background: $black;
        }
      }
    }
  }
}
</style>
