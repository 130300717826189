<template>
  <div class="field">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Field',
}
</script>

<style scoped lang="scss">
.field {
  padding: 5px 0;
}
</style>
