<template>
  <Page id="update-required">
    <div class="container container-extra-small">
      <div class="has-text-centered">
        <Logo/>
      </div>
      <p class="has-text-centered has-margin-vertical">
        A new version has been released that<br />
        requires you to reload the application.
      </p>
      <div class="has-text-centered" style="margin-top: 30px;">
        <Button primary @click="handleReloadApplication">
          Reload Application
        </Button>
      </div>
    </div>
  </Page>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import Logo from '@/components/Logo';

export default {
  name: 'UpdateRequiredSplash',
  components: {
    Logo,
  },
  computed: {},
  data () {
    return {};
  },
  methods: {
    ...mapActions({}),
    handleReloadApplication () {
      window.location.reload(true);
    },
  },
  props: {},
};
</script>

<style scoped lang="scss">
#update-required {
  align-items: center;
  background: $dark;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $zindex-screen-block;
}
</style>
