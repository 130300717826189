import FAQs from '@/views/Support/FAQs';

const routes = [
  {
    name: 'support/faqs',
    path: '/support/faqs',
    component: FAQs,
    meta: {
      icon: 'circle-question',
      middleware: ['auth'],
      title: `FAQ's`,
    },
  },
];

export default routes;
