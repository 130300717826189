<template>
  <div id="alarm-index" class="is-padded">
    <AlarmResourceList />
  </div>
</template>

<script>
import AlarmResourceList from '@/components-V2/Alarm/ResourceList';

export default {
  name: 'Index',
  components: {
    AlarmResourceList,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">

</style>
