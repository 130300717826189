<template>
  <div class="ue-file-input">

    <FakeInput class="ue-file is-flex is-align-items-center is-justify-content-space-between" v-if="file">

      <div>
        {{ file.name }}
      </div>

      <div class="file-controls is-flex is-align-items-center">

        <BTooltip label="Preview">
          <div class="file-control file-preview" @click="handleFilePreview">
            <BIcon icon="search"/>
          </div>
        </BTooltip>

        <BTooltip label="Scan">
          <div class="file-control file-edit" @click="handleFileScan" v-if="editable">
            <BIcon icon="barcode-read"/>
          </div>
        </BTooltip>

        <BTooltip label="Edit">
          <div class="file-control file-edit" @click="handleFileEdit" v-if="editable">
            <BIcon icon="pencil"/>
          </div>
        </BTooltip>

        <BTooltip label="Delete">
          <div class="file-control file-delete" @click="handleFileDelete">
            <BIcon icon="trash-alt"/>
          </div>
        </BTooltip>

      </div>

    </FakeInput>

    <template v-else>
      <FilePickerButton :loading="loading" v-model="selectedFile"/>
      <Help v-if="types.length">Allowed: {{ types.join(', ') }}</Help>
    </template>

    <Field v-if="localPreview" class="has-margin-top">
      <Label>Preview</Label>
      <img :src="file.proxyUrl" :alt="file.name"/>
    </Field>

    <Field v-if="scanning || scanResults" class="has-margin-top">
      <Label>Scan Results</Label>
      <div class="is-relative" style="height: 200px;" v-if="scanning">
        <BLoading active :isFullPage="false"/>
      </div>
      <JSON v-if="scanResults" :data="scanResults"/>
    </Field>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'FileInput',
  computed: {
    file() {
      // return this.value ? this.$store.getters['file/findBy'](this.value) : null;
      // Not sure why 👆 isn't working

      return this.files.find(file => file.id === this.value) || null;
    },
    files() {
      return this.$store.getters['file/all'];
    },
  },
  data() {
    return {
      loading: false,
      localPreview: false,
      scanning: false,
      scanResults: null,
      selectedFile: null,
    };
  },
  methods: {
    ...mapActions({
      fileScan: 'file/scan',
      fileStore: 'file/store',
      fileDestroy: 'file/destroy',
    }),
    handleFileDelete() {
      this.$deleteDialog({
        target: this.file.name,
        targetType: 'file',
        onConfirm: () => {
          this.loading = true;
          this.fileDestroy({
            id: this.file.id,
          }).then(() => {
            this.$emit('input', null);
            window.app.snackbar('File Deleted');
            this.selectedFile = null;
            this.scanResults = null;
            this.localPreview = false;
          }).finally(() => {
            this.loading = false;
          });
        }
      })
    },
    handleFileEdit() {
      this.$emit('edit');
    },
    handleFilePreview() {

      if (this.localPreview) {
        this.localPreview = false;
      } else if (this.file && this.file.mime.includes('image/')) {
        this.localPreview = true;
      } else if (this.file) {
        window.open(this.file.proxyUrl);
      }
    },
    handleFileScan() {

      if (this.scanResults) {
        this.scanResults = null;
        return;
      }

      this.scanning = true;
      this.fileScan({
        id: this.file.id,
      }).then((response) => {
        this.scanResults = response.data.data.scan;
      }).finally(() => {
        this.scanning = false;
      });
    },
    handleFileStore() {

      this.loading = true;

      const formData = new FormData();

      formData.append('file', this.selectedFile);
      formData.append('name', this.selectedFile.name);

      this.fileStore({formData}).then((response) => {
        this.selectedFile = null;
        this.$emit('input', parseInt(response));
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.loading = false;
      });

    }
  },
  props: {
    disabled: {
      default: false,
      required: false,
      type: Boolean,
    },
    editable: {
      default: false,
      type: Boolean,
    },
    types: {
      default: () => [],
      required: false,
      type: Array,
    },
    value: {
      required: true,
    },
  },
  watch: {
    selectedFile: {
      handler() {
        if (this.selectedFile) {
          this.handleFileStore();
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
.ue-file-input {
  .ue-file {

  }

  .file-controls {

    .file-control {
      border-radius: 5px;
      cursor: pointer;
      padding: 2.5px;

      &:hover {
        background: $grey-dark;
        color: $white;
      }
    }
  }
}
</style>
