<template>
  <router-link :to="editRoute" class="api-key resource-list-item" :class="{ 'is-dark': $themeDark }">
    {{ apiKey.name }}
  </router-link>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    apiKey() {
      return this.resource;
    },
    editRoute() {
      let route = `/api-key/${this.apiKey.uuid}/edit`;

      if (this.domainUUID) {
        return `/domain${route}`;
      }

      return route;
    },
  },
  created() {
    this.domainUUID = window.app.storage.getItem('domainBeingManaged');
  },
  data() {
    return {
      domainUUID: null,
    };
  },
  props: {
    resource: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
