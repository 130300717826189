<template>
  <div id="region-show" class="is-padded" v-if="region">

    <div class="is-padded-vertical">
      <RegionPreview :region="region"/>
    </div>

    <Section title="Meta">
      <LabelLineValue label="Color" :value="region.color" clickToCopy/>
    </Section>

    <Section title="Map">
      <MapInput v-model="region.geojson" :color="region.color" :readonly="true"></MapInput>
    </Section>

    <Section title="Devices">
      <DeviceResourceList :query="{regionId: region.id}" hideAddButton />
    </Section>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import tinycolor from 'tinycolor2';

import MapInput from '@/components-V2/Inputs/MapInput';
import DeviceResourceList from '@/components-V2/Device/ResourceList';
import RegionPreview from '@/components-V2/Region/Preview';

export default {
  name: 'Show',
  components: {
    MapInput,
    DeviceResourceList,
    RegionPreview,
  },
  computed: {
    devices() {
      return this.region ? this.region.devices : [];
    },
    fullPath() {
      return this.$route.fullPath;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    pageTitle() {
      return `Region: ${this.region ? this.region.name : ''}`;
    },
    region() {
      return this.$route.params.regionUUID ? this.$store.getters['region/findBy'](this.$route.params.regionUUID, 'uuid') : null;
    },
  },
  created() {
    this.loadRegion();
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      show: 'region/show'
    }),
    handleEdit() {
      const route = window.app.findRouteByName('region/edit', [], {
        params: {
          regionUUID: this.region.uuid
        },
      });

      this.$router.push(route);
    },
    isLight(color) {
      return tinycolor(color).isLight();
    },
    loadRegion() {
      this.loading = true;
      return this.show({
        id: this.$route.params.regionUUID,
      }).then((response) => {

      }).catch((error) => {
        window.app.snackbar('Error loading region');
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    fullPath() {
      this.loadRegion();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
