import {
  ResourceService,
} from '@/internal';

export default class ApiKeyService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'api-key';
  }
}
