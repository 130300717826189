<template>
  <div class="timezone-input">
    <BAutocomplete v-model="search"
                   :data="searchResults"
                   icon="search"
                   placeholder="Search by City"
                   clearable
                   @select="handleSelect"
                   :class="{ 'is-dark': $themeDark }"
    >
      <template #empty>No results found</template>
      <template slot-scope="props">
        {{ props.option }}
      </template>
    </BAutocomplete>
  </div>
</template>

<script>
import timezoneData from 'moment-timezone/data/meta/latest';

export default {
  name: 'TimezoneInput',
  computed: {
    searchResults() {
      return this.search && this.search.length > 1 ? this.zones.filter(zone => zone.toLowerCase().includes(this.search.toLowerCase())) : [];
    },
    zones() {
      const zones = [];

      for (let [key, value] of Object.entries(timezoneData.zones)) {
        let label = value.name.replace(/_/g, ' ');
        zones.push(value.name);
      }

      return zones;
    },
  },
  data() {
    return {
      search: '',
    };
  },
  methods: {
    handleSelect(value) {
      this.$emit('input', value);
    },
  },
  props: {
    value: {
      required: true,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.search !== this.value) {
          this.search = this.value;
        }
      },
    },
  }
}
</script>

<style scoped lang="scss">
.timezone-input {

}
</style>
