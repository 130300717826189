import {
  ResourceService,
} from '@/internal';

export default class InventoryService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'inventory';
  }

  claim(id, payload = {}) {
    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `inventory/${id}/claim`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  unclaim(id, payload = {}) {
    const {params, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `inventory/${id}/unclaim`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }
}
