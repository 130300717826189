import storage from '@/drivers/LocalStorageDriver';
import {bbox as turfBbox} from '@turf/turf';
import router from '@/router';

const mapStorage = storage.getItem('map') || {};

const customMapStyles = mapStorage.customMapStyles || {};

export default {
  key: 'map',
  namespaced: true,
  state: {
    controlMapLayers: false,
    controlMapStyles: false,
    lockedOnInspected: true,
    map: null,
    mapStyle: mapStorage && mapStorage.style ? mapStorage.style : 'dark',
    mapStyles: {
      default: {
        label: 'Greyscale',
        preview: require('../../assets/images/mapStyles/default.png'),
        url: 'nickhough/ckzwya9qr000j14qmqtktgvjx',
      },
      light: {
        label: 'Light',
        preview: require('../../assets/images/mapStyles/light.png'),
        url: 'mapbox/light-v10',
      },
      dark: {
        label: 'Dark',
        preview: require('../../assets/images/mapStyles/dark.png'),
        url: 'mapbox/dark-v10',
      },
      streets: {
        label: 'Streets',
        preview: require('../../assets/images/mapStyles/streets.png'),
        url: 'mapbox/streets-v11',
      },
      outdoors: {
        label: 'Outdoors',
        preview: require('../../assets/images/mapStyles/outdoors.png'),
        url: 'mapbox/outdoors-v11',
      },
      satellite: {
        label: 'Satellite',
        preview: require('../../assets/images/mapStyles/satellite.png'),
        url: 'mapbox/satellite-streets-v11',
      },
      topographical: {
        label: 'Topographical',
        preview: require('../../assets/images/mapStyles/topographical.png'),
        url: 'nickhough/ckwxmqm6i824414mnet4x9iaa',
      },
      ...customMapStyles,
    },
    userLocation: null,
  },
  actions: {
    centerOnUser({commit, dispatch, state}) {

      if (!state.userLocation) {
        return navigator.geolocation.getCurrentPosition((position) => {
          if (position) {
            return dispatch('setUserLocation', {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          }

          return false;
        });
      }

      if (state.userLocation) {
        state.map.flyTo({
          center: [state.userLocation.lng, state.userLocation.lat],
          zoom: 16,
          essential: true,
        });
      }

    },
    flattenOrientation({state}) {
      state.map.flyTo({
        bearing: 0,
        pitch: 0,
      });
    },
    setLockedOnInspected({commit, state}, payload) {
      return commit('lockedOnInspected', payload);
    },
    setMap({commit}, payload) {
      return commit('map', payload);
    },
    setMapStyle({commit, state}, payload) {
      return commit('mapStyle', payload);
    },
    setUserLocation({commit}, payload) {
      return commit('userLocation', payload);
    },
    toggleControlLayer({commit}, layer) {
      return commit('toggleControlLayer', layer)
    },
    zoomToAll({state, rootState, rootGetters}) {

      const devices = rootGetters['device/all'];

      let geoJson = {
        type: 'FeatureCollection',
        features: devices.filter(device => device.position).map((device) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [device.position.lng, device.position.lat]
            },
          };
        })
      };

      let bbox = turfBbox(geoJson);

      const primaryMenu = rootState.ui.primaryMenu;
      const inspectorActive = router.currentRoute && router.currentRoute.path !== '/'
      const inspector = rootState.ui.inspector;

      const padding = 100;
      const left = primaryMenu.active ? primaryMenu.width + padding : padding;
      const right = inspectorActive ? inspector.width + padding : padding;

      try {
        state.map.fitBounds(bbox, {
          padding: {
            top: padding,
            bottom: padding,
            left,
            right,
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
  mutations: {
    lockedOnInspected(state, payload) {
      state.lockedOnInspected = payload;
    },
    map(state, payload) {
      state.map = payload;
    },
    mapStyle(state, payload) {
      state.mapStyle = payload;
    },
    toggleControlLayer(state, layer) {

      const layers = [
        'controlMapLayers',
        'controlMapStyles'
      ];

      layers.forEach((_layer) => {
        state[_layer] = _layer === layer ? !state[_layer] : false;
      });
    },
    userLocation(state, payload) {
      state.userLocation = payload;
    },
  },
};
