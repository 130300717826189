<template>
  <BTooltip :label="title" position="is-right">
    <div class="device-status-dot" :class="{
          'is-inactive': !device.active,
          'is-online': device.active && device.status && device.status === 'online',
          'is-offline': device.active && device.status && device.status === 'offline',
          'is-pending': device.active && device.status === 'pending' || device.status === null,
         }"
         :style="{
          background: device.statusColor,
         }"
    >
    </div>
  </BTooltip>
</template>

<script>
export default {
  name: 'StatusDot',
  computed: {
    title() {
      if (this.device.status === null) {
        return 'Pending';
      } else if (!this.device.active) {
        return 'Inactive';
      } else {
        return this.$titleize(this.device.status);
      }
    },
  },
  props: {
    device: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">
.device-status-dot {
  $size: 10px;
  border-radius: 50%;
  display: inline-block;
  height: $size;
  width: $size;
}
</style>
