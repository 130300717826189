import Index from '@/views/Rule/Index';
import Show from '@/views/Rule/Show';
import Form from '@/views/Rule/Form';

import MeatCacheRuleForm from '@/_instances/MeatCache/views/Rule/Form.vue';
import {isLakeStreet, isMeatCache} from '@/helpers';

let createRoute;
let editRoute;

if (isMeatCache() || isLakeStreet()) {

  createRoute = {
    name: 'rule/create',
    path: '/rule/create',
    component: MeatCacheRuleForm,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('home');
      },
      containerSize: 'container-extra-extra-small',
      icon: 'bell-ring',
      middleware: ['auth'],
      title: () => {
        return 'Rule Settings';
      },
    },
  };

  editRoute = {
    name: 'rule/edit',
    path: '/rule/:ruleUUID/edit',
    component: MeatCacheRuleForm,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('home');
      },
      containerSize: 'container-extra-extra-small',
      icon: 'bell-ring',
      middleware: ['auth'],
      title: () => {
        return 'Rule Settings';
      },
    },
  };

} else {

  createRoute = {
    name: 'rule/create',
    path: '/rule/create',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('rule/index');
      },
      containerSize: 'container-extra-small',
      icon: 'bolt',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? `New ${window.app.vue.$label('Rule', false)}` : 'New Rule';
      },
    },
  };

  editRoute = {
    name: 'rule/edit',
    path: '/rule/:ruleUUID/edit',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('rule/index');
      },
      containerSize: 'container-extra-small',
      icon: 'bolt',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? `Edit ${window.app.vue.$label('Rule', false)}` : 'Edit Rule';
      },
    },
  };

}

const routes = [
  {
    name: 'rule/index',
    path: '/rule',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'bolt',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? window.app.vue.$label('Rules', true) : 'Rules';
      },
    },
  },
  {
    ...createRoute,
  },
  {
    name: 'rule/show',
    path: '/rule/:ruleUUID',
    component: Show,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('rule/index');
      },
      containerSize: 'container-medium',
      icon: 'bolt',
      middleware: ['auth'],
      title: () => {
        return window.app && window.app.vue ? window.app.vue.$label('Rule', false) : 'Rule';
      },
      editRoute: (route) => {
        return window.app.findRouteByName('rule/edit', {
          ruleUUID: route.params.ruleUUID,
        });
      },
    },
  },
  {
    ...editRoute,
  },
];

export default routes;
