<template>
  <div class="uplink-state" v-if="device">

    <template v-if="device.parameters">
      <template v-for="parameter in parameters">
        <LabelLineValue v-if="parameter.latestParameterValue"
                        :label="parameter.title"
                        :value="parameter.latestParameterValue ? `${parameter.latestParameterValue.formattedValue}` : 'No Data'"
                        :time="parameter.latestParameterValue.createdAt.format(system.settings.datetimeFormat)"
        />
      </template>
    </template>

    <template v-if="device.position && !device.autoUpdatePosition">

      <LabelLineValue v-if="device.position"
                      label="Latitude"
                      :value="device.position.lat.toFixed(6)"
                      clickToCopy
      />

      <LabelLineValue v-if="device.position"
                      label="Longitude"
                      :value="device.position.lng.toFixed(6)"
                      clickToCopy
      />

      <LabelLineValue v-if="device.position && device.position.altitude"
                      label="Altitude"
                      :value="device.position.altitude.toFixed(2)"
                      clickToCopy
      />

    </template>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'State',
  computed: {
    parameters() {
      return this.device ? this.device.parameters : [];
    },
    system() {
      return this.$store.state.system;
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {
    device: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
</style>
