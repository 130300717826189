import {
  ResourceService,
} from '@/internal';

export default class ConverterService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'converter';
  }

  test(id, payload = {}) {
    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url:`device/${id}/converter`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

}
