<template>
  <div id="notification-channels-form" class="is-padded">

    <Instruction>
      Channels are where <router-link to="/my/notifications">Notifications</router-link> generated by {{$domainName}} can be sent.
    </Instruction>

    <div class="notification-channels" v-if="!showForm">

      <template v-for="notificationChannel in notificationChannels">
        <div class="notification-channel">
          <div class="left">
            <div class="name">
              <BIcon :icon="iconForType(notificationChannel.type).icon"
                     :pack="iconForType(notificationChannel.type).pack || 'fal'"/>
              {{ notificationChannel.name }}
              <BTooltip :label="notificationChannel.verified ? 'Verified' : 'Unverified'">
                <BIcon icon="check-circle"
                       :type="notificationChannel.verified ? 'is-success' : 'is-danger'"
                       v-if="notificationChannel.requiresVerification"
                />
              </BTooltip>
            </div>
          </div>
          <div class="right">
            <div class="connection-string">
              <template v-if="notificationChannel.type === 'vonage'">
                {{ formatPhone(notificationChannel.connectionString) }}
              </template>
              <template v-else>
                {{ notificationChannel.connectionString }}
              </template>
            </div>
            <div class="delete-contact-method" :class="{ 'has-text-light': $themeDark }"
                 @click="handleDeleteNotificationChannel(notificationChannel)">
              <BIcon :icon="notificationChannel.isPrimary ? 'lock' : 'times'"/>
            </div>
          </div>
        </div>
      </template>

      <div class="controls has-text-right has-margin-top">
        <Button primary @click="handleAddNotificationChannel">Add Channel</Button>
      </div>
    </div>

    <div class="contact-method-form" v-else>

      <template v-if="form.fields.verificationUUID">
        <Verifier :form="form"
                  v-model="form.fields.verificationCode"
                  :channel="form.fields.type"
                  :connection-string="form.fields.connectionString"
                  :loading="loading"
                  reason="Notification Channel"
                  showControls
                  @verify="handleSaveNotificationChannel"
                  @cancel="handleVerificationCodeCancel"
                  @uuid="handleUuidUpdate"

        />
      </template>

      <template v-else>

        <Field>
          <Label>Type *</Label>
          <SelectInput v-model="form.fields.type" :options="types"/>
          <FormError field="type" :form="form"/>
        </Field>

        <Field>
          <Label>Name *</Label>
          <TextInput v-model="form.fields.name" placeholder="Personal, Work, etc."/>
          <FormError field="name" :form="form"/>
        </Field>

        <Field v-if="form.fields.type === 'vonage'">
          <Label>Phone Number *</Label>
          <PhoneInput v-model="form.fields.connectionString"/>
          <FormError field="connectionString" :form="form"/>
        </Field>

        <Field v-if="form.fields.type === 'mail'">
          <Label>Email Address *</Label>
          <TextInput v-model="form.fields.connectionString"/>
          <FormError field="connectionString" :form="form"/>
        </Field>

        <Field v-if="form.fields.type === 'slack'">
          <Label>Webhook URL *</Label>
          <UrlInput v-model="form.fields.connectionString"/>
          <FormError field="connectionString" :form="form"/>
        </Field>

        <Field v-if="form.fields.verificationUUID">
          <Label>Validation Code *</Label>
          <TextInput v-model="form.fields.verificationCode"/>
        </Field>

        <Teleport to="#inspector-footer">

          <Controls>
            <template v-slot:right>
              <Button :light="$themeDark" :dark="$themeLight" outlined class="has-margin-right" @click="handleCancelNotificationChannel">Cancel</Button>
              <Button primary :loading="loading" @click="handleSaveNotificationChannel">Save</Button>
            </template>
          </Controls>

        </Teleport>



      </template>

    </div>

  </div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';

import {
  mapActions,
} from 'vuex';

import {
  Form,
} from '@/internal';

export default {
  name: 'NotificationChannelsForm',
  computed: {
    notificationChannels() {
      return this.$store.getters['notificationChannel/collection'](this.ids)
        .sort((a, b) => a.type < b.type ? 1 : -1)
        .sort((a, b) => a.isPrimary < b.isPrimary ? 1 : -1);
    },
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  created() {
    this.loadNotificationChannels();
  },
  data() {
    return {
      form: new Form({
        connectionString: '',
        name: '',
        type: 'mail',
        verificationCode: '',
        verificationUUID: null,
      }),
      ids: [],
      loading: false,
      showForm: false,
      types: [
        {
          label: 'Email',
          value: 'mail',
        },
        // {
        //   label: 'Phone',
        //   value: 'vonage',
        // },
        {
          label: 'Slack',
          value: 'slack',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      index: 'notificationChannel/index',
      store: 'notificationChannel/store',
      destroy: 'notificationChannel/destroy',
      notificationIndex: 'notification/index',
    }),
    formatPhone(phone) {
      const phoneNumber = parsePhoneNumber(phone);
      return phoneNumber.formatNational();
    },
    handleAddNotificationChannel() {
      this.showForm = true;
    },
    handleCancelNotificationChannel() {
      this.showForm = false;
    },
    handleDeleteNotificationChannel(notificationChannel) {

      if (notificationChannel.isPrimary) {
        window.app.snackbar({
          message: `You can't delete your primary email`,
          type: 'is-danger',
        });
        return;
      }

      this.$deleteDialog({
        target: notificationChannel.name,
        targetType: 'Channel',
        onConfirm: () => {
          this.destroy({
            id: notificationChannel.id,
          }).then(() => {
            window.app.snackbar({
              message: 'Channel Deleted',
            });
            this.loadNotificationChannels();
          });
        },
      });
    },
    handleSaveNotificationChannel() {

      if (this.loading) {
        return;
      }

      this.loading = true;
      this.form.errors.clear();
      this.store({
        ...this.form.fields,
      }).then(() => {

        window.app.snackbar({
          message: 'Channel Added',
        });
        this.showForm = false;
        this.form.reset();
        this.loadNotificationChannels();

      }).catch((error) => {

        if (window.app.verificationRequiredError(error)) {
          const verificationRequired = window.app.verificationRequiredError(error);
          this.form.fields.verificationUUID = verificationRequired ? verificationRequired.uuid : null;
        }

        this.form.recordErrors(error);

      }).finally(() => {
        this.loading = false;
      });
    },
    handleUuidUpdate(uuid) {
      this.form.fields.verificationUUID = uuid;
    },
    handleVerificationCodeCancel() {
      this.form.fields.verificationUUID = null;
      this.form.fields.verificationCode = null;
    },
    iconForType(type) {
      switch (type) {
        case 'vonage':
          return {
            icon: 'phone',
          };
        case 'slack':
          return {
            icon: 'slack',
            pack: 'fab',
          };
        case 'mail':
          return {
            icon: 'envelope',
          };
        default:
          return {
            icon: 'question',
          };
      }
    },
    loadNotificationChannels() {
      this.index().then(({ids}) => {
        this.ids = ids;
      }).then(() => {
        this.notificationIndex();
      });
    }
  },
  props: {},
}
</script>

<style scoped lang="scss">
.notification-channels {
  .notification-channel {

    border: 1px solid $grey;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0;
    padding: 5px;

    .left {
      width: 50%;
    }

    .right {
      display: flex;
      width: 50%;

      .connection-string {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .delete-contact-method {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        font-size: 1.25em;

        .icon {
          position: relative;
          top: 1px;
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
