import {
  Model,
  ActivityService as Service,
  User,
} from '@/internal';

export default class Activity extends Model {

  static get key() {
    return 'activity';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {};
  }

  get causer() {
    return this.belongsTo(User, 'causerId', 'id', 'causer');
  }
}
