<template>
  <div id="primary-menu"
       :class="{
           'is-active': primaryMenu.active,
           'is-anchored': $layoutAnchored || isMobile,
           'is-dark': $themeDark,
           'is-floating': $layoutFloating && !isMobile,
           'is-full-screen': isFullScreen,
           'is-inactive': !primaryMenu.active,
           'is-mobile': isMobile,
           'is-standalone': $isStandalone,
       }"
       ref="primaryMenu"
       :key="key"
  >

    <BLoading active v-if="loading"/>

    <div id="primary-menu-header" class="is-flex is-align-items-center" v-if="showPrimaryMenuToggle">

      <div id="primary-menu-toggle" class="pointer" @click="handlePrimaryMenuToggle">
        <BIcon icon="bars" custom-class="fa-lg"/>
      </div>

      <router-link to="/" id="brand" v-if="!isMobile || primaryMenu.active">
        <Logo/>
      </router-link>

    </div>

    <template v-if="primaryMenu.active">

      <div id="scrollable">
        <div id="menus">
          <template v-for="menu in menusArray">
            <NestedList :list="menu" @toggle="handleMenuToggle(menu)"/>
          </template>
        </div>
      </div>

<!--      <LicenseWarning />-->

      <div class="system">

        <Button block primary outlined class="has-margin-bottom" v-if="system.updateAvailable"
                @click="handleReloadApplication">
          <BIcon icon="bolt"/>
          Update Available
        </Button>

        <div class="version">
          {{ version }}
        </div>

      </div>

    </template>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  NestedList,
} from '@/internal';

import Logo from '@/components/Logo';
import MenuItem from '@/components/Menu/MenuItem';

import AccountMenu from '@/menus/AccountMenu';
import CoreObjectsMenu from '@/menus/CoreObjectsMenu';
import DomainDashboardMenu from '@/menus/DomainDashboardMenu';
import DomainMenu from '@/menus/DomainMenu';
import LicenseWarning from '@/components-V2/LicenseWarning/LicenseWarning';
import ProductsMenu from '@/menus/ProductsMenu';
import SupportMenu from '@/menus/SupportMenu';
import ShopMenu from '@/menus/ShopMenu';
import SystemMenu from '@/menus/SystemMenu';
import ToolsMenu from '@/menus/ToolsMenu';
import UserMenu from '@/menus/UserMenu';

export default {
  name: 'PrimaryMenu',
  components: {
    LicenseWarning,
    Logo,
    MenuItem,
  },
  computed: {
    account() {
      return this.user && this.user.account ? this.$store.getters['account/findBy'](this.user.account.uuid, 'uuid') : null;
    },
    domain() {
      return window.app.env.domainUUID ? this.$store.getters['domain/findBy'](window.app.env.domainUUID, 'uuid') : null;
    },
    domainBeingManaged() {
      return this.domainBeingManagedUUID ? this.$store.getters['domain/findBy'](this.domainBeingManagedUUID, 'uuid') : null;
    },
    inspector() {
      return this.$store.state.ui.inspector;
    },
    isFullScreen() {
      return this.$route.meta.fullScreen || this.inspector.fullScreen;
    },
    isManagingDomain() {
      return window.location.origin.includes(process.env.VUE_APP_DOMAIN_MANAGEMENT_URL);
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    key() {
      return btoa({
        userId: this.user.id,
        assets: this.user.featureEnabled('assets'),
      });
    },
    menusArray() {
      return Object.values(this.menus);
    },
    primaryMenu() {
      return this.$store.state.ui.primaryMenu;
    },
    showPrimaryMenuToggle() {
      return !this.isMobile;
    },
    system() {
      return this.$store.state.system;
    },
    user() {
      return this.$store.getters['auth/user'] || null;
    },
    version() {
      return `V${this.system.version.app}`;
    },
  },
  async created() {
    this.domainBeingManagedUUID = window.app.storage.getItem('domainBeingManaged');
    await this.loadDomainBeingManaged();
    await this.loadAccount();
    this.loadMenus();
    window.app.vue.$on('ldInitialized', this.handleLdInitialized);
  },
  destroyed() {
    window.app.vue.$off('ldInitialized', this.handleLdInitialized);
  },
  data() {
    return {
      domainBeingManagedUUID: null,
      loading: false,
      menus: {},
    };
  },
  methods: {
    ...mapActions({
      accountShow: 'account/show',
      domainShow: 'domain/show',
      primaryMenuUpdate: 'ui/primaryMenu',
    }),
    handleLdInitialized() {
      this.loadMenus();
    },
    handleMenuToggle(menu) {

      menu.open = !menu.open;

      this.menus = {
        ...this.menus,
        [menu.id]: menu,
      };

      return this.handlePrimaryMenuUpdate({
        ...this.primaryMenu,
        menuState: {
          ...this.primaryMenu.menuState,
          [menu.id]: menu.open,
        }
      })
    },
    handlePrimaryMenuToggle() {
      this.primaryMenuUpdate({
        width: this.$refs.primaryMenu.clientWidth,
        active: !this.primaryMenu.active,
      });
    },
    handlePrimaryMenuUpdate(payload) {
      this.primaryMenuUpdate({
        width: this.$refs.primaryMenu.clientWidth,
        ...payload,
      });
    },
    handleReloadApplication() {
      window.location.reload(true);
    },
    loadAccount() {

      if (!this.user.account.id) {
        return false;
      }

      this.loading = true;
      return this.accountShow({
        id: this.user.account.id,
      }).finally(() => {
        this.loading = false;
      });
    },
    loadDomainBeingManaged() {

      if (!this.domainBeingManagedUUID) {
        return;
      }

      this.loading = true;

      return this.domainShow({
        id: this.domainBeingManagedUUID,
      }).catch((error) => {
        console.error(error);
        window.app.snackbar('Error loading Domain');
      }).finally(() => {
        this.loading = false;
      });
    },
    loadMenus() {

      let menusArray = [];
      let menus = {};

      // Domain Management

      if (this.isManagingDomain) {

        menusArray.push(DomainMenu);

        if (this.domainBeingManaged.featureEnabled('products')) {
          menusArray.push(ProductsMenu);
        }

        menusArray.push(UserMenu);

        menusArray.push(SystemMenu);

      }

      // Typical Menu

      else {

        if (this.domain.featureEnabled('domain_dashboard')) {
          menusArray.push(DomainDashboardMenu);
        }

        menusArray.push(CoreObjectsMenu);

        menusArray.push(ToolsMenu);

        // menusArray.push(SupportMenu);

        menusArray.push(UserMenu);

        menusArray.push(AccountMenu);

        if (false) {
          menusArray.push(ShopMenu);
        }

        if (this.user.isDomainOwner) {
          menusArray.push(DomainMenu);
        }

        menusArray.push(SystemMenu);

      }

      menusArray.forEach((_menu) => {

        let menu = _menu({
          account: this.account,
          domain: this.domain,
          domainBeingManaged: this.domainBeingManaged,
          $label: this.$label,
          $router: this.$router,
          system: this.system,
          user: this.user,
        });

        menu.open = this.primaryMenu.menuState[menu.id] !== undefined ? this.primaryMenu.menuState[menu.id] : menu.open;

        if (menu.menuItems.length || menu.onClick) {
          menus[menu.id] = new NestedList(menu);
        }
      });

      this.menus = {
        ...menus,
      };
    },
  },
  props: {},
  watch: {
    ['$route.fullPath']: {
      handler() {
        if (this.isMobile) {
          this.primaryMenuUpdate({
            active: false,
          });
        }
      },
    },
    user: {
      deep: true,
      handler() {
        this.loadMenus();
      },
    }
  }
}
</script>

<style scoped lang="scss">
$primary-menu-toggle-size: 56px;

#primary-menu {
  background: $theme-light-background-color;
  border-right: 1px solid transparent;
  bottom: auto;
  display: flex;
  flex-direction: column;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: $zindex-navigation;

  &.is-active {
    bottom: 0;
    width: $primary-menu-width-active;

    &.is-mobile {
      bottom: 60px;
      border-right: none !important;
      right: 0;
      position: fixed;
      width: 100%;

      .system {
        display: none;
      }
    }

    &.is-standalone {
      bottom: 80px;
    }
  }

  &.is-inactive {
    width: $primary-menu-width-inactive;

    &.is-mobile {
      width: $primary-menu-toggle-size;
    }

    &.is-full-screen {
      z-index: $zindex-inspector + 1;
    }

    &.is-floating {
      #primary-menu-header {
        border: none;
      }
    }
  }

  &.is-anchored {

    &.is-active {
      border-right: 1px solid $navigation-border-color;
    }

    &.is-inactive {
      border-right: 1px solid $navigation-border-color;
    }
  }

  &.is-floating {
    border: none;
    border-radius: $layout-floating-border-radius;
    left: $layout-floating-spacing;
    top: $layout-floating-spacing;

    &.is-active {
      bottom: $layout-floating-spacing;
    }
  }

  &.is-dark {
    background: $theme-dark-background-color;
    color: $white;
  }

  #primary-menu-header {
    border-bottom: 1px solid $navigation-border-color;
  }

  #account-switcher {
    border-top: 1px solid $navigation-border-color;
  }

  #primary-menu-toggle {
    align-items: center;
    display: flex;
    height: $primary-menu-toggle-size;
    justify-content: center;
    width: $primary-menu-toggle-size;
  }

  #brand {
    padding: 9px 15px;
  }

  #scrollable {
    flex: 1;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  #menus {
    padding: 15px;
  }

  .system {
    border-top: 1px solid $navigation-border-color;
    font-size: 0.8em;
    padding: 23px 15px;
    text-align: center;

    .version {
      background: $grey-dark;
      border-radius: 15px;
      display: inline-block;
      color: $white;
      padding: 2.5px 10px;
      text-align: center;
    }
  }
}
</style>
