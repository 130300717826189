<template>
  <div></div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'Logout',
  created() {
    this.handleLogOut();
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    handleLogOut() {
      this.logout().then(() => {

        window.app.snackbar({
          message: 'You\'ve been logged out',
        });

        this.$emit('close');

        window.location.replace('/auth/login');
      });
    },
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
