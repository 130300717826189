<template>
  <div id="map-layer-manager">
    <template v-for="group in groups">
      <div class="group-label">{{ group.label }}</div>
      <div class="group-layers">
        <template v-for="layer in group.layers">
          <MapLayerManagerRow :value="layer" v-if="!layer.parent"/>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import MapLayerManagerRow from '@/components-V2/Map/MapLayerManagerRow';

export default {
  name: 'MapLayerManager',
  components: {
    MapLayerManagerRow,
  },
  computed: {
    flatLayers () {
      return this.$store.state.mapLayer.layers;
    },
    groups () {
      return [
        {
          id: 'map',
          label: 'Map',
        },
      ].map((group) => {
        return {
          ...group,
          layers: this.nestedLayers.filter(layer => layer.group === group.id && !layer.hidden),
        };
      });
    },
    nestedLayers () {
      return this.flatLayers.filter(layer => !layer.parent).map((layer) => {
        return {
          ...layer,
          children: this.flatLayers.filter(_layer => _layer.parent !== undefined && _layer.parent === layer.id),
        };
      });
    },
  },
  data () {
    return {};
  },
};
</script>

<style scoped lang="scss">
#map-layer-manager {
  cursor: default;
  padding: 5px 10px;

  .group-label {
    color: $info;
    font-size: 0.8em;
    text-transform: uppercase;

    &:not(:first-child) {
      margin-top: 15px;
    }
  }
}
</style>
