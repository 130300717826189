<template>
  <div id="integration-show" class="is-padded" v-if="integration">
    <IntegrationPreview :integration="integration"/>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import IntegrationPreview from '@/components-V2/Integration/Preview';

export default {
  name: 'Show',
  components: {
    IntegrationPreview,
  },
  computed: {
    fullPath() {
      return this.$route.fullPath;
    },
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    pageTitle() {
      return `Integration: ${this.integration ? this.integration.name : ''}`;
    },
    integration() {
      return this.$route.params.integrationUUID ? this.$store.getters['integration/findBy'](this.$route.params.integrationUUID, 'uuid') : null;
    },
  },
  created() {
    this.loadIntegration();
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      show: 'integration/show'
    }),
    handleEdit() {
      const route = window.app.findRouteByName('integration/edit', [], {
        params: {
          integrationUUID: this.integration.uuid
        },
      });

      this.$router.push(route);
    },
    loadIntegration() {
      this.loading = true;
      return this.show({
        id: this.$route.params.integrationUUID,
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    fullPath() {
      this.loadIntegration();
    }
  }
}
</script>

<style scoped lang="scss">

</style>
