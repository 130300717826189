<template>
  <div class="domain-license-picker">

    <div class="has-margin-bottom">

      <Title v-if="pickerFor" class="has-text-centered">
        Picking License for: {{pickerFor.name}}
      </Title>

      <p class="has-text-centered subtle">
        Need more storage, less ingest, vice versa?<br/>
        Explore our different license types.
      </p>

      <div class="group-picker">
        <template v-for="group in groups">
          <div class="group" :class="{ 'is-active': group.id === selectedGroup }" @click="handleGroupChange(group)">
            {{ group.label }}
          </div>
        </template>
      </div>

    </div>

    <hr>

    <div class="columns">

      <div class="column is-half">

        <div class="licenses">
          <template v-for="product in groupProducts">

            <div class="license"
                 :class="{ 'is-active': product.uplinkEngineProductId === selectedProduct, 'is-dark': $themeDark }"
                 @click="handleProductChange(product)">

              <div class="name">
                {{ product.name }}
              </div>

              <div class="price">
                {{ formattedPrice(product.domainStripePrice.unitAmount) }}/mo
              </div>

            </div>

          </template>
        </div>

      </div>

      <div class="column">

        <div class="license-details" v-if="product">

          <div class="top">

            <Title class="has-text-centered">Device License</Title>

            <hr/>

            <div class="quotas has-text-centered">

              <div class="quota ingest">
                <BIcon icon="inbox-in"/>
                Data Ingest: {{ $numeral(product.quotas.ingestPerDay).format('0,0') }} / day
              </div>

              <div class="quota storage">
                <BIcon icon="database"/>
                Data Storage: {{ $daysToHumanReadable(product.quotas.storageDays) }}
              </div>

              <div class="quota storage">
                <BIcon icon="refresh"/>
                Renewal: Automatic
              </div>

              <div class="quota storage">
                <BIcon icon="ban"/>
                Cancel: Anytime
              </div>
            </div>

          </div>

          <div class="bottom">

            <Button primary block @click="handleProductSelected">
              {{ current ? 'Change License' : 'Select License' }}
            </Button>

          </div>

        </div>

      </div>

    </div>

    <template v-if="current">

      <hr>

      <div class="has-text-centered">
        <Button ghost class="has-text-danger" @click="handleProductRemove">
          Remove License
        </Button>
      </div>

    </template>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';
import _ from 'lodash';

export default {
  name: 'LicensePicker',
  computed: {
    current() {
      return this.currentStripePriceId ? this.unfilteredProducts.find(product => product.domainStripePrice.id === this.currentStripePriceId) : null;
    },
    groups() {
      return this.products ? _.uniq(this.products.map(p => p.group).filter(g => g)).map((id) => {
        return {
          id,
          label: this.$titleize(id),
        };
      }) : [];
    },
    groupProducts() {
      return this.selectedGroup ? this.products.filter(product => product.group === this.selectedGroup) : [];
    },
    products() {
      return this.unfilteredProducts
          .sort((a, b) => a.domainStripePrice.unitAmount < b.domainStripePrice.unitAmount ? 1 : -1)
          .sort((a, b) => a.group > b.group ? 1 : -1)
          .filter(product => product.enabled || product.uplinkEngineProductId === this.selectedProduct);
    },
    product() {
      return this.selectedProduct ? this.unfilteredProducts.find(product => product.uplinkEngineProductId === this.selectedProduct) : null;
    },
    unfilteredProducts() {
      return this.domain ? this.domain.products : [];
    },
  },
  created() {
    if (this.current) {
      this.selectedGroup = this.current.group;
      this.selectedProduct = this.current.uplinkEngineProductId;
    } else {
      this.selectedProduct = this.groupProducts[0].uplinkEngineProductId;
    }
  },
  data() {
    return {
      selectedGroup: 'balanced',
      selectedProduct: null,
    };
  },
  methods: {
    ...mapActions({}),
    handleGroupChange(group) {
      this.selectedGroup = group.id;
      this.selectedProduct = this.groupProducts[0].uplinkEngineProductId;
    },
    handleProductChange(product) {
      this.selectedProduct = product.uplinkEngineProductId;
    },
    handleProductRemove() {
      this.$emit('removed');
    },
    handleProductSelected() {
      this.$emit('selected', this.product);
    },
    formattedPrice(cents) {
      let price = cents / 100;
      return this.$numeral(price).format('$0,0.00');
    },
  },
  props: {
    currentStripePriceId: {
      required: false,
    },
    domain: {
      required: true,
    },
    pickerFor: {
      required: false,
    },
  },
}
</script>

<style scoped lang="scss">
.domain-license-picker {

  padding: 15px;

  .license-details {
    display: flex;
    flex-direction: column;
    height: 100%;

    .top {
      flex: 1;
      text-align: center;
    }
  }

  .licenses {

    .license {
      align-items: center;
      background: $grey-lighter;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      margin: 7.5px 0;
      padding: 15px;
      text-align: center;

      .name {
        text-align: left;
        white-space: nowrap;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.is-active {
        border: 1px solid $primary;
      }

      &.is-dark {
        background: $black-ter;

        &.is-active {
          border: 1px solid $primary;
        }
      }
    }
  }

  .quotas {
    display: inline-block;
    margin: 0 auto;

    .quota {
      font-size: 1.1em;
      margin: 15px 0;
      text-align: left;
    }
  }

  .group-picker {

    align-items: center;
    display: flex;
    justify-content: space-around;
    text-align: center;

    .group {
      border-radius: 3px;
      color: $primary;
      cursor: pointer;
      flex: 1;
      padding: 5px 15px;
      margin: 0 20px;
      text-align: center;

      &.is-active {
        border: 1px solid $primary;
      }
    }
  }
}
</style>
