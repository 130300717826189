import Index from '@/views/LorawanNetworkServer/Index';
import Form from '@/views/LorawanNetworkServer/Form';

const routes = [
  {
    name: 'lorawan-network-server/index',
    path: '/lorawan-network-server',
    component: Index,
    meta: {
      containerSize: 'container-medium',
      icon: 'server',
      middleware: ['auth'],
      title: 'LoRaWAN Network Servers',
    },
  },
  {
    name: 'lorawan-network-server/create',
    path: '/lorawan-network-server/create',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('lorawan-network-server/index');
      },
      containerSize: 'container-extra-small',
      icon: 'server',
      middleware: ['auth'],
      title: 'Create LoRaWAN Network Server',
    },
  },
  {
    name: 'lorawan-network-server/edit',
    path: '/lorawan-network-server/:lorawanNetworkServerUUID/edit',
    component: Form,
    meta: {
      backRoute: () => {
        return window.app.findRouteByName('lorawan-network-server/index');
      },
      containerSize: 'container-extra-small',
      icon: 'server',
      middleware: ['auth'],
      title: 'Edit LoRaWAN Network Server',
    },
  },
];

export default routes;
