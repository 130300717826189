import {
  Device as Model,
  DeviceService,
  ResourceStore,
} from '@/internal';

const service = new DeviceService();

const store = new ResourceStore({
  model: Model,
  actions: {
    chartData({commit}, payload = {}) {
      return service.chartData(payload.id, payload);
    },
    copyParameters({commit}, payload = {}) {
      return service.copyParameters(payload.id, payload).then(response => {
        return response.data.data;
      });
    },
    onboard({commit}, payload = {}) {
      return service.onboard(payload.code, payload);
    },
    share({commit}, payload = {}) {
      return service.share(payload.id, payload);
    },
    resubscribe({commit}, payload = {}) {
      return service.resubscribe(payload.id, payload);
    },
    subscribe({commit}, payload = {}) {
      return service.subscribe(payload.id, payload);
    },
    subscriptionLicenseSync({commit}, payload = {}) {
      return service.subscriptionLicenseSync(payload.id, payload).then((response) => {
        ResourceStore.processData(Model.vuexModuleKey, commit, response.data);
      });
    },
    unsubscribe({commit}, payload = {}) {
      return service.unsubscribe(payload.id, payload);
    },
  },
});

export default store.toObject();
