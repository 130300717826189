import {
  Inventory as Model,
  InventoryService,
  ResourceStore,
} from '@/internal';

const service = new InventoryService();

const store = new ResourceStore({
  model: Model,
  actions: {
    claim({commit}, payload = {}) {
      return service.claim(payload.id, payload);
    },
    unclaim({commit}, payload = {}) {
      return service.unclaim(payload.id, payload).then((response) => {
        ResourceStore.processData(Model.vuexModuleKey, commit, response.data);
      });
    },
  }
});

export default store.toObject();
