<template>
  <div id="onboarding" class="is-padded">
    <Instruction>
      <p>
        This feature allows unauthenticated users to onboard devices to your account.
      </p>
      <p>
        Great feature for allowing users to demo a product without needing to sign up for an account.
      </p>
    </Instruction>

    <Instruction type="is-warning">
      <p>Any usage incurred by these devices will be charged to your account.</p>
      <p>The user who onboarded the device will not be
        charged anything.</p>
    </Instruction>

    <hr />

    <OnboardingResourceList/>

  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex';

import OnboardingResourceList from '@/components-V2/Onboarding/ResourceList';

export default {
  name: 'Index',
  components: {
    OnboardingResourceList,
  },
  computed: {
    domainOnboardUrl() {
      return `${window.app.env.appBaseUrl}/onboard`;
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({}),
  },
  props: {},
}
</script>

<style scoped lang="scss">

</style>
