<template>
  <Page id="login-page">

    <div class="container container-extra-extra-small">
      <div class="columns">
        <div class="column">

          <Title>Log In</Title>

          <Box flat :black="$themeDark">
            <template v-if="form.fields.verificationUUID">
              <Verifier :form="form"
                        v-model="form.fields.verificationCode"
                        :loading="loading"
                        showControls
                        @verify="handleLogin"
                        @cancel="handleVerificationCodeCancel"
              />
            </template>

            <template v-else>

              <Field>
                <Label>Email</Label>
                <EmailInput id="email" v-model="form.fields.email" @keydown.native.enter="handleLogin"/>
                <FormError field="email" :form="form"/>
              </Field>

              <Field>
                <Label>Password</Label>
                <PasswordInput id="password" v-model="form.fields.password" @keydown.native.enter="handleLogin"/>
                <FormError field="password" :form="form"/>
              </Field>

              <div class="columns is-flex is-align-items-center">
                <div class="column">
                  <Field>
                    <BCheckbox v-model="remember">Remember?</BCheckbox>
                  </Field>
                </div>
                <div class="column has-text-right">
                  <router-link :to="forgotPasswordRoute">
                    Forgot Password?
                  </router-link>
                </div>
              </div>

              <div class="help has-text-centered has-margin-vertical">
                By logging in, you are agreeing to our<br/>
                <a :href="privacyPolicy" target="_blank">Privacy Policy</a> &
                <a :href="termsOfUse" target="_blank">Terms of Use</a>.
              </div>

              <div class="has-margin-top">

                <Button id="loginButton" primary block :loading="loading" @click="handleLogin">Log In</Button>

                <template v-if="$domainFeatures.public_sign_up.enabled">

                  <div class="has-text-centered is-padded" :class="{ 'has-text-light': $themeDark }">
                    - or -
                  </div>

                  <Button router-link outlined block :light="$themeDark" :to="`/auth/sign-up?flow=${flow}`">
                    Sign Up
                  </Button>

                </template>

              </div>

            </template>

          </Box>

        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import {mapActions} from 'vuex';

import {
  Form,
} from '@/internal';

let flow;

export default {
  name: 'Login',
  computed: {
    authenticated() {
      return this.$store.state.auth.authenticated;
    },
    flow() {
      return this.$route.query.flow || 'default';
    },
    forgotPasswordRoute() {
      return window.app.findRouteByName('auth/forgot-password');
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {

    flow = this.flow;

    if (this.authenticated) {
      this.$router.push('/');
    }
  },
  data() {
    return {
      form: new Form({
        email: window.app.storage.getItem('remember') || '',
        password: '',
        verificationKey: 'auth',
        verificationCode: null,
        verificationUUID: null,
      }),
      loading: false,
      privacyPolicy: process.env.VUE_APP_PRIVACY_POLICY,
      remember: window.app.storage.getItem('remember') ? true : false,
      termsOfUse: process.env.VUE_APP_TERMS_OF_USE,
    };
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    handleLogin() {

      if (this.remember) {
        window.app.storage.setItem('remember', this.form.fields.email);
      }

      this.loading = true;
      this.login({
        device: navigator.userAgent || navigator.vendor,
        ...this.form.fields,
      }).then(() => {

        // For some reason the computed property was changing before this could execute
        if (flow === 'private-label') {
          window.location.replace(`${process.env.VUE_APP_DOMAIN_MANAGEMENT_URL}/domain/create?t=${btoa(window.app.storage.getItem('token'))}`);
          return;
        }

        window.app.snackbar({
          message: 'Logged In',
        });

        const redirectRoute = window.app.storage.getItem('redirectRoute');

        if (redirectRoute) {
          window.app.d('Pushing redirectRoute', redirectRoute);
          this.$router.push(redirectRoute);
        } else if (window.app.auth.redirectRouteAfterLogin()) {
          window.app.d('Pushing redirectRouteAfterLogin', window.app.auth.redirectRouteAfterLogin());
          this.$router.push(window.app.auth.redirectRouteAfterLogin());
        } else {
          window.app.d('window.app.auth.redirectRouteAfterLogin not defined');
        }

      }).catch((error) => {

        if (window.app.verificationRequiredError(error)) {
          const verificationRequired = window.app.verificationRequiredError(error);
          this.form.fields.verificationUUID = verificationRequired ? verificationRequired.uuid : null;
        }

        this.form.recordErrors(error);
        window.app.snackbarError(error);

      }).finally(() => {
        this.loading = false;
      });
    },
    handleVerificationCodeCancel() {
      this.form.fields.verificationCode = null;
      this.form.fields.verificationUUID = null;
    },
  },
};
</script>

<style scoped lang="scss">
#login-page {
  .logo {
    height: 100px;
  }
}
</style>
