import {getLayerFromStorage} from '@/helpers';

export default class MapLayer {
  constructor (config = {}) {

    const savedLayer = config.id ? getLayerFromStorage(config.id) : null;

    this.id = config.id;
    this.alwaysEnabled = config.alwaysEnabled || false;
    this.children = config.children || [];
    this.color = savedLayer.color || config.color || null;
    this.colorEnd = savedLayer.colorEnd || config.colorEnd || null;
    this.colorLocked = config.colorLocked || false;
    this.cursorHover = config.cursorHover || null;
    this.enabled = savedLayer.enabled || config.enabled || false;
    this.group = config.group || null;
    this.hidden = config.hidden || false;
    this.label = config.label || '';
    this.onClick = config.onClick || null;
    this.parent = config.parent || null;
    this.requiresAuth = config.requiresAuth || false;
    this.source = config.source || null;
    this.syncedWith = config.syncedWith || null;
    this.terrain = config.terrain || null;
    this._layout = config.layout || null;
  }

  layout(source = {}) {
    return this._layout ? {
      id: this.id,
      source: this.id,
      ...this._layout(source),
    } : null;
  }
}
