<template>
  <div class="dashboard-preview">
    <Subtitle>{{dashboard.name}}</Subtitle>
  </div>
</template>

<script>
export default {
  name: 'Preview',
  computed: {},
  data() {
    return {};
  },
  props: {
    dashboard: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
