<template>
  <div class="phone-input"  :class="{ 'is-dark': $themeDark }">
    <VuePhoneNumberInput :value="formattedValue"
                         @input="handleInput"
                         @update="handleUpdate"
                         ref="phoneInput"
                         :only-countries="onlyCountries"
    />
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'PhoneInput',
  components: {
    VuePhoneNumberInput,
  },
  computed: {
    formattedValue() {
      return this.phonePayload ? (this.phonePayload.formatNational || this.phonePayload.phoneNumber) : this.value;
    },
  },
  created() {
    this.$emit('input', this.value);
  },
  data() {
    return {
      phonePayload: null,
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
    handleUpdate(data) {
      this.phonePayload = data;
      this.$emit('update', data);
    },
  },
  props: {
    value: {},
    onlyCountries: {
      default: null,
    },
  },
  watch: {
    value() {
      if (this.phonePayload) {
        this.$emit('input', this.phonePayload.formattedNumber || '');
      }
    },
  },
};
</script>

<style lang="scss">
.phone-input {
  &.is-dark {
    .vue-phone-number-input {
      input.country-selector__input {
        background: $dark;
        color: $white;
      }

      input.input-tel__input {
        background: $dark;
        color: $white;
      }
    }
  }
}
</style>
