<template>
  <div class="action-label">
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'ActionLabel',
  computed: {
    integration() {
      return this.action.config.integrationId ? this.$store.getters['integration/show'](this.action.config.integrationId) : null;
    },
    label() {
      switch (this.action.type) {
        case 'App\\Actions\\Notification':
          return `Send ${this.notificationMethodLabel(this.action.config.channel).label} Notification`;
        case 'App\\Actions\\Integration':
          return `Trigger Integration: ${this.integration.name}`;
        default:
          return 'Unknown Action Type';
      }
    },
  },
  data() {
    return {
      notificationChannelOptions: [
        {
          label: '- Select Notification Type -',
          value: null,
        },
        {
          label: 'Email',
          value: 'mail',
        },
        {
          label: 'Slack',
          value: 'slack',
        },
        {
          label: 'SMS',
          value: 'sms',
        },
      ],
    };
  },
  methods: {
    notificationMethodLabel(channel) {
      return this.notificationChannelOptions.find(option => option.value === channel);
    }
  },
  props: {
    action: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">
.action-label {

}
</style>
