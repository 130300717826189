import {
  ResourceService,
} from '@/internal';

export default class UplinkExportService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'uplink-export';
  }
}
