<template>
  <div class="is-padded-vertical">
    <Box flat :black="$themeDark">
      <Title>Unknown Product</Title>
      <p>
        Please contact customer support.
      </p>
    </Box>
  </div>
</template>

<script>
export default {
  name: 'UnknownProduct',
};
</script>

<style scoped lang="scss">

</style>
