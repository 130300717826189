<template>
  <div class="font-awesome-icon-input">
    <div class="preview-icon" v-if="value">
      <BIcon custom-class="fa-lg" :key="value" :icon="value" />
    </div>
    <BAutocomplete v-model="search"
                   :data="searchResults"
                   icon="search"
                   placeholder="Search..."
                   clearable
                   @select="handleSelect"
                   :class="{ 'is-dark': $themeDark }"
    >
      <template #empty>No results found</template>
      <template slot-scope="props">
        <BIcon custom-class="fa-xl" :icon="props.option" style="margin-right: 5px;"/>
        {{ props.option }}
      </template>
    </BAutocomplete>
  </div>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core';
import {fal} from '@fortawesome/pro-light-svg-icons'

library.add(fal);

export default {
  name: 'FontAwesomeIconInput',
  computed: {
    icons() {
      return Object.keys(library.definitions.fal) || [];
    },
    searchResults() {
      return this.search && this.search.length > 1 ? this.icons.filter(icon => icon.includes(this.search.toLowerCase())) : [];
    },
  },
  data() {
    return {
      search: '',
    };
  },
  methods: {
    handleSelect(value) {
      this.$emit('input', value);
    },
  },
  props: {
    value: {
      required: true,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.search !== this.value) {
          this.search = this.value;
        }
      },
    },
  }
}
</script>

<style lang="scss">
.font-awesome-icon-input {
  position: relative;

  .preview-icon {
    align-items: center;
    background: $grey-dark;
    border-radius: 4px;
    color: $white;
    display: flex;
    justify-content: center;
    left: 5px;
    position: absolute;
    bottom: 5px;
    top: 5px;
    width: 30px;
    z-index: 10;
  }

  .input {
    &::placeholder {
      color: $grey;
    }
  }
}
</style>
