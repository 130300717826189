var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'has-footer-content': _vm.hasFooterContent,
        [`is-${_vm.inspector.position}`]: !_vm.isFullScreen,
        'is-anchored': _vm.$layoutAnchored || _vm.isMobile,
        'is-dark': _vm.$themeDark,
        'is-floating': _vm.$layoutFloating && !_vm.isMobile,
        'is-full-screen': _vm.isFullScreen,
        'is-mobile': _vm.isMobile,
        'is-standalone': _vm.$isStandalone,
        'primary-menu-is-active': _vm.primaryMenu.active,
     },style:({
       height: _vm.isPositionBottom ? `${_vm.height}px` : 'auto',
       width: _vm.isPositionRight ? `${_vm.width}px` : 'auto',
      }),attrs:{"id":"inspector-container"}},[(_vm.isPositionBottom && !_vm.isMobile)?_c('div',{staticClass:"resizable-handle resizable-handle-height"},[_c('div',{staticClass:"resizable-handle-line resizable-handle-height-line"})]):_vm._e(),(_vm.isPositionRight && !_vm.isMobile)?_c('div',{staticClass:"resizable-handle resizable-handle-width"},[_c('div',{staticClass:"resizable-handle-line resizable-handle-width-line"})]):_vm._e(),(!_vm.hideControls)?_c('InspectorControls'):_vm._e(),(_vm.inspectorFooterMounted)?_c('div',{attrs:{"id":"inspector-wrapper"}},[_c('div',{attrs:{"id":"inspector"}},[_c('div',{class:`${_vm.container}`,attrs:{"id":"inspector-content"}},[_vm._t("default")],2)])]):_vm._e(),_c('InspectorFooter',{on:{"mounted":function($event){_vm.inspectorFooterMounted = true}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }