import {
  Alarm as Model,
  ResourceStore,
} from '@/internal';

const store = new ResourceStore({
  model: Model,
  actions: {
    saveMany({commit, dispatch}, payload = {}) {
      return service.saveMany(payload).then(response => {
        return ResourceStore.processData('alarm', commit, response.data);
      });
    },
  },
});

export default store.toObject();
